import axios from 'axios';
import {
  FETCH_USEFUL_REQUEST,
  FETCH_USEFUL_SUCCESS,
  FETCH_USEFUL_FAILURE
} from '../types/actionTypes';
import {
  USEFUL_API
} from '../services/api.service';

/**
 * @description fetch all useful
 */
export const usefulFetchData = (ownProps) => {
  return async dispatch => {
    dispatch({type: FETCH_USEFUL_REQUEST});
    try {
      const {data} = await axios.get(USEFUL_API);
      dispatch({type: FETCH_USEFUL_SUCCESS, payload: data.data.statistics});
      const usefulStatistics = [];
      for (let i = 0; i < data.data.statistics.length; i++) {
        usefulStatistics.push(data.data.statistics[i]);
      }
      localStorage.setItem('useful_categories', JSON.stringify(usefulStatistics));
    } catch (error) {
      dispatch({type: FETCH_USEFUL_FAILURE, payload: error});
    }
  }
}
