import React, {Component} from 'react';
import {withFormik} from 'formik';
import {connect} from 'react-redux';
import CloseModal from '../../../shared/CloseModal';
import NotesForm from '../NotesForm';
import {
  representativeNotesFetchData,
  representativeNotesPostData
} from '../../../../redux/actions/representativeAction';
import {removeSuccessMessage} from '../../../../redux/actions/messageAction';
import {noteSchema} from '../../../../shared/validation';
import ModalSuccess from '../../../shared/ModalSuccess';

/**
 * @description Component Class for AddAssignment new object
 */
class AddNotesForm extends Component {
  componentWillReceiveProps(nextProps) {
    if (nextProps.files.fileToken && nextProps.files.fileToken !== this.props.files.fileToken) {
      this.props.setFieldValue('images', [...this.props.values.images, ...nextProps.files.fileToken])
    }
  }

  render() {
    return (
      <div className="modal-custom">
        <div className="container-fluid">
          <div className="row">
            <div className="col-xl-4 offset-xl-4">
              <div className="modal-custom-inner">
                <div className="modal-custom-header d-flex align-items-center justify-content-between">
                  <h4>
                    Qeyd yarat
                  </h4>
                  <CloseModal modal="showNotesModal" />
                </div>
                <NotesForm formProps = {this.props} />
                <section className="whitebox-body">
                  {this.props.notes.success ? <ModalSuccess text="Qeyd uğurla əlavə olundu" /> : null }
                </section>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapPropsToValues = (props) => {
  return {
    title: '',
    address: '',
    comment: '',
    images: []
  }
}

const AddNotes = withFormik({
  enableReinitialize: false,
  mapPropsToValues,
  validationSchema: noteSchema,
  validateOnBlur: true,
  validateOnChange: true,
  handleSubmit: (values, {props, resetForm, setSubmitting}) => {
    props.addNote(values).then(() => props.fetchRepresentatives())

    setTimeout(() => {
      props.removeSuccess();
    }, 4000);
  }
})(AddNotesForm);

const mapDispatchToProps = (dispatch, ownProps) => ({
  fetchRepresentatives: () => dispatch(representativeNotesFetchData(ownProps)),
  addNote: notesData => dispatch(representativeNotesPostData(notesData, ownProps)),
  removeSuccess: () => dispatch(removeSuccessMessage())
})

const mapStateToProps = state => {
  return {
    files: state.fileReducer,
    notes: state.representativeReducer
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(AddNotes);
