import InputError from '../components/dashboard/errors/InputError';
import React from 'react';

/**
 * @param array
 * @param size
 * @returns {*[]|({length}|*)[]|*}
 */
export const arrayChunk = (array, size) => {
  if (!array) return [];
  const firstChunk = array.slice(0, size); // create the first chunk of the given array
  if (!firstChunk.length) {
    return array; // this is the base case to terminal the recursive
  }
  return [firstChunk].concat(arrayChunk(array.slice(size, array.length), size));
}

/**
 *
 * @param name
 * @param apiProps
 * @param localProps
 * @returns {JSX.Element}
 */
export const handleInputError = (name, apiProps, localProps) => {
  let apiErrors = apiProps && apiProps.error ? apiProps.data : [];
  let {touched, errors} = localProps;

  if (touched && errors && touched[name] && errors[name]) {
    apiErrors[name] = [errors[name]];
  }

  return <InputError errors={apiErrors} name={name}/>
}

/**
 *
 * @returns {string}
 * @param str
 */
export const capitalize = (str) => {
  return str.charAt(0).toUpperCase() + str.slice(1);
}

