import React from 'react';

/**
 * @description Component Class for modal success
 */
const RightBanner = (props) => {
  return props.banners ? props.banners.map((banner, i) => {
    return (
      <div className="right-banners d-none d-lg-block" key={i}>
        <a target="_blank" rel="noopener noreferrer" href={banner.url}>
          <img className="right-banner" src={`${process.env.REACT_APP_SERVER}/storage/` + banner.image.path} alt="banner"/>
        </a>
      </div>
    )
  }) : null;
}
export default RightBanner;
