import axios from 'axios';
import {
  LOGIN_SUCCESS,
  LOGIN_FAILURE,
  LOGOUT_SUCCESS,
  LOGOUT_FAILURE,
  LOGIN_REQUEST,
  FETCH_USERS_REQUEST,
  FETCH_USERS_SUCCESS,
  FETCH_USERS_FAILURE,
  SIGN_UP_REQUEST,
  SIGN_UP_SUCCESS,
  SIGN_UP_FAILURE
} from '../types/actionTypes';
import {login, logout} from '../services/auth.service';
import {LOGIN_API, LOGOUT_API, FETCH_USERS_API, SIGN_UP_API} from '../services/api.service';

/**
 * @description redux action for users to LOGIN
 * @param  {Object} user
 * @param  {Object} ownProps
 */
export const userLoginFetch = (user, ownProps) => {
  return async dispatch => {
    dispatch({type: LOGIN_REQUEST});
    try {
      const {data} = await axios.post(LOGIN_API, {
        username: user.username,
        password: user.password,
        recaptcha: user.recaptcha,
        grant_type: process.env.REACT_APP_GRANT_TYPE
      });
      dispatch({type: LOGIN_SUCCESS, payload: data});
      login(data.data.token, data.data.user);
      window.location.reload()

    //  ownProps.ownProps.push('/dashboard');
    
    } catch (error) {
      // dispatch({type: LOGIN_FAILURE, payload: error.response.data});
      console.error(error);
      // window.location.reload()
    }
  }
}

/**
 * @description redux action for users to REGISTER
 * @param userData
 * @param  {Object} ownProps
 */
export const userSignUpFetch = (userData, ownProps) => {
  return async dispatch => {
    dispatch({type: SIGN_UP_REQUEST});
    try {
      const {data} = await axios.post(SIGN_UP_API, userData);
      dispatch({type: SIGN_UP_SUCCESS, payload: data});
      ownProps.history.push('/login');
    } catch (error) {
      // dispatch({type: SIGN_UP_FAILURE, payload: error.response.data});
      console.log(error);
    }
  }
}

/**
 * @description redux action for users to LOGOUT
 * @param ownProps
 */
export const userLogoutFetch = (ownProps) => {
  return async dispatch => {
    try {
      const {data} = await axios.get(LOGOUT_API);
      dispatch({type: LOGOUT_SUCCESS, payload: data});
      logout();
      ownProps.history.push('/dashboard');
      window.location.reload();
    } catch (error) {
      dispatch({type: LOGOUT_FAILURE, payload: error});
    }
  }
}

/**
 * @description fetch all user list
 */
export const usersFetchData = (role = null) => {
  return async dispatch => {
    dispatch({type: FETCH_USERS_REQUEST});
    try {
      const {data} = await axios.get(FETCH_USERS_API, {
        params: {
          role: role
        }
      });
      dispatch({type: FETCH_USERS_SUCCESS, payload: data.data.users});
    } catch (error) {
      dispatch({type: FETCH_USERS_FAILURE, payload: error});
    }
  }
}
