import React, {Component} from 'react';
import {withFormik} from 'formik';
import AssignmentForm from '../AssignmentForm';
import {taskPostData} from '../../../../redux/actions/taskAction';
import {connect} from 'react-redux';
import CloseModal from '../../../shared/CloseModal';
import ModalSuccess from '../../../shared/ModalSuccess';
import {taskSchema} from '../../../../shared/validation';
import {removeSuccessMessage} from '../../../../redux/actions/messageAction';

/**
 * @description Component Class for AddAssignment new object
 */
class AddAssignmentForm extends Component {
  state = {
    employees:[]
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.files.fileToken && nextProps.files.fileToken !== this.props.files.fileToken) {
      this.props.setFieldValue('images', [...this.props.values.images, ...nextProps.files.fileToken])
    }
  }

  render() {
    return (
      <div className="modal-custom">
        <div className="container-fluid">
          <div className="row">
            <div className="col-xl-8 offset-xl-2">
              <div className="modal-custom-inner">
                <div className="modal-custom-header d-flex align-items-center justify-content-between">
                  <h4>
                    Tapşırıq yarat
                  </h4>
                  <CloseModal tooltip={true} modal="showAssignmentModal" />
                </div>
                <AssignmentForm formProps = {this.props} submitText={'Tapşırıq yarat'}/>

                {this.props.tasks.success ? <ModalSuccess text="Yeni Tapşırıq uğurla yaradıldı" /> : null }
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapPropsToValues = (props) => {
  return {
    employees: [],
    due_date: '',
    due_time: '',
    description: '',
    images: []
  }
}

const AddAssignment = withFormik({
  enableReinitialize: false,
  mapPropsToValues,
  validationSchema: taskSchema,
  validateOnBlur: true,
  validateOnChange: true,
  handleSubmit: (values, {props, resetForm, setSubmitting}) => {
    props.addTask(values);

    setTimeout(() => {
      props.removeSuccess();
    }, 4000);
  }
})(AddAssignmentForm);

const mapDispatchToProps = (dispatch, ownProps) => ({
  addTask: taskData => dispatch(taskPostData(taskData, ownProps)),
  removeSuccess: () => dispatch(removeSuccessMessage())
})

const mapStateToProps = state => {
  return {
    files: state.fileReducer,
    tasks: state.taskReducer
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(AddAssignment);

