
import React, {Component} from 'react';
import {connect} from 'react-redux';
import InputMask from 'react-input-mask';
import {vacancyCategoryFetchData, cityFetchData, experienceFetchData, educationFetchData, resumePostData} from '../../../../redux/actions/socialAction';
import {removeSuccessMessage} from '../../../../redux/actions/messageAction';
import {isAuthenticated} from '../../../../redux/services/auth.service';
import {Redirect} from 'react-router-dom';
import {filePostFetch} from '../../../../redux/actions/fileAction';
import ButtonLoader from '../../../loader/ButtonLoader';

/**
 * @description Apply component
 */
class ResumeForm extends Component {
  state = {
    files: [],
    salaries: [
      {
        value: 100,
        label: 100
      },
      {
        value: 200,
        label: 200
      },
      {
        value: 300,
        label: 300
      },
      {
        value: 400,
        label: 400
      },
      {
        value: 500,
        label: 500
      },
      {
        value: 600,
        label: 600
      },
      {
        value: 700,
        label: 700
      },
      {
        value: 800,
        label: 800
      },
      {
        value: 900,
        label: 900
      },
      {
        value: 1000,
        label: 1000
      },
      {
        value: 1100,
        label: 1100
      },
      {
        value: 1200,
        label: 1200
      },
      {
        value: 1300,
        label: 1300
      },
      {
        value: 1400,
        label: 1400
      },
      {
        value: 1500,
        label: 1500
      },
      {
        value: 1600,
        label: 1600
      },
      {
        value: 1700,
        label: 1700
      },
      {
        value: 1800,
        label: 1800
      },
      {
        value: 1900,
        label: 1900
      },
      {
        value: 2000,
        label: 2000
      },
      {
        value: 2100,
        label: 2100
      },
      {
        value: 2200,
        label: 2200
      },
      {
        value: 2300,
        label: 2300
      },
      {
        value: 2400,
        label: 2400
      },
      {
        value: 2500,
        label: 2500
      },
      {
        value: 2600,
        label: 2600
      },
      {
        value: 2700,
        label: 2700
      },
      {
        value: 2800,
        label: 2800
      },
      {
        value: 2900,
        label: 2900
      },
      {
        value: 3000,
        label: 3000
      },
      {
        value: 3100,
        label: 3100
      },
      {
        value: 3200,
        label: 3200
      },
      {
        value: 3300,
        label: 3300
      },
      {
        value: 3400,
        label: 3400
      },
      {
        value: 3500,
        label: 3500
      },
      {
        value: 3600,
        label: 3600
      },
      {
        value: 3700,
        label: 3700
      },
      {
        value: 3800,
        label: 3800
      },
      {
        value: 3900,
        label: 3900
      },
      {
        value: 4000,
        label: 4000
      },
      {
        value: 5000,
        label: 5000
      },
      {
        value: 6000,
        label: 6000
      },
      {
        value: 7000,
        label: 7000
      },
      {
        value: 8000,
        label: 8000
      },
      {
        value: 9000,
        label: 9000
      },
      {
        value: 10000,
        label: 10000
      }
    ],
    ages: [
      {
        value: 18
      },
      {
        value: 19
      },
      {
        value: 20
      },
      {
        value: 21
      },
      {
        value: 22
      },
      {
        value: 23
      },
      {
        value: 24
      },
      {
        value: 25
      },
      {
        value: 26
      },
      {
        value: 27
      },
      {
        value: 28
      },
      {
        value: 29
      },
      {
        value: 30
      },
      {
        value: 31
      },
      {
        value: 32
      },
      {
        value: 33
      },
      {
        value: 34
      },
      {
        value: 35
      },
      {
        value: 36
      },
      {
        value: 37
      },
      {
        value: 38
      },
      {
        value: 39
      },
      {
        value: 40
      },
      {
        value: 41
      },
      {
        value: 42
      },
      {
        value: 43
      },
      {
        value: 44
      },
      {
        value: 45
      },
      {
        value: 46
      },
      {
        value: 47
      },
      {
        value: 48
      },
      {
        value: 49
      },
      {
        value: 50
      },
      {
        value: 51
      },
      {
        value: 52
      },
      {
        value: 53
      },
      {
        value: 54
      },
      {
        value: 55
      },
      {
        value: 56
      },
      {
        value: 57
      },
      {
        value: 58
      },
      {
        value: 59
      },
      {
        value: 60
      },
      {
        value: 61
      },
      {
        value: 62
      },
      {
        value: 63
      }
    ]
  }
  componentDidMount() {
    this.props.fetchCategories();
    this.props.fetchCities();
    this.props.fetchExperience();
    this.props.fetchEducation();
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.files.fileToken && nextProps.files.fileToken !== this.props.files.fileToken) {
      this.props.formProps.setFieldValue('image', [...nextProps.files.fileToken])
    }
  }

  /**
   * @description file upload
   * @param {Object} e
   * @memberOf ObjectForm
   */
  setFile = (e) => {
    const fileData = new FormData();
    for (let i = 0; i < e.currentTarget.files.length; i++) {
      fileData.append('files[]', e.currentTarget.files[i])
    }
    this.setState({
      fileData
    })
    this.props.uploadFile(fileData)
  }

  /**
   * @description file delete
   * @memberOf AppealForm
   * @param token
   */
  removeFiles = (token) => {
    const editedImages = this.props.formProps.values.image.filter((file) => file.token !== token);
    this.props.formProps.setFieldValue('image', editedImages);
  }

  render() {
    if (!isAuthenticated()) {
      return <Redirect to='/login' />
    }
    const {values, handleBlur, handleChange, handleSubmit, errors, touched} = this.props.formProps;

    const categories = this.props.social.categories ? this.props.social.categories.map(category => {
      return (
        <option key={category.id} value={category.id}>{category.name}</option>
      )
    }) : null

    const cities = this.props.social.cities ? this.props.social.cities.map(city => {
      return (
        <option key={city.id} value={city.id}>{city.name}</option>
      )
    }) : null

    const educations = this.props.social.education ? this.props.social.education.map(edu => {
      return (
        <option key={edu.id} value={edu.id}>{edu.name}</option>
      )
    }) : null

    const experiences = this.props.social.experiences ? this.props.social.experiences.map(e => {
      return (
        <option key={e.id} value={e.id}>{e.range}</option>
      )
    }) : null

    const salaries = this.state.salaries.map(salary => {
      return (
        <option key={salary.value} value={salary.value}>{salary.label}</option>
      )
    });

    const fileNames = values.image ? values.image.map((file, index) => {
      return (
        <p className="file-item mr-3 mb-4" key={index}>
          <img src={`${process.env.REACT_APP_SERVER}/storage/` + file.path} alt={file.path}/>
          <button
            className="btn-remove"
            onClick={() => this.removeFiles(file.token)}
          >x
          </button>
        </p>
      )
    }) : ''

    const ages = this.state.ages.map(age => {
      return (
        <option key={age.value} value={age.value}>{age.value}</option>
      )
    })
    return (
      <div className="add-form">
        <div className="row">

          <div className="col-lg-6 col-xl-4">
            <div className="form-group form-group-custom">
              <label htmlFor="fullname">Ad, soyad</label>
              <input
                type="text"
                name="fullname"
                className='form-control form-control-custom'
                placeholder="Ad, Soyad"
                value={values.fullname}
                onChange={handleChange}
                onBlur={handleBlur}
              />
              <p className="error">
                {(this.props.social.resumes && this.props.social.resumes.error && this.props.social.resumes.data.fullname)
                  ? this.props.social.resumes.data.fullname.map(error => <span key={error}>{error}</span>)
                  : null}

                {(touched.fullname && errors.fullname)
                  ? <span>{errors.fullname}</span>
                  : null}
              </p>
            </div>

            <div className="form-group form-group-custom">
              <label htmlFor="vacancy_category_id">Kateqoriya</label>
              <select
                name="vacancy_category_id"
                className='form-control form-control-custom selectbox'
                value={values.vacancy_category_id}
                onChange={(e) => { e.persist(); handleChange(e); }}
                onBlur={handleBlur}

              >
                <option disabled value="">Kateqoriya seçin.</option>
                {categories}
              </select>

              <p className="error">
                {(this.props.social.resumes && this.props.social.resumes.error && this.props.social.resumes.data.vacancy_category_id)
                  ? this.props.social.resumes.data.vacancy_category_id.map(error => <span key={error}>{error}</span>)
                  : null}

                {(touched.vacancy_category_id && errors.vacancy_category_id)
                  ? <span>{errors.vacancy_category_id}</span>
                  : null}
              </p>
            </div>

            <div className="form-group form-group-custom">
              <label htmlFor="profession">Vəzifə</label>
              <input
                type="text"
                name="profession"
                className='form-control form-control-custom'
                placeholder="Vəzifə"
                value={values.profession}
                onChange={handleChange}
                onBlur={handleBlur}
              />

              <p className="error">
                {(this.props.social.resumes && this.props.social.resumes.error && this.props.social.resumes.data.profession)
                  ? this.props.social.resumes.data.profession.map(error => <span key={error}>{error}</span>)
                  : null}

                {(touched.profession && errors.profession)
                  ? <span>{errors.profession}</span>
                  : null}
              </p>
            </div>

            <div className="form-group form-group-custom">
              <label htmlFor="gender">Cins</label>
              <select
                name="gender"
                className='form-control form-control-custom selectbox'
                value={values.gender}
                onChange={(e) => { e.persist(); handleChange(e); }}
                onBlur={handleBlur}

              >
                <option disabled value="">Cins seçin.</option>
                <option value="0">Kişi</option>
                <option value="1">Qadın</option>
              </select>

              <p className="error">
                {(this.props.social.resumes && this.props.social.resumes.error && this.props.social.resumes.data.gender)
                  ? this.props.social.resumes.data.gender.map(error => <span key={error}>{error}</span>)
                  : null}

                {(touched.gender && errors.gender)
                  ? <span>{errors.gender}</span>
                  : null}
              </p>
            </div>

            <div className="row">
              <div className="form-group form-group-custom col-xl-6">
                <label htmlFor="city_id">Şəhər</label>
                <select
                  name="city_id"
                  className='form-control form-control-custom selectbox'
                  value={values.city_id}
                  onChange={(e) => { e.persist(); handleChange(e); }}
                  onBlur={handleBlur}

                >
                  <option disabled value="">Şəhər seçin.</option>
                  {cities}
                </select>

                <p className="error">
                  {(this.props.social.resumes && this.props.social.resumes.error && this.props.social.resumes.data.city_id)
                    ? this.props.social.resumes.data.city_id.map(error => <span key={error}>{error}</span>)
                    : null}

                  {(touched.city_id && errors.city_id)
                    ? <span>{errors.city_id}</span>
                    : null}
                </p>
              </div>

              <div className="form-group form-group-custom col-xl-6">
                <label htmlFor="age">Yaş</label>
                <select
                  name="age"
                  className='form-control form-control-custom selectbox'
                  value={values.age}
                  onChange={(e) => { e.persist(); handleChange(e); }}
                  onBlur={handleBlur}

                >
                  <option disabled value="">Yaş seçin.</option>
                  {ages}
                </select>

                <p className="error">
                  {(this.props.social.resumes && this.props.social.resumes.error && this.props.social.resumes.data.age)
                    ? this.props.social.resumes.data.age.map(error => <span key={error}>{error}</span>)
                    : null}

                  {(touched.age && errors.age)
                    ? <span>{errors.age}</span>
                    : null}
                </p>
              </div>
            </div>

            <div className="row">
              <div className="form-group form-group-custom col-xl-6">
                <label htmlFor="education_id">Education</label>
                <select
                  name="education_id"
                  className='form-control form-control-custom selectbox'
                  value={values.education_id}
                  onChange={(e) => { e.persist(); handleChange(e); }}
                  onBlur={handleBlur}

                >
                  <option disabled value="">Təhsil seçin.</option>
                  {educations}
                </select>

                <p className="error">
                  {(this.props.social.resumes && this.props.social.resumes.error && this.props.social.resumes.data.education_id)
                    ? this.props.social.resumes.data.education_id.map(error => <span key={error}>{error}</span>)
                    : null}

                  {(touched.education_id && errors.education_id)
                    ? <span>{errors.education_id}</span>
                    : null}
                </p>
              </div>

              <div className="form-group form-group-custom col-xl-6">
                <label htmlFor="experience_id">Təcrübə</label>
                <select
                  name="experience_id"
                  className='form-control form-control-custom selectbox'
                  value={values.experience_id}
                  onChange={(e) => { e.persist(); handleChange(e); }}
                  onBlur={handleBlur}

                >
                  <option disabled value="">Təcrübə seçin.</option>
                  {experiences}
                </select>

                <p className="error">
                  {(this.props.social.resumes && this.props.social.resumes.error && this.props.social.resumes.data.experience_id)
                    ? this.props.social.resumes.data.experience_id.map(error => <span key={error}>{error}</span>)
                    : null}

                  {(touched.experience_id && errors.experience_id)
                    ? <span>{errors.experience_id}</span>
                    : null}
                </p>
              </div>
            </div>

            <div className="row">
              <div className="col-xl-12">
                <label htmlFor="salary">Maaş</label>
              </div>
              <div className="form-group form-group-custom col-xl-6">
                <select
                  name="min_salary"
                  className='form-control form-control-custom selectbox'
                  value={values.min_salary}
                  onChange={(e) => { e.persist(); handleChange(e); }}
                  onBlur={handleBlur}
                >
                  <option disabled value=''>min</option>
                  {salaries}
                </select>

                <p className="error">
                  {(this.props.social.resumes && this.props.social.resumes.error && this.props.social.resumes.data.min_salary)
                    ? this.props.social.resumes.data.min_salary.map(error => <span key={error}>{error}</span>)
                    : null}

                  {(touched.min_salary && errors.min_salary)
                    ? <span>{errors.min_salary}</span>
                    : null}
                </p>
              </div>

              <div className="form-group form-group-custom col-xl-6">
                <select
                  name="max_salary"
                  className='form-control form-control-custom selectbox'
                  value={values.max_salary}
                  onChange={(e) => { e.persist(); handleChange(e); }}
                  onBlur={handleBlur}
                >
                  <option disabled value=''>max</option>
                  {salaries}
                </select>

                <p className="error">
                  {(this.props.social.resumes && this.props.social.resumes.error && this.props.social.resumes.data.max_salary)
                    ? this.props.social.resumes.data.max_salary.map(error => <span key={error}>{error}</span>)
                    : null}

                  {(touched.max_salary && errors.max_salary)
                    ? <span>{errors.max_salary}</span>
                    : null}
                </p>
              </div>
            </div>

            <div className="form-group form-group-custom">
              <label htmlFor="contact_number">Əlaqə nömrəsi</label>
              <InputMask
                mask="9999999999"
                className='form-control form-control-custom'
                maskChar={null}
                name="contact_number"
                value={values.contact_number}
                onChange={handleChange}
                onBlur={handleBlur}
                beforeMaskedValueChange={this.beforeMaskedValueChange}
                placeholder="xxxxxxxxxx"
              />

              <p className="error">
                {(this.props.social.resumes && this.props.social.resumes.error && this.props.social.resumes.data.contact_number)
                  ? this.props.social.resumes.data.contact_number.map(error => <span key={error}>{error}</span>)
                  : null}

                {(touched.contact_number && errors.contact_number)
                  ? <span>{errors.contact_number}</span>
                  : null}
              </p>
            </div>

          </div>

          <div className="col-lg-6 col-xl-4">

            <div className="form-group form-group-custom">
              <label htmlFor="email">E-mail</label>
              <input
                type="email"
                name="email"
                className='form-control form-control-custom'
                value={values.email}
                onChange={handleChange}
                onBlur={handleBlur}
              />
              <p className="error">
                {(this.props.social.resumes && this.props.social.resumes.error && this.props.social.resumes.data.email)
                  ? this.props.social.resumes.data.email.map(error => <span key={error}>{error}</span>)
                  : null}

                {(touched.email && errors.email)
                  ? <span>{errors.email}</span>
                  : null}
              </p>
            </div>

            <div className="form-group form-group-custom">
              <label htmlFor="ability">Bacarıqlar</label>
              <textarea
                name="ability"
                className='form-control form-control-custom'
                value={values.ability}
                onChange={handleChange}
                onBlur={handleBlur}
                maxLength="255"
              />

              <p className="error">
                {(this.props.social.resumes && this.props.social.resumes.error && this.props.social.resumes.data.ability)
                  ? this.props.social.resumes.data.ability.map(error => <span key={error}>{error}</span>)
                  : null}

                {(touched.ability && errors.ability)
                  ? <span>{errors.ability}</span>
                  : null}
              </p>
            </div>

            <div className="form-group form-group-custom">
              <label htmlFor="more_info">Əlavə məlumat</label>
              <textarea
                name="more_info"
                className='form-control form-control-custom'
                value={values.more_info}
                onChange={handleChange}
                onBlur={handleBlur}
                maxLength="255"
              />
            </div>

            <div className="row">
              <div className="col-md-6 form-group form-group-custom">
                <div className="file-name d-flex justify-content-start flex-wrap">
                  {fileNames}
                </div>
              </div>
              <div className="col-md-6 form-group form-group-custom over-hide">
                <div className="btn file-button">Şəkil yüklə</div>
                <input
                  name="file"
                  type="file"
                  placeholder="Şəkil yüklə"
                  // value={values.images}
                  onChange={(e) => { this.setFile(e) }}
                  onBlur={handleBlur}
                />
              </div>
            </div>

          </div>

          <div className="col-12">
            <div className="row">
              <div className="col-lg-6 col-xl-4">
                <div className="form-group form-group-custom">
                  <button
                    type="button"
                    onClick={() => handleSubmit(values)}
                    className="btn btn-theme w-100"
                    disabled={this.props.social.isFetching ? true : null}
                  >
                    {this.props.files.isFetching ? <ButtonLoader /> : <span>{this.props.submitText}</span>}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  fetchCategories: () => dispatch(vacancyCategoryFetchData()),
  fetchCities: () => dispatch(cityFetchData()),
  fetchExperience: () => dispatch(experienceFetchData()),
  fetchEducation: () => dispatch(educationFetchData()),
  addResume: (resumeData) => dispatch(resumePostData(resumeData)),
  uploadFile: file => dispatch(filePostFetch(file)),
  removeSuccess: () => dispatch(removeSuccessMessage())
})

const mapStateToProps = state => {
  return {
    files: state.fileReducer,
    social: state.socialReducer
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ResumeForm);
