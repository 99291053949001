import React, {Component} from 'react';
import {ModalContext} from '../../context/context';

/**
 * @description Component Class for close modal
 */
class CloseModal extends Component {
  static contextType = ModalContext;

  /**
   * @description close related modal
   * @memberOf CloseModal
   */
  closeModal = () => {
    switch (this.props.modal) {
    case 'showObjectModal':
      this.context.showObjectModal(false);
      break;

    case 'showEditObjectModal':
      this.context.showEditObjectModal(false);
      break;

    case 'showAssignmentModal':
      this.context.showAssignmentModal(false);
      break;

    case 'showDetailObjectMap':
      this.context.showDetailObjectMap(false);
      break;

    case 'showAssignmentEditModal':
      this.context.showAssignmentEditModal(false);
      break;

    case 'showAppealEditModal':
      this.context.showAppealEditModal(false);
      break;
    case 'showNotesModal':
      this.context.showNotesModal(false);
      break;
    case 'showForwardModal':
      this.context.showForwardModal(false);
      break;
    case 'showSuccessModal':
      this.context.showSuccessModal(false);
      break;
    case 'showEditAboutModal':
      this.context.showEditAboutModal(false);
      break;

    case 'showLawDetailModal':
      this.context.showLawDetailModal(false);
      break;

    case 'showEventDetailModal':
      this.context.showEventDetailModal(false);
      break;
    case 'showAddTransportModal':
      this.context.showAddTransportModal(false);
      break;
    case 'showEditTransportModal':
      this.context.showEditTransportModal(false);
      break;
    case 'showTransportDetailModal':
      this.context.showTransportDetailModal(false);
      break;
    case 'showEditKarabakhAboutModal':
      this.context.showEditKarabakhAboutModal(false);
      break;
    case 'showEditKarabakhVictoryModal':
      this.context.showEditKarabakhVictoryModal(false);
      break;

    default:
      break;
    }
  }

  render() {
    return  (
      <span
        onClick={this.closeModal}
        className="close-modal"
        data-tooltip={this.props.tooltip ? 'Bağla' : null}
      >
        <i className="icon icon-close-modal" />
      </span>
    );
  }
}

export default CloseModal;
