import React, {Component} from 'react';
import {withFormik} from 'formik';
import {connect} from 'react-redux';
import AppealForm from '../AppealForm';
import {appealPostData} from '../../../../redux/actions/appealAction';
import {removeSuccessMessage} from '../../../../redux/actions/messageAction';
import ModalSuccess from '../../../shared/ModalSuccess';
import {appealSchema} from '../../../../shared/validation';
import {isAuthenticated} from '../../../../redux/services/auth.service';
import {Redirect} from 'react-router-dom';

/**
 * @description Component Class for AddAppealForm new object
 */
class AddAppealForm extends Component {
  componentWillReceiveProps(nextProps) {
    if (nextProps.files.fileToken && nextProps.files.fileToken !== this.props.files.fileToken) {
      this.props.setFieldValue('images', [...this.props.values.images, ...nextProps.files.fileToken])
    }
  }

  render() {
    if (!isAuthenticated()) {
      return <Redirect to='/login' />
    }
    return (
      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-12">
            <div className="whitebox">
              <div className="modal-custom-inner">
                <header className="whitebox-header bordered">

                  <div className="whitebox-title ">
                    <h4>
                      Müraciət əlavə et
                    </h4>
                  </div>

                </header>
                <section className="whitebox-body">
                  <AppealForm formProps={this.props}/>
                  {this.props.appeals.success ? <ModalSuccess text="Müraciətiniz uğurla qeydə alındı" /> : null }
                </section>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapPropsToValues = (props) => {
  return {
    title: '',
    appeal_category_id: '',
    phone: '',
    text: '',
    address: '',
    images: []
  }
}
const AddAppeal = withFormik({
  enableReinitialize: false,
  mapPropsToValues,
  validationSchema: appealSchema,
  validateOnBlur: true,
  validateOnChange: true,
  handleSubmit: (values, {props, setSubmitting, resetForm}) => {
    props.addAppeal(values);
    setTimeout(() => {
      props.removeSuccess();
    }, 4000);
  }
})(AddAppealForm);

const mapDispatchToProps = (dispatch, ownProps) => ({
  addAppeal: (paramId) => dispatch(appealPostData(paramId, ownProps)),
  removeSuccess: () => dispatch(removeSuccessMessage())
})

const mapStateToProps = state => {
  return {
    files: state.fileReducer,
    appeals: state.appealReducer
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(AddAppeal);
