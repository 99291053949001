import {
  FETCH_EVENTS_REQUEST,
  FETCH_EVENTS_SUCCESS,
  FETCH_EVENTS_FAILURE,
  ADD_EVENT_REQUEST,
  ADD_EVENT_SUCCESS,
  ADD_EVENT_FAILURE,
  EDIT_EVENTS_REQUEST,
  EDIT_EVENTS_SUCCESS,
  EDIT_EVENTS_FAILURE,
  REMOVE_SUCCESS_MESSAGE,
  FETCH_EVENT_DETAIL_REQUEST,
  FETCH_EVENT_DETAIL_SUCCESS,
  FETCH_EVENT_DETAIL_FAILURE,
  DELETE_EVENTS_REQUEST,
  DELETE_EVENTS_SUCCESS,
  DELETE_EVENTS_FAILURE
} from '../types/actionTypes';

/**
 * @description initial state for eventReducer
 */
const initialState = {};

/**
 * @description updates state depending on action data
 * @param {Object} state = initialState
 * @param {string} type = action.type
 * @param {} payload
 * @returns return state
 */
export default function eventReducer(state = initialState, {type, payload}) {
  switch (type) {
  case ADD_EVENT_REQUEST:
    return {
      ...state,
      isFetching: true,
      success: false
    };
  case ADD_EVENT_SUCCESS:
    return {
      ...state,
      isFetching: false,
      success: true
    };
  case ADD_EVENT_FAILURE:
    return {
      ...state,
      isFetching: false,
      success: false
    };

  case EDIT_EVENTS_REQUEST:
    return {
      ...state,
      isFetching: true,
      success: false
    };
  case EDIT_EVENTS_SUCCESS:
    return {
      ...state,
      isFetching: false,
      success: true
    };
  case EDIT_EVENTS_FAILURE:
    return {
      ...state,
      isFetching: false,
      success: false
    };

  case DELETE_EVENTS_REQUEST:
    return {
      ...state,
      isFetching: true,
      removed: false
    };
  case DELETE_EVENTS_SUCCESS:
    return {
      ...state,
      isFetching: false,
      removed: true
    };
  case DELETE_EVENTS_FAILURE:
    return {
      ...state,
      isFetching: false,
      removed: false
    };

  case FETCH_EVENTS_REQUEST:
    return {
      ...state,
      isFetching: true,
      removed: false,
      events: payload
    };
  case FETCH_EVENTS_SUCCESS:
    return {
      ...state,
      isFetching: false,
      removed: false,
      events: payload
    };
  case FETCH_EVENTS_FAILURE:
    return {
      ...state,
      isFetching: false,
      removed: false,
      events: payload
    };

  case FETCH_EVENT_DETAIL_REQUEST:
    return {
      ...state,
      isFetching: true,
      event: payload
    };
  case FETCH_EVENT_DETAIL_SUCCESS:
    return {
      ...state,
      isFetching: false,
      event: payload
    };
  case FETCH_EVENT_DETAIL_FAILURE:
    return {
      ...state,
      isFetching: false,
      event: payload
    };

  case REMOVE_SUCCESS_MESSAGE:
    return {
      ...state,
      success:false
    };
  default:
    return state
  }
}
