import React, {Component} from 'react';
import {Map, GoogleApiWrapper, Marker, InfoWindow} from 'google-maps-react';
import {MapOptions} from '../../../MapEnv';
import {objectFetchUsefulData} from '../../../redux/actions/objectAction';
import {connect} from 'react-redux';
import Loader from '../../shared/Loader';

/**
 * @description UsefulMap component
 */
class  UsefulMap extends Component  {
  static defaultProps = {
    center: {
      lat: 40.3334754,
      lng: 49.8248459
    }
  };

  state = {
    markers: [],
    showingInfoWindow: false,
    usefulTitle: '',
    activeMarker: {},
    selectedPlace: {},
    marker: {
      title: '',
      name: '',
      position: {lat: '', lng: ''}
    },
    map: null
  }

  componentDidMount() {
    const id = window.location.pathname.split('/')[2];
    this.props.fetchObjects(id).then(() => {
      this.fitBounds()
    })

    let useful = JSON.parse(localStorage.getItem('useful_categories'));
    useful = useful.filter((category) => category.id === Number(id));
    this.setState({usefulTitle: useful[0].name});
  }

  static getDerivedStateFromProps(nextProps) {
    if (nextProps.objects) {
      let markers = [];
      for (let i = 0; i < nextProps.objects.length; i++) {
        let obj = {};
        obj['title'] = nextProps.objects[i].category.name;
        obj['lat'] = nextProps.objects[i].building.lat;
        obj['lng'] = nextProps.objects[i].building.lng;
        obj['name'] = nextProps.objects[i].building.name;
        obj['address'] = nextProps.objects[i].building.address;
        obj['images'] = nextProps.objects[i].images;
        obj['owner'] = nextProps.objects[i].building.ownership;
        obj['activities'] = nextProps.objects[i].activities
        markers.push(obj)
      }
      return {markers};
    }
    return null;
  }

  onMarkerClick = (props, marker, e) => {
    return (
      this.setState({
        selectedPlace: props,
        activeMarker: marker,
        showingInfoWindow: true
      })
    )
  }

  /**
   * @description Set map center position by markers
   */
  fitBounds = () => {
    const {google} = this.props
    const bounds = new google.maps.LatLngBounds()

    if (this.state.markers && this.state.markers.length && this.state.map) {
      this.state.markers.forEach(marker => {
        const {lat, lng} = marker
        bounds.extend(new google.maps.LatLng(lat, lng))
      });
      this.state.map.fitBounds(bounds)
    }
  }

  /**
   * @description Set map element to state after nap initialising
   * @param props
   * @param map
   */
  setMap = (props, map) => {
    this.setState({map})
  }

  render() {
    const {mapStyles, mapCenterDetail} = MapOptions();
    const markers = this.state.markers ? this.state.markers.map((marker, index) => {
      return (
        <Marker
          key={index}
          title={marker.title}
          name={marker.name}
          address={marker.address}
          image={marker.images[0] ? marker.images[0].path : ''}
          owner={marker.owner}
          lng={marker.lng}
          lat={marker.lat}
          position={marker}
          activities = {marker.activities}
          onClick={this.onMarkerClick}
        />
      )
    }) : <Loader />
    return (
      <div className="objects-wrapper pt-0">
        <ul className="breadcrumps mb-4">
          <li className="breadcrumps-item">Faydalı</li>
          <li className="breadcrumps-item">{this.state.usefulTitle}</li>
        </ul>
        <Map
          google={this.props.google}
          zoom={14}
          style={mapStyles}
          initialCenter={mapCenterDetail}
          onReady={this.setMap}
        >
          {markers}
          <InfoWindow marker={this.state.activeMarker} visible={this.state.showingInfoWindow}>
            <div className="map-object-info">
              <p><b>{this.state.selectedPlace.name}</b></p>
              <img className={'map-object-info-img'} src={`${process.env.REACT_APP_SERVER}/storage/${this.state.selectedPlace.image || ''}`} alt=""/>
              <p>{this.state.selectedPlace.title}</p>
              <p>{this.state.selectedPlace.address}</p>

              <div className="row">
                <div className="col-md-6">
                  <p>{this.state.selectedPlace.lng}</p>
                </div>
                <div className="col-md-6">
                  <p>{this.state.selectedPlace.lat}</p>
                </div>
              </div>
              <p><b>Fəaliyyətlər</b></p>
              <div className="row">
                {this.state.selectedPlace.activities ? this.state.selectedPlace.activities.map((act, i) => {
                  return (
                    <div className="col-md-12" key={i}>
                      <p>Ad - {act.name}</p>
                      {act.workers ? <p>İşçi sayı - {act.workers}</p> : null}
                      {act.description ? <p>Təsvir - {act.description}</p> : null}
                      <hr/>
                    </div>
                  )
                }) : null}
              </div>

              <div>
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href={`https://maps.google.com/?q=${this.state.selectedPlace.lat},${this.state.selectedPlace.lng}`}
                >
                  Google xəritədə baxın
                </a>
              </div>
            </div>
          </InfoWindow>
        </Map>
      </div>
    )
  }
}

const mapDispatchToProps = (dispatch, ownProps) => ({
  fetchObjects: (paramId) => dispatch(objectFetchUsefulData(paramId, ownProps))
})

const mapStateToProps = state => {
  return state.objectReducer;
}

export default connect(mapStateToProps, mapDispatchToProps)(GoogleApiWrapper({
  apiKey: process.env.REACT_APP_MAP_KEY
})(UsefulMap));

