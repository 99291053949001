import React, {Component} from 'react';
import OwlCarousel from 'react-owl-carousel';

/**
 * @description Component Class for Visit Carousel
 */
export default class OrganizationCarousel extends Component {
  render() {
    return (
      <OwlCarousel className='owl-theme' loop margin={10} items='1' autoplay='true'>
        {this.props.images ? this.props.images.map((image) => {
          return (
            <div className='item'>
              <img src={`${process.env.REACT_APP_SERVER}/storage/` + (image.path ? image.path : '')} alt=""/>
            </div>
          )
        })
          : null }
      </OwlCarousel>
    );
  }
}
