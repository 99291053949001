import axios from 'axios';
import {
  FETCH_APPEAL_REQUEST,
  FETCH_APPEAL_SUCCESS,
  FETCH_APPEAL_FAILURE,
  FETCH_APPEAL_DETAIL_REQUEST,
  FETCH_APPEAL_DETAIL_SUCCESS,
  FETCH_APPEAL_DETAIL_FAILURE,
  APPEAL_FORWARD_REQUEST,
  APPEAL_FORWARD_SUCCESS,
  APPEAL_FORWARD_FAILURE,
  APPEAL_ACCEPT_REQUEST,
  APPEAL_ACCEPT_SUCCESS,
  APPEAL_ACCEPT_FAILURE,
  APPEAL_DONE_REQUEST,
  APPEAL_DONE_SUCCESS,
  APPEAL_DONE_FAILURE,
  ADD_APPEAL_REQUEST,
  ADD_APPEAL_SUCCESS,
  ADD_APPEAL_FAILURE,
  FETCH_APPEAL_CATEGORY_REQUEST,
  FETCH_APPEAL_CATEGORY_SUCCESS,
  FETCH_APPEAL_CATEGORY_FAILURE
} from '../types/actionTypes';
import {
  APPEAL_API,
  APPEAL_API_BY_ID,
  FORWARD_APPEAL_API,
  ACCEPT_APPEAL_API,
  DONE_APPEAL_API,
  APPEAL_API_CATEGORY
} from '../services/api.service';

/**
 * @description fetch all appeal
 * @param  {Number} paramId
 */
export const appealFetchData = (params, ownProps) => {
  return async dispatch => {
    dispatch({type: FETCH_APPEAL_REQUEST});
    try {
      const {data} = await axios.get(APPEAL_API, {params: params});
      dispatch({type: FETCH_APPEAL_SUCCESS, payload: data.data.appeals, meta: data.meta});
    } catch (error) {
      dispatch({type: FETCH_APPEAL_FAILURE, payload: error});
    }
  }
}

/**
 * @description redux action for TASK EDIT
 * @param  {Object} user
 * @param  {Object} ownProps
 * @param  {string} APPEAL_API
 */
export const appealPostData = (appealData, ownProps) => {
  return async dispatch => {
    dispatch({type: ADD_APPEAL_REQUEST});
    try {
      const {data} = await axios.post(APPEAL_API, {
        title: appealData.title,
        appeal_category_id: Number(appealData.appeal_category_id),
        phone: Number(appealData.phone),
        text: appealData.text,
        address: appealData.address,
        images: appealData.images
      });
      dispatch({type: ADD_APPEAL_SUCCESS, payload: data.data.appeal});
    } catch (error) {
      dispatch({type: ADD_APPEAL_FAILURE, payload: error.response.data});
    }
  }
}

/**
 * @description redux action for appeals fetch
 * @param  {Object} user
 * @param  {Object} ownProps
 * @param  {string} APPEAL_API
 */
export const appealFetchDetail = (id, ownProps) => {
  return async dispatch => {
    dispatch({type: FETCH_APPEAL_DETAIL_REQUEST});
    try {
      const {data} = await axios.get(APPEAL_API_BY_ID(id));
      dispatch({type: FETCH_APPEAL_DETAIL_SUCCESS, payload: data.data.appeal});
    } catch (error) {
      dispatch({type: FETCH_APPEAL_DETAIL_FAILURE, payload: error.response.data.data});
    }
  }
}

/**
 * @description redux action for appeals fetch
 * @param  {Object} ownProps
 * @param  {string} APPEAL_API_CATEGORY
 */
export const appealFetchCategory = () => {
  return async dispatch => {
    dispatch({type: FETCH_APPEAL_CATEGORY_REQUEST});
    try {
      const {data} = await axios.get(APPEAL_API_CATEGORY);
      dispatch({type: FETCH_APPEAL_CATEGORY_SUCCESS, payload: data.data.appealCategories});
    } catch (error) {
      dispatch({type: FETCH_APPEAL_CATEGORY_FAILURE, payload: error.response.data.data});
    }
  }
}

/**
 * @description redux action for appeal forward edit
 * @param  {Object} ownProps
 * @param  {string} FORWARD_APPEAL_API
 */
export const forwardAppeal = (appealDetail, ownProps) => {
  return async dispatch => {
    dispatch({type: APPEAL_FORWARD_REQUEST});
    try {
      const {data} = await axios.post(FORWARD_APPEAL_API, {
        appeal_id:       appealDetail.appeal_id,
        employee_id:     Number(appealDetail.employee)
      });
      dispatch({type: APPEAL_FORWARD_SUCCESS, payload: data.data});
    } catch (error) {
      dispatch({type: APPEAL_FORWARD_FAILURE, payload: error.response.data.data});
    }
  }
}

/**
 * @description accept appeal
 * @param  {Number} paramId
 */
export const acceptAppeal = (paramId, ownProps) => {
  return async dispatch => {
    dispatch({type: APPEAL_ACCEPT_REQUEST});
    try {
      const {data} = await axios.get(ACCEPT_APPEAL_API(paramId), {
      });
      dispatch({type: APPEAL_ACCEPT_SUCCESS, payload: data.data});
      // window.location.reload();
    } catch (error) {
      dispatch({type: APPEAL_ACCEPT_FAILURE, payload: error.response.data});
    }
  }
}

/**
 * @description done appeal
 * @param  {Number} paramId
 */
export const doneAppeal = (paramId, ownProps) => {
  return async dispatch => {
    dispatch({type: APPEAL_DONE_REQUEST});
    try {
      const {data} = await axios.get(DONE_APPEAL_API(paramId), {
      });
      dispatch({type: APPEAL_DONE_SUCCESS, payload: data});
    } catch (error) {
      dispatch({type: APPEAL_DONE_FAILURE, payload: error.response.data});
    }
  }
}

