import React, {Component} from 'react';
import {connect} from 'react-redux';
import {resumeFetchDetail} from '../../../../redux/actions/socialAction';
import Loader from '../../../shared/Loader';
import moment from 'moment';

import userImg from '../../../../assets/img/user.png';

/**
 * @description Resume component
 */
class Resume extends Component {
  componentDidMount() {
    const {id} = this.props.match.params
    this.props.fetchResume(id);
  }
  render() {
    return (
      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-12">
            {this.props.resume ?
              <div className="whitebox">

                <header className="whitebox-header">
                  <div className="whitebox-title">
                    <h4>
                      {this.props.resume.fullname}
                    </h4>
                    <ul className="breadcrumps">
                      <li className="breadcrumps-item">Sosial bölmə</li>
                      <li className="breadcrumps-item">İş axtaranlar</li>
                    </ul>
                  </div>
                </header>

                <section className="whitebox-body">
                  <div className="row">
                    <div className="col-md-4 col-lg-3">
                      {this.props.resume.image.length ? <img className="img-fluid" src={`${process.env.REACT_APP_SERVER}/storage/` + this.props.resume.image[0].path} alt={this.props.resume.image.path} /> :
                        <img className="img-fluid" src={userImg} alt={userImg}/> }
                    </div>
                    <div className="col-md-3 col-lg-2">
                      <article className="clr_blue">
                        <p><b>Şəhər:</b> {this.props.resume.city.name}</p>
                        <p><b>Yaş:</b> {this.props.resume.age}</p>
                        <p><b>Cins:</b> {this.props.resume.gender_type}</p>
                        <p><b>Təhsil:</b> {this.props.resume.education.name}</p>
                      </article>
                    </div>
                    <div className="col-md-4 col-lg-7">
                      <article className="clr_blue">
                        <p><b>İş təcrübəsi:</b> {this.props.resume.experience.range}</p>
                        <p><b>Elanın tarixi:</b> {moment(this.props.resume.created_at).format('YYYY-MM-DD')}</p>
                        <p><b>Telefon:</b>{this.props.resume.contact_number}</p>
                        <p><b>E-mail:</b> {this.props.resume.email}</p>
                      </article>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-lg-12">
                      <article className="clr_blue">
                        <h4>
                        Bacarıqlar
                        </h4>
                        <p>
                          {this.props.resume.ability}
                        </p>
                      </article>

                      <article className="clr_blue">
                        <h4>
                        Əlavə məlumat
                        </h4>
                        <p>
                          {this.props.resume.more_info}
                        </p>
                      </article>
                    </div>
                  </div>

                </section>

              </div> : <Loader/>}
          </div>

        </div>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  fetchResume: (id) => dispatch(resumeFetchDetail(id))
})

const mapStateToProps = state => {
  return state.socialReducer;
}

export default connect(mapStateToProps, mapDispatchToProps)(Resume);
