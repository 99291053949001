import React, {Component} from 'react';
import {connect} from 'react-redux';
import {NavLink} from 'react-router-dom';
import {municipalitiesListData, organizationFetchData} from '../../../../redux/actions/karabakhAction';
import {isAuthenticated} from '../../../../redux/services/auth.service';
import {checkBlock} from '../../../../shared/checkBlocks';
import {
    KARABAKH_MUNICIPALITIES_DELETE_FAILURE,
    KARABAKH_MUNICIPALITIES_DELETE_REQUEST, KARABAKH_MUNICIPALITIES_DELETE_SUCCESS,
    KARABAKH_ORGANIZATION_DELETE_FAILURE,
    KARABAKH_ORGANIZATION_DELETE_REQUEST,
    KARABAKH_ORGANIZATION_DELETE_SUCCESS
} from '../../../../redux/types/actionTypes';
import {KARABAKH_ORGANIZATION_API, MUNICIPALITIES_DELETE_API} from '../../../../redux/services/api.service';
import {deleteAction} from '../../../../redux/actions/deleteAction';

/**
 * @description Page1 component
 */
class Index extends Component {
    state = {};

    componentDidMount() {
        this.props.fetchAll();
    }

    removeVisit = (ids) => {
        if (window.confirm('Are you sure?')) {
            const idsArray = [];
            idsArray.push(ids);
            const types =
                {
                    request: KARABAKH_MUNICIPALITIES_DELETE_REQUEST,
                    success: KARABAKH_MUNICIPALITIES_DELETE_SUCCESS,
                    failure: KARABAKH_MUNICIPALITIES_DELETE_FAILURE
                };
            this.props.delete(idsArray, types, MUNICIPALITIES_DELETE_API);

            document.getElementById(`org_${ids}`).remove();
        }
    }

    render() {
        const list = this.props.municipalityNews ? this.props.municipalityNews.map((item) => {
            return (
                <div className="list_link" key={item.id} id={`org_${item.id}`}>
                    <a className="row d-flex">
                        <div className="img p-2 col-md-3 col-12">
                            <img src={`${process.env.REACT_APP_SERVER}/storage/` + (item.image ? item.image.path : '')}/>
                        </div>
                        <div className="content col-md-9 col-12 align-self-center">
                            <NavLink
                                to={{pathname: `/municipalities/${this.props.match.params.category}/${item.id}`, aboutProps: {title: `${item.name}`}}}
                                exact
                            >
                                <h3 className="content_title">{item.name}</h3>
                                <p className="content_body">
                                    {item.short_description}
                                </p>
                            </NavLink>

                            <span className="time">{item.created_at}</span>
                            {isAuthenticated() && checkBlock('karabakh', 'karabakh-delete') ?
                                <button
                                    className="btn btn-default float-right"
                                    onClick={() => this.removeVisit(item.id)}
                                >
                  <span className="d-lg-block">
                    <i className="icon icon-delete mr-2"/>
                  </span>
                                </button> : null}
                            {isAuthenticated() && checkBlock('karabakh', 'karabakh-edit') ?
                                <NavLink
                                    to={`/municipalities/${this.props.match.params.category}/edit/${item.id}`}
                                    exact
                                    className="btn btn-default float-right"
                                >
                  <span className="d-lg-block">
                    <i className="icon icon-edit mr-2"/>
                  </span>
                                </NavLink> : null}
                        </div>
                    </a>
                </div>
            );
        }) : null

        return (
            <div className="container-fluid" id="karabakh">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="whitebox">
                            <header className="whitebox-header bordered d-md-flex align-items-center justify-content-between">
                                <div className="whitebox-title">
                                    <ul className="breadcrumps mb-3 mb-md-0">
                                        <li className="breadcrumps-item">
                                            <a href="/municipalities">
                                                Bələdiyyələr
                                            </a>
                                        </li>

                                    </ul>
                                </div>
                                {isAuthenticated() && checkBlock('karabakh', 'karabakh-add') ?
                                    <NavLink
                                        to={`/municipalities/${this.props.match.params.category}/add`}
                                        exact
                                        className="btn btn-theme d-flex align-items-center"
                                    >
                                        <span className="d-none d-lg-block">Əlavə edin</span>
                                        <i className="icon icon-plus"/>
                                    </NavLink> : null}
                            </header>
                            <section className="whitebox-body">
                                {list}
                            </section>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapDispatchToProps = (dispatch, ownProps) => ({
    fetchAll: (params) => dispatch(municipalitiesListData(ownProps)),
    delete: (ids, types, api) => dispatch(deleteAction(ids, types, api))
})

const mapStateToProps = state => {
    return state.karabakhReducer;
}

export default connect(mapStateToProps, mapDispatchToProps)(Index);
