import React, {Component} from 'react';
import {connect} from 'react-redux';
import {withFormik} from 'formik';
import {heroSchema} from '../../../../shared/validation';
import ModalSuccess from '../../../shared/ModalSuccess';
import {removeSuccessMessage} from '../../../../redux/actions/messageAction';
import xariBulbulTitle from '../../../../assets/img/xaribulbul_title.svg';
import {heroPostData} from '../../../../redux/actions/karabakhAction';
import HeroForm from './HeroForm';

/**
 * @description
 */
class HeroAdd extends Component {
  componentWillReceiveProps(nextProps) {
    if (nextProps.files.fileToken && nextProps.files.fileToken !== this.props.files.fileToken) {
      this.props.setFieldValue('images', [...this.props.values.images, ...nextProps.files.fileToken])
    }
  }

  render() {
    return (
      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-12">
            <div className="whitebox"  id="legislation">
              <header className="whitebox-header bordered">
                <div className="whitebox-title d-flex justify-content-start">
                  <img src={xariBulbulTitle} alt="Qarabağ Azərbaycandır!" className={'pr-2'}/>
                  <div className={'category_title d-flex align-items-center'}>
                    <span>Qarabağ Azərbaycandır!</span>
                  </div>
                  {this.props.heroes.errors ? <span>{this.props.heroes.errors.error}</span> : null}
                </div>
              </header>
              <HeroForm formProps={this.props} />
              {this.props.heroes.success ? <ModalSuccess text="Əlavə edildi" /> : null }
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapPropsToValues = (props) => {
  return {
    name: '',
    images: [],
    military_rank: '',
    birth_data: '',
    martyrdom_data: '',
    awards: ''
  }
}

const HeroAddForm = withFormik({
  enableReinitialize: false,
  mapPropsToValues,
  validationSchema: heroSchema,
  validateOnBlur: true,
  validateOnChange: true,
  handleSubmit: (values, {props, setSubmitting}) => {
    props.addHero(values);

    setTimeout(() => {
      props.removeSuccess();
    }, 4000);
  }
})(HeroAdd);

const mapDispatchToProps = (dispatch, ownProps) => ({
  addHero: data => dispatch(heroPostData(data, ownProps)),
  removeSuccess: () => dispatch(removeSuccessMessage())
})

const mapStateToProps = state => {
  return {
    files: state.fileReducer,
    heroes: state.karabakhReducer
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(HeroAddForm);
