import React, {Component} from 'react';
import {connect} from 'react-redux';
import {NavLink} from 'react-router-dom';
// import {heroFetchData, heroGetDataByType} from '../../../../redux/actions/karabakhAction';
import {isAuthenticated} from '../../../../redux/services/auth.service';
import {checkBlock} from '../../../../shared/checkBlocks';
import {KARABAKH_HERO_API} from '../../../../redux/services/api.service';
import {deleteAction} from '../../../../redux/actions/deleteAction';
import {
  KARABAKH_HERO_DELETE_FAILURE,
  KARABAKH_HERO_DELETE_REQUEST,
  KARABAKH_HERO_DELETE_SUCCESS
} from '../../../../redux/types/actionTypes';
import axios from 'axios';

/**
 * @description Hero component
 */
class Hero extends Component {
  state = {customHeroes: []};

  constructor(props) {
    super(props);
    const defaultTypeId = new URLSearchParams(this.props.location.search).get('type_id');
    this.state = {
      customHeroes: [],
      stateIsChanged: false,
      activeButtonId: defaultTypeId || '3'
    };
  }
  async componentDidMount() {
    // this.props.fetchHeroes();
    const typeId = this.state.activeButtonId;
    const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/karabakh/hero/type/${typeId}`);
    // this.state.customHeroes.push(response.data.data.heroes);
    this.setState({
      customHeroes: response.data.data.heroes,
      stateIsChanged: true,
      activeButtonId: typeId
    })
  }

  remove = (ids) => {
    if (window.confirm('Are you sure?')) {
      const idsArray = [];
      idsArray.push(ids);
      const types =
        {
          request: KARABAKH_HERO_DELETE_REQUEST,
          success: KARABAKH_HERO_DELETE_SUCCESS,
          failure: KARABAKH_HERO_DELETE_FAILURE
        };
      this.props.delete(idsArray, types, KARABAKH_HERO_API);

      document.getElementById(`hero_${ids}`).remove();
    }
  }
  handleClick = async (e) => {
    const typeId = e.target.getAttribute('data-id');
    const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/karabakh/hero/type/${typeId}`);
    this.props.history.push({
      search: '?type_id=' + typeId
    })
    // this.state.customHeroes.push(response.data.data.heroes);
    this.setState({
      customHeroes: response.data.data.heroes,
      stateIsChanged: true,
      activeButtonId: typeId
    })
  }

  render() {
    console.log(this.state.activeButtonId)
    const customHeroes = this.state.customHeroes.length > 0 || this.state.stateIsChanged ? this.state.customHeroes : [];
    const martyr = [
      {src: '1.jpg'},
      {src: '2.jpg'},
      {src: '3.jpg'},
      {src: '4.jpg'},
      {src: '5.jpg'},
      {src: '6.jpg'},
      {src: '7.jpg'},
      {src: '8.jpg'},
      {src: '9.jpg'},
      {src: '10.jpg'},
      {src: '11.jpg'},
      {src: '12.jpg'},
      {src: '13.jpg'},
      {src: '14.jpg'},
      {src: '15.jpg'}
    ];
    const war1HeroImages = [
      {src: 'milli1.jpg'},
      {src: 'milli2.jpg'},
      {src: 'milli3.jpg'}
    ];
    if (this.state.activeButtonId === '2' || this.state.activeButtonId === '3') {
      const images = this.state.activeButtonId === '2' ? martyr : war1HeroImages;
      return (
        <div className="container-fluid" id="karabakh">
          <div className="row">
            <div className="col-lg-12">
              <div className="whitebox">
                <header className="whitebox-header bordered d-md-flex align-items-center justify-content-between">
                  <div className="whitebox-title">
                    <h4>Şəhidlərimiz və Milli Qəhrəmanlarımız</h4>
                    <ul className="breadcrumps mb-3 mb-md-0">
                      <li className="breadcrumps-item">
                        <a href="/karabakh">
                          Qarabağ Azərbaycandır
                        </a>
                      </li>
                      <li className="breadcrumps-item">Şəhidlərimiz və Milli Qəhrəmanlarımız</li>
                    </ul>
                  </div>
                  {isAuthenticated() && checkBlock('karabakh', 'karabakh-add') ?
                    <NavLink
                      to={'/karabakh/hero/add/new'}
                      exact
                      className={`btn btn-theme d-flex align-items-center hidden`}
                    >
                      <span className="d-none d-lg-block">Yeni əlavə etmək</span>
                      <i className="icon icon-plus" />
                    </NavLink> : null }
                </header>
                <section className="whitebox-body">
                  <div className="text-center">
                    <button onClick={this.handleClick} data-id="3" className={`btn hero-type-buttons ${this.state.activeButtonId === '3' ? 'btn-primary' : 'btn-light'} mr-2`}>Milli Qəhrəmanlar</button>
                    <button onClick={this.handleClick} data-id="1" className={`btn hero-type-buttons ${this.state.activeButtonId === '1' ? 'btn-primary' : 'btn-light'} mr-2`}>1-ci Qarabağ müharibəsi iştirakçıları</button>
                    <button onClick={this.handleClick} data-id="2" className={`btn hero-type-buttons ${this.state.activeButtonId === '2' ? 'btn-primary' : 'btn-light'} mr-2`}>2-ci Qarabağ müharibəsi iştirakçıları</button>
                  </div>

                  <hr/>
                  <div className={`text-center`}>
                    {
                      images.map(function(imageProps) {
                        return (
                          <li className={`unStyledList`} key={imageProps.src}>
                            <img src={`/heroes/${imageProps.src}`} alt={imageProps.alt} />
                          </li>
                        );
                      })
                    }
                  </div>
                </section>
              </div>
            </div>
          </div>
        </div>
      )
    }
    const heroes =  customHeroes ? customHeroes.map((hero, index) => {
      return (
        <tr key={hero.id} id={`hero_${hero.id}`}>
          <th scope="row">{index + 1}</th>
          <td>
            <img
              className="hero_img"
              src={`${process.env.REACT_APP_SERVER}/storage/${hero.image ? hero.image.path : ''}`}
              alt={hero.name}
            />
          </td>
          <td className="text-center">
            <p>
              {hero.name}
            </p>
          </td>
          <td className="text-center">
            <p>
              {hero.military_rank}
            </p>
          </td>
          <td className="text-center">
            <p>
              {hero.birth_data}
            </p>
          </td>
          <td className="text-center">
            <p>
              {hero.martyrdom_data}
            </p>
          </td>
          <td className="text-center">
            <p>
              {hero.awards}
            </p>
          </td>

          {isAuthenticated() && checkBlock('karabakh', 'karabakh-edit') ?
            <td>
              <button
                className="btn btn-default float-right"
                onClick={() => this.remove(hero.id)}
              >
                <span className="d-lg-block">
                  <i className="icon icon-delete mr-2"/>
                </span>
              </button>
              <NavLink
                to={`/karabakh/hero/edit/${hero.id}`}
                exact
                className="btn btn-default float-right"
              >
                <span className="d-lg-block">
                  <i className="icon icon-edit mr-2"/>
                </span>
              </NavLink>
            </td>
            : null }
        </tr>
      );
    }) : null

    return (
      <div className="container-fluid" id="karabakh">
        <div className="row">
          <div className="col-lg-12">
            <div className="whitebox">
              <header className="whitebox-header bordered d-md-flex align-items-center justify-content-between">
                <div className="whitebox-title">
                  <h4>Şəhidlərimiz və Milli Qəhrəmanlarımız</h4>
                  <ul className="breadcrumps mb-3 mb-md-0">
                    <li className="breadcrumps-item">
                      <a href="/karabakh">
                        Qarabağ Azərbaycandır
                      </a>
                    </li>
                    <li className="breadcrumps-item">Şəhidlərimiz və Milli Qəhrəmanlarımız</li>
                  </ul>
                </div>
                {isAuthenticated() && checkBlock('karabakh', 'karabakh-add') ?
                  <NavLink
                    to={'/karabakh/hero/add/new'}
                    exact
                    className={`btn btn-theme d-flex align-items-center ${this.state.activeButtonId === '1' ? '' : 'hidden'}`}
                  >
                    <span className="d-none d-lg-block">Yeni əlavə etmək</span>
                    <i className="icon icon-plus" />
                  </NavLink> : null }
              </header>
              <section className="whitebox-body">
                <div className="text-center">
                  <button onClick={this.handleClick} data-id="3" className={`btn hero-type-buttons ${this.state.activeButtonId === '3' ? 'btn-primary' : 'btn-light'} mr-2`}>Milli Qəhrəmanlar</button>
                  <button onClick={this.handleClick} data-id="1" className={`btn hero-type-buttons ${this.state.activeButtonId === '1' ? 'btn-primary mr-2' : 'btn-light mr-2'}`}>1-ci Qarabağ müharibəsi iştirakçıları</button>
                  <button onClick={this.handleClick} data-id="2" className={`btn hero-type-buttons ${this.state.activeButtonId === '2' ? 'btn-primary mr-2' : 'btn-light mr-2'}`}>2-ci Qarabağ müharibəsi iştirakçıları</button>
                </div>

                <hr/>
                <table className="table table-bordered heroes table-responsive">
                  <thead>
                    <tr>
                      <th scope="col" className="text-center">
                        <span>№</span>
                      </th>
                      <th scope="col" className="text-center">
                        <span>Şəkil</span>
                      </th>
                      <th scope="col" className="text-center">
                        <span>Soyadı, adı, atasının adı</span>
                      </th>
                      <th scope="col" className="text-center">
                        <span>Rütbəsi</span>
                      </th>
                      <th scope="col" className="text-center">
                        <span>Doğum tarixi</span>
                      </th>
                      <th scope="col" className="text-center">
                        <span>Vəfat tarixi</span>
                      </th>
                      <th scope="col" className="text-center">
                        <span>Təltifləri</span>
                      </th>
                      {isAuthenticated() && checkBlock('karabakh', 'karabakh-add') ?
                        <th scope="col" className="text-center">
                          <span>Əməliyyatlar</span>
                        </th>
                        : null }
                    </tr>
                  </thead>
                  <tbody>
                    {heroes}
                  </tbody>
                </table>
              </section>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch, ownProps) => ({
  // fetchHeroes: (params) => dispatch(heroFetchData(params, ownProps)),
  // getHeroesByType: (params) => dispatch(heroGetDataByType(params, ownProps)),
  delete: (ids, types, api) => dispatch(deleteAction(ids, types, api))
})

const mapStateToProps = state => {
  return state.karabakhReducer;
}

export default connect(mapStateToProps, mapDispatchToProps)(Hero);
