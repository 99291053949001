import React, {Component} from 'react';
import {NavLink, Redirect} from 'react-router-dom';
import {connect} from 'react-redux';
import {objectCategoryFetchData} from '../../../redux/actions/objectAction';
import Loader from '../../shared/Loader';
import {isUnAuthPublicEnabled} from '../../../redux/services/auth.service';

/**
 * @description Objects component
 */
class Objects extends Component {
  state = {
    categories: []
  }

  componentDidMount() {
    this.props.fetchObjectCategories();
  }

  render() {
    if (!isUnAuthPublicEnabled()) {
      return <Redirect to='/login' />
    }
    const categories = this.props.objectCategories ? this.props.objectCategories.map((category) => {
      return (
        <div className="col-xl-4" key={category.id}>
          <NavLink
            to={{pathname: `/objects/category/${category.id}`, aboutProps:{title:`${category.name}`}}}
            exact
            className="objects-link"
          >
            <img src={`${process.env.REACT_APP_SERVER}/storage/` + category.image} alt={category.image} />
            <span>{category.name}</span>
          </NavLink>
        </div>
      )
    }) : null

    return (
      <div className="container-fluid">
        <div className="row">

          <div className="col-lg-12">
            <div className="whitebox back-img fullheight">
              <header className="whitebox-header bordered">
                <div className="whitebox-title">
                  <h4>
                    Obyektlər
                  </h4>
                  <ul className="breadcrumps">
                    <li className="breadcrumps-item">Statistika</li>
                    <li className="breadcrumps-item">Obyektlər</li>
                  </ul>
                </div>
              </header>

              <section className="whitebox-body">
                <div className="row">
                  {this.props.isFetching ? <Loader /> : categories}
                </div>
              </section>

            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch, ownProps) => ({
  fetchObjectCategories: () => dispatch(objectCategoryFetchData(ownProps))
})

const mapStateToProps = state => {
  return state.objectReducer;
}

export default connect(mapStateToProps, mapDispatchToProps)(Objects);
