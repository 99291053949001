import {
  FETCH_MENUS_REQUEST,
  FETCH_MENUS_SUCCESS,
  FETCH_MENUS_FAILURE
} from '../types/actionTypes';

/**
 * @description initial state for menuReducer
 */
const initialState = {};

/**
 * @description updates state depending on action data
 * @param {Object} state = initialState
 * @param {string} type = action.type
 * @param {} payload
 * @param {Array} menus
 * @returns return state
 */
export default function menuReducer(state = initialState, {type, payload}) {
  switch (type) {
  case FETCH_MENUS_REQUEST:
    return {
      ...state,
      isFetching: true,
      menus: payload
    };
  case FETCH_MENUS_SUCCESS:
    return {
      ...state,
      isFetching: false,
      menus: payload
    };
  case FETCH_MENUS_FAILURE:
    return {
      ...state,
      isFetching: false,
      menus: payload
    };

  default:
    return state
  }
}
