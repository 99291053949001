import {
  FETCH_STATISTIC_REQUEST,
  FETCH_STATISTIC_SUCCESS,
  FETCH_STATISTIC_FAILURE,
  FETCH_STATISTIC_DETAIL_REQUEST,
  FETCH_STATISTIC_DETAIL_SUCCESS,
  FETCH_STATISTIC_DETAIL_FAILURE
} from '../types/actionTypes';

/**
 * @description initial state for statistics
 */
const initialState = {};

/**
 * @description updates state depending on action data
 * @param {Object} state = initialState
 * @param {string} type = action.type
 * @param {} payload
 * @param {Array} statuses
 * @returns return state
 */
export default function statisticReducer(state = initialState, {type, payload}) {
  switch (type) {
  case FETCH_STATISTIC_REQUEST:
    return {
      ...state,
      isFetching: true,
      statistics: payload
    };
  case FETCH_STATISTIC_SUCCESS:
    return {
      ...state,
      isFetching: false,
      statistics: payload
    };
  case FETCH_STATISTIC_FAILURE:
    return {
      ...state,
      isFetching: false,
      statistics: payload
    };

  case FETCH_STATISTIC_DETAIL_REQUEST:
    return {
      ...state,
      isFetching: true,
      statisticDetails: payload
    };
  case FETCH_STATISTIC_DETAIL_SUCCESS:
    return {
      ...state,
      isFetching: false,
      statisticDetails: payload
    };
  case FETCH_STATISTIC_DETAIL_FAILURE:
    return {
      ...state,
      isFetching: false,
      statisticDetails: payload
    };

  default:
    return state
  }
}
