import React, {Component} from 'react';
import MainMap from './map/MainMap';
import RightBanner from '../banners/RightBanner';
import {connect} from 'react-redux';
import {fetchBannersRight} from '../../../redux/actions/bannerAction';
import {MapOptions} from '../../../MapEnv';
import WebsiteBanner from '../banners/WebsiteBanner';

/**
 * @description Component Class for Dashboard Main page which is contain Objects,Social,Faq
 */
class Main extends Component {
  state = {
    defaultMap: {
      center: {},
      zoom: 0
    }
  }
  componentDidMount() {
    this.props.fetchBanner()
    this.setDefaultCenter()
  }

  setDefaultCenter = () => {
    const {mapCenter, defaultZoom} = MapOptions();

    this.setState({defaultMap: {center: mapCenter, zoom: defaultZoom}})
  }

  render() {
    return (
      <>
        <div className="main-panel-content">
          <div className="container-fluid">
            <div className="row">
              <div className={`${this.props.bannersRight && this.props.bannersRight.length ? 'col-lg-9' : 'col-lg-12'}`}>
                <div className="whitebox">
                  <header className="whitebox-header">
                    <div
                      className="whitebox-title d-flex align-items-center justify-content-between">
                      <h4>Səbail rayonu </h4>
                      <p onClick={this.setDefaultCenter}>Rayonun ümumi ərazisi</p>
                    </div>
                  </header>

                  <section className="whitebox-body">
                    <div className="objects-wrapper h-100">
                      <MainMap defaultMap={this.state.defaultMap} />
                    </div>
                  </section>

                </div>
              </div>

              {this.props.bannersRight ?
                <div className="col-lg-3">
                  <RightBanner banners={this.props.bannersRight} />
                </div>
                : null}

            </div>
          </div>
        </div>
        <WebsiteBanner />
      </>
    );
  }
}

const mapDispatchToProps = (dispatch, ownProps) => ({
  fetchBanner: () => dispatch(fetchBannersRight(ownProps))
})

const mapStateToProps = state => {
  return state.bannerReducer;
}

export default connect(mapStateToProps, mapDispatchToProps)(Main);

