
/**
 * @description default props for transport form
 * @param props
 * @returns {{category_id: string, name: string, description: string, definition: string, id: string, stations: [], transport: {period: string, cost: string, stations: []}}}
 */
export const transportFormsMapProps = (props) => {
  return {
    definition: '',
    id: '',
    category_id: '',
    name: '',
    description: '',
    stations: [],
    transport: {
      cost: '',
      period: '',
      stations: []
    }
  }
}

/**
 * @description default props for vacancy form
 * @param props
 * @returns {{profession: string, education_id: string, more_info: string, vacancy_category_id: string, contact_number: string, max_age: string, experience_id: string, max_salary: string, candidate_requirements: string, related_person: string, company_name: string, min_salary: string, id: string, min_age: string, email: string, city_id: string}}
 */
export const vacancyFormsMapProps = (props) => {
  return {
    id: '',
    vacancy_category_id: '',
    profession: '',
    city_id: '',
    min_salary: '',
    max_salary: '',
    min_age: '',
    max_age: '',
    education_id: '',
    experience_id: '',
    related_person: '',
    contact_number: '',
    email: '',
    company_name: '',
    candidate_requirements: '',
    more_info: ''
  }
}
