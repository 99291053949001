import React, {Component} from 'react';
import {NavLink, Route, Redirect} from 'react-router-dom';
import Vacancy from './vacancy/Vacancy';
import Resume from './resumes/Resumes';
import {checkBlock} from '../../../shared/checkBlocks';
import {vacancyCategoryFetchData} from '../../../redux/actions/socialAction';
import {connect} from 'react-redux';
import {isUnAuthPublicEnabled} from '../../../redux/services/auth.service';

/**
 * @description Social component
 */
class Social extends Component {
  state = {
    controlParam: 'vacancy',
    categoryId: null
  };

  componentDidMount() {
    this.props.fetchCategories();
  }

  controlVacancy = (controlParam) => {
    this.setState({controlParam});
  }

  selectCategory = (e) => {
    const id = Number(e.target.value) || 'all';
    this.setState({
      categoryId: id
    })
  }

  render() {
    if (!isUnAuthPublicEnabled()) {
      return <Redirect to='/login' />
    }
    const categories = this.props.categories ? this.props.categories.map(category => {
      return (
        <option key={category.id} value={category.id}>{category.name}</option>
      )
    }) : null
    return (
      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-12">
            <div className="whitebox">

              <header className="whitebox-header">
                <div className="d-md-flex align-items-center justify-content-between">
                  <div>
                    <div className="whitebox-title mb-3 mb-md-0">
                      <h4>Sosial bölmə</h4>
                    </div>
                  </div>

                  <div>

                    {this.state.controlParam === 'vacancy' ?
                      <div className="d-flex align-items-center justify-content-lg-end">
                        <div className="form-group form-group-custom m-0 mr-2">
                          <select name="assignment_category"
                            className="form-control form-control-custom selectbox"
                            placeholder="assignment_category"
                            onChange={(e) => this.selectCategory(e)}
                          >
                            <option disabled value="">Kateqoriyalar</option>
                            <option value="">Bütün Vakansiyalar</option>
                            {categories}
                          </select>
                        </div>

                        {checkBlock('social', 'vacancy-add') ?
                          <div className="form-group form-group-custom m-0">
                            <NavLink
                              to="/add-vacancy"
                              className="btn btn-theme d-flex align-items-center"
                            >
                              <span className="d-none d-lg-block">Vakansiya yarat</span>
                              <i className="icon icon-plus"/>
                            </NavLink>
                          </div> : null}
                      </div>
                      :
                      <div className="d-flex align-items-center justify-content-lg-end">
                        <div className="form-group form-group-custom m-0 mr-2">
                          <select name="assignment_category"
                            defaultValue="assignment_category"
                            className="form-control form-control-custom selectbox"
                            placeholder="assignment_category"
                          >
                            <option disabled value="">Kateqoriyalar</option>
                            {categories}
                          </select>
                        </div>

                        {checkBlock('social', 'resume-add') ?
                          <div className="form-group form-group-custom m-0">
                            <NavLink
                              to="/resume/add/new"
                              className="btn btn-theme d-flex align-items-center"
                            >
                              <span className="d-none d-lg-block">İş üçün müraciət</span>
                              <i className="icon icon-plus"/>
                            </NavLink>
                          </div> : null}
                      </div>
                    }

                  </div>
                </div>
              </header>

              <section className="whitebox-body">
                <nav>
                  <ul className="social-links pb-2">

                    <li>
                      <NavLink
                        to="/social"
                        exact
                        activeClassName="active"
                        onClick={() => this.controlVacancy('vacancy')}
                      >
                        Vakansiyalar
                      </NavLink>
                    </li>

                    <li>
                      <NavLink
                        to="/social/resumes"
                        activeClassName="active"
                        onClick={() => this.controlVacancy('resume')}
                      >
                        İş axtaranlar
                      </NavLink>
                    </li>
                  </ul>
                </nav>

                <Route
                  path="/social"
                  exact
                  render={() => <Vacancy categoryId={this.state.categoryId} />}
                />
                <Route
                  path="/social/resumes"
                  render={() => <Resume />}
                />
              </section>

            </div>
          </div>
        </div>
      </div>
    );
  }
}
const mapDispatchToProps = (dispatch, ownProps) => ({
  fetchCategories: () => dispatch(vacancyCategoryFetchData())
})

const mapStateToProps = state => {
  return state.socialReducer
}

export default connect(mapStateToProps, mapDispatchToProps)(Social);
