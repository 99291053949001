import React, {Component} from 'react';

/**
 * @description dropdown body for notifications
 */
class Dropdown extends Component {
  clearNotifications = () => {
    this.props.clearNotifications();
    document.location.href = '';
  }

  render() {
    return (
      <div>
        <div className="notify-drop-title">
          <div className="row">
            <div className="col-md-6 col-sm-6 col-xs-6">Bildirişlər</div>
            <div className="col-md-6 col-sm-6 col-xs-6 text-right">
              <a
                href="#"
                className="rIcon allRead"
                data-tooltip="Bütün bildirişlər silinəcək."
                data-placement="bottom"
                onClick={() => (this.clearNotifications())}
              >
                Təmizlə.
              </a>
            </div>
          </div>
        </div>
        <div className="drop-content">
          {this.props.getNotifications().map((notification, key) => (
            <li className="item" key={key}>
              <div className="col-md-3 col-sm-3 col-xs-3">
              </div>
              <div className="col-md-9 col-sm-9 col-xs-9 pd-l0">
                <a
                  className="title"
                  href={'#'}
                  onClick={() => (this.props.readNotification(notification.id || 0, notification.url || '/#'))}
                >
                  {notification.title}
                </a>
                <a
                  className="body"
                  href={'#'}
                  onClick={() => (this.props.readNotification(notification.id || 0, notification.url || '/#'))}
                >
                  {notification.body}
                </a>
                <hr />
              </div>
            </li>
          ))}
        </div>
      </div>
    );
  }
}

export default Dropdown;
