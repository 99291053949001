/**
 * @description setting user access token to localstorage
 * @param  {string} token
 * @param username
 */
export const login = (token, user) => {
  localStorage.setItem('token', token);
  localStorage.setItem('username', user.name);
}

/**
 * @description removing token from localstorage when user log out
 */
export const logout = () => {
  localStorage.removeItem('token');
  localStorage.removeItem('username');
}

/**
 * @description checking user loggin or not by localstorage token property
 */
export const isAuthenticated = () => {
  const token = localStorage.getItem('token');

  if (token && token !== 'undefined' && token !== 'null') {
    return true;
  }
  return false;
}

/**
 * @description get token from localstorage
 */
export const getToken = () => {
  return localStorage.getItem('token');
}

/**
 * @description get username from localstorage
 */
export const getUserName = () => {
  return localStorage.getItem('username');
}

/**
 * @description get auth header
 * @return {Object} headers
 */
export const getAuthHeader = () => {
  return {
    headers:{
      'Authorization': `Bearer ${getToken()}`
    }
  }
}

/**
 * @description public auth
 */
export const isUnAuthPublicEnabled = () => {
  const param = process.env.REACT_APP_UNAUTH_PUBLIC;

  if (param === 'true' || isAuthenticated()) {
    return true;
  }
  return false;
}
