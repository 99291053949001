import React, {Component, Fragment} from 'react';
import ButtonLoader from '../../loader/ButtonLoader';
import {connect} from 'react-redux';

/**
 * @description About form
 */
class AboutForm extends Component {
  state = {
    inputs: ['']
  };
  componentDidMount() {
    if (this.props.formProps) {
      let {aboutDetails} = this.props.formProps;
      this.setState({
        inputs: aboutDetails.data || ['']
      })
    }
  }

  /**
   * @description add dynamic input
   */
  handleAddFields = () => {
    const values = [...this.state.inputs];
    values.push(['']);
    this.setState({
      inputs: values
    });
    this.props.setParentData(values);
  };

  /**
   * @description remove dynamic input field by index
   * @param index
   */
  handleRemoveFields = index => {
    const values = [...this.state.inputs];
    values.splice(index, 1);
    this.setState({
      inputs: values
    });
    this.props.setParentData(values);
  };

  /**
   * @description handle data when dynamic inputs changing
   * @param index
   * @param event
   */
  handleDataChange = (index, event) => {
    const values = [...this.state.inputs];
    values[index] = event.target.value;

    this.setState({
      inputs: values
    });

    this.props.setParentData(values);
  };

  render() {
    const {values, handleBlur, handleChange, handleSubmit, touched, errors} = this.props.formProps;
    return (
      <div className="add-form about-form">
        <div className="col-md-12">
          <div className="row">
            <div className="col-md-6">
              <div className="form-group form-group-custom">
                <label htmlFor="title">Rayon haqqında</label>
                <textarea
                  name="description"
                  className='form-control form-control-custom'
                  value={values.description}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                <p className="error">
                  {(this.props.error && this.props.data.description)
                    ? this.props.data.description.map(error => <span key={error}>{error}</span>)
                    : null}

                  {(touched.description && errors.description)
                    ? <span>{errors.description}</span>
                    : null}
                </p>
              </div>
            </div>
            <div className="col-md-6 scrolled">
              <div className="form-group form-group-custom">
                <div className="row">
                  <div className="col-md-10">
                    <label htmlFor="activities">Əlavə məlumatlar</label>
                  </div>
                  <div className="col-md-2">
                    <button
                      className="btn btn-link"
                      type="button"
                      onClick={() => this.handleAddFields()}
                    >
                      +
                    </button>
                  </div>
                </div>

                {this.state.inputs.map((value, index) => (
                  <Fragment key={index}>
                    <div className="row">
                      <div className="col-md-10">
                        <input
                          type="text"
                          className="form-control form-control-custom mb-3"
                          name="data[]"
                          value={value}
                          onChange={event => this.handleDataChange(index, event)}
                          onBlur={handleBlur}
                        />
                      </div>
                      <div className="col-md-2">
                        <div className="d-flex align-items-center justify-content-end mb-4">
                          <button
                            className="btn btn-link"
                            type="button"
                            onClick={() => this.handleRemoveFields(index)}
                          >
                            -
                          </button>
                        </div>
                      </div>
                    </div>
                  </Fragment>
                ))}
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-12">
          <div className="row">
            <div className="col-md-4 offset-md-4">
              <div className="form-group form-group-custom">
                <button
                  type="button"
                  onClick={() => handleSubmit(values)}
                  className="btn btn-theme w-100"
                  disabled={this.props.isFetching ? true : null}
                >
                  {this.props.formProps.isFetching ? <ButtonLoader /> : <span>Yadda saxla</span>}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default connect(null, null)(AboutForm);
