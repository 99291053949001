import React, {Component} from 'react';
import {connect} from 'react-redux';
import {objectFetchDetail} from '../../../../redux/actions/objectAction';
import {ModalContext} from '../../../../context/context';
import OpenModal from '../../../shared/OpenModal';
import moment from 'moment';
import {isAuthenticated} from '../../../../redux/services/auth.service';
import {checkBlock} from '../../../../shared/checkBlocks';
import Loader from '../../../shared/Loader';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';

/**
 * @description ObjectDetail component
 */
class ObjectDetail extends Component {
  state = {
    itemCount: 3
  }
  componentDidMount() {
    const {id} = this.props.match.params
    this.props.fetchObjectDetail(id);
  }

  render() {
    const images = this.props.objectDetails && this.props.objectDetails.images ? this.props.objectDetails.images.map((img, i) => {
      return (
        <div className="object-images m-4" key={i}>
          <a target="_blank" rel="noopener noreferrer" href={`${process.env.REACT_APP_SERVER}/storage/` + img.path}>
            <img className="img-fluid" src={`${process.env.REACT_APP_SERVER}/storage/` + img.path} alt={img.path} />
          </a>
        </div>
      )
    }) : null
    return (
      <div className="container-fluid">
        <div className="row">

          <div className="col-lg-12">
            {this.props.objectDetails ?
              <div className="whitebox">
                <header className="whitebox-header bordered d-flex align-items-center justify-content-between">
                  <div className="whitebox-title">
                    <h4>
                      {this.props.objectDetails.building.name}
                    </h4>
                  </div>

                  <div className="d-flex align-items-center">
                    <ul className="btn-groups">
                      {isAuthenticated() && checkBlock('objects', 'object-edit') ?
                        <li>
                          <ModalContext.Consumer>
                            {(context) => (
                              <button
                                className="btn btn-default d-flex align-items-center"
                                onClick= {() => { context.showEditObjectModal(true) }}
                              >
                                <i className="icon icon-edit mr-2"></i>
                                Redaktə et
                              </button>
                            )}
                          </ModalContext.Consumer>
                        </li> : null}
                      <li>
                        <button className="btn btn-default btn-print btn- d-flex align-items-center">
                          <i className="icon icon-print mr-2"></i>
                          Çap et
                        </button>
                      </li>
                    </ul>

                    {isAuthenticated() && checkBlock('objects', 'object-add') ?
                      <OpenModal modal="showObjectModal" text="Obyekt yarat" />
                      : null }
                  </div>
                </header>

                <section className="whitebox-body">
                  <div className="row">
                    <div className="col-lg-7 mt-5">
                      <article>
                        <h5 className="mb-3">Obyekt haqqında</h5>
                        <p>Yerləşdiyi ünvan (küçə) : {this.props.objectDetails.building.address}</p>
                        <p>Obyektin mülkiyyət növü : {this.props.objectDetails.building.ownership}</p>
                        <p>Tikilmə ili : {moment(this.props.objectDetails.building.construction_date).format('YYYY-MM-DD')}</p>
                        <p>Qeydiyyatda olan ailə üzvlərinin sayı : {this.props.objectDetails.building.registered_peoples_count}</p>

                        <p>Kateqoriya : {this.props.objectDetails.category ? this.props.objectDetails.category.name : ''}</p>
                        <p>Növ : {this.props.objectDetails.type ? this.props.objectDetails.type.name : ''}</p>
                        <p>Ərazi : {this.props.objectDetails.zone ? this.props.objectDetails.zone.name : ''}</p>
                        <div className="d-flex alin-items-center">
                          <p className="mr-4">
                            <ModalContext.Consumer>
                              {(context) => (
                                <span
                                  className="see-on-map"
                                  onClick= {() => { context.showDetailObjectMap(true, {lat: this.props.objectDetails.building.lat, lng: this.props.objectDetails.building.lng}) }}
                                >
                                Xəritədə bax
                                </span>
                              )}
                            </ModalContext.Consumer>
                          </p>
                          <p>{this.props.objectDetails.building.lat} / {this.props.objectDetails.building.lng}</p>
                        </div>
                      </article>

                      <article>
                        <h5 className="mb-3">Fəaliyyətlər</h5>
                        <div className="row">
                          {this.props.objectDetails.activities.map((activity, index) => {
                            return (
                              <div className="col-md-6" key={index}>
                                <p>Ad : {activity.name}</p>
                                {activity.workers ? <p>İşçi sayı - {activity.workers}</p> : null}
                                {activity.description ? <p>Ətraflı - {activity.description}</p> : null}
                              </div>
                            )
                          }) }
                        </div>
                      </article>

                      <article>
                        <h5>Tarixçə</h5>
                        <p>
                          {this.props.objectDetails.building.history}
                        </p>
                      </article>

                      <article>
                        <h5>Qeydlər</h5>
                        <p>
                          {this.props.objectDetails.building.note}
                        </p>
                      </article>
                    </div>

                    <div className="col-lg-5 mt-5">
                      <article>
                        <p>Mənzillərin sayı : {this.props.objectDetails.building.apartments_count}</p>
                        <p>Mərtəbələrin sayı : {this.props.objectDetails.building.floors_count}</p>
                        <p>Blokların sayi : {this.props.objectDetails.building.block_count}</p>
                        <p>Blokların vəziyyəti : {this.props.objectDetails.building.block_status}</p>
                        <p>Sahəsi : {this.props.objectDetails.building.area_size}</p>
                        <p>Təhsil statusu : {this.props.objectDetails.building.education_status}</p>
                        <p>Mövcud vəziyyəti : {this.props.objectDetails.building.current_situation}</p>
                        <p>İstismar vəziyyəti : {this.props.objectDetails.building.operation_status}</p>
                      </article>
                    </div>

                  </div>

                  <div className="row">
                    <div className="col-md-12">
                      <OwlCarousel
                        items={this.state.itemCount}
                        autoplay
                        dots={false}
                      >
                        {images}
                      </OwlCarousel>
                    </div>
                  </div>
                </section>

              </div>
              : <Loader />}
          </div>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch, ownProps) => ({
  fetchObjectDetail: (paramId) => dispatch(objectFetchDetail(paramId, ownProps))
})

const mapStateToProps = state => {
  return state.objectReducer;
}

export default connect(mapStateToProps, mapDispatchToProps)(ObjectDetail);
