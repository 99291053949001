import React, {Component} from 'react';
import {connect} from 'react-redux';
import {withFormik} from 'formik';
import {heroSchema} from '../../../../shared/validation';
import ModalSuccess from '../../../shared/ModalSuccess';
import {removeSuccessMessage} from '../../../../redux/actions/messageAction';
import xaribulbulTitle from '../../../../assets/img/xaribulbul_title.svg';
import {heroFetchDetailData, heroUpdateData} from '../../../../redux/actions/karabakhAction';
import HeroForm from './HeroForm';

/**
 * @description
 */
class HeroEdit extends Component {
  componentDidMount() {
    const {id} = this.props.match.params;
    this.props.fetchHeroDetail(id);
  }
  componentWillReceiveProps(nextProps) {
    if (nextProps.files.fileToken && nextProps.files.fileToken !== this.props.files.fileToken) {
      this.props.setFieldValue('images', [...this.props.values.images, ...nextProps.files.fileToken])
    }
  }

  render() {
    return (
      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-12">
            <div className="whitebox"  id="legislation">
              <header className="whitebox-header bordered">
                <div className="whitebox-title d-flex justify-content-start">
                  <img src={xaribulbulTitle} alt="Qarabağ Azərbaycandır!" className={'pr-2'}/>
                  <div className={'category_title d-flex align-items-center'}>
                    <span>Qarabağ Azərbaycandır!</span>
                  </div>
                  {this.props.heroes.errors ? <span>{this.props.heroes.errors.error}</span> : null}
                </div>
              </header>
              <HeroForm formProps={this.props} />
              {this.props.heroes.success ? <ModalSuccess text="Əlavə edildi" /> : null }
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapPropsToValues = (props) => {
  if (props.heroes && props.heroes.hero) {
    const hero = props.heroes.hero;

    return {
      'id': hero.id,
      'name': hero.name,
      'images': hero.images,
      'military_rank': hero.military_rank,
      'birth_data': hero.birth_data,
      'martyrdom_data': hero.martyrdom_data,
      'awards': hero.awards
    };
  }

  return {
    id: '',
    name: '',
    images: [],
    military_rank: '',
    birth_data: '',
    martyrdom_data: '',
    awards: ''
  }
}

const HeroEditForm = withFormik({
  enableReinitialize: true,
  mapPropsToValues,
  validationSchema: heroSchema,
  validateOnBlur: true,
  validateOnChange: true,
  handleSubmit: (values, {props, setSubmitting}) => {
    props.updateHero(values);
    setTimeout(() => {
      props.removeSuccess();
    }, 4000);
  }
})(HeroEdit);

const mapDispatchToProps = (dispatch, ownProps) => ({
  fetchHeroDetail: id => dispatch(heroFetchDetailData(id, ownProps)),
  updateHero: data => dispatch(heroUpdateData(data, ownProps)),
  removeSuccess: () => dispatch(removeSuccessMessage())
})

const mapStateToProps = state => {
  return {
    files: state.fileReducer,
    heroes: state.karabakhReducer
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(HeroEditForm);
