import axios from 'axios';
import {
  FETCH_STATUS_REQUEST,
  FETCH_STATUS_SUCCESS,
  FETCH_STATUS_FAILURE
} from '../types/actionTypes';
import {
  STATUS_API
} from '../services/api.service';

/**
 * @description fetch all tasks
 */
export const statusFetchData = (ownProps) => {
  return async dispatch => {
    dispatch({type: FETCH_STATUS_REQUEST});
    try {
      const {data} = await axios.get(STATUS_API);
      dispatch({type: FETCH_STATUS_SUCCESS, payload: data.data.taskStatuses});
    } catch (error) {
      dispatch({type: FETCH_STATUS_FAILURE, payload: error});
    }
  }
}
