import React, {Component} from 'react';
import AddLocationMap from '../map/AddLocationMap';
import {connect} from 'react-redux';
import {withFormik} from 'formik';
import {objectPostData} from '../../../../redux/actions/objectAction';
import {objectSchema} from '../../../../shared/validation';
import ObjectForm from '../ObjectForm';
import CloseModal from '../../../shared/CloseModal';
import ModalSuccess from '../../../shared/ModalSuccess';
import {removeSuccessMessage} from '../../../../redux/actions/messageAction';

/**
 * @description Component Class for adding new object
 */
class AddObjectForm extends Component {
  componentDidMount() {
    this.props.setFieldValue('activities', []);
  }

  /**
   * @description getCoords from Map and sets to inputs
   * @description Hide modal
   */
  addCoordsToLangLng = (lat, lng) => {
    this.props.setFieldValue('lat', lat);
    this.props.setFieldValue('lng', lng);
  }

  /**
   * @description get activities from Object form
   */
  setActivities = (activities) => {
    this.props.setFieldValue('activities', activities);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.files.fileToken && nextProps.files.fileToken !== this.props.files.fileToken) {
      this.props.setFieldValue('images', [...this.props.values.images, ...nextProps.files.fileToken])
    }
  }

  render() {
    return (
      <div className="modal-custom">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-10 offset-lg-1">
              <div className="modal-custom-inner">
                <div className="modal-custom-header d-flex align-items-center justify-content-between">
                  <h4>
                  Yeni obyekt yarat

                    <p className="error">
                      {this.props.objects.errors ? <span>{this.props.objects.errors.error}</span> : null}
                    </p>

                  </h4>
                  <CloseModal tooltip={true} modal='showObjectModal'/>
                </div>
                <div className="row">
                  <div className="col-md-5 scrolled">
                    <ObjectForm parentCallBack={this.setActivities} formProps={this.props} />
                  </div>

                  <div className="col-md-7">
                    <div className="objects-wrapper">
                      <AddLocationMap onSelectCoords={this.addCoordsToLangLng} />
                    </div>
                  </div>
                </div>
                {this.props.objects.success ? <ModalSuccess text="Yeni obyekt uğurla yaradıldı" /> : null }
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapPropsToValues = (props) => {
  return {
    name: '',
    ownership: '',
    operation_status: '',
    block_count: '',
    block_status: '',
    floors_count: '',
    apartments_count: '',
    registered_peoples_count: '',
    area_size: '',
    current_situation: '',
    activities: [],
    education_status: '',
    address: '',
    lat: '',
    lng: '',
    construction_date: '',
    note: '',
    history: '',
    images: [],
    object_category_id: '',
    object_type_id: '',
    object_zone_id: ''
  }
}

const AddObject = withFormik({
  enableReinitialize: false,
  mapPropsToValues,
  validationSchema: objectSchema,
  validateOnBlur: true,
  validateOnChange: true,
  handleSubmit: (values, {props, setSubmitting}) => {
    props.addObject(values);
    setTimeout(() => {
      props.removeSuccess();
    }, 4000);
  }
})(AddObjectForm);

const mapDispatchToProps = (dispatch, ownProps) => ({
  addObject: objectData => dispatch(objectPostData(objectData, ownProps)),
  removeSuccess: () => dispatch(removeSuccessMessage())
})

const mapStateToProps = state => {
  return {
    files: state.fileReducer,
    objects: state.objectReducer
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(AddObject);
