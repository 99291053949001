import React, {Component} from 'react';
import {connect} from 'react-redux';
import {visitFetchData, visitFetchDetailData} from '../../../../redux/actions/karabakhAction';
import {NavLink} from 'react-router-dom';
import VisitCarousel from './VisitCarousel';

/**
 * @description Component Class for Visit Detail
 */
class VisitDetail extends Component {
  state = {
    visitId: ''
  }
  componentDidMount() {
    this.initData();
    this.props.fetchVisits();
  }
  initData = () => {
    const {id} = this.props.match.params;

    this.setState({
      visitId: id
    });

    this.props.fetchDetail(id);
  }
  componentDidUpdate(prevProps, prevState, snapshot) {
    const {id} = this.props.match.params;
    if (prevState.visitId !== id) {
      this.initData();
    }
  }

  render() {
    const visits =  this.props.visits ? this.props.visits.map((visit) => {
      const image = (visit.image && visit.image.path) ? visit.image.path : '';
      return (
        <div className="list_link" key={visit.id}>
          <NavLink
            to={{pathname: `/karabakh/visit/${visit.id}`, aboutProps:{title:`${visit.name}`}}}
            exact
            className="row d-flex"
          >
            <div className="img p-2 col-md-4 col-12">
              <img src={`${process.env.REACT_APP_SERVER}/storage/` + image} />
            </div>
            <div className="content col-md-8 col-12 align-self-center">
              <h3 className="content_title_inline">{visit.name}</h3>
              <p className="content_body_inline">
                {visit.short_description}
              </p>
            </div>
          </NavLink>
        </div>
      );
    }) : null
    return (
      <>
        {this.props.visit ?
          <div className="container-fluid" id="karabakh">
            <div className="row">
              <div className="col-lg-12">
                <div className="whitebox">
                  <header className="whitebox-header bordered d-md-flex align-items-center justify-content-between">
                    <div className="whitebox-title">
                      <h4>Ali Baş Komandanın Qarabağ bölgəsinə səfərləri</h4>
                      <ul className="breadcrumps mb-3 mb-md-0">
                        <li className="breadcrumps-item">
                          <a href="/karabakh">
                            Qarabağ Azərbaycandır
                          </a>
                        </li>
                        <li className="breadcrumps-item">
                          <a href="/karabakh/page/1">
                            Ali Baş Komandanın Qarabağ bölgəsinə səfərləri
                          </a>
                        </li>
                      </ul>
                    </div>
                  </header>
                  <section className="whitebox-body">
                    <div className="row detail">
                      <div className="col-md-7 col-12">
                        <div className="row">
                          <div className="col-12">
                            <div className="slider">
                              <VisitCarousel
                                images={this.props.visit && this.props.visit.images ? this.props.visit.images : []}
                              />
                            </div>
                            <div className="text">
                              <h3 className="title mt-24">{this.props.visit.name}</h3>
                              {
                                this.props.visit.youtube_id ?
                                  <div className="youtube">
                                    <iframe
                                      width="100%"
                                      height="600"
                                      src={`https://www.youtube.com/embed/${this.props.visit.youtube_id}`}
                                      frameBorder="0"
                                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                      allowFullScreen
                                      title="Embedded youtube"
                                    />
                                  </div> : null
                              }
                              <div className="body">{this.props.visit.description}</div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-5 col-12">
                        <div className="visits-list">
                          {visits}
                        </div>
                      </div>
                    </div>
                  </section>
                </div>
              </div>
            </div>
          </div> : null }
      </>
    );
  }
}

const mapDispatchToProps = (dispatch, ownProps) => ({
  fetchDetail: id => dispatch(visitFetchDetailData(id, ownProps)),
  fetchVisits: (params) => dispatch(visitFetchData(params, ownProps))
});

const mapStateToProps = state => {
  return state.karabakhReducer;
}

export default connect(mapStateToProps, mapDispatchToProps)(VisitDetail);
