import React from 'react';
import success from '../../assets/img/success.svg';

/**
 * @description Component Class for modal success
 */
const SuccessMessage = ({text, closeModal}) =>

  <div className="success-message">
    <div className="d-flex justify-content-end pr-5">
      <span
        onClick={closeModal}
        className="close-modal"
      >
        <i className="icon icon-close-modal" />
      </span>
    </div>
    <div className="message-content">
      <img src={success} alt={success} />
      <p>
        {text}
      </p>
    </div>
  </div>

export default SuccessMessage;
