import axios from 'axios';
import {
  FETCH_STATISTIC_REQUEST,
  FETCH_STATISTIC_SUCCESS,
  FETCH_STATISTIC_FAILURE,
  FETCH_STATISTIC_DETAIL_REQUEST,
  FETCH_STATISTIC_DETAIL_SUCCESS,
  FETCH_STATISTIC_DETAIL_FAILURE
} from '../types/actionTypes';
import {
  STATISTIC_API, STATISTIC_API_BY_ID
} from '../services/api.service';

/**
 * @description fetch all statistics
 */
export const statisticsFetchData = (ownProps) => {
  return async dispatch => {
    dispatch({type: FETCH_STATISTIC_REQUEST});
    try {
      const {data} = await axios.get(STATISTIC_API);
      let statistics = data.data.statistics;
      let statisticsArray = [];
      for (let [key, value] of Object.entries(statistics)) {
        statisticsArray.push({key, value})
      }
      localStorage.setItem('statistics', JSON.stringify(statisticsArray));
      dispatch({type: FETCH_STATISTIC_SUCCESS, payload: statisticsArray});
    } catch (error) {
      dispatch({type: FETCH_STATISTIC_FAILURE, payload: error});
    }
  }
}

/**
 * @description redux action for statistic detail
 * @param  {Object} ownProps
 * @param  {string} NORMATIVE_API_BY_ID
 */
export const statisticsFetchDetailData = (slug, ownProps) => {
  return async dispatch => {
    dispatch({type: FETCH_STATISTIC_DETAIL_REQUEST});
    try {
      const {data} = await axios.get(STATISTIC_API_BY_ID(slug));
      dispatch({type: FETCH_STATISTIC_DETAIL_SUCCESS, payload: data.data.statistics});
    } catch (error) {
      dispatch({type: FETCH_STATISTIC_DETAIL_FAILURE, payload: error.response.data.data});
    }
  }
}
