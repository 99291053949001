import React from 'react';
import AuthHeader from './AuthHeader';
import Login from './Login';

/**
 * @description Component Class for Login
 */
const LoginWrapper = (props) => {
  return  (
    <section className="auth-wrapper">
      <AuthHeader/>
      <div className="col-lg-5 offset-lg-7 p-0">
        <div className="auth-box">
          <div className="auth-form-wrapper">
            <div className="d-flex align-items-center justify-content-between mb-5">
              <h5 className="auth-box-title">Giriş</h5>
            </div>
            <Login ownProps={props} />
          </div>
        </div>
      </div>
    </section>
  );
}

export default LoginWrapper;
