import React from 'react'
import {MapOptions} from '../../../MapEnv';

const {center} = MapOptions();

/**
 * @description base component for components that using google maps
 */
export default class GoogleApiComponent extends React.Component {
  /**
   * @static {Object} default props
   * @memberOf GoogleApiComponent Class
   */
  static defaultProps = {center};
}
