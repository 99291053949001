import React, {Component} from 'react';
import ButtonLoader from '../../loader/ButtonLoader';
import Select from 'react-select';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import {connect} from 'react-redux';
import {filePostFetch} from '../../../redux/actions/fileAction';
import {usersFetchData} from '../../../redux/actions/userAction';
import {statusFetchData} from '../../../redux/actions/statusAction';

/**
 * @description Task form
 */
class AssignmentForm extends Component {
  state = {
    options: [],
    selectedOption: null,
    dueDate: null,
    dueTime: null,
    files: []
  };

  componentDidMount() {
    this.props.getUsers('employee');
    this.props.getStatuses();
  }

  /**
   * @description get date of task
   * @param {Object} Date
   * @memberOf AssignmentForm
   */
  handleDateChange = date => {
    this.setState({
      dueDate: date
    });
    this.props.formProps.setFieldValue('due_date', date)
  };

  /**
   * @description get time of task
   * @param {Object} Time
   * @memberOf AssignmentForm
   */
  handleTimeChange = time => {
    this.setState({
      dueTime: time
    });
    this.props.formProps.setFieldValue('due_time', time)
  };

  /**
   * @description file upload
   * @param {Object} e
   * @memberOf ObjectForm
   */
  setFile = (e) =>  {
    const fileData  = new FormData();
    const files = [];
    for (let i = 0; i < e.currentTarget.files.length; i++) {
      files.push(e.currentTarget.files[i].name);
      fileData.append('files[]', e.currentTarget.files[i])
    }
    this.setState({
      files
    })
    this.props.uploadFile(fileData)
  }

  static getDerivedStateFromProps(nextProps) {
    if (nextProps.users.users) {
      let options = [];
      for (let i = 0; i < nextProps.users.users.length; i++) {
        let obj = {};
        obj['value'] = nextProps.users.users[i].id
        obj['label'] = nextProps.users.users[i].name
        options.push(obj)
      }
      return {options};
    }
    return null;
  }

  render() {
    const {values, handleSubmit, handleBlur, handleChange, errors, touched, setFieldValue} = this.props.formProps;
    const {options} = this.state;
    const fileNames = this.state.files.length ? this.state.files.map((file, index) => {
      return (
        <p className="m-0" key={index}>{file}</p>
      )
    }) : ''
    const statusOptions = this.props.statuses.statuses ? this.props.statuses.statuses.map((status) => {
      return (
        <option key={status.id} value={status.step}>{status.name}</option>
      )
    }) : null
    return (
      <div className="add-form">

        {this.props.edit ?
          <div className="form-group form-group-custom">
            <label htmlFor="status">Statusu dəyiş</label>
            <select
              name="status"
              className='form-control form-control-custom selectbox'
              value={values.status}
              onChange={handleChange}
              onBlur={handleBlur}
            >
              {statusOptions}
            </select>
          </div>
          : null}
        <div className="form-group form-group-custom">
          <label htmlFor="person_in_charge">Məsul şəxs</label>
          <Select
            isMulti
            name='employees'
            value={values.employees}
            onChange={(option) => setFieldValue('employees', option)}
            options={options}
            className=""
            placeholder='Məsul şəxs seçin'
          />
          <p className="error">
            {(this.props.formProps.tasks && this.props.formProps.tasks.error && this.props.formProps.tasks.data.employees)
              ? this.props.formProps.tasks.data.employees.map(error => <span key={error}>{error}</span>)
              : null}

            {(touched.employees && errors.employees)
              ? <span>{errors.employees}</span>
              : null}
          </p>
        </div>

        <div className="row form-group form-group-custom">
          <div className="col-md-12">
            <label htmlFor="deadline">İcra müddətinin bitmə tarixi</label>
          </div>
          <div className="form-group form-group-custom col-md-6 mb-0">
            <DatePicker
              name="due_date"
              selected={values.due_date}
              onChange={this.handleDateChange}
              showMonthDropdown
              className="form-control form-control-custom"
              placeholderText="İcra müddətinin bitmə tarixi"
            />
            <p className="error">
              {(touched.due_date && errors.due_date)
                ? <span>{errors.due_date}</span>
                : null}
            </p>
          </div>

          <div className="form-group form-group-custom col-md-6 mb-0">
            <DatePicker
              className="form-control form-control-custom"
              selected={values.due_time}
              onChange={this.handleTimeChange}
              name="due_time"
              showTimeSelect
              showTimeSelectOnly
              timeIntervals={15}
              timeCaption="Time"
              dateFormat="HH:mm aa"
              placeholderText="İcra müddətinin bitmə vaxtı"
            />
            <p className="error">
              {(touched.due_time && errors.due_time)
                ? <span>{errors.due_time}</span>
                : null}
            </p>
          </div>

          <div className="col-md-12">
            <p className="error">
              {(this.props.formProps.tasks && this.props.formProps.tasks.error && this.props.formProps.tasks.data.due_date)
                ? this.props.formProps.tasks.data.due_date.map(error => <span key={error}>{error}</span>)
                : null}
            </p>
          </div>
        </div>

        <div className="form-group form-group-custom">
          <label htmlFor="description">Tapşırığın mətni</label>
          <textarea
            className="form-control form-control-custom"
            name="description"
            placeholder="Tapşırığın mətni"
            value={values.description}
            onChange={handleChange}
            onBlur={handleBlur}
          />

          <p className="error">
            {(this.props.formProps.tasks && this.props.formProps.tasks.error && this.props.formProps.tasks.data.description)
              ? this.props.formProps.tasks.data.description.map(error => <span key={error}>{error}</span>)
              : null}

            {(touched.description && errors.description)
              ? <span>{errors.description}</span>
              : null}
          </p>
        </div>

        <div className="row">
          <div className="col-md-6 form-group form-group-custom">
            <div className="file-name">
              {fileNames}
            </div>
          </div>
          <div className="col-md-6 form-group form-group-custom over-hide">
            <div className="btn file-button">Şəkil yüklə</div>
            <input
              name="file"
              type="file"
              multiple
              placeholder="Şəkil yüklə"
              // value={values.images}
              onChange={(e) => { this.setFile(e) }}
              onBlur={handleBlur}
            />
          </div>
          <div className="col-md-12">
            <p className="error">
              {(this.props.formProps.files.fileToken && this.props.formProps.files.fileToken.error)
                ? Object.entries(this.props.formProps.files.fileToken.data).map(file => file[1].map((error, index) => <span key={index}>{error}</span>))
                : null}
            </p>
          </div>
        </div>
        <div className="form-group form-group-custom">
          <button
            type="button"
            onClick={() => handleSubmit(values)}
            className="btn btn-theme w-100"
            disabled={this.props.isFetching ? true : null}
          >
            {this.props.formProps.isFetching ? <ButtonLoader /> : <span>
              {this.props.submitText}
            </span>}
          </button>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch, ownProps) => ({
  uploadFile: file => dispatch(filePostFetch(file, ownProps)),
  getUsers: (role) => dispatch(usersFetchData(role)),
  getStatuses:() => dispatch(statusFetchData())
})

const mapStateToProps = state => {
  return {
    users: state.userReducer,
    statuses: state.statusReducer
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(AssignmentForm);
