import {
  FILE_REQUEST,
  FILE_SUCCESS,
  FILE_FAILURE,
  FILE_DELETE_REQUEST,
  FILE_DELETE_SUCCESS,
  FILE_DELETE_FAILURE
} from '../types/actionTypes';

/**
   * @description initial state for loginReducer
   */
const initialState = {};

/**
 * @description updates state depending on action data
 * @param {Object} state = initialState
 * @param {string} type = action.type
 * @param {} payload
 * @returns return state
 */
export default function fileReducer(state = initialState, {type, payload}) {
  switch (type) {
  case FILE_REQUEST:
    return {
      ...state,
      isFetching: true,
      fileToken: payload
    };
  case FILE_SUCCESS:
    return {
      ...state,
      isFetching: false,
      fileToken: payload
    };
  case FILE_FAILURE:
    return {
      ...state,
      isFetching: false,
      fileToken: payload
    };
  case FILE_DELETE_REQUEST:
    return {
      ...state,
      isFetching: true,
      fileToken: payload
    };
  case FILE_DELETE_SUCCESS:
    return {
      ...state,
      isFetching: false,
      fileToken: payload
    };
  case FILE_DELETE_FAILURE:
    return {
      ...state,
      isFetching: false,
      fileToken: payload
    };
  default:
    return state
  }
}
