/**
 * @description all validation texts of form inputs
 */
const validationText = {
  userMin: 'Ad ən az 3 simvol ola bilər!',
  userEmail: 'İstifadəçi adı etibarlı bir e-poçt ünvanı olmalıdır!',
  userPhoneMin: 'Telefon nömrəsi 9 rəqəmdən az olmamalıdır!',
  min: 'Ən az 2 simvol ola bilər!',
  max: 'Ən çox 255 simvol ola bilər!',
  date: 'Format düz deyil,tarix əlavə edin!',
  password: 'Şifrə ən az 8 simvol ola bilər!',
  passwordConfirmMin: 'Şifrənin təkrarı ən az 8 simvol ola bilər!',
  passwordConfirmEqual: 'Şifrə ilə şifrənin təkrarı eyni deyil!',
  emptyField: 'Xananı doldurun!',
  required: (name) => (name + ' mütləq daxil edilməlidir')
};

export default validationText;
