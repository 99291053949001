import React, {Component} from 'react'
import {connect} from 'react-redux'
import {
  handleTokenRefresh,
  getToken,
  backgroundMessage,
  handleReadNotification,
  handleClearNotifications
} from '../../../redux/actions/firebaseAction'
import notification from '../../../assets/img/notification.svg'
import Dropdown from './Dropdown'

/**
 * @description Notification component for displaying Firebase notifications
 */
class Notification extends Component {
  state = {
    notifications: [],
    showDropdownMenu: false,
    unsupported: false
  }
  componentDidMount = () => {
    document.addEventListener('mousedown', this.handleClickOutside)
    this.props.getToken().then(() => {
      if (this.props.token === 'unsupported') {
        return this.setState({unsupported: true})
      }
      this.props.handleTokenRefresh(this.props.token)
      this.props.getBgMessage()
    })
  }

  static getDerivedStateFromProps(props, state) {
    let notifications = state.notifications
    if (props.notifyData && props.notifyData.notifications) {
      notifications = props.notifyData.notifications
    }
    if (props.bgMessage) {
      notifications.push(props.bgMessage.notification)
      notifications.push(props.bgMessage.notification)
    }
    return {notifications}
  }

  /**
   * @description show and hide dropdown menu
   */
  showDropdownMenu = () => {
    if (this.state.showDropdownMenu) {
      this.setState({showDropdownMenu: false})
      return
    }
    this.setState({showDropdownMenu: true})
  }

  /**
   * @description Set the wrapper ref
   */
  setWrapperRef = (node) => {
    this.wrapperRef = node
  }

  /**
   * @description close dropdow when user click outside
   * @param event
   */
  handleClickOutside = (event) => {
    if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
      this.setState({showDropdownMenu: false})
    }
  }
  render() {
    return (
      <div id="notify-drop">
        <div className="dropdown dropdown-wrapper">
          <div className="notification-badge" onClick={this.showDropdownMenu}>
            <img src={notification} alt={notification}/>
            {(!this.state.unsupported && this.state.notifications.length) &&
              <span className='pulse-button'>
                {this.state.notifications.length}
              </span>
            }
          </div>
          <div className={`dropdown dropdown-for-component ${this.state.showDropdownMenu ? 'show' : 'hidden'}`} ref={this.setWrapperRef}>
            {
              this.state.unsupported ?
                'Sizin brauzer bildiriş sistemini dəstəkləmir.' :
                <Dropdown
                  getNotifications={() => (this.state.notifications)}
                  readNotification={this.props.readNotification}
                  clearNotifications={() => (this.props.clearNotifications())}
                />
            }
          </div>
        </div>
      </div>
    )
  }
}

const mapDispatchToProps = (dispatch, ownProps) => ({
  handleTokenRefresh: (token) => dispatch(handleTokenRefresh(token, ownProps)),
  getToken: () => dispatch(getToken(ownProps)),
  getBgMessage: () => dispatch(backgroundMessage(ownProps)),
  readNotification: (id, url) => dispatch(handleReadNotification(id, url, ownProps)),
  clearNotifications: () => dispatch(handleClearNotifications(ownProps))
})

const mapStateToProps = state => {
  return state.firebaseReducer
}

export default connect(mapStateToProps, mapDispatchToProps)(Notification)
