import axios from 'axios';
import {
  FETCH_MENUS_REQUEST,
  FETCH_MENUS_SUCCESS,
  FETCH_MENUS_FAILURE
} from '../types/actionTypes';
import {
  MENU_API
} from '../services/api.service';

/**
 * @description fetch all menus acts
 */
export const menusFetchData = () => {
  return async dispatch => {
    dispatch({type: FETCH_MENUS_REQUEST});
    try {
      const {data} = await axios.get(MENU_API);
      let menus = data.data.menu;
      let menuArray = [];
      for (let [key, value] of Object.entries(menus)) {
        menuArray.push({key, value})
      }
      dispatch({type: FETCH_MENUS_SUCCESS, payload: menuArray});
      localStorage.setItem('menus', JSON.stringify(data.data.menu));
    } catch (error) {
      dispatch({type: FETCH_MENUS_FAILURE, payload: error});
    }
  }
}

