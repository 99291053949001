import React, {Component} from 'react';
import {withFormik} from 'formik';
import {connect} from 'react-redux';
import {usersFetchData} from '../../../redux/actions/userAction';
import ButtonLoader from '../../loader/ButtonLoader';
import {ModalContext} from '../../../context/context';
import {forwardAppeal} from '../../../redux/actions/appealAction';

/**
 * @description Component Class for Forward
 */
class ForwardForm extends Component {
  static contextType = ModalContext;
  state = {
    showButton: false
  };

  componentDidMount() {
    this.props.getUsers('employee');
    this.props.setFieldValue('appeal_id', this.props.appealsProps.appealDetails.id);
  }

  onSelectUser = (e) => {
    this.setState({
      showButton: true
    })
  }

  render() {
    const {values, handleSubmit, handleChange, handleBlur} = this.props;
    const users = this.props.users.users ? this.props.users.users.map(user => {
      return (
        <option key={user.id} value={user.id}>{user.name}</option>
      )
    }) : null
    return (
      <div className="add-form">
        {this.props.appealsProps.forwardStatus ?
          <div className="form-group alert-primary text-center">Müraciət yönləndirildi</div> : null
        }
        <div className="form-group form-group-custom">
          <label htmlFor="person">Məsul şəxs</label>
          <select
            name="employee"
            className={'form-control form-control-custom selectbox '}
            defaultValue={0}
            onChange={(e) => { e.persist(); handleChange(e); this.onSelectUser(e) }}
            onBlur={handleBlur}
          >
            <option value='0' disabled>Məsul şəxs seçin</option>
            {users}
          </select>
        </div>
        <div className="form-group form-group-custom">
          {this.state.showButton ?
            <button
              type="button"
              className="btn btn-theme w-100"
              onClick={() => handleSubmit(values)}
              disabled={this.props.isFetching ? true : null}
            >
              {this.props.appealsProps.isFetching ? <ButtonLoader /> : <span>Yönləndir</span>}
            </button> : null}
        </div>
      </div>
    );
  }
}

const mapPropsToValues = (props) => {
  return {
    appeal_id: '',
    employee: ''
  }
}

const Forward = withFormik({
  enableReinitialize: false,
  mapPropsToValues,
  validateOnBlur: true,
  validateOnChange: true,
  handleSubmit: (values, {props, resetForm, setSubmitting}) => {
    props.forwardAppeal(values);
  }
})(ForwardForm);

const mapDispatchToProps = (dispatch, ownProps) => ({
  getUsers: (role) => dispatch(usersFetchData(role)),
  forwardAppeal: appealDetail => dispatch(forwardAppeal(appealDetail, ownProps))
})

const mapStateToProps = state => {
  return {
    users: state.userReducer
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(Forward);

