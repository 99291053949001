import * as firebase from 'firebase/app';
import 'firebase/messaging';

let messaging = {unsupported: true};

/**
 * @description Firebase app initialization
 */
const initializedFirebaseApp = firebase.initializeApp({
  apiKey: process.env.REACT_APP_FCM_API_KEY,
  authDomain: process.env.REACT_APP_FCM_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_FCM_DATABASE_URL,
  projectId: process.env.REACT_APP_FCM_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FCM_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FCM_SENDER_ID,
  appId: process.env.REACT_APP_FCM_APP_ID,
  measurementId: process.env.REACT_APP_FCM_MEASUREMENT_ID
});

if (firebase.messaging.isSupported()) {
  messaging = initializedFirebaseApp.messaging();
}

export {messaging}
