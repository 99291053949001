import {
  FETCH_USEFUL_REQUEST,
  FETCH_USEFUL_SUCCESS,
  FETCH_USEFUL_FAILURE
} from '../types/actionTypes';
/**
 * @description initial state for objectReducer
 */
const initialState = {};

/**
 * @description updates state depending on action data
 * @param {Object} state = initialState
 * @param {string} type = action.type
 * @param {} payload
 * @param {Array} usefuls
 * @returns return state
 */
export default function usefulReducer(state = initialState, {type, payload}) {
  switch (type) {
  case FETCH_USEFUL_REQUEST:
    return {
      ...state,
      success: false,
      isFetching: true,
      usefuls: payload
    };
  case FETCH_USEFUL_SUCCESS:
    return {
      ...state,
      success: true,
      isFetching: false,
      usefuls: payload
    };
  case FETCH_USEFUL_FAILURE:
    return {
      ...state,
      success: false,
      isFetching: false,
      usefuls: payload
    };

  default:
    return state
  }
}
