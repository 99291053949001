import React, {Component} from 'react';
import {connect} from 'react-redux';
import {NavLink} from 'react-router-dom';

/**
 * @description CategoryCollapse component
 */
class CategoryCollapse extends Component {
  state = {
    activeCategory: 0
  };
  setActiveCategory = (e, catId) => {
    e.preventDefault();
    if (catId === this.state.activeCategory) {
      catId = 0;
    }
    this.setState({
      activeCategory: catId
    });
  };
  render() {
    return (
      <>
        {
          this.props.categories ? this.props.categories.map((category) => {
            if (category.children.length) {
              return (
                <div key={category.id}>
                  <div
                    className="category-link"
                    onClick={(e) => this.setActiveCategory(e, category.id)}
                  >
                    <span className="category-title">{category.name}</span>
                  </div>
                  <div className={'category-children-collapse ' +
                  ((this.state.activeCategory === category.id) ? 'active' : null)}>
                    {category.children.map((children) =>
                      <div key={children.id}>
                        {
                          <NavLink
                            to={{pathname: `/acts/${children.id}`}}
                            exact
                            className="category-link"
                          >
                            <span className="category-title">{children.name}</span>
                          </NavLink>
                        }
                      </div>
                    )}
                  </div>
                </div>
              )
            }
            return (
              <NavLink
                to={{pathname: `/acts/${category.id}`}}
                exact
                className="category-link"
                key={category.id}
              >
                <span className="category-title">{category.name}</span>
              </NavLink>
            )
          }) : null
        }
      </>
    );
  }
}

export default connect(null, null)(CategoryCollapse);

