import React, {Component} from 'react';
import Search from './search/Search';
import {getUserName, isAuthenticated} from '../../../../redux/services/auth.service';
import Back from '../../back/Back';
import Notification from '../../Notification/Notification';
import {isMobile} from 'react-device-detect';
import {connect} from 'react-redux';
import {fetchBannersHeader} from '../../../../redux/actions/bannerAction';

/**
 * @description Component Class for Header (Dashboard Header)
 */
class Header extends Component {
  state = {
    showSearch: false,
    notification: false
  };
  componentDidMount() {
    if (!isMobile) {
      this.setState({notification: true})
    }
  }

  showSearchBox = () => {
    if (this.state.showSearch) {
      this.setState({showSearch: false});
      return;
    }
    this.setState({showSearch: true})
  }

  render() {
    const username = getUserName();
    return  (
      <header id="header">
        <div className="container-fluid">
          <div className="row">
            <div className="col-2 col-md-1">
              <Back />
            </div>
            {isAuthenticated() ?
              <div className="col-8 col-md-3 order-md-2 d-flex justify-content-center justify-content-md-end align-items-center">
                <div className="notifications">
                  {this.state.notification ? <Notification /> : null}
                </div>
                <div className="current-user ml-4">
                  <div className="profile-name">
                    <p>{username}</p>
                  </div>
                </div>
              </div> : null}

            <div className="col-2 d-flex d-md-none align-items-center justify-content-end">
              <span className={this.state.showSearch ? 'search-close' : 'search-icon'} onClick={this.showSearchBox}/>
            </div>

            <div className='search-box-wrapper col-md-8 order-md-1 pl-md-0'>
              <div className={this.state.showSearch ? 'show' : 'hide'}>
                <Search/>
              </div>
            </div>

          </div>
        </div>
      </header>
    );
  }
}

const mapDispatchToProps = (dispatch, ownProps) => ({
  fetchBanner: () => dispatch(fetchBannersHeader(ownProps))
})

const mapStateToProps = state => {
  return state.bannerReducer;
}

export default connect(mapStateToProps, mapDispatchToProps)(Header);
