import React, {Component} from 'react';
import {connect} from 'react-redux';
import {appealCommentPostData, appealCommentsFetchData} from '../../../../redux/actions/commentsAction';
import CommentBox from '../../comment/CommentBox';

/**
 * @description Component Class for CommentBox detail
 */
class AppealComment extends Component {
  componentDidMount() {
    this.props.getAppealComments(this.props.paramId)
  }
  render() {
    return (
      <CommentBox
        comments={this.props.comments}
        addComment={this.props.addComment}
      />
    );
  }
}

const mapDispatchToProps = (dispatch, ownProps) => ({
  addComment: commentData => dispatch(appealCommentPostData(commentData, ownProps)),
  getAppealComments: id => dispatch(appealCommentsFetchData(id))
});

const mapStateToProps = state => {
  return state.appealCommentsReducer;
};

export default connect(mapStateToProps, mapDispatchToProps)(AppealComment);

