import {
  FETCH_KARABAKH_CATEGORY_FAILURE,
  FETCH_KARABAKH_CATEGORY_REQUEST,
  FETCH_KARABAKH_CATEGORY_SUCCESS
} from '../types/actionTypes';

/**
 * @description initial state for karabakhCategoriesReducer
 */
const initialState = {};

/**
 * @description updates state depending on action data
 * @param {Object} state = initialState
 * @param {string} type = action.type
 * @param {} payload
 * @param {Array} objects
 * @returns return state
 */
export default function karabakhCategoriesReducer(state = initialState, {type, payload, meta}) {
  switch (type) {
  case FETCH_KARABAKH_CATEGORY_REQUEST:
    return {
      ...state,
      isFetching: true,
      categories: payload
    };
  case FETCH_KARABAKH_CATEGORY_SUCCESS:
    return {
      ...state,
      isFetching: false,
      categories: payload
    };
  case FETCH_KARABAKH_CATEGORY_FAILURE:
    return {
      ...state,
      isFetching: false,
      categories: payload
    };

  default:
    return state
  }
}
