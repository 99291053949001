import React from 'react';
import Loader from '../../../shared/Loader';
import {connect} from 'react-redux';
import {transportFetchData, transportRemove} from '../../../../redux/actions/transportAction';
import {isAuthenticated} from '../../../../redux/services/auth.service';
import {checkBlock} from '../../../../shared/checkBlocks';
import {ModalContext} from '../../../../context/context';

/**
 * @description Transport component
 */
class Transport extends React.Component {
  componentDidMount() {
    this.props.fetchTransport()
    this.props.toggleAddTransportModal(true)
  }

  render() {
    const useful = this.props.useful ?
      this
        .props
        .useful
        .map((useful) => {
          return (
            <tr key={useful.id}>
              <td>{useful.name}</td>
              <td>{useful.transport && useful.transport.period ? useful.transport.period : ''}</td>
              <td>{useful.transport && useful.transport.cost ? useful.transport.cost : 0} AZN</td>
              <td>
                <div className="d-flex align-center justify-content-end">
                  {isAuthenticated() && checkBlock('useful', 'transport-edit') ?
                    <ModalContext.Consumer>
                      {(context) => (
                        <button
                          className="btn btn-rounded"
                          onClick={() => context.showEditTransportModal(true, useful)}
                        >
                          <i className="icon icon-edit-n" />
                        </button>
                      )}
                    </ModalContext.Consumer>
                    : null }
                  <ModalContext.Consumer>
                    {(context) => (
                      <button
                        className="btn btn-rounded"
                        onClick={() => context.showTransportDetailModal(true, useful)}
                      >
                        <i className="icon icon-detail" />
                      </button>
                    )}
                  </ModalContext.Consumer>
                  {isAuthenticated() && checkBlock('useful', 'transport-delete') ?
                    <button
                      type="button"
                      className="btn btn-rounded"
                      onClick={() => this.props.removeTransport([useful.id])}
                    >
                      <i className="icon icon-delete-2 mr-1"/>
                    </button>
                    : null }
                </div>

              </td>
            </tr>
          )
        }) : null
    return (
      <div className="objects-wrapper pt-0">
        <div>
          <ul className="breadcrumps mb-4">
            <li className="breadcrumps-item">Faydalı</li>
            <li className="breadcrumps-item">Marşrut Avtobus xəttləri</li>
          </ul>
        </div>
        <section className="whitebox-body">
          <div className="table-wrapper">
            {this.props.useful ?
              <table className="table table-custom">
                <thead>
                  <tr>
                    <th>Ad</th>
                    <th>Period</th>
                    <th>Qiymət</th>
                    <th/>
                  </tr>
                </thead>
                <tbody>
                  {useful}
                </tbody>
              </table> : <Loader />}
          </div>
        </section>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch, ownProps) => ({
  fetchTransport: () => dispatch(transportFetchData(ownProps)),
  removeTransport: (transportIds) => dispatch(transportRemove({ids: transportIds}, ownProps))
})

const mapStateToProps = state => {
  return state.transportReducer;
}

export default connect(mapStateToProps, mapDispatchToProps)(Transport);
