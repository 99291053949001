import React, {Component} from 'react';
import Useful from './Useful';
import {Route, Redirect} from 'react-router-dom';
import UsefulMap from './UsefulMap';
import {usefulFetchData} from '../../../redux/actions/usefulAction';
import {connect} from 'react-redux';
import {isAuthenticated, isUnAuthPublicEnabled} from '../../../redux/services/auth.service';
import Transport from './transport/Transport';
import {checkBlock} from '../../../shared/checkBlocks';
import OpenModal from '../../shared/OpenModal';

/**
 * @description UsefulWrapper component
 */
class UsefulWrapper extends Component {
  state = {
    addTransportModalButton: false
  }
  componentDidMount() {
    this.props.fetchUseful()
  }
  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location && this.state.addTransportModalButton) {
      this.toggleAddTransportModal(false)
    }
  }

  /**
   * Show showAddTransportModal button when Transport component was rendered or vice versa
   * @param value
   */
  toggleAddTransportModal = (value) => {
    this.setState({
      addTransportModalButton: value
    })
  }

  render() {
    if (!isUnAuthPublicEnabled()) {
      return <Redirect to='/login' />
    }
    return (
      <div className="container-fluid">
        <div className="row">

          <div className="col-lg-12">
            <div className="whitebox">
              <header className="whitebox-header pb-0">
                <div className="whitebox-title d-md-flex align-items-center justify-content-between">
                  <h4>
                    Faydalı
                  </h4>
                  <div className="float-right">
                    {this.state.addTransportModalButton &&
                    isAuthenticated() &&
                    checkBlock('useful', 'transport-add') ?
                      <OpenModal modal="showAddTransportModal" text="Marşrut xətti yarat" />
                      : null }
                  </div>
                </div>
              </header>

              <section className="whitebox-body">
                <Route
                  path="/useful"
                  exact
                  render={() => <Useful items={this.props.usefuls}/>}
                />
                <Route
                  path="/useful/:id"
                  exact
                  render={() => <UsefulMap />}
                />
                <Route
                  path="/useful/transport/:id"
                  render={() => <Transport toggleAddTransportModal={this.toggleAddTransportModal}/>}
                />
              </section>

            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch, ownProps) => ({
  fetchUseful: () => dispatch(usefulFetchData(ownProps))
})

const mapStateToProps = state => {
  return state.usefulReducer;
}

export default connect(mapStateToProps, mapDispatchToProps)(UsefulWrapper);
