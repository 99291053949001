import axios from 'axios';
import {
    KARABAKH_ABOUT_API, KARABAKH_CAT_API,
    KARABAKH_HERO_API,
    KARABAKH_HERO_API_BY_ID, KARABAKH_HERO_API_BY_TYPE, KARABAKH_MUNICIPALITIES_API,
    KARABAKH_ORGANIZATION_API,
    KARABAKH_ORGANIZATION_API_BY_ID,
    KARABAKH_VICTORY_API,
    KARABAKH_VISIT_API,
    KARABAKH_VISIT_API_BY_ID, MUNICIPALITIES_API, MUNICIPALITIES_CATEGORY_API, MUNICIPALITIES_DELETE_API, MUNICIPALITIES_LIST_API, MUNICIPALITIES_POST_API, MUNICIPALITIES_SHOW_API, MUNICIPALITIES_UPDATE_API
} from '../services/api.service';
import {
    FETCH_KARABAKH_ABOUT_DETAIL_FAILURE,
    FETCH_KARABAKH_ABOUT_DETAIL_REQUEST,
    FETCH_KARABAKH_ABOUT_DETAIL_SUCCESS,
    FETCH_KARABAKH_HERO_DETAIL_FAILURE,
    FETCH_KARABAKH_HERO_DETAIL_REQUEST,
    FETCH_KARABAKH_HERO_DETAIL_SUCCESS,
    FETCH_KARABAKH_HERO_FAILURE,
    FETCH_KARABAKH_HERO_REQUEST,
    FETCH_KARABAKH_HERO_SUCCESS,
    FETCH_KARABAKH_ORGANIZATION_DETAIL_FAILURE,
    FETCH_KARABAKH_ORGANIZATION_DETAIL_REQUEST,
    FETCH_KARABAKH_ORGANIZATION_DETAIL_SUCCESS,
    FETCH_KARABAKH_ORGANIZATION_FAILURE,
    FETCH_KARABAKH_ORGANIZATION_REQUEST,
    FETCH_KARABAKH_ORGANIZATION_SUCCESS,
    FETCH_KARABAKH_VICTORY_DETAIL_FAILURE,
    FETCH_KARABAKH_VICTORY_DETAIL_REQUEST,
    FETCH_KARABAKH_VICTORY_DETAIL_SUCCESS,
    FETCH_KARABAKH_VISIT_DETAIL_FAILURE,
    FETCH_KARABAKH_VISIT_DETAIL_REQUEST,
    FETCH_KARABAKH_VISIT_DETAIL_SUCCESS,
    FETCH_KARABAKH_VISIT_FAILURE,
    FETCH_KARABAKH_VISIT_REQUEST,
    FETCH_KARABAKH_VISIT_SUCCESS, FETCH_MUNICIPALITIES_CATEGORY_FAILURE, FETCH_MUNICIPALITIES_CATEGORY_REQUEST, FETCH_MUNICIPALITIES_CATEGORY_SUCCESS,
    KARABAKH_ABOUT_UPDATE_FAILURE,
    KARABAKH_ABOUT_UPDATE_REQUEST,
    KARABAKH_ABOUT_UPDATE_SUCCESS,
    KARABAKH_HERO_ADD_FAILURE,
    KARABAKH_HERO_ADD_REQUEST,
    KARABAKH_HERO_ADD_SUCCESS,
    KARABAKH_HERO_UPDATE_FAILURE,
    KARABAKH_HERO_UPDATE_REQUEST,
    KARABAKH_HERO_UPDATE_SUCCESS, KARABAKH_MUNICIPALITIES_ADD_FAILURE, KARABAKH_MUNICIPALITIES_ADD_REQUEST, KARABAKH_MUNICIPALITIES_ADD_SUCCESS, KARABAKH_MUNICIPALITIES_DELETE_FAILURE, KARABAKH_MUNICIPALITIES_DELETE_REQUEST, KARABAKH_MUNICIPALITIES_DELETE_SUCCESS, KARABAKH_MUNICIPALITIES_LIST_FAILURE, KARABAKH_MUNICIPALITIES_LIST_REQUEST, KARABAKH_MUNICIPALITIES_LIST_SUCCESS, KARABAKH_MUNICIPALITIES_SHOW_FAILURE, KARABAKH_MUNICIPALITIES_SHOW_REQUEST, KARABAKH_MUNICIPALITIES_SHOW_SUCCESS, KARABAKH_MUNICIPALITIES_UPDATE_FAILURE, KARABAKH_MUNICIPALITIES_UPDATE_REQUEST, KARABAKH_MUNICIPALITIES_UPDATE_SUCCESS,
    KARABAKH_ORGANIZATION_ADD_FAILURE,
    KARABAKH_ORGANIZATION_ADD_REQUEST,
    KARABAKH_ORGANIZATION_ADD_SUCCESS,
    KARABAKH_ORGANIZATION_UPDATE_FAILURE,
    KARABAKH_ORGANIZATION_UPDATE_REQUEST,
    KARABAKH_ORGANIZATION_UPDATE_SUCCESS,
    KARABAKH_VICTORY_UPDATE_FAILURE,
    KARABAKH_VICTORY_UPDATE_REQUEST,
    KARABAKH_VICTORY_UPDATE_SUCCESS,
    KARABAKH_VISIT_ADD_FAILURE,
    KARABAKH_VISIT_ADD_REQUEST,
    KARABAKH_VISIT_ADD_SUCCESS,
    KARABAKH_VISIT_UPDATE_FAILURE,
    KARABAKH_VISIT_UPDATE_REQUEST,
    KARABAKH_VISIT_UPDATE_SUCCESS
} from '../types/actionTypes';

/**
 * @description fetch all visits
 * @param params
 * @param ownProps
 */
export const visitFetchData = (params, ownProps) => {
    return async dispatch => {
        dispatch({type: FETCH_KARABAKH_VISIT_REQUEST});
        try {
            const {data} = await axios.get(KARABAKH_VISIT_API, {params: params});
            dispatch({type: FETCH_KARABAKH_VISIT_SUCCESS, payload: data.data.visits, meta: data.meta});
        } catch (error) {
            dispatch({type: FETCH_KARABAKH_VISIT_FAILURE, payload: error.response.data});
        }
    }
}

/**
 * @description get visit by id
 * @param id
 * @param ownProps
 */
export const visitFetchDetailData = (id, ownProps) => {
    return async dispatch => {
        dispatch({type: FETCH_KARABAKH_VISIT_DETAIL_REQUEST});
        try {
            const {data} = await axios.get(KARABAKH_VISIT_API_BY_ID(id));
            dispatch({type: FETCH_KARABAKH_VISIT_DETAIL_SUCCESS, payload: data.data.visit, meta: data.meta});
        } catch (error) {
            dispatch({type: FETCH_KARABAKH_VISIT_DETAIL_FAILURE, payload: error.response.data});
        }
    }
}

/**
 * @description redux action for visit add
 * @param visitData
 * @param  {Object} ownProps
 */
export const visitPostData = (visitData, ownProps) => {
    return async dispatch => {
        dispatch({type: KARABAKH_VISIT_ADD_REQUEST});
        try {
            const {data} = await axios.post(KARABAKH_VISIT_API, visitData);
            dispatch({type: KARABAKH_VISIT_ADD_SUCCESS, payload: data});
        } catch (error) {
            dispatch({type: KARABAKH_VISIT_ADD_FAILURE, payload: error.response.data});
        }
    }
}

/**
 * @description update visit
 */
export const visitUpdateData = (visitData, ownProps) => {
    return async dispatch => {
        dispatch({type: KARABAKH_VISIT_UPDATE_REQUEST});
        try {
            const {data} = await axios.put(KARABAKH_VISIT_API_BY_ID(visitData.id), visitData);
            dispatch({type: KARABAKH_VISIT_UPDATE_SUCCESS, payload: data.data.visit});
        } catch (error) {
            dispatch({type: KARABAKH_VISIT_UPDATE_FAILURE, payload: error.response.data});
        }
    }
}

/**
 * @description fetch all
 * @param params
 * @param ownProps
 */
export const heroFetchData = (params, ownProps) => {
    return async dispatch => {
        dispatch({type: FETCH_KARABAKH_HERO_REQUEST});
        try {
            const {data} = await axios.get(KARABAKH_HERO_API, {params: params});
            dispatch({type: FETCH_KARABAKH_HERO_SUCCESS, payload: data.data.heroes, meta: data.meta});
        } catch (error) {
            dispatch({type: FETCH_KARABAKH_HERO_FAILURE, payload: error.response.data});
        }
    }
}

/**
 * @description fetch all
 * @param params
 * @param ownProps
 */
export const heroGetDataByType = (params, ownProps) => {
    return async dispatch => {
        try {
            const {data} = await axios.get(KARABAKH_HERO_API_BY_TYPE, {params: params});
            dispatch({type: FETCH_KARABAKH_HERO_SUCCESS, payload: data.data.heroes, meta: data.meta});
        } catch (error) {
            dispatch({type: FETCH_KARABAKH_HERO_FAILURE, payload: error.response.data});
        }
    }
}

/**
 * @description get by id
 * @param id
 * @param ownProps
 */
export const heroFetchDetailData = (id, ownProps) => {
    return async dispatch => {
        dispatch({type: FETCH_KARABAKH_HERO_DETAIL_REQUEST});
        try {
            const {data} = await axios.get(KARABAKH_HERO_API_BY_ID(id));
            dispatch({type: FETCH_KARABAKH_HERO_DETAIL_SUCCESS, payload: data.data.hero, meta: data.meta});
        } catch (error) {
            dispatch({type: FETCH_KARABAKH_HERO_DETAIL_FAILURE, payload: error.response.data});
        }
    }
}

/**
 * @description redux action for hero add
 * @param heroData
 * @param  {Object} ownProps
 */
export const heroPostData = (heroData, ownProps) => {
    return async dispatch => {
        dispatch({type: KARABAKH_HERO_ADD_REQUEST});
        try {
            const {data} = await axios.post(KARABAKH_HERO_API, heroData);
            dispatch({type: KARABAKH_HERO_ADD_SUCCESS, payload: data});
        } catch (error) {
            dispatch({type: KARABAKH_HERO_ADD_FAILURE, payload: error.response.data});
        }
    }
}

/**
 * @description update
 */
export const heroUpdateData = (heroData, ownProps) => {
    return async dispatch => {
        dispatch({type: KARABAKH_HERO_UPDATE_REQUEST});
        try {
            const {data} = await axios.put(KARABAKH_HERO_API_BY_ID(heroData.id), heroData);
            dispatch({type: KARABAKH_HERO_UPDATE_SUCCESS, payload: data.data.hero});
        } catch (error) {
            dispatch({type: KARABAKH_HERO_UPDATE_FAILURE, payload: error.response.data});
        }
    }
}

/**
 * @description fetch bout of Karabakh
 * @param ownProps
 */
export const aboutKarabakhFetchData = (ownProps) => {
    return async dispatch => {
        dispatch({type: FETCH_KARABAKH_ABOUT_DETAIL_REQUEST});
        try {
            const {data} = await axios.get(KARABAKH_ABOUT_API);
            dispatch({type: FETCH_KARABAKH_ABOUT_DETAIL_SUCCESS, payload: data.data.about, meta: data.meta});
        } catch (error) {
            dispatch({type: FETCH_KARABAKH_ABOUT_DETAIL_FAILURE, payload: error.response.data});
        }
    }
}

/**
 * @description update
 */
export const aboutKarabakhUpdateData = (aboutData, ownProps) => {
    return async dispatch => {
        dispatch({type: KARABAKH_ABOUT_UPDATE_REQUEST});
        try {
            const {data} = await axios.put(KARABAKH_ABOUT_API, aboutData);
            dispatch({type: KARABAKH_ABOUT_UPDATE_SUCCESS, payload: data.data.about});
        } catch (error) {
            dispatch({type: KARABAKH_ABOUT_UPDATE_FAILURE, payload: error.response.data});
        }
    }
}

/**
 * @description fetch
 * @param ownProps
 */
export const victoryKarabakhFetchData = (ownProps) => {
    return async dispatch => {
        dispatch({type: FETCH_KARABAKH_VICTORY_DETAIL_REQUEST});
        try {
            const {data} = await axios.get(KARABAKH_VICTORY_API);
            dispatch({type: FETCH_KARABAKH_VICTORY_DETAIL_SUCCESS, payload: data.data.victory, meta: data.meta});
        } catch (error) {
            dispatch({type: FETCH_KARABAKH_VICTORY_DETAIL_FAILURE, payload: error.response.data});
        }
    }
}

/**
 * @description update
 */
export const victoryKarabakhUpdateData = (aboutData, ownProps) => {
    return async dispatch => {
        dispatch({type: KARABAKH_VICTORY_UPDATE_REQUEST});
        try {
            const {data} = await axios.put(KARABAKH_VICTORY_API, aboutData);
            dispatch({type: KARABAKH_VICTORY_UPDATE_SUCCESS, payload: data.data.victory});
        } catch (error) {
            dispatch({type: KARABAKH_VICTORY_UPDATE_FAILURE, payload: error.response.data});
        }
    }
}

/**
 * @description fetch all organizations
 * @param params
 * @param ownProps
 */
export const organizationFetchData = (params, ownProps) => {
    return async dispatch => {
        dispatch({type: FETCH_KARABAKH_ORGANIZATION_REQUEST});
        try {
            const {data} = await axios.get(KARABAKH_ORGANIZATION_API, {params: params});
            dispatch({type: FETCH_KARABAKH_ORGANIZATION_SUCCESS, payload: data.data.organizations, meta: data.meta});
        } catch (error) {
            dispatch({type: FETCH_KARABAKH_ORGANIZATION_FAILURE, payload: error.response.data});
        }
    }
}

/**
 * @description get organization by id
 * @param id
 * @param ownProps
 */
export const organizationFetchDetailData = (id, ownProps) => {
    return async dispatch => {
        dispatch({type: FETCH_KARABAKH_ORGANIZATION_DETAIL_REQUEST});
        try {
            const {data} = await axios.get(KARABAKH_ORGANIZATION_API_BY_ID(id));
            dispatch({type: FETCH_KARABAKH_ORGANIZATION_DETAIL_SUCCESS, payload: data.data.organization, meta: data.meta});
        } catch (error) {
            dispatch({type: FETCH_KARABAKH_ORGANIZATION_DETAIL_FAILURE, payload: error.response.data});
        }
    }
}

/**
 * @description redux action for organization add
 * @param visitData
 * @param  {Object} ownProps
 */
export const organizationPostData = (visitData, ownProps) => {
    return async dispatch => {
        dispatch({type: KARABAKH_ORGANIZATION_ADD_REQUEST});
        try {
            const {data} = await axios.post(KARABAKH_ORGANIZATION_API, visitData);
            dispatch({type: KARABAKH_ORGANIZATION_ADD_SUCCESS, payload: data});
        } catch (error) {
            dispatch({type: KARABAKH_ORGANIZATION_ADD_FAILURE, payload: error.response.data});
        }
    }
}


export const municipalitiesPostData = (postData, ownProps) => {
    return async dispatch => {
        dispatch({type: KARABAKH_MUNICIPALITIES_ADD_REQUEST});
        try {
            const {data} = await axios.post(`${MUNICIPALITIES_POST_API}`, {...postData, cat_id: ownProps.match.params.category});
            dispatch({type: KARABAKH_MUNICIPALITIES_ADD_SUCCESS, payload: data});
        } catch (error) {
            dispatch({type: KARABAKH_MUNICIPALITIES_ADD_FAILURE, payload: error.response.data});
        }
    }
}


export const municipalitiesUpdateData = (id, postData, ownProps) => {
    return async dispatch => {
        dispatch({type: KARABAKH_MUNICIPALITIES_UPDATE_REQUEST});
        try {
            const {data} = await axios.put(`${MUNICIPALITIES_UPDATE_API}${id}`, {...postData, cat_id: ownProps.match.params.category});
            dispatch({type: KARABAKH_MUNICIPALITIES_UPDATE_SUCCESS, payload: data});
        } catch (error) {
            dispatch({type: KARABAKH_MUNICIPALITIES_UPDATE_FAILURE, payload: error.response.data});
        }
    }
}

export const municipalitiesShowData = (id, ownProps) => {
    return async dispatch => {
        dispatch({type: KARABAKH_MUNICIPALITIES_SHOW_REQUEST});
        try {
            const {data} = await axios.get(`${MUNICIPALITIES_SHOW_API}${id}`);
            dispatch({type: KARABAKH_MUNICIPALITIES_SHOW_SUCCESS, payload: data.data.municipalityNews});
        } catch (error) {
            dispatch({type: KARABAKH_MUNICIPALITIES_SHOW_FAILURE, payload: error.response.data});
        }
    }
}


export const municipalitiesDeleteData = (id, ownProps) => {
    return async dispatch => {
        dispatch({type: KARABAKH_MUNICIPALITIES_DELETE_REQUEST});
        try {
            const {data} = await axios.get(`${MUNICIPALITIES_DELETE_API}${id}`);
            dispatch({type: KARABAKH_MUNICIPALITIES_DELETE_SUCCESS, payload: data});
        } catch (error) {
            dispatch({type: KARABAKH_MUNICIPALITIES_DELETE_FAILURE, payload: error.response.data});
        }
    }
}

export const municipalitiesListData = (ownProps) => {
    return async dispatch => {
        dispatch({type: KARABAKH_MUNICIPALITIES_LIST_REQUEST});
        try {
            const {data} = await axios.get(`${MUNICIPALITIES_LIST_API}${ownProps.match.params.category}`);
            dispatch({type: KARABAKH_MUNICIPALITIES_LIST_SUCCESS, payload: data.data.municipalityNews});
        } catch (error) {
            dispatch({type: KARABAKH_MUNICIPALITIES_LIST_FAILURE, payload: error.response.data});
        }
    }
}

/**
 * @description update organization
 */
export const organizationUpdateData = (organizationData, ownProps) => {
    return async dispatch => {
        dispatch({type: KARABAKH_ORGANIZATION_UPDATE_REQUEST});
        try {
            const {data} = await axios.put(KARABAKH_ORGANIZATION_API_BY_ID(organizationData.id), organizationData);
            dispatch({type: KARABAKH_ORGANIZATION_UPDATE_SUCCESS, payload: data.data.organization});
        } catch (error) {
            dispatch({type: KARABAKH_ORGANIZATION_UPDATE_FAILURE, payload: error.response.data});
        }
    }
}

export const municipalitiesCategoryFetchData = (ownProps) => {
    return async dispatch => {
        dispatch({type: FETCH_MUNICIPALITIES_CATEGORY_REQUEST});
        try {
            const {data} = await axios.get(KARABAKH_MUNICIPALITIES_API);
            dispatch({type: FETCH_MUNICIPALITIES_CATEGORY_SUCCESS, payload: data.data.categories});
            const categories = [];
            for (let i = 0; i < data.data.categories.length; i++) {
                categories.push(data.data.categories[i]);
            }
            localStorage.setItem('municipalities_categories', JSON.stringify(categories));
        } catch (error) {
            dispatch({type: FETCH_MUNICIPALITIES_CATEGORY_FAILURE, payload: error.response.data});
        }
    }
}
