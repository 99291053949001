import {
  ADD_TASK_COMMENTS_REQUEST,
  ADD_TASK_COMMENTS_SUCCESS,
  ADD_TASK_COMMENTS_FAILURE,
  FETCH_TASK_COMMENTS_REQUEST,
  FETCH_TASK_COMMENTS_SUCCESS,
  FETCH_TASK_COMMENTS_FAILURE
} from '../types/actionTypes';

/**
 * @description initial state for menuReducer
 */
const initialState = {};

/**
 * @description updates state depending on action data
 * @param {Object} state = initialState
 * @param {string} type = action.type
 * @param {} payload
 * @param {Array} menus
 * @returns return state
 */
export default function taskCommentsReducer(state = initialState, {type, payload}) {
  switch (type) {
  case ADD_TASK_COMMENTS_REQUEST:
    return {
      ...state,
      isFetching: true
    };
  case ADD_TASK_COMMENTS_SUCCESS:
    return {
      ...state,
      isFetching: false
    };
  case ADD_TASK_COMMENTS_FAILURE:
    return {
      ...state,
      isFetching: false
    };
  case FETCH_TASK_COMMENTS_REQUEST:
    return {
      ...state,
      isFetching: true,
      comments: payload
    };
  case FETCH_TASK_COMMENTS_SUCCESS:
    return {
      ...state,
      isFetching: false,
      comments: payload
    };
  case FETCH_TASK_COMMENTS_FAILURE:
    return {
      ...state,
      isFetching: false,
      comments: payload
    };

  default:
    return state
  }
}
