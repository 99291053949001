import React, {Component} from 'react';
import {withFormik} from 'formik';
import AssignmentForm from '../AssignmentForm';
import {taskEditData} from '../../../../redux/actions/taskAction';
import {connect} from 'react-redux';
import CloseModal from '../../../shared/CloseModal';
import ModalSuccess from '../../../shared/ModalSuccess';
import {taskSchema} from '../../../../shared/validation';
import {removeSuccessMessage} from '../../../../redux/actions/messageAction';
import {ModalContext} from '../../../../context/context';

/**
 * @description Component Class for EditAssignment new object
 */
class EditAssignmentForm extends Component {
  static contextType = ModalContext

  componentDidMount() {
    const {task} = this.context.state;
    let selectedUsers = [];
    let employees = [];
    for (let i = 0; i < task.employees.length; i++) {
      let obj = {};
      obj['value'] = task.employees[i].id;
      obj['label'] = task.employees[i].name;
      selectedUsers.push(obj);
      employees.push(task.employees[i].id);
    }
    this.props.setValues({
      'id': task.id,
      'status': task.status.id,
      'employees': selectedUsers,
      'due_date': new Date(task.due_date),
      'due_time': new Date(task.due_date),
      'description': task.description,
      'images': task.images
    });
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.files.fileToken && nextProps.files.fileToken !== this.props.files.fileToken) {
      this.props.setFieldValue('images', [...this.props.values.images, ...nextProps.files.fileToken]);
    }
  }

  render() {
    return (
      <div className="modal-custom">
        <div className="container-fluid">
          <div className="row">
            <div className="col-xl-8 offset-xl-2">
              <div className="modal-custom-inner">
                <div className="modal-custom-header d-flex align-items-center justify-content-between">
                  <h4>
                    Tapşırığı dəyiş
                  </h4>
                  <CloseModal tooltip={true} modal="showAssignmentEditModal" />
                </div>
                <AssignmentForm edit={true} formProps = {this.props} submitText={'Tapşırığı dəyiş'}/>

                {this.props.tasks.success ? <ModalSuccess text="Tapşırıq uğurla dəyişdirildi" /> : null }
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapPropsToValues = (props) => {
  return {
    id: '',
    status: '',
    employees: [],
    due_date: '',
    due_time: '',
    description: '',
    images: []
  }
}

const EditAssignment = withFormik({
  enableReinitialize: false,
  mapPropsToValues,
  validationSchema: taskSchema,
  validateOnBlur: true,
  validateOnChange: true,
  handleSubmit: (values, {props, resetForm, setSubmitting}) => {
    props.addTask(values);

    setTimeout(() => {
      props.removeSuccess();
    }, 4000);
  }
})(EditAssignmentForm);

const mapDispatchToProps = (dispatch, ownProps) => ({
  addTask: taskData => dispatch(taskEditData(taskData, ownProps)),
  removeSuccess: () => dispatch(removeSuccessMessage())
})

const mapStateToProps = state => {
  return {
    files: state.fileReducer,
    tasks: state.taskReducer
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(EditAssignment);

