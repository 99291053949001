import React, {Component} from 'react';
import {resumeFetchData} from '../../../../redux/actions/socialAction';
import {connect} from 'react-redux';
import {isAuthenticated} from '../../../../redux/services/auth.service';
import {NavLink} from 'react-router-dom';
import {checkBlock} from '../../../../shared/checkBlocks';
import {deleteAction} from '../../../../redux/actions/deleteAction';
import {DELETE_RESUME_REQUEST, DELETE_RESUME_SUCCESS, DELETE_RESUME_FAILURE} from '../../../../redux/types/actionTypes';
import {RESUME_API} from '../../../../redux/services/api.service';

/**
 * @description Component Class for user's Resumes
 */
class Resumes extends Component {
  componentDidMount() {
    this.props.fetchResume()
  }

  removeObject = (ids) => {
    const idsArray = [];
    const types =
    {
      request: DELETE_RESUME_REQUEST,
      success: DELETE_RESUME_SUCCESS,
      failure: DELETE_RESUME_FAILURE
    };
    const api = RESUME_API;
    idsArray.push(ids);
    this.props.deleteResume(idsArray, types, api);

    document.getElementById(`resume${ids}`).remove();
  }

  render() {
    const resumes = this.props.resumes ?
      this.props.resumes.map(resume => {
        return (
          <tr key={resume.id} id={`resume${resume.id}`}>
            <td className="d-flex align-items-center">
              <span className="company-logo mr-2"/>
              {resume.fullname}
            </td>
            <td>{resume.contact_number}</td>
            <td>{resume.profession}</td>
            <td>
              <div className="d-flex align-items-center">
                <NavLink
                  to={'/resume/' + resume.id}
                  className="btn btn-rounded"
                  activeClassName="active"
                >
                  <i className="icon icon-detail" />
                </NavLink>
                {isAuthenticated() && checkBlock('social', 'resume-edit') ?
                  <NavLink
                    to={'/resume/edit/' + resume.id}
                    className="btn btn-rounded"
                    activeClassName="active"
                  >
                    <i className="icon icon-edit-n" />
                  </NavLink>
                  : null }
                {isAuthenticated() && checkBlock('social', 'resume-delete') ?
                  <button
                    type="button"
                    className="btn btn-rounded"
                    onClick={() => this.removeObject(resume.id)}
                  >
                    <i className="icon icon-delete-2 mr-1"/>
                  </button>
                  : null }
              </div>
            </td>
          </tr>
        )
      }) : null
    return  (
      <div className="table-wrapper">
        <table className="table table-custom">
          <thead>
            <tr>
              <th>İşçinin adı, soyadı</th>
              <th>Əlaqə</th>
              <th>Vakansiya</th>
              <th>Ətraflı</th>
            </tr>
          </thead>

          <tbody>
            {resumes}
          </tbody>
        </table>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch, ownProps) => ({
  fetchResume: () => dispatch(resumeFetchData(ownProps)),
  deleteResume: (ids, types, api) => dispatch(deleteAction(ids, types, api))
})

const mapStateToProps = state => {
  return state.socialReducer;
}

export default connect(mapStateToProps, mapDispatchToProps)(Resumes);
