import React from 'react';
// import logo from '../../assets/img/logo.svg';

/**
 * @description Component Class for Header of Auth (in this case Login Component)
 */
const AuthHeader = () =>
  <header className="auth-header">
    <div className="container">
      <div className="row">
        <div className="col-md-12">
          {/* <img src={logo} alt="logo" className="auth-header-logo" /> */}
        </div>
      </div>
    </div>
  </header>

export default AuthHeader;
