import {
  FETCH_STATUS_REQUEST,
  FETCH_STATUS_SUCCESS,
  FETCH_STATUS_FAILURE
} from '../types/actionTypes';

/**
 * @description initial state for objectReducer
 */
const initialState = {};

/**
 * @description updates state depending on action data
 * @param {Object} state = initialState
 * @param {string} type = action.type
 * @param {} payload
 * @param {Array} statuses
 * @returns return state
 */
export default function taskReducer(state = initialState, {type, payload}) {
  switch (type) {
  case FETCH_STATUS_REQUEST:
    return {
      ...state,
      isFetching: true,
      statuses: payload
    };
  case FETCH_STATUS_SUCCESS:
    return {
      ...state,
      isFetching: false,
      statuses: payload
    };
  case FETCH_STATUS_FAILURE:
    return {
      ...state,
      isFetching: false,
      statuses: payload
    };
  default:
    return state
  }
}
