import React, {Component} from 'react';
import ButtonLoader from '../../../loader/ButtonLoader';
import {connect} from 'react-redux';
import {filePostFetch} from '../../../../redux/actions/fileAction';
import 'react-datepicker/dist/react-datepicker.css';
import InputError from '../../errors/InputError';

/**
 * @description HeroForm
 */
class HeroForm extends Component {
  state = {
    fileData: []
  }

  /**
   * @description file upload
   * @param {Visit} e
   * @memberOf VisitForm
   */
  setFile = (e) => {
    const fileData = new FormData();
    for (let i = 0; i < e.currentTarget.files.length; i++) {
      fileData.append('files[]', e.currentTarget.files[i])
    }
    this.setState({
      fileData
    })
    this.props.uploadFile(fileData)
  }

  /**
   * @description file delete
   * @memberOf ObjectForm
   * @param token
   */
  removeFiles = (token) => {
    const editedImages = this.props.formProps.values.images.filter((file) => file.token !== token);
    this.props.formProps.setFieldValue('images', editedImages);
  }

  /**
   * @description all errors for one input
   * @param name
   * @returns {*}
   */
  handleInputError = (name) => {
    let {heroes} = this.props;
    let heroErrors = (heroes && heroes.hero && heroes.hero.error) ? heroes.hero.data : [];
    let {touched, errors} = this.props.formProps;

    if (touched[name] && errors[name]) {
      heroErrors[name] = [errors[name]];
    }

    return <InputError errors={heroErrors} name={name}/>
  }

  render() {
    const {values, handleSubmit, handleBlur, handleChange, touched, errors} = this.props.formProps;
    const fileNames = values.images ? values.images.map((file, index) => {
      return (
        <p className="file-item mr-3 mb-4" key={index}>
          <img src={`${process.env.REACT_APP_SERVER}/storage/` + file.path} alt={file.path}/>
          <button
            className="btn-remove"
            onClick={() => this.removeFiles(file.token)}
          >x
          </button>
        </p>
      )
    }) : ''
    return (
      <section className="whitebox-body">
        <div className="add-form">
          <div className="row">
            <div className="col-6">
              <div className="form-group form-group-custom">
                <label htmlFor="name">Soyadı, adı, atasının adı</label>
                <input
                  type="text"
                  name="name"
                  className={'form-control form-control-custom ' + ((touched.name && errors.name) ? 'has-error' : null)}
                  placeholder="Soyadı, adı, atasının adı qeyd edin"
                  value={values.name}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                {this.handleInputError('name')}
              </div>
            </div>
            <div className="col-6">
              <div className="form-group form-group-custom">
                <label htmlFor="name">Rütbəsi</label>
                <input
                  type="text"
                  name="military_rank"
                  className={'form-control form-control-custom ' + ((touched.name && errors.name) ? 'has-error' : null)}
                  placeholder="Rütbə qeyd edin"
                  value={values.military_rank}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                {this.handleInputError('military_rank')}
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-6">
              <div className="form-group form-group-custom">
                <label htmlFor="name">Doğum tarixi</label>
                <input
                  type="text"
                  name="birth_data"
                  className={'form-control form-control-custom ' + ((touched.name && errors.name) ? 'has-error' : null)}
                  placeholder="Doğum tarixi qeyd edin"
                  value={values.birth_data}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                {this.handleInputError('birth_data')}
              </div>
            </div>
            <div className="col-6">
              <div className="form-group form-group-custom">
                <label htmlFor="name">Vəfat tarixi</label>
                <input
                  type="text"
                  name="martyrdom_data"
                  className={'form-control form-control-custom ' + ((touched.name && errors.name) ? 'has-error' : null)}
                  placeholder="Vəfat tarixi qeyd edin"
                  value={values.martyrdom_data}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                {this.handleInputError('martyrdom_data')}
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-6">
              <div className="form-group form-group-custom">
                <label htmlFor="name">Təltifləri</label>
                <textarea
                  name="awards"
                  className={'form-control form-control-custom ' + ((touched.awards && errors.awards) ? 'has-error' : null)}
                  placeholder="Təltifləri qeyd edin"
                  value={values.awards}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                {this.handleInputError('awards')}
              </div>
            </div>
            <div className="col-6">
              <div className="form-group form-group-custom">
                <label htmlFor="name">Şəkil</label>
                <div className="row">
                  <div className="col-md-6">
                    <div className="file-name d-flex justify-content-start flex-wrap">
                      {fileNames}
                    </div>
                  </div>
                  <div className="col-md-6 over-hide">
                    <div className="btn file-button">Şəkil yüklə</div>
                    <input
                      name="file"
                      type="file"
                      placeholder="Şəkil yüklə"
                      // value={values.images}
                      onChange={(e) => {
                        this.setFile(e)
                      }}
                      onBlur={handleBlur}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="form-group form-group-custom">
            <button
              type="button"
              onClick={() => (handleSubmit(values))}
              className="btn btn-theme w-100"
              disabled={this.props.isFetching ? true : null}
            >
              {this.props.files.isFetching ? <ButtonLoader/> : <span>Yadda saxla</span>}
            </button>
          </div>
        </div>
      </section>
    );
  }
}

const mapDispatchToProps = (dispatch, ownProps) => ({
  uploadFile: file => dispatch(filePostFetch(file, ownProps))
})

const mapStateToProps = state => {
  return {
    heroes: state.karabakhReducer,
    files: state.fileReducer
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(HeroForm);
