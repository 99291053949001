import React, {Component} from 'react'
import {connect} from 'react-redux'
import {withFormik} from 'formik'
import {transportSchema} from '../../../../shared/validation'
import CloseModal from '../../../shared/CloseModal'
import ModalSuccess from '../../../shared/ModalSuccess'
import {removeSuccessMessage} from '../../../../redux/actions/messageAction'
import TransportForm from './TransportForm'
import TransportMap from './TransportMap'
import {transportEditData, transportFetchData} from '../../../../redux/actions/transportAction'
import {ModalContext} from '../../../../context/context'
import {transportFormsMapProps} from '../../../../shared/formMapProps';

/**
 * @description Component Class for edit transport
 */
class EditTransportForm extends Component {
  static contextType = ModalContext

  state = {
    stations: []
  }
  componentDidMount() {
    const {details} = this.context.state

    this.props.setValues({
      definition: 'transport',
      id: details.id,
      category_id: details.category_id,
      name: details.name,
      description: details.description,
      stations: details.transport ? details.transport.stations : [],
      transport: details.transport || []
    })

    if (details.transport) {
      this.setState({
        stations: details.transport.stations || []
      })
    }
  }

  addStations = (data) => {
    this.setState({stations: data})
    this.props.setFieldValue('stations', data)
  }

  render() {
    return (
      <div className="modal-custom">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-10 offset-lg-1">
              <div className="modal-custom-inner">
                <div className="modal-custom-header d-flex align-items-center justify-content-between">
                  <h4>
                    Marşrut xətti yarat

                    <p className="error">
                      {
                        this.props.errorData && this.props.errorData.error ?
                          <span>{this.props.errorData.error}</span> :
                          null
                      }
                    </p>
                  </h4>
                  <CloseModal tooltip={true} modal='showEditTransportModal'/>
                </div>
                <div className="row">
                  <div className="col-md-5 scrolled">
                    <TransportForm
                      parentCallBack={this.setActivities}
                      formProps={this.props}
                      stations={this.state.stations}
                      stationTypes={this.state.stationTypes}
                    />
                  </div>
                  <div className="col-md-7">
                    <div className="objects-wrapper">
                      <TransportMap
                        addStations={this.addStations}
                        stations={this.state.stations}
                        stationTypes={this.state.stationTypes}
                      />
                    </div>
                  </div>
                </div>
                {this.props.success ? <ModalSuccess text="Marşrut xətti uğurla dəyişdirildi" /> : null }
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

const mapPropsToValues = (props) => transportFormsMapProps(props)

const EditTransport = withFormik({
  enableReinitialize: false,
  mapPropsToValues,
  validationSchema: transportSchema,
  validateOnBlur: true,
  validateOnChange: true,
  handleSubmit: (values, {props, setSubmitting}) => {
    props.editTransport(values).then(() => {
      props.fetchTransport()
      setTimeout(() => {
        props.removeSuccess()
      }, 3000)
    })
  }
})(EditTransportForm)

const mapDispatchToProps = (dispatch, ownProps) => ({
  fetchTransport: () => dispatch(transportFetchData(ownProps)),
  editTransport: transportData => dispatch(transportEditData(transportData, ownProps)),
  removeSuccess: () => dispatch(removeSuccessMessage())
})

const mapStateToProps = state => {
  return state.transportReducer
}

export default connect(mapStateToProps, mapDispatchToProps)(EditTransport)
