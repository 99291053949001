import axios from 'axios';
import {
  FETCH_REPRESENTATIVE_REQUEST,
  FETCH_REPRESENTATIVE_SUCCESS,
  FETCH_REPRESENTATIVE_FAILURE,
  FETCH_REPRESENTATIVE_DETAIL_REQUEST,
  FETCH_REPRESENTATIVE_DETAIL_SUCCESS,
  FETCH_REPRESENTATIVE_DETAIL_FAILURE,
  ADD_REPRESENTATIVE_REQUEST,
  ADD_REPRESENTATIVE_SUCCESS,
  ADD_REPRESENTATIVE_FAILURE
} from '../types/actionTypes';
import {
  REPRESENTATIVE_API, REPRESENTATIVE_API_BY_ID
} from '../services/api.service';

/**
 * @description fetch all representayives's notes
 */
export const representativeNotesFetchData = (params, ownProps) => {
  return async dispatch => {
    dispatch({type: FETCH_REPRESENTATIVE_REQUEST});
    try {
      const {data} = await axios.get(REPRESENTATIVE_API, {params: params});
      dispatch({type: FETCH_REPRESENTATIVE_SUCCESS, payload: data.data.representativeNotes, meta: data.meta});
    } catch (error) {
      dispatch({type: FETCH_REPRESENTATIVE_FAILURE, payload: error});
    }
  }
}

/**
 * @description post representayives's notes
 */
export const representativeNotesPostData = (notesData, ownProps) => {
  return async dispatch => {
    dispatch({type: ADD_REPRESENTATIVE_REQUEST});
    try {
      const {data} = await axios.post(REPRESENTATIVE_API, {
        title: notesData.title,
        address: notesData.address,
        comment: notesData.comment,
        images: notesData.images
      });
      dispatch({type: ADD_REPRESENTATIVE_SUCCESS, payload: data.data.representativeNotes});
    } catch (error) {
      dispatch({type: ADD_REPRESENTATIVE_FAILURE, payload: error});
    }
  }
}

/**
 * @description redux action for Representatives's notes fetch
 * @param  {Object} ownProps
 * @param  {string} REPRESENTATIVE_API_BY_ID
 */
export const representativeFetchDetailData = (id, ownProps) => {
  return async dispatch => {
    dispatch({type: FETCH_REPRESENTATIVE_DETAIL_REQUEST});
    try {
      const {data} = await axios.get(REPRESENTATIVE_API_BY_ID(id));
      dispatch({type: FETCH_REPRESENTATIVE_DETAIL_SUCCESS, payload: data.data.representativeNote});
    } catch (error) {
      dispatch({type: FETCH_REPRESENTATIVE_DETAIL_FAILURE, payload: error.response.data.data});
    }
  }
}
