import React, {Component} from 'react';
import ButtonLoader from '../../loader/ButtonLoader';
import {connect} from 'react-redux';
import {filePostFetch} from '../../../redux/actions/fileAction';

/**
 * @description Task form
 */
class NotesForm extends Component {
  /**
   * @description file upload
   * @param {Object} e
   * @memberOf AppealForm
   */
  setFile = (e) =>  {
    const fileData  = new FormData();
    for (let i = 0; i < e.currentTarget.files.length; i++) {
      fileData.append('files[]', e.currentTarget.files[i])
    }
    this.setState({
      fileData
    })
    this.props.uploadFile(fileData)
  }

  /**
   * @description file delete
   * @param {Object} e
   * @memberOf AppealForm
   */
  removeFiles = (token) => {
    const editedImages = this.props.formProps.values.images.filter((file) => file.token !== token);
    this.props.formProps.setFieldValue('images', editedImages);
  }
  render() {
    const {values, handleBlur, handleChange, handleSubmit, touched, errors} = this.props.formProps;
    const fileNames = values.images ? values.images.map((file, index) => {
      return (
        <p className="file-item mr-3 mb-4" key={index}>
          <img src={`${process.env.REACT_APP_SERVER}/storage/` + file.path} alt={file.path}/>
          <button
            className="btn-remove"
            onClick={() => this.removeFiles(file.token)}
          >x</button>
        </p>
      )
    }) : ''
    return (
      <div className="add-form">
        <div className="form-group form-group-custom">
          <label htmlFor="title">Qeydin başlığı</label>
          <input
            type="text"
            name="title"
            className='form-control form-control-custom'
            placeholder="Qeydin başlığı"
            value={values.title}
            onChange={handleChange}
            onBlur={handleBlur}
          />
          <p className="error">
            {(this.props.note && this.props.note.error && this.props.note.data.title)
              ? this.props.note.data.title.map(error => <span key={error}>{error}</span>)
              : null}

            {(touched.title && errors.title)
              ? <span>{errors.title}</span>
              : null}
          </p>
        </div>

        <div className="form-group form-group-custom">
          <label htmlFor="address">Ünvan</label>
          <input
            type="text"
            name="address"
            className='form-control form-control-custom'
            placeholder="Ünvan"
            value={values.address}
            onChange={handleChange}
            onBlur={handleBlur}
          />
          <p className="error">
            {(this.props.note && this.props.note.error && this.props.note.data.address)
              ? this.props.note.data.address.map(error => <span key={error}>{error}</span>)
              : null}

            {(touched.address && errors.address)
              ? <span>{errors.address}</span>
              : null}
          </p>
        </div>

        <div className="form-group form-group-custom">
          <label htmlFor="details">Ətraflı məlumat</label>
          <textarea
            name="comment"
            className='form-control form-control-custom'
            value={values.note}
            onChange={handleChange}
            onBlur={handleBlur}
          />
          <p className="error">
            {(this.props.note && this.props.note.error && this.props.note.data.comment)
              ? this.props.note.data.comment.map(error => <span key={error}>{error}</span>)
              : null}

            {(touched.comment && errors.comment)
              ? <span>{errors.comment}</span>
              : null}
          </p>
        </div>

        <div className="row">
          <div className="col-md-6 form-group form-group-custom">
            <div className="file-name d-flex justify-content-start flex-wrap">
              {fileNames}
            </div>
          </div>
          <div className="col-md-6 form-group form-group-custom over-hide">
            <div className="btn file-button">Şəkil yüklə</div>
            <input
              name="file"
              type="file"
              multiple
              placeholder="Şəkil yüklə"
              onChange={(e) => { this.setFile(e) }}
              onBlur={handleBlur}
            />
            <p className="error">
              {(this.props.note && this.props.note.error && this.props.note.data.file)
                ? this.props.note.data.file.map(error => <span key={error}>{error}</span>)
                : null}

              {(touched.file && errors.file)
                ? <span>{errors.file}</span>
                : null}
            </p>
          </div>
        </div>
        <div className="form-group form-group-custom">
          <button
            type="button"
            onClick={() => handleSubmit(values)}
            className="btn btn-theme w-100"
            disabled={this.props.isFetching ? true : null}
          >
            {this.props.files.isFetching ? <ButtonLoader /> : <span>Qeyd yarat</span>}
          </button>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch, ownProps) => ({
  uploadFile: file => dispatch(filePostFetch(file, ownProps))
})

const mapStateToProps = state => {
  return {
    files: state.fileReducer
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(NotesForm);
