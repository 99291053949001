import React, {Component} from 'react';
import {connect} from 'react-redux';
import {withFormik} from 'formik';
import ModalSuccess from '../../../shared/ModalSuccess';
import {removeSuccessMessage} from '../../../../redux/actions/messageAction';
import {organizationFetchDetailData, organizationUpdateData} from '../../../../redux/actions/karabakhAction';
import xariBulbulTitle from '../../../../assets/img/xaribulbul_title.svg';
import {organizationSchema} from '../../../../shared/validation';
import OrganizationForm from './OrganizationForm';

/**
 * @description
 */
class OrganizationEdit extends Component {
  componentDidMount() {
    const {id} = this.props.match.params;
    this.props.fetchDetail(id);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.files.fileToken && nextProps.files.fileToken !== this.props.files.fileToken) {
      this.props.setFieldValue('images', [...this.props.values.images, ...nextProps.files.fileToken])
    }
  }

  render() {
    return (
      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-12">
            <div className="whitebox"  id="visit">
              <header className="whitebox-header bordered">
                <div className="whitebox-title d-flex justify-content-start">
                  <img src={xariBulbulTitle} alt="Qarabağ Azərbaycandır!" className={'pr-2'}/>
                  <div className={'category_title d-flex align-items-center'}>
                    <span>Qarabağ Azərbaycandır!</span>
                  </div>
                  {this.props.organizations.errors ? <span>{this.props.organizations.errors.error}</span> : null}
                </div>
              </header>
              <OrganizationForm formProps={this.props} />
              {this.props.organizations.success ? <ModalSuccess text="Məlumat dəyişdirildi" /> : null }
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapPropsToValues = (props) => {
  if (props.organizations && props.organizations.organization) {
    const visit = props.organizations.organization;

    return {
      id: visit.id,
      name: visit.name,
      description: visit.description,
      images: visit.images
    }
  }
  return {
    name: '',
    description: '',
    images: []
  }
}

const VisitEditForm = withFormik({
  enableReinitialize: true,
  mapPropsToValues,
  validationSchema: organizationSchema,
  validateOnBlur: true,
  validateOnChange: true,
  handleSubmit: (values, {props, setSubmitting}) => {
    props.updateOrganization(values);

    setTimeout(() => {
      props.removeSuccess();
    }, 4000);
  }
})(OrganizationEdit);

const mapDispatchToProps = (dispatch, ownProps) => ({
  updateOrganization: data => dispatch(organizationUpdateData(data, ownProps)),
  fetchDetail: id => dispatch(organizationFetchDetailData(id, ownProps)),
  removeSuccess: () => dispatch(removeSuccessMessage())
})

const mapStateToProps = state => {
  return {
    files: state.fileReducer,
    organizations: state.karabakhReducer
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(VisitEditForm);
