import {
  FETCH_ABOUT_REQUEST,
  FETCH_ABOUT_SUCCESS,
  FETCH_ABOUT_FAILURE,
  EDIT_ABOUT_REQUEST,
  EDIT_ABOUT_SUCCESS,
  EDIT_ABOUT_FAILURE, REMOVE_SUCCESS_MESSAGE
} from '../types/actionTypes';

/**
 * @description initial state for normativeReducer
 */
const initialState = {};

/**
 * @description updates state depending on about data
 * @param {Object} state = initialState
 * @param {string} type = action.type
 * @param {} payload
 * @returns return state
 */
export default function aboutReducer(state = initialState, {type, payload}) {
  switch (type) {
  case FETCH_ABOUT_REQUEST:
    return {
      ...state,
      isFetching: true,
      aboutDetails: payload
    };
  case FETCH_ABOUT_SUCCESS:
    return {
      ...state,
      isFetching: false,
      aboutDetails: payload
    };
  case FETCH_ABOUT_FAILURE:
    return {
      ...state,
      isFetching: false,
      aboutDetails: payload
    };

  case EDIT_ABOUT_REQUEST:
    return {
      ...state,
      isFetching: true,
      success: false
    };
  case EDIT_ABOUT_SUCCESS:
    return {
      ...state,
      isFetching: false,
      success: true
    };
  case EDIT_ABOUT_FAILURE:
    return {
      ...state,
      isFetching: false,
      success: false
    };
  case REMOVE_SUCCESS_MESSAGE:
    return {
      ...state,
      success:false
    };
  default:
    return state
  }
}
