import React, {Component} from 'react';
import arrowLeft from '../../../assets/img/left-arrow.svg';
import {withRouter} from 'react-router';

/**
 * @description Component Class for Header of Auth (in this case Login Component)
 */
class Back extends Component {
  componentDidMount() {
    window.onpopstate = this.goBack
  }

  render() {
    return (
      <button className="btn btn-default btn-back" onClick={this.props.history.goBack}>
        <img className="mr-3" src={arrowLeft} alt={arrowLeft}/>
      </button>
    )
  }
}
const BackWithRouter = withRouter(Back);

export default BackWithRouter;
