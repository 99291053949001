import axios from 'axios';
import {
  FILE_REQUEST,
  FILE_SUCCESS,
  FILE_FAILURE,
  FILE_DELETE_SUCCESS
} from '../types/actionTypes';
import {FILE_API} from '../services/api.service';

/**
 * @description redux action for file
 * @param  {Object} fileData
 */
export const filePostFetch = (fileData, ownProps) => {
  return async dispatch => {
    dispatch({type: FILE_REQUEST});
    try {
      const {data} = await axios.post(FILE_API('image'), fileData,
        {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        });
      dispatch({type: FILE_SUCCESS, payload: data.data.tokens});
    } catch (error) {
      dispatch({type: FILE_FAILURE, payload: error.response.data});
    }
  }
}

/**
 * @description redux action for file
 * @param  {Object} fileData
 */
export const fileDelete = (fileData, token) => {
  const filteredFiles = fileData.filter(file => file.token !== token);
  return async dispatch => {
    dispatch({type: FILE_DELETE_SUCCESS, payload: filteredFiles});
  }
}
