import React from 'react';
import success from '../../assets/img/success.svg';

/**
 * @description Component Class for modal success
 */
const ModalSuccess = (props) =>

  <div className="modal-success d-flex align-items-center justify-content-center flex-column">
    <img src={success} alt={success} />
    <p>
      {props.text}
    </p>
  </div>

export default ModalSuccess;
