import {
  FETCH_REPRESENTATIVE_REQUEST,
  FETCH_REPRESENTATIVE_SUCCESS,
  FETCH_REPRESENTATIVE_FAILURE,
  FETCH_REPRESENTATIVE_DETAIL_REQUEST,
  FETCH_REPRESENTATIVE_DETAIL_SUCCESS,
  FETCH_REPRESENTATIVE_DETAIL_FAILURE,
  ADD_REPRESENTATIVE_REQUEST,
  ADD_REPRESENTATIVE_SUCCESS,
  ADD_REPRESENTATIVE_FAILURE,
  DELETE_REPRESENTATIVE_REQUEST,
  DELETE_REPRESENTATIVE_SUCCESS,
  DELETE_REPRESENTATIVE_FAILURE,
  REMOVE_SUCCESS_MESSAGE
} from '../types/actionTypes';

/**
 * @description initial state for objectReducer
 */
const initialState = {};

/**
 * @description updates state depending on action data
 * @param {Object} state = initialState
 * @param {string} type = action.type
 * @param {} payload
 * @param {Array} representatives
 * @returns return state
 */
export default function representativeReducer(state = initialState, {type, payload, meta}) {
  switch (type) {
  case ADD_REPRESENTATIVE_REQUEST:
    return {
      ...state,
      isFetching: true,
      success: false,
      note: payload
    };
  case ADD_REPRESENTATIVE_SUCCESS:
    return {
      ...state,
      isFetching: false,
      success: true,
      note: payload
    };
  case ADD_REPRESENTATIVE_FAILURE:
    return {
      ...state,
      isFetching: false,
      success: false,
      note: payload
    };

  case DELETE_REPRESENTATIVE_REQUEST:
    return {
      ...state,
      success: false,
      isFetching: true
    };
  case DELETE_REPRESENTATIVE_SUCCESS:
    return {
      ...state,
      success: true,
      isFetching: false
    };
  case DELETE_REPRESENTATIVE_FAILURE:
    return {
      ...state,
      success: false,
      isFetching: false,
      errors: payload
    };

  case FETCH_REPRESENTATIVE_REQUEST:
    return {
      ...state,
      isFetching: true,
      notes: payload
    };
  case FETCH_REPRESENTATIVE_SUCCESS:
    return {
      ...state,
      isFetching: false,
      notes: payload,
      meta: meta
    };
  case FETCH_REPRESENTATIVE_FAILURE:
    return {
      ...state,
      isFetching: false,
      notes: payload
    };

  case FETCH_REPRESENTATIVE_DETAIL_REQUEST:
    return {
      ...state,
      isFetching: true,
      notesDetail: payload
    };
  case FETCH_REPRESENTATIVE_DETAIL_SUCCESS:
    return {
      ...state,
      isFetching: false,
      notesDetail: payload
    };
  case FETCH_REPRESENTATIVE_DETAIL_FAILURE:
    return {
      ...state,
      isFetching: false,
      notesDetail: payload
    };
  case REMOVE_SUCCESS_MESSAGE:
    return {
      ...state,
      success:false
    };
  default:
    return state
  }
}
