import React from 'react';
import validationText from '../../../../shared/validation-text'

/**
 * @description MapForm component
 */
class MapForm extends React.Component {
  state = {
    activeMarker: {},
    errors: {
      name: false,
      type: false
    }
  }

  componentDidMount() {
    this.setState({
      activeMarker: this.props.activeMarker
    })
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.activeMarker.position !== prevProps.activeMarker.position) {
      this.setState({
        activeMarker: this.props.activeMarker
      })
    }
  }

  /**
   * @description update state values on input change
   * @param event
   */
  handleInputChange = (event) => {
    let {activeMarker} = this.state;
    activeMarker[event.target.name] = event.target.value;
    this.setState({activeMarker: activeMarker});
  }

  /**
   * @description add or update parent date if inputs not empty
   * @param marker
   * @returns {void|*}
   */
  pushMarker = (marker) => {
    const {required, min} = validationText
    const errors = {
      name: !marker.name ? required('Ad') : (marker.name.length < 2 ? min : false),
      type: marker.type ? false : required('Növ')
    }
    this.setState({
      errors: errors
    })

    if (errors.name || errors.type) {
      return false
    }

    return this.props.pushMarker(this.state.activeMarker)
  }

  render() {
    let {activeMarker} = this.state;
    let {stationTypes} = this.props;

    return (
      <div className="info-window">
        <div className="form-group">
          <input
            name="name"
            type="text"
            className="form-control mm-3"
            value={activeMarker.name || ''}
            onChange={this.handleInputChange}
          />
          <p className="error">
            {this.state.errors.name ? <span>{this.state.errors.name}</span> : null}
          </p>
        </div>
        <div className="form-group">
          <select
            name="type"
            className="form-control mb-3"
            value={activeMarker.type || 'waypoint'}
            onChange={this.handleInputChange}
          >
            {
              stationTypes.map((type, i) => (
                <option key={i} value={type.value}>
                  {type.name}
                </option>
              ))
            }
          </select>
          <p className="error">
            {this.state.errors.type ? <span>{this.state.errors.type}</span> : null}
          </p>
        </div>
        <div className="form-group">
          <button
            type="button"
            className="btn btn-submit"
            onClick={() => this.pushMarker(activeMarker)}
          >
            Əlavə et
          </button>
          <button
            type="button"
            disabled={!activeMarker.index && activeMarker.index !== 0}
            className="btn btn-submit"
            onClick={() => this.props.removeMarker(activeMarker.index)}
          >
            Sil
          </button>
        </div>
      </div>
    )
  }
}

export default MapForm;
