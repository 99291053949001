import React, {Component} from 'react';
import {connect} from 'react-redux';
import {withFormik} from 'formik';
import {objectCategoryFetchData, objectZonesFetchData} from '../../../../../redux/actions/objectAction';
import {withRouter} from 'react-router-dom';
import {SearchContext} from '../../../../../context/context';

/**
 * @description Component Class for searching objects by address, id or category
 */
class Search extends Component {
  static contextType = SearchContext;
  componentDidMount() {
    this.props.fetchObjectCategories();
    this.props.fetchZones();
  }

  getSearchResults = () => {
    this.context.setSearchValues(this.props.values);
    this.props.history.push('/search-results');
  }

  render() {
    const {values, handleBlur, handleChange} = this.props;

    const objectCategories = this.props.objectCategories ? this.props.objectCategories.map(category => {
      return (
        <option key={category.id} value={category.id}>{category.name}</option>
      )
    }) : null

    const objectZones = this.props.objectZones ? this.props.objectZones.map(category => {
      return (
        <option key={category.id} value={category.id}>{category.name}</option>
      )
    }) : null
    return  (
      <div className="row">
        <div className="form-group form-group-custom col-xl-3 mb-3 m-0">

          {/* input for address or id number of objects */}
          <input
            type="text"
            name="keyword"
            className="form-control form-control-custom search-input"
            placeholder="Axtar"
            value={values.keyword}
            onChange={handleChange}
            onBlur={handleBlur}
          />
        </div>

        <div className="form-group form-group-custom col-xl-3 mb-3 m-0">

          {/* selectbox for categories of objects */}
          <select name="category_id"
            className="form-control form-control-custom selectbox"
            placeholder="category"
            defaultValue={values.category_id}
            onChange={handleChange}
            onBlur={handleBlur}
          >
            <option value=''>Bütün Kateqoriyalar</option>
            {objectCategories}
          </select>
        </div>

        <div className="form-group form-group-custom col-xl-3 mb-3 m-0">

          {/* selectbox for categories of objects */}
          <select name="zone_id"
            className="form-control form-control-custom selectbox"
            defaultValue={values.zone_id}
            onChange={handleChange}
            onBlur={handleBlur}
          >
            <option value=''>Bütün Ərazilər</option>
            {objectZones}
          </select>
        </div>

        <div className="form-group form-group-custom col-xl-2 m-0">
          <button
            className="btn btn-theme w-100"
            onClick={this.getSearchResults}
          >
            Təsdiqlə
          </button>
        </div>
      </div>
    );
  }
}

const mapPropsToValues = (props) => {
  return {
    keyword: '',
    category_id: '',
    zone_id: ''
  }
}

const SearchForm = withFormik({
  enableReinitialize: false,
  mapPropsToValues
})(Search);

const mapDispatchToProps = (dispatch, ownProps) => ({
  fetchObjectCategories: () => dispatch(objectCategoryFetchData(ownProps)),
  fetchZones: () => dispatch(objectZonesFetchData())
})

const mapStateToProps = state => {
  return state.objectReducer
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SearchForm));
