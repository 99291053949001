import axios from 'axios';
import {
  ADD_TASK_SUCCESS,
  ADD_TASK_REQUEST,
  ADD_TASK_FAILURE,
  FETCH_TASK_REQUEST,
  FETCH_TASK_SUCCESS,
  FETCH_TASK_FAILURE,
  EDIT_TASK_SUCCESS,
  EDIT_TASK_FAILURE,
  EDIT_TASK_REQUEST,
  FETCH_TASK_DETAIL_REQUEST,
  FETCH_TASK_DETAIL_SUCCESS,
  FETCH_TASK_DETAIL_FAILURE,
  TASK_FORWARD_REQUEST,
  TASK_FORWARD_SUCCESS,
  TASK_FORWARD_FAILURE,
  TASK_ACCEPT_REQUEST,
  TASK_ACCEPT_SUCCESS,
  TASK_ACCEPT_FAILURE,
  TASK_DONE_REQUEST,
  TASK_DONE_SUCCESS, TASK_DONE_FAILURE
} from '../types/actionTypes';
import {
  ACCEPT_TASK_API, DONE_TASK_API,
  FORWARD_TASK_API,
  TASK_API, TASK_API_BY_ID
} from '../services/api.service';
import moment from 'moment';

/**
 * @description redux action for TASK EDIT
 * @param taskData
 * @param  {Object} ownProps
 */
export const taskPostData = (taskData, ownProps) => {
  let employees = [];
  for (let i = 0; i < taskData.employees.length; i++) {
    employees.push(Number(taskData.employees[i].value))
  }
  return async dispatch => {
    dispatch({type: ADD_TASK_REQUEST});
    try {
      const {data} = await axios.post(TASK_API, {
        description:   taskData.description,
        due_date:      moment(taskData.due_date).format('YYYY-MM-DD') + ' ' + moment(taskData.due_time).format('HH:mm:ss'),
        employees:     employees,
        status_id:      1,
        images:        taskData.images
      });
      dispatch({type: ADD_TASK_SUCCESS, payload: data.data.task});
    } catch (error) {
      dispatch({type: ADD_TASK_FAILURE, payload: error.response.data.data});
    }
  }
}

/**
 * @description fetch all tasks
 */
export const taskFetchData = (params, ownProps) => {
  return async dispatch => {
    dispatch({type: FETCH_TASK_REQUEST});
    try {
      const {data} = await axios.get(TASK_API, {params: params});
      dispatch({type: FETCH_TASK_SUCCESS, payload: data.data.tasks, meta: data.meta});
    } catch (error) {
      dispatch({type: FETCH_TASK_FAILURE, payload: error});
    }
  }
}

/**
 * @description redux action for TASK edit
 * @param taskData
 * @param  {Object} ownProps
 */
export const taskEditData = (taskData, ownProps) => {
  let employees = [];
  for (let i = 0; i < taskData.employees.length; i++) {
    employees.push(Number(taskData.employees[i].value))
  }
  return async dispatch => {
    dispatch({type: EDIT_TASK_REQUEST});
    try {
      const {data} = await axios.put(TASK_API_BY_ID(taskData.id), {
        description:   taskData.description,
        due_date:      moment(taskData.due_date).format('YYYY-MM-DD') + ' ' + moment(taskData.due_time).format('HH:mm:ss'),
        employees:     employees,
        status_id:      1,
        images:        taskData.images
      });
      dispatch({type: EDIT_TASK_SUCCESS, payload: data.data.task});
    } catch (error) {
      dispatch({type: EDIT_TASK_FAILURE, payload: error.response.data.data});
    }
  }
}

/**
 * @description redux action for TASK fetch
 * @param id
 * @param  {Object} ownProps
 */
export const taskFetchDetail = (id, ownProps) => {
  return async dispatch => {
    dispatch({type: FETCH_TASK_DETAIL_REQUEST});
    try {
      const {data} = await axios.get(TASK_API_BY_ID(id));
      dispatch({type: FETCH_TASK_DETAIL_SUCCESS, payload: data.data.task});
    } catch (error) {
      dispatch({type: FETCH_TASK_DETAIL_FAILURE, payload: error.response.data.data});
    }
  }
}

/**
 * @description forward task
 * @param taskData
 * @param  {Object} ownProps
 */
export const forwardTask = (taskData, ownProps) => {
  return async dispatch => {
    dispatch({type: TASK_FORWARD_REQUEST});
    try {
      const {data} = await axios.post(FORWARD_TASK_API, {
        task_id: taskData.task_id,
        employee_id: taskData.employee
      });
      dispatch({type: TASK_FORWARD_SUCCESS, payload: data.data});
    } catch (error) {
      dispatch({type: TASK_FORWARD_FAILURE, payload: error.response.data.data});
    }
  }
}

/**
 * @description accept task
 * @param paramId
 * @param ownProps
 * @returns {function(...[*]=)}
 */
export const acceptTask = (paramId, ownProps) => {
  return async dispatch => {
    dispatch({type: TASK_ACCEPT_REQUEST});
    try {
      const {data} = await axios.get(ACCEPT_TASK_API(paramId), {
      });
      dispatch({type: TASK_ACCEPT_SUCCESS, payload: data.data});
    } catch (error) {
      dispatch({type: TASK_ACCEPT_FAILURE, payload: error.response.data});
    }
  }
}

/**
 * @description done task
 * @param paramId
 * @param ownProps
 * @returns {function(...[*]=)}
 */
export const doneTask = (paramId, ownProps) => {
  return async dispatch => {
    dispatch({type: TASK_DONE_REQUEST});
    try {
      const {data} = await axios.get(DONE_TASK_API(paramId), {
      });
      dispatch({type: TASK_DONE_SUCCESS, payload: data});
    } catch (error) {
      dispatch({type: TASK_DONE_FAILURE, payload: error.response.data});
    }
  }
}
