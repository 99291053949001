import React, {Component} from 'react';
import AddLocationMap from '../map/AddLocationMap';
import {connect} from 'react-redux';
import {withFormik} from 'formik';
import {objectEditData} from '../../../../redux/actions/objectAction';
import {objectSchema} from '../../../../shared/validation';
import ObjectForm from '../ObjectForm';
import CloseModal from '../../../shared/CloseModal';
import ModalSuccess from '../../../shared/ModalSuccess';
import {removeSuccessMessage} from '../../../../redux/actions/messageAction';

/**
 * @description Component Class for adding new object
 */
class AddObjectForm extends Component {
  /**
   * @description component on init
   * @description adds event listener to dom
   * @memberOf AddObject
   */
  componentDidMount() {
    const {objectDetails} = this.props.objects;
    this.props.setValues({
      'id': objectDetails.id,
      'name': objectDetails.building.name,
      'ownership': objectDetails.building.ownership,
      'operation_status': objectDetails.building.operation_status,
      'block_count': objectDetails.building.block_count,
      'block_status': objectDetails.building.block_status,
      'floors_count': objectDetails.building.floors_count,
      'apartments_count': objectDetails.building.apartments_count,
      'registered_peoples_count': objectDetails.building.registered_peoples_count,
      'area_size': objectDetails.building.area_size,
      'current_situation': objectDetails.building.current_situation,
      'activities': objectDetails.activities,
      'education_status': objectDetails.building.education_status,
      'address': objectDetails.building.address,
      'lat': objectDetails.building.lat,
      'lng': objectDetails.building.lng,
      'construction_date': new Date(objectDetails.building.construction_date),
      'history': objectDetails.building.history,
      'note': objectDetails.building.note,
      'images': objectDetails.images,
      'object_category_id': objectDetails.object_category_id,
      'object_type_id': objectDetails.object_type_id,
      'object_zone_id': objectDetails.object_zone_id
    });
  }

  /**
   * @description getCoords from Map and sets to inputs
   * @description Hide modal
   */
  addCoordsToLangLng = (lat, lng) => {
    this.props.setFieldValue('lat', lat);
    this.props.setFieldValue('lng', lng);
  }

  /**
   * @description get activities from Object form
   */
  setActivities = (activities) => {
    this.props.setFieldValue('activities', activities);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.files.fileToken && nextProps.files.fileToken !== this.props.files.fileToken) {
      this.props.setFieldValue('images', [...this.props.values.images, ...nextProps.files.fileToken])
    }
  }

  render() {
    const coords = {lat: this.props.objects.objectDetails.building.lat, lng: this.props.objects.objectDetails.building.lng}
    return (
      <div className="modal-custom">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-10 offset-lg-1">
              <div className="modal-custom-inner">
                <div className="modal-custom-header d-flex align-items-center justify-content-between">
                  <h4>
                  Obyekti redaktə et

                    <p className="error mt-2">
                      {this.props.objects.errors ? <span>{this.props.objects.errors.error}</span> : null}
                    </p>

                  </h4>

                  <CloseModal tooltip={true} modal='showEditObjectModal' />
                </div>
                <div className="row">
                  <div className="col-md-5 scrolled">
                    <ObjectForm
                      activities={this.props.objects.objectDetails.activities}
                      formProps = {this.props}
                      objectCategory = {this.props.objects.objectDetails.object_category_id}
                      objectType = {this.props.objects.objectDetails.object_type_id}
                      parentCallBack={this.setActivities}
                    />
                  </div>

                  <div className="col-md-7">
                    <div className="objects-wrapper">
                      <AddLocationMap coords = {coords} onSelectCoords={this.addCoordsToLangLng} />
                    </div>
                  </div>
                </div>

                {this.props.objects.success ? <ModalSuccess text="Yeni obyekt uğurla dəyişdirildi" /> : null }
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapPropsToValues = (props) => {
  return {
    id: '',
    name: '',
    ownership: '',
    operation_status: '',
    block_count: '',
    block_status: '',
    floors_count: '',
    apartments_count: '',
    registered_peoples_count: '',
    area_size: '',
    current_situation: '',
    activities: [],
    education_status: '',
    address: '',
    lat: '',
    lng: '',
    construction_date: '',
    history: '',
    note: '',
    images: [],
    object_category_id: '',
    object_type_id: '',
    object_zone_id: ''
  }
}

const AddObject = withFormik({
  enableReinitialize: false,
  mapPropsToValues,
  validationSchema: objectSchema,
  validateOnBlur: true,
  validateOnChange: true,
  handleSubmit: (values, {props, setSubmitting}) => {
    props.editObject(values);
    setTimeout(() => {
      props.removeSuccess();
    }, 4000);
  }
})(AddObjectForm);

const mapDispatchToProps = (dispatch, ownProps) => ({
  editObject: objectData => dispatch(objectEditData(objectData, ownProps)),
  removeSuccess: () => dispatch(removeSuccessMessage())
})

const mapStateToProps = state => {
  return {
    files: state.fileReducer,
    objects: state.objectReducer
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(AddObject);
