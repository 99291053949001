import React, {Component} from 'react';
import {connect} from 'react-redux';
import CloseModal from '../../../shared/CloseModal';
import {ModalContext} from '../../../../context/context';
import {NavLink} from 'react-router-dom';
import {isAuthenticated} from '../../../../redux/services/auth.service';
import {checkBlock} from '../../../../shared/checkBlocks';
import {deleteAction} from '../../../../redux/actions/deleteAction';
import {DELETE_EVENTS_REQUEST, DELETE_EVENTS_SUCCESS, DELETE_EVENTS_FAILURE} from '../../../../redux/types/actionTypes';
import {EVENT_API} from '../../../../redux/services/api.service';

/**
 * @description Component Class for EditAssignment new object
 */
class EventDetail extends Component {
  static contextType = ModalContext

  closeModal = () => {
    this.context.showEventDetailModal(false)
  }

  removeObject = (ids) => {
    const idsArray = [];
    const types =
    {
      request: DELETE_EVENTS_REQUEST,
      success: DELETE_EVENTS_SUCCESS,
      failure: DELETE_EVENTS_FAILURE
    };
    const api = EVENT_API;
    idsArray.push(ids);
    this.props.deleteEvent(idsArray, types, api);

    this.context.showEventDetailModal(false)
  }

  render() {
    return (
      <div className="modal-custom">
        <div className="container-fluid">
          <div className="row">
            <div className="col-xl-8 offset-xl-2">
              <div className="modal-custom-inner">
                <div className="modal-custom-header d-flex align-items-center justify-content-between">
                  <h4>
                    Ətraflı
                  </h4>
                  <CloseModal modal="showEventDetailModal" />
                </div>
                <div className="table-wrapper">
                  <table className="table table-custom">
                    <thead>
                      <tr>
                        <th className="width20percent">Yer</th>
                        <th>Qeyd</th>
                        <th/>
                      </tr>
                    </thead>

                    <tbody>
                      <tr>
                        <td>
                          {this.context.state.details.event.extendedProps.place}
                        </td>
                        <td>
                          {this.context.state.details.event.extendedProps.note}
                        </td>
                        <td>
                          <div className="d-flex align-items-center justify-content-center">
                            {isAuthenticated() && checkBlock('events', 'event-edit') ?
                              <NavLink
                                to={`/events/edit/${this.context.state.details.event.id}`}
                                exact
                                className="btn btn-rounded"
                                onClick={this.closeModal}
                              >
                                <i className="icon icon-edit-gray" />
                              </NavLink>
                              : null}
                            {isAuthenticated() && checkBlock('objects', 'object-delete') ?
                              <button
                                type="button"
                                className="btn btn-rounded"
                                onClick={() => this.removeObject(this.context.state.details.event.id)}
                              >
                                <i className="icon icon-delete-2 mr-1"/>
                              </button>
                              : null }
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch, ownProps) => ({
  deleteEvent: (ids, types, api) => dispatch(deleteAction(ids, types, api))
})

export default connect(null, mapDispatchToProps)(EventDetail);

