import * as Yup from 'yup';
import validationText from './validation-text';

// A custom validation function. This must return an object which keys are

/**
 * @description Yup schema for validation of login form
 */
export const loginSchema = Yup
  .object()
  .shape({
    username: Yup
      .string()
      .min(3, validationText.userMin)
      .email(validationText.userEmail)
      .required(validationText.emptyField),
    password: Yup
      .string()
      .min(8, validationText.password)
      .required(validationText.emptyField),
    
    
  });

/**
 * @description Yup schema for validation of login form
 */
export const eventSchema = Yup
  .object()
  .shape({
    title: Yup
      .string()
      .min(2, validationText.min)
      .required(validationText.emptyField),
    place: Yup
      .string()
      .min(2, validationText.min)
      .required(validationText.emptyField),
    note: Yup
      .string()
      .min(2, validationText.min)
      .required(validationText.emptyField),
    start: Yup
      .date()
      .required(validationText.emptyField),
    end: Yup
      .date()
      .required(validationText.emptyField)
  });

/**
 * @description Yup schema for validation of login form
 */
export const registerSchema = Yup
  .object()
  .shape({
    name: Yup
      .string()
      .min(3, validationText.userMin)
      .required(validationText.emptyField),
    email: Yup
      .string()
      .min(3, validationText.userMin)
      .email(validationText.userEmail)
      .required(validationText.emptyField),
    phone: Yup
      .string()
      .min(9, validationText.userPhoneMin)
      .required(validationText.emptyField),
    password: Yup
      .string()
      .min(8, validationText.password).matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
      "Minimum 8 simvoldan ibarət olmalıdır, Böyük, Kiçik, minimum 1ədəd və özəl simvollardan istifadə edin : ?%$/&"
    )
      .required(validationText.emptyField),
    password_confirmation: Yup
      .string()
      .min(8, validationText.passwordConfirmMin).matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
      "Minimum 8 simvoldan ibarət olmalıdır, Böyük, Kiçik, minimum 1ədəd və özəl simvollardan istifadə edin : ?%$/&"
    )
      .required(validationText.emptyField)
      .test('password_confirmation', validationText.passwordConfirmEqual, function (value) {
        return this.parent.password === value;
      })
  });

/**
 * @description Yup schema for validation of object form
 */
export const objectSchema = Yup
  .object()
  .shape({
    name: Yup
      .string()
      .min(2, validationText.min)
      .required(validationText.emptyField),
    ownership: Yup
      .string()
      .min(2, validationText.min)
      .required(validationText.emptyField),
    operation_status: Yup
      .string()
      .min(2, validationText.min)
      .required(validationText.emptyField),
    block_count: Yup
      .string()
      .required(validationText.emptyField),
    block_status: Yup
      .string()
      .min(2, validationText.min)
      .required(validationText.emptyField),
    floors_count: Yup
      .string()
      .required(validationText.emptyField),
    apartments_count: Yup
      .string()
      .required(validationText.emptyField),
    registered_peoples_count: Yup
      .string()
      .required(validationText.emptyField),
    area_size: Yup
      .string()
      .required(validationText.emptyField),
    current_situation: Yup
      .string()
      .min(2, validationText.min)
      .required(validationText.emptyField),
    activities: Yup
      .array(),
    education_status: Yup
      .string()
      .min(2, validationText.min)
      .required(validationText.emptyField),
    address: Yup
      .string()
      .min(2, validationText.min)
      .max(255, validationText.max)
      .required(validationText.emptyField),
    lat: Yup
      .string()
      .required(validationText.emptyField),
    lng: Yup
      .string()
      .required(validationText.emptyField),
    construction_date: Yup
      .string()
      .min(2, validationText.min)
      .required(validationText.emptyField),
    object_category_id: Yup
      .string()
      .required(validationText.emptyField),
    object_type_id: Yup
      .string()
      .required(validationText.emptyField),
    object_zone_id: Yup
      .string()
      .required(validationText.emptyField)
  });

/**
 * @description Yup schema for validation of task form
 */
export const taskSchema = Yup
  .object()
  .shape({
    employees: Yup
      .string()
      .required(validationText.emptyField),
    due_date: Yup
      .string()
      .required(validationText.emptyField),
    due_time: Yup
      .string()
      .required(validationText.emptyField),
    description: Yup
      .string()
      .min(2, validationText.min)
      .required(validationText.emptyField)
  });

/**
 * @description Yup schema for validation of task form
 */
export const appealSchema = Yup
  .object()
  .shape({
    title: Yup
      .string()
      .required(validationText.emptyField),
    appeal_category_id: Yup
      .string()
      .required(validationText.emptyField),
    phone: Yup
      .string()
      .required(validationText.emptyField),
    text: Yup
      .string()
      .min(2, validationText.min)
      .required(validationText.emptyField),
    address: Yup
      .string()
      .min(2, validationText.min)
      .max(255, validationText.max)
      .required(validationText.emptyField)
  });

/**
 * @description Yup schema for validation of note form
 */
export const noteSchema = Yup
  .object()
  .shape({
    title: Yup
      .string()
      .required(validationText.emptyField),
    comment: Yup
      .string()
      .required(validationText.emptyField),
    address: Yup
      .string()
      .min(2, validationText.min)
      .max(255, validationText.max)
      .required(validationText.emptyField)
  });

/**
 * @description Yup schema for vacancy
 */
export const vacancySchema = Yup
  .object()
  .shape({
    vacancy_category_id: Yup
      .string()
      .required(validationText.emptyField),
    profession: Yup
      .string()
      .required(validationText.emptyField),
    related_person: Yup
      .string()
      .required(validationText.emptyField),
    city_id: Yup
      .string()
      .required(validationText.emptyField),
    min_salary: Yup
      .number()
      .required(validationText.emptyField),
    max_salary: Yup
      .number()
      .required(validationText.emptyField),
    min_age: Yup
      .string()
      .required(validationText.emptyField),
    max_age: Yup
      .string()
      .required(validationText.emptyField),
    education_id: Yup
      .string()
      .required(validationText.emptyField),
    experience_id: Yup
      .string()
      .required(validationText.emptyField),
    contact_number: Yup
      .string()
      .required(validationText.emptyField),
    company_name: Yup
      .string()
      .min(2, validationText.min)
      .max(255, validationText.max)
      .required(validationText.emptyField),
    candidate_requirements: Yup
      .string()
      .min(2, validationText.min)
      .required(validationText.emptyField)
  });

/**
 * @description Yup schema for vacancy
 */
export const lawsSchema = Yup
  .object()
  .shape({
    category_id: Yup
      .string()
      .required(validationText.emptyField),
    title: Yup
      .string()
      .min(2, validationText.min)
      .required(validationText.emptyField),
    description: Yup
      .string()
      .min(2, validationText.min)
      .required(validationText.emptyField),
    number: Yup
      .string()
      .max(255, validationText.max),
    adopted_at: Yup
      .string()
      .max(255, validationText.max)
  });

/*
 * @description Yup schema for validation of about form
 */
export const aboutSchema = Yup
  .object()
  .shape({
    description: Yup
      .string()
      .required(validationText.emptyField),
    data: Yup
      .array()
  });

/**
 * @description Yup schema for resumes
 */
export const resumeSchema = Yup
  .object()
  .shape({
    vacancy_category_id: Yup
      .string()
      .required(validationText.emptyField),
    city_id: Yup
      .string()
      .required(validationText.emptyField),
    fullname: Yup
      .string()
      .required(validationText.emptyField),
    profession: Yup
      .string()
      .required(validationText.emptyField),
    min_salary: Yup
      .string()
      .required(validationText.emptyField),
    age: Yup
      .string()
      .required(validationText.emptyField),
    education_id: Yup
      .string()
      .required(validationText.emptyField),
    experience_id: Yup
      .string()
      .required(validationText.emptyField),
    contact_number: Yup
      .string()
      .required(validationText.emptyField),
    gender: Yup
      .string()
      .required(validationText.emptyField),
    ability: Yup
      .string()
      .min(2, validationText.min)
      .required(validationText.emptyField)
  });

/**
 * @description Yup schema for transport (Useful)
 */
export const transportSchema = Yup
  .object()
  .shape({
    category_id: Yup
      .string()
      .required(validationText.emptyField),
    name: Yup
      .string()
      .required(validationText.emptyField)
      .min(2, validationText.min)
      .max(255, validationText.max)
  });

/**
 * @description Yup schema for validation of visit form
 */
export const visitSchema = Yup
  .object()
  .shape({
    name: Yup
      .string()
      .min(2, validationText.min)
      .required(validationText.emptyField),
    description: Yup
      .string()
      .min(2, validationText.min)
      .required(validationText.emptyField),
    images: Yup
      .array()
      .required(validationText.emptyField)
  });

/**
 * @description Yup schema for validation form
 */
export const heroSchema = Yup
  .object()
  .shape({
    name: Yup
      .string()
      .min(2, validationText.min)
      /*
            .required(validationText.emptyField) */
      ,
    military_rank: Yup
      .string()
      .min(2, validationText.min)
      /*
            .required(validationText.emptyField) */
      ,
    birth_data: Yup
      .string()
      .min(2, validationText.min)
      /*
            .required(validationText.emptyField) */
      ,
    martyrdom_data: Yup
      .string()
      .min(2, validationText.min)
      /*
            .required(validationText.emptyField) */
      ,
    awards: Yup
      .string()
      .min(2, validationText.min)
    // .required(validationText.emptyField)
  });

/**
 * @description Yup schema for validation of about form
 */
export const aboutKarabakhSchema = Yup
  .object()
  .shape({
    description: Yup
      .string()
      .required(validationText.emptyField)
  });

/**
 * @description Yup schema for validation of organization form
 */
export const organizationSchema = Yup
  .object()
  .shape({
    name: Yup
      .string()
      .min(2, validationText.min)
      .required(validationText.emptyField),
    description: Yup
      .string()
      .min(2, validationText.min)
      .required(validationText.emptyField),
    images: Yup
      .array()
      .required(validationText.emptyField)
  });