import axios from 'axios';
import {
  FETCH_ABOUT_REQUEST,
  FETCH_ABOUT_SUCCESS,
  FETCH_ABOUT_FAILURE,
  EDIT_ABOUT_REQUEST,
  EDIT_ABOUT_SUCCESS,
  EDIT_ABOUT_FAILURE
} from '../types/actionTypes';
import {
  ABOUT_API,
  ABOUT_EDIT_API
} from '../services/api.service';

/**
 * @description fetch about
 */
export const aboutFetchData = (ownProps) => {
  return async dispatch => {
    dispatch({type: FETCH_ABOUT_REQUEST});
    try {
      const {data} = await axios.get(ABOUT_API);
      dispatch({type: FETCH_ABOUT_SUCCESS, payload: data.data.about});
    } catch (error) {
      dispatch({type: FETCH_ABOUT_FAILURE, payload: error});
    }
  }
};

/**
 * @description redux action for About edit
 * @param aboutData
 * @param  {Object} ownProps
 */
export const aboutEditData = (aboutData, ownProps) => {
  return async dispatch => {
    dispatch({type: EDIT_ABOUT_REQUEST});
    try {
      const {data} = await axios.put(ABOUT_EDIT_API(aboutData.id), aboutData);
      dispatch({type: EDIT_ABOUT_SUCCESS, payload: data.data});
    } catch (error) {
      dispatch({type: EDIT_ABOUT_FAILURE, payload: error.response.data.data});
    }
  }
};
