import React, {Component} from 'react';
import {ModalContext} from '../../context/context';

/**
 * @description Component Class for close modal
 */
class OpenModal extends Component {
  static contextType = ModalContext;

  /**
   * @description close related modal
   * @memberOf CloseModal
   */
  openModal = (e) => {
    e.preventDefault();

    switch (this.props.modal) {
    case 'showObjectModal':
      this.context.showObjectModal(true);
      break;
    case 'showAssignmentModal':
      this.context.showAssignmentModal(true);
      break;
    case 'showNotesModal':
      this.context.showNotesModal(true);
      break;
    case 'showAddTransportModal':
      this.context.showAddTransportModal(true);
      break;

    default:
      break;
    }
  }

  render() {
    return  (
      <button
        className={`btn btn-theme d-flex align-items-center ${this.props.dBlock ? 'd-block w-100 justify-content-center' : ''}`}
        onClick= {this.openModal}
      >
        <span className="d-none d-lg-block">{this.props.text}</span>
        {!this.props.iconNone ? <i className="icon icon-plus"/> : null }
      </button>
    );
  }
}

export default OpenModal;
