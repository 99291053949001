import React, {Component} from 'react';
import Sidebar from './layouts/sidebar/Sidebar';
import Header from './layouts/header/Header';
import AddObject from './objects/add/AddObject';
import {ModalContext, SidebarContext, SearchContext} from '../../context/context';
import AddAssignment from './assignments/add/AddAssignment';
import EditObject from './objects/edit/EditObject';
import sidebarIsCollapsed from './layouts/sidebar/sidebar-condition';
import DetailMap from './objects/object/DetailMap';
import EditAssignment from './assignments/edit/EditAssignment';
import EditAppeal from './citizen/edit/EditAppeal';
import AddNotes from './representatives/add/AddNotes';
import Forward from './forward/Forward';
import Assignment from './assignments/Assignment';
import Main from './main/Main';
import Objects from './objects/Objects';
import About from './about/About';
import AllObjects from './objects/all/AllObjects';
import Social from './social/Social';
import ObjectDetail from './objects/object/ObjectDetail';
import AssignmentDetail from './assignments/detail/AssignmentDetail';
import RepresentativeNotes from './representatives/RepresentativeNotes';
import AppealDetail from './citizen/detail/AppealDetail';
import AddVacancy from './social/vacancy/AddVacancy';
import Resume from './social/resumes/Resume';
import Statistics from './statistics/Statistics';
import StatisticInner from './statistics/StatisticInner';
import EventCalendar from './events/EventCalendar';
import VacancyInner from './social/vacancy/VacancyInner';
import Appeal from './citizen/Appeal';
import UsefulWrapper from './useful/UsefulWrapper';
import AddAppeal from './citizen/add/AddAppeal';
import PrivateRoute from '../../PrivateRoute';
import InfoModal from '../shared/InfoModal';
import PreLogin from './pre/PreLogin';
import NotesDetail from './representatives/detail/NotesDetail';
import EditAbout from './about/edit/EditAbout';
import Legislation from './legislation/Legislation';
import LawList from './legislation/LawList';
import AddLaw from './legislation/add/AddLaw';
import LawDetail from './legislation/detail/LawDetail';
import EditLaw from './legislation/edit/EditLaw';
import {isUnAuthPublicEnabled} from '../../redux/services/auth.service';
import {Redirect, withRouter} from 'react-router-dom';
import AddEvent from './events/add/AddEvent';
import EditEvent from './events/edit/EditEvent';
import Results from './objects/search/Results';
import EventDetail from './events/detail/EventDetail';
import AddTransport from './useful/transport/AddTransport';
import EditTransport from './useful/transport/EditTransport';
import TransportDetail from './useful/transport/TransportDetail';
import EditVacancy from './social/vacancy/edit/EditVacancy';
import AddResume from './social/resumes/add/AddResume';
import EditResume from './social/resumes/edit/EditResume';
import KarabakhCategories from './karabakh/KarabakhCategories';
import Visit from './karabakh/visits/Visit';
import VisitDetail from './karabakh/visits/VisitDetail';
import VisitAdd from './karabakh/visits/VisitAdd';
import VisitEdit from './karabakh/visits/VisitEdit';
import AboutKarabakh from './karabakh/about/AboutKarabakh';
import EditKarabakhAbout from './karabakh/about/EditKarabakhAbout';
import Hero from './karabakh/heroes/Hero';
import HeroAdd from './karabakh/heroes/HeroAdd';
import HeroEdit from './karabakh/heroes/HeroEdit';
import VictoryKarabakh from './karabakh/victory/VictoryKarabakh';
import EditKarabakhVictory from './karabakh/victory/EditKarabakhVictory';
import OrganizationDetail from './karabakh/organization/OrganizationDetail';
import Organization from './karabakh/organization/Organization';
import OrganizationAdd from './karabakh/organization/OrganizationAdd';
import OrganizationEdit from './karabakh/organization/OrganizationEdit';
import MunicipalitiesCategories from "./karabakh/MunicipalitiesCategories";
import Index from "./karabakh/municipalities/Index";
import Add from "./karabakh/municipalities/Add";
import Show from "./karabakh/municipalities/Show";
import Edit from "./karabakh/municipalities/Edit";

/**
 * @description Dashboard Wrapper Component which is contain all main routes
 */
class Dashboard extends Component {
    state = {
        addObject: false,
        addAssignment: false,
        editAssignment: false,
        editAppeal: false,
        detailObjectMap: false,
        collapsed: false,
        addNotes: false,
        showForward: false,
        showInfo: false,
        showEventDetail: false,
        showLawDetail: false,
        showSearchResult: {},
        addTransport: false,
        editTransport: false,
        transportDetail: false,
        editKarabakhAbout: false,
        editKarabakhVictory: false
    }

    render() {
        if (!isUnAuthPublicEnabled()) {
            return <Redirect to='/login'/>
        }

        return (
            <ModalContext.Provider value={
                {
                    state: this.state,
                    showObjectModal: (value) => this.setState({addObject: value}),
                    showEditObjectModal: (value) => this.setState({editObject: value}),
                    showDetailObjectMap: (value, coords) => this.setState({detailObjectMap: value, coords: coords}),
                    showAssignmentModal: (value) => this.setState({addAssignment: value}),
                    showAssignmentEditModal: (value, task) => this.setState({editAssignment: value, task: task}),
                    showAppealEditModal: (value, appealId) => this.setState({editAppeal: value, appealId: appealId}),
                    showForwardModal: (value, appealId) => this.setState({showForward: value, appealId: appealId}),
                    showNotesModal: (value) => this.setState({addNotes: value}),
                    showEditAboutModal: (value, about) => this.setState({editAbout: value, about: about}),
                    showInfoModal: (value) => this.setState({showInfo: value}),
                    showSuccessModal: (value) => this.setState({showSuccess: value}),
                    showLawDetailModal: (value, details) => this.setState({showLawDetail: value, details: details}),
                    showEventDetailModal: (value, details) => this.setState({showEventDetail: value, details: details}),
                    showAddTransportModal: (value) => this.setState({addTransport: value}),
                    showEditTransportModal: (value, details) => this.setState({editTransport: value, details: details}),
                    showTransportDetailModal: (value, details) => this.setState({transportDetail: value, details: details}),
                    showEditKarabakhAboutModal: (value, details) => this.setState({editKarabakhAbout: value, details: details}),
                    showEditKarabakhVictoryModal: (value, details) => this.setState({editKarabakhVictory: value, details: details})
                }
            }>

                <SidebarContext.Provider value={
                    {
                        state: this.state,
                        controlSidebar: (value) => this.setState({collapsed: value})
                    }
                }>

                    <SearchContext.Provider value={
                        {
                            state: this.state,
                            setSearchValues: (values) => this.setState({showSearchResult: values})
                        }
                    }>
                        <div className="wrapper">

                            <Sidebar/>

                            <div className={'main-panel ' + ((this.state.collapsed || sidebarIsCollapsed()) ? 'expanded' : null)}>

                                <Header/>

                                <PrivateRoute exact path="/dashboard" component={Main}/>
                                <PrivateRoute exact path="/objects" component={Objects}/>
                                <PrivateRoute exact path="/objects/category/:id" component={AllObjects}/>
                                <PrivateRoute exact path="/objects/:id" component={ObjectDetail}/>

                                <PrivateRoute exact path="/about" component={About}/>

                                <PrivateRoute exact path="/statistics" component={Statistics}/>
                                <PrivateRoute exact path="/statistics/:id" component={StatisticInner}/>

                                <PrivateRoute exact path="/events" component={EventCalendar}/>
                                <PrivateRoute exact path="/events/new/add" component={AddEvent}/>
                                <PrivateRoute exact path="/events/edit/:id" component={EditEvent}/>

                                <PrivateRoute exact path="/appeal" component={Appeal}/>
                                <PrivateRoute exact path="/appeal/add" component={AddAppeal}/>
                                <PrivateRoute exact path="/appeal/detail/:id" component={AppealDetail}/>

                                <PrivateRoute exact path="/karabakh" component={KarabakhCategories}/>
                                <PrivateRoute exact path="/municipalities" component={MunicipalitiesCategories}/>
                                <PrivateRoute exact path="/municipalities/:category" component={Index}/>
                                <PrivateRoute exact path="/municipalities/:category/add" component={Add}/>
                                <PrivateRoute exact path="/municipalities/:category/:id" component={Show}/>
                                <PrivateRoute exact path="/municipalities/:category/edit/:id" component={Edit}/>

                                <PrivateRoute exact path="/karabakh/visit" component={Visit}/>
                                <PrivateRoute exact path="/karabakh/visit/:id" component={VisitDetail}/>
                                <PrivateRoute exact path="/karabakh/visit/add/new" component={VisitAdd}/>
                                <PrivateRoute exact path="/karabakh/visit/edit/:id" component={VisitEdit}/>

                                <PrivateRoute exact path="/karabakh/hero" component={Hero}/>
                                <PrivateRoute exact path="/karabakh/hero/add/new" component={HeroAdd}/>
                                <PrivateRoute exact path="/karabakh/hero/edit/:id" component={HeroEdit}/>

                                <PrivateRoute exact path="/karabakh/about" component={AboutKarabakh}/>

                                <PrivateRoute exact path="/karabakh/victory" component={VictoryKarabakh}/>

                                <PrivateRoute exact path="/karabakh/organization" component={Organization}/>
                                <PrivateRoute exact path="/karabakh/organization/:id" component={OrganizationDetail}/>
                                <PrivateRoute exact path="/karabakh/organization/add/new" component={OrganizationAdd}/>
                                <PrivateRoute exact path="/karabakh/organization/edit/:id" component={OrganizationEdit}/>

                                <PrivateRoute exact path="/acts" component={Legislation}/>
                                <PrivateRoute exact path="/acts/:id" component={LawList}/>
                                <PrivateRoute exact path="/acts/new/add" component={AddLaw}/>
                                <PrivateRoute exact path="/acts/edit/:id" component={EditLaw}/>

                                <PrivateRoute exact path="/notes" component={RepresentativeNotes}/>
                                <PrivateRoute exact path="/notes/:id" component={NotesDetail}/>

                                <PrivateRoute exact path="/assignments" component={Assignment}/>
                                <PrivateRoute exact path="/assignments/:id" component={AssignmentDetail}/>

                                <PrivateRoute path="/social" component={Social}/>

                                <PrivateRoute exact path="/add-vacancy" component={AddVacancy}/>
                                <PrivateRoute exact path="/vacancy/edit/:id" component={EditVacancy}/>
                                <PrivateRoute exact path="/vacancy/:id" component={VacancyInner}/>

                                <PrivateRoute exact path="/resume/:id" component={Resume}/>
                                <PrivateRoute exact path="/resume/add/new" component={AddResume}/>
                                <PrivateRoute exact path="/resume/edit/:id" component={EditResume}/>

                                <PrivateRoute path="/useful" component={UsefulWrapper}/>

                                <PrivateRoute exact path="/pre/login" component={PreLogin}/>

                                <PrivateRoute exact path="/search-results" component={withRouter(Results)}/>
                            </div>

                            {this.state.addObject ? <AddObject/> : null}

                            {this.state.editObject ? <EditObject/> : null}

                            {this.state.detailObjectMap ? <DetailMap/> : null}

                            {this.state.addAssignment ? <AddAssignment/> : null}

                            {this.state.editAssignment ? <EditAssignment/> : null}

                            {this.state.showForward ? <Forward/> : null}

                            {this.state.editAppeal ? <EditAppeal/> : null}

                            {this.state.addNotes ? <AddNotes/> : null}

                            {this.state.editAbout ? <EditAbout/> : null}

                            {this.state.showInfo ? <InfoModal/> : null}

                            {this.state.showEventDetail ? <EventDetail/> : null}

                            {this.state.showLawDetail ? <LawDetail/> : null}

                            {this.state.addTransport ? <AddTransport/> : null}

                            {this.state.editTransport ? <EditTransport/> : null}

                            {this.state.transportDetail ? <TransportDetail/> : null}

                            {this.state.editKarabakhAbout ? <EditKarabakhAbout/> : null}

                            {this.state.editKarabakhVictory ? <EditKarabakhVictory/> : null}

                        </div>
                    </SearchContext.Provider>
                </SidebarContext.Provider>

            </ModalContext.Provider>
        );
    }
}

export default Dashboard;
