import React, {Component} from 'react';
import OpenModal from '../../shared/OpenModal';
import {connect} from 'react-redux';
import {taskFetchData} from '../../../redux/actions/taskAction';
import moment from 'moment';
import {ModalContext} from '../../../context/context';
import {NavLink, Redirect} from 'react-router-dom';
import {isAuthenticated} from '../../../redux/services/auth.service';
import {statusFetchData} from '../../../redux/actions/statusAction';
import Loader from '../../shared/Loader';
import {deleteAction} from '../../../redux/actions/deleteAction';
import {DELETE_TASK_REQUEST, DELETE_TASK_SUCCESS, DELETE_TASK_FAILURE} from '../../../redux/types/actionTypes';
import {TASK_API} from '../../../redux/services/api.service';
import {checkBlock} from '../../../shared/checkBlocks';
import Pagination from '../pagination/Pagination';

/**
 * @description Assignment component
 */
class Assignment extends Component {
  constructor(props) {
    super(props);

    this.state = {
      params: {
        page: 1,
        pageCount: 1,
        perPage: 10,
        statusId: 0
      }
    };
  }

  componentDidMount() {
    this.fetchData();
    this.props.fetchStatuses();
  }

  selectStatus = (e) => {
    this.handlePaginationState({'statusId': e.target.value})

    this.fetchData()
  }

  removeObject = (ids) => {
    const idsArray = [];
    const types =
    {
      request: DELETE_TASK_REQUEST,
      success: DELETE_TASK_SUCCESS,
      failure: DELETE_TASK_FAILURE
    };
    const api = TASK_API;
    idsArray.push(ids);
    this.props.deleteObject(idsArray, types, api);

    document.getElementById(`task${ids}`).remove();
  }

  callBack = (page) => {
    this.handlePaginationState({page: page})

    this.fetchData()
  }

  fetchData = () => {
    let params = {
      page: this.state.params.page,
      per_page: this.state.params.perPage
    };

    if (this.state.params.statusId) {
      params['status_id'] = this.state.params.statusId
    }

    this.props.fetchTasks(params).then(() => {
      if (this.props.tasks.meta) {
        let meta = this.props.tasks.meta;
        this.handlePaginationState({
          perPage: parseInt(meta.per_page),
          page: meta.current_page,
          pageCount: meta.last_page
        });
      }
    });
  }

  handlePaginationState = (params) => {
    let state = this.state;

    Object.keys(params).forEach((key, value) => {
      state['params'][key] = params[key]
    })

    this.setState(state);
  }

  render() {
    if (!isAuthenticated()) {
      return <Redirect to='/login' />
    }
    const tasks = this.props.tasks.tasks ? this.props.tasks.tasks
      .map(task => {
        return (
          <tr key={task.id} id={`task${task.id}`}>
            <td>{task.employees.map((employee, index) => <span key={index} className="d-block">{employee.name}</span>)}</td>
            <td>{task.description.slice(0, 100)}</td>
            <td>{moment(task.created_at).format('YYYY-MM-DD')}</td>
            <td>{moment(task.due_date).format('YYYY-MM-DD')}</td>
            <td><label className={'label-status label-' + task.status.step + ''}>{task.status.name}</label></td>
            <td>
              <div className="d-flex">
                <ModalContext.Consumer>
                  {(context) => (
                    <button
                      className="btn btn-rounded"
                      onClick={() => context.showAssignmentEditModal(true, task)}
                    >
                      <i className="icon icon-edit-n" />
                    </button>
                  )}
                </ModalContext.Consumer>

                <NavLink
                  to={'/assignments/' + task.id}
                  className="btn btn-rounded"
                  activeClassName="active"
                >
                  <i className="icon icon-detail" />
                </NavLink>

                {isAuthenticated() && checkBlock('assignments', 'task-delete') ?
                  <button
                    type="button"
                    className="btn btn-rounded"
                    onClick={() => this.removeObject(task.id)}
                  >
                    <i className="icon icon-delete-2 mr-1"/>
                  </button>
                  : null }
              </div>
            </td>
          </tr>
        )
      }) : null

    const statuses = this.props.statuses.statuses ?
      this.props.statuses.statuses.map((status, i) => {
        return (
          <option key={i} value={status.id}>{status.name}</option>
        )
      }) : null
    return (
      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-12">
            <div className="whitebox">
              <header className="whitebox-header bordered">

                <div className="row">

                  <div className="col-md-6 d-flex align-items-center">
                    <div className="whitebox-title">
                      <h4>Tapşırıqlar</h4>
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div className="d-md-flex align-items-center justify-content-end mt-3 mt-0">
                      <div className="form-group form-group-custom mb-3 mb-md-0 mr-2">
                        <select name="assignment_category"
                          defaultValue="assignment_category"
                          className="form-control form-control-custom selectbox"
                          placeholder="assignment_category"
                          onChange={(e) => this.selectStatus(e)}
                        >
                          <option value="">Bütün tapşırıqlar</option>
                          {statuses}
                        </select>
                      </div>

                      <div className="form-group form-group-custom m-0">
                        <OpenModal modal="showAssignmentModal" text="Tapşırıq yarat" />
                      </div>
                    </div>

                  </div>
                </div>
              </header>
              <section className="whitebox-body">
                <div className="table-wrapper">
                  {this.props.tasks.tasks ?
                    <table className="table table-custom">
                      <thead>
                        <tr>
                          <th>Məsul şəxs</th>
                          <th>Tapşırığın mətni</th>
                          <th>Verilmə tarixi</th>
                          <th>Bitmə tarixi</th>
                          <th className="text-center">Status</th>
                          <th>Əməliyyat</th>
                        </tr>
                      </thead>

                      <tbody>
                        {tasks}
                      </tbody>
                    </table> : <Loader />}
                  <Pagination
                    page={this.state.params.page}
                    perPage={this.state.params.perPage}
                    pageCount={this.state.params.pageCount}
                    callBack={this.callBack}
                  />
                </div>
              </section>

            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch, ownProps) => ({
  fetchTasks: (param = null) => dispatch(taskFetchData(param, ownProps)),
  fetchStatuses: () => dispatch(statusFetchData(ownProps)),
  deleteObject: (ids, types, api) => dispatch(deleteAction(ids, types, api))
})

const mapStateToProps = state => {
  return {
    tasks: state.taskReducer,
    statuses: state.statusReducer
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Assignment);
