import axios from 'axios';
import {
  FETCH_BANNER_HEADER_REQUEST,
  FETCH_BANNER_HEADER_SUCCESS,
  FETCH_BANNER_HEADER_FAILURE,
  FETCH_BANNER_RIGHT_REQUEST,
  FETCH_BANNER_RIGHT_SUCCESS,
  FETCH_BANNER_RIGHT_FAILURE,
  FETCH_BANNER_WEBSITES_REQUEST,
  FETCH_BANNER_WEBSITES_SUCCESS,
  FETCH_BANNER_WEBSITES_FAILURE
} from '../types/actionTypes';
import {BANNER_API} from '../services/api.service';

/**
 * @description redux action for banner
 */
export const fetchBannersHeader = (ownProps) => {
  return async dispatch => {
    dispatch({type: FETCH_BANNER_HEADER_REQUEST});
    try {
      const {data} = await axios.get(BANNER_API, {
        params: {
          type: 'header'
        }
      });
      dispatch({type: FETCH_BANNER_HEADER_SUCCESS, payload: data.data.banners});
    } catch (error) {
      dispatch({type: FETCH_BANNER_HEADER_FAILURE, payload: error.response.data});
    }
  }
}

/**
 * @description redux action for banner
 */
export const fetchBannersRight = (ownProps) => {
  return async dispatch => {
    dispatch({type: FETCH_BANNER_RIGHT_REQUEST});
    try {
      const {data} = await axios.get(BANNER_API, {
        params: {
          type: 'right'
        }
      });
      dispatch({type: FETCH_BANNER_RIGHT_SUCCESS, payload: data.data.banners});
    } catch (error) {
      dispatch({type: FETCH_BANNER_RIGHT_FAILURE, payload: error.response.data});
    }
  }
}

/**
 * @description redux action for banner
 */
export const fetchBannersWebsites = (ownProps) => {
  return async dispatch => {
    dispatch({type: FETCH_BANNER_WEBSITES_REQUEST});
    try {
      const {data} = await axios.get(BANNER_API, {
        params: {
          type: 'websites'
        }
      });
      dispatch({type: FETCH_BANNER_WEBSITES_SUCCESS, payload: data.data.banners});
    } catch (error) {
      dispatch({type: FETCH_BANNER_WEBSITES_FAILURE, payload: error.response.data});
    }
  }
}
