import {
  ADD_TASK_REQUEST,
  ADD_TASK_SUCCESS,
  ADD_TASK_FAILURE,
  FETCH_TASK_REQUEST,
  FETCH_TASK_SUCCESS,
  FETCH_TASK_FAILURE,
  REMOVE_SUCCESS_MESSAGE,
  EDIT_TASK_REQUEST,
  EDIT_TASK_SUCCESS,
  EDIT_TASK_FAILURE,
  FETCH_TASK_DETAIL_REQUEST,
  FETCH_TASK_DETAIL_SUCCESS,
  FETCH_TASK_DETAIL_FAILURE,
  TASK_FORWARD_REQUEST,
  TASK_FORWARD_SUCCESS,
  TASK_FORWARD_FAILURE,
  TASK_ACCEPT_REQUEST,
  TASK_ACCEPT_SUCCESS,
  TASK_ACCEPT_FAILURE,
  TASK_DONE_REQUEST,
  TASK_DONE_SUCCESS,
  TASK_DONE_FAILURE,
  DELETE_TASK_REQUEST,
  DELETE_TASK_SUCCESS,
  DELETE_TASK_FAILURE
} from '../types/actionTypes';
/**
 * @description initial state for objectReducer
 */
const initialState = {};

/**
 * @description updates state depending on action data
 * @param {Object} state = initialState
 * @param {string} type = action.type
 * @param {} payload
 * @param {Array} tasks
 * @returns return state
 */
export default function taskReducer(state = initialState, {type, payload, meta}) {
  switch (type) {
  case ADD_TASK_REQUEST:
    return {
      ...state,
      success: false,
      isFetching: true
    };
  case ADD_TASK_SUCCESS:
    return {
      ...state,
      success: true,
      isFetching: false
    };
  case ADD_TASK_FAILURE:
    return {
      ...state,
      success: false,
      isFetching: false
    };

  case DELETE_TASK_REQUEST:
    return {
      ...state,
      success: false,
      isFetching: true
    };
  case DELETE_TASK_SUCCESS:
    return {
      ...state,
      success: true,
      isFetching: false
    };
  case DELETE_TASK_FAILURE:
    return {
      ...state,
      success: false,
      isFetching: false,
      errors: payload
    };

  case EDIT_TASK_REQUEST:
    return {
      ...state,
      success: false,
      isFetching: true
    };
  case EDIT_TASK_SUCCESS:
    return {
      ...state,
      success: true,
      isFetching: false
    };
  case EDIT_TASK_FAILURE:
    return {
      ...state,
      success: false,
      isFetching: false
    };

  case FETCH_TASK_REQUEST:
    return {
      ...state,
      isFetching: true,
      tasks: payload
    };
  case FETCH_TASK_SUCCESS:
    return {
      ...state,
      isFetching: false,
      tasks: payload,
      meta: meta
    };
  case FETCH_TASK_FAILURE:
    return {
      ...state,
      isFetching: false,
      tasks: payload
    };

  case FETCH_TASK_DETAIL_REQUEST:
    return {
      ...state,
      isFetching: true,
      task: payload
    };
  case FETCH_TASK_DETAIL_SUCCESS:
    return {
      ...state,
      isFetching: false,
      task: payload
    };
  case FETCH_TASK_DETAIL_FAILURE:
    return {
      ...state,
      isFetching: false,
      task: payload
    };
  case TASK_FORWARD_REQUEST:
    return {
      ...state,
      isFetching: true,
      forwardStatus: false
    };
  case TASK_FORWARD_SUCCESS:
    return {
      ...state,
      isFetching: false,
      forwardStatus: true
    };
  case TASK_FORWARD_FAILURE:
    return {
      ...state,
      isFetching: false,
      forwardStatus: payload
    };

  case TASK_ACCEPT_REQUEST:
    return {
      ...state,
      isFetching: true,
      taskAcceptStatus: false
    };
  case TASK_ACCEPT_SUCCESS:
    return {
      ...state,
      isFetching: false,
      taskAcceptStatus: true
    };
  case TASK_ACCEPT_FAILURE:
    return {
      ...state,
      isFetching: false,
      taskAcceptStatus: false
    };

  case TASK_DONE_REQUEST:
    return {
      ...state,
      isFetching: true,
      taskDoneStatus: false,
      taskDoneData: payload
    };
  case TASK_DONE_SUCCESS:
    return {
      ...state,
      isFetching: false,
      taskDoneStatus: true,
      taskDoneData: payload
    };
  case TASK_DONE_FAILURE:
    return {
      ...state,
      isFetching: false,
      taskDoneStatus: false,
      taskDoneData: payload
    };

  case REMOVE_SUCCESS_MESSAGE:
    return {
      ...state,
      success:false
    };
  default:
    return state
  }
}
