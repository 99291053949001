import {combineReducers} from 'redux';
import userReducer from './userReducer';
import objectReducer from './objectReducer';
import fileReducer from './fileReducer';
import taskReducer from './taskReducer';
import appealReducer from './appealReducer';
import statusReducer from './statusReducer';
import representativeReducer from './representativeReducer';
import menuReducer from './menuReducer';
import legislationReducer from './legislationReducer';
import appealCommentsReducer from './appealCommentsReducer';
import taskCommentsReducer from './taskCommentsReducer';
import firebaseReducer from './firebaseReducer';
import usefulReducer from './usefulReducer';
import socialReducer from './socialReducer';
import statisticReducer from './statisticReducer';
import aboutReducer from './aboutReducer';
import bannerReducer from './bannerReducer';
import eventReducer from './eventReducer';
import transportReducer from './transportReducer';
import karabakhCategoriesReducer from './karabakhCategoriesReducer';
import municipalitiesCategoriesReducer from './municipalitiesCategoriesReducer';
import karabakhReducer from './karabakhReducer';

/**
 * @description combine all reducers in one (root) reducer
 * @param  {Object} {userReducer}
 */
export default combineReducers({
  userReducer,
  objectReducer,
  fileReducer,
  taskReducer,
  appealReducer,
  statusReducer,
  representativeReducer,
  menuReducer,
  legislationReducer,
  appealCommentsReducer,
  taskCommentsReducer,
  firebaseReducer,
  usefulReducer,
  socialReducer,
  statisticReducer,
  aboutReducer,
  bannerReducer,
  eventReducer,
  transportReducer,
  karabakhCategoriesReducer,
  municipalitiesCategoriesReducer,
  karabakhReducer
})
