import React from 'react';
import waypointIcon from '../../../assets/img/bus-waypoint.svg';
import originIcon from '../../../assets/img/bus-origin-destination.svg';
import {arrayChunk} from '../../../shared/helpers';

const wrappedPromise = function() {
  var wrappedPromise = {};
  var promise = new Promise(function(resolve, reject) {
    wrappedPromise.resolve = resolve;
    wrappedPromise.reject = reject;
  });
  wrappedPromise.then = promise.then.bind(promise);
  wrappedPromise.catch = promise.catch.bind(promise);
  wrappedPromise.promise = promise;

  return wrappedPromise;
}

/**
 * @description Direction component
 */
export class Direction extends React.Component {
  componentDidMount() {
    this.directionPromise = wrappedPromise();
    this.renderDirection();
  }

  componentDidUpdate(prevProps) {
    this.renderDirection();
  }

  componentWillUnmount() {
    if (this.directionsRenderer) {
      this.directionsRenderer.setMap(null);
    }
  }

  /**
   * @description google maps directions service renderer
   * @returns {null}
   */
  renderDirection() {
    const {
      data, map, google, draggable, panel, ...props
    } = this.props;

    if (!google) {
      return null;
    }

    this.params = {map, draggable, panel, ...props, suppressMarkers: true};
    this.directionsService = new google.maps.DirectionsService();
    this.directionsRenderer = new google.maps.DirectionsRenderer(this.params);

    if (!data.travelMode) {
      data.travelMode = google.maps.DirectionsTravelMode.DRIVING;
    }

    this.overMaxWaypoint(data, map, google);
    this.directionPromise.resolve(this.directionsRenderer);
  }

  /**
   * @description google maps max waypoint exceeded fixer
   * @param data
   * @param map
   * @param google
   */
  overMaxWaypoint(data, map, google) {
    if (!data.origin || !data.destination) {
      return;
    }

    // if count of waypoint more than 25, use chunked data for fixing google max waypoint error
    // every foreach element makes new response
    const chunkedData = arrayChunk(data.waypoints.map((waypoint) => ({location: waypoint.location})), 25)

    this.addDirection(google, data, chunkedData, 0)

    return this.directionMarkers(data, google, map)
  }

  /**
   * @description add route direction recursively
   * @param google
   * @param data
   * @param chunkedData
   * @param index
   */
  addDirection(google, data, chunkedData, index) {
    if (!chunkedData[index]) {
      return
    }
    if (!index) {
      index = 0
    }

    const waypoints = chunkedData[index]
    let directionData = {
      origin: data.origin,
      destination: data.destination,
      waypoints: waypoints,
      travelMode: data.travelMode
    }

    // make origin from first element of waypoints array, if it's not first loop
    if (index !== 0) {
      directionData.origin = `${waypoints[0].location.lat}, ${waypoints[0].location.lng}`
    }
    // make destination from first element of next waypoints array, if it's not last loop
    if (index !== chunkedData.indexOf(chunkedData[chunkedData.length - 1])) {
      directionData.destination = `${data.waypoints[(index + 1) * waypoints.length].location.lat}, ${data.waypoints[(index + 1) * waypoints.length].location.lng}`
    }

    // Render route recursively and make one route
    this.displayRoute(directionData, google, () => {
      return this.addDirection(google, data, chunkedData, ++index)
    })
  }

  /**
   * @description set route on google maps
   * @param data
   * @param google
   * @param callBack
   */
  displayRoute(data, google, callBack) {
    const display = new google.maps.DirectionsRenderer(this.params)
    this.directionsService.route(data, function(response, status) {
      if (status !== 'OK') {
        return display.setMap(null);
      }
      display.setDirections(response);
      callBack()
    });
  }

  /**
   * @description add marker to route waypoints
   * @param data
   * @param google
   * @param map
   */
  directionMarkers(data, google, map) {
    const origin = {lat: Number(data.origin.split(',')[0]), lng: Number(data.origin.split(',')[1])}
    const destination = {lat: Number(data.destination.split(',')[0]), lng: Number(data.destination.split(',')[1])}

    // eslint-disable-next-line no-new
    new google.maps.Marker({
      position: origin,
      map,
      title: data.origin_title || '',
      icon: {url: originIcon, scaledSize: {width: 50, height: 50}}
    })
    // eslint-disable-next-line no-new
    new google.maps.Marker({
      position: destination,
      map,
      title: data.destination_title || '',
      icon: {url: originIcon, scaledSize: {width: 50, height: 50}}
    });
    data.waypoints.map(waypoint => {
      return new google.maps.Marker({
        position: waypoint.location,
        map,
        title: waypoint.title || '',
        icon: {url: waypointIcon, scaledSize: {width: 50, height: 50}}
      })
    })
  }

  render() {
    return null;
  }
}

export default Direction
