import React, {Component} from 'react';
import {connect} from 'react-redux';
import {NavLink, Redirect} from 'react-router-dom';
import {legislationFetchCategories} from '../../../redux/actions/legislationActions';
import Loader from '../../shared/Loader';
import {checkBlock} from '../../../shared/checkBlocks';
import CategoryCollapse from './CategoryCollapse';
import {isAuthenticated, isUnAuthPublicEnabled} from '../../../redux/services/auth.service';

/**
 * @description Legislation component
 */
class Legislation extends Component {
  state = {
    activeCategory: 0
  };
  componentDidMount() {
    this.props.fetchLegislationCategories();
  }
  render() {
    if (!isUnAuthPublicEnabled()) {
      return <Redirect to='/login' />
    }
    return (
      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-12">
            <div className="whitebox" id="legislation">
              <header className="whitebox-header bordered d-flex align-items-center justify-content-between">
                <div className="whitebox-title">
                  <h4>
                    Qanunvericilik
                  </h4>
                </div>
                {isAuthenticated() && checkBlock('acts', 'legislation-add') ?
                  <NavLink
                    to={'/acts/new/add'}
                    exact
                    className="btn btn-theme d-flex align-items-center"
                    activeClassName="active"
                  >
                    <span className="d-none d-lg-block">Qanunvericilik əlavə edin</span>
                    <i className="icon icon-plus" />
                  </NavLink> : null }
              </header>
              <section className="whitebox-body">
                {
                  this.props.categories ? <CategoryCollapse categories={this.props.categories} /> : <Loader/>
                }
              </section>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch, ownProps) => ({
  fetchLegislationCategories: () => dispatch(legislationFetchCategories(ownProps))
});

const mapStateToProps = state => {
  return state.legislationReducer;
};

export default connect(mapStateToProps, mapDispatchToProps)(Legislation);

