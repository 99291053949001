import React, {Component} from 'react';
import ButtonLoader from '../../../loader/ButtonLoader';
import {connect} from 'react-redux';
import {filePostFetch} from '../../../../redux/actions/fileAction';
import 'react-datepicker/dist/react-datepicker.css';
import InputError from '../../errors/InputError';

/**
 * @description VisitForm
 */
class VisitForm extends Component {
  state = {
    fileData: []
  }
  /**
   * @description file upload
   * @param {Visit} e
   * @memberOf VisitForm
   */
  setFile = (e) => {
    const fileData = new FormData();
    for (let i = 0; i < e.currentTarget.files.length; i++) {
      fileData.append('files[]', e.currentTarget.files[i])
    }
    this.setState({
      fileData
    })
    this.props.uploadFile(fileData)
  }

  /**
   * @description file delete
   * @memberOf ObjectForm
   * @param token
   */
  removeFiles = (token) => {
    const editedImages = this.props.formProps.values.images.filter((file) => file.token !== token);
    this.props.formProps.setFieldValue('images', editedImages);
  }

  /**
   * @description all errors for one input
   * @param name
   * @returns {*}
   */
  handleInputError = (name) => {
    let {visits} = this.props;
    let visitErrors = (visits && visits.visit && visits.visit.error) ? visits.visit.data : [];

    let {touched, errors} = this.props.formProps;

    if (touched[name] && errors[name]) {
      visitErrors[name] = [errors[name]];
    }

    return <InputError errors={visitErrors} name={name}/>
  }

  render() {
    const {values, handleSubmit, handleBlur, handleChange, touched, errors} = this.props.formProps;
    const fileNames = values.images ? values.images.map((file, index) => {
      return (
        <p className="file-item mr-3 mb-4" key={index}>
          <img src={`${process.env.REACT_APP_SERVER}/storage/` + file.path} alt={file.path}/>
          <button
            className="btn-remove"
            onClick={() => this.removeFiles(file.token)}
          >x
          </button>
        </p>
      )
    }) : ''
    return (
      <section className="whitebox-body">
        <div className="add-form">
          <div className="form-group form-group-custom">
            <label htmlFor="name">Başlıq</label>
            <input
              type="text"
              name="name"
              className={'form-control form-control-custom ' + ((touched.name && errors.name) ? 'has-error' : null)}
              placeholder="Başlıq qeyd edin"
              value={values.name}
              onChange={handleChange}
              onBlur={handleBlur}
            />
            {this.handleInputError('name')}
          </div>
          <div className="form-group form-group-custom">
            <label htmlFor="address">Tekst</label>
            <textarea
              name="description"
              className={'form-control form-control-custom ' + ((touched.description && errors.description) ? 'has-error' : null)}
              placeholder="Tekst qeyd edin"
              value={values.description}
              onChange={handleChange}
              onBlur={handleBlur}
            />
            {this.handleInputError('description')}
          </div>
          <div className="form-group form-group-custom">
            <label htmlFor="name">Youtube linki</label>
            <input
              type="text"
              name="youtube_link"
              className={'form-control form-control-custom ' + ((touched.youtube_link && errors.youtube_link) ? 'has-error' : null)}
              placeholder="Youtube linki qeyd edin"
              value={values.youtube_link}
              onChange={handleChange}
            />
            {this.handleInputError('youtube_link')}
          </div>
          <div className="row">
            <div className="col-md-6 form-group form-group-custom">
              <div className="file-name d-flex justify-content-start flex-wrap">
                {fileNames}
              </div>
            </div>
            <div className="col-md-6 form-group form-group-custom over-hide">
              <div className="btn file-button">Şəkil yüklə</div>
              <input
                name="file"
                type="file"
                multiple
                placeholder="Şəkil yüklə"
                // value={values.images}
                onChange={(e) => {
                  this.setFile(e)
                }}
                onBlur={handleBlur}
              />
            </div>
          </div>

          <div className="form-group form-group-custom">
            <button
              type="button"
              onClick={() => (handleSubmit(values))}
              className="btn btn-theme w-100"
              disabled={this.props.isFetching ? true : null}
            >
              {this.props.files.isFetching ? <ButtonLoader/> : <span>Yadda saxla</span>}
            </button>
          </div>
        </div>
      </section>
    );
  }
}

const mapDispatchToProps = (dispatch, ownProps) => ({
  uploadFile: file => dispatch(filePostFetch(file, ownProps))
})

const mapStateToProps = state => {
  return {
    visits: state.karabakhReducer,
    files: state.fileReducer
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(VisitForm);
