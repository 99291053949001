import React, {Component} from 'react';
import {NavLink, Redirect} from 'react-router-dom';
import {connect} from 'react-redux';
import {municipalitiesCategoryFetchData} from '../../../redux/actions/karabakhAction';
import Loader from '../../shared/Loader';
import {isUnAuthPublicEnabled} from '../../../redux/services/auth.service';

/**
 * @description KarabakhCategories component
 */
class MunicipalitiesCategories extends Component {
  state = {
    categories: []
  }

  componentDidMount() {
    this.props.fetchMunicipalitiesCategories();
  }

  render() {
    if (!isUnAuthPublicEnabled()) {
      return <Redirect to='/login' />
    }
    const categories = this.props.categories ? this.props.categories.map((category) => {
      return (
        <div className="col-xl-4" key={category.id}>
          <NavLink
            to={{pathname: `/municipalities/${category.slug}`, aboutProps:{title:`${category.name}`}}}
            exact
            className="category_link d-flex justify-content-start"
          >
            <div className="img p-2">
              <img src={`${process.env.REACT_APP_SERVER}/storage/` + category.image} alt={category.image} />
            </div>
            <div className="name d-flex align-items-center">
              <span>{category.name}</span>
            </div>
          </NavLink>
        </div>
      )
    }) : null

    return (
      <div className="container-fluid" id={'karabakh'}>
        <div className="row">
          <div className="col-lg-12">
            <div className="whitebox back-img fullheight">
              <header className="whitebox-header">
                <div className="whitebox-title d-flex justify-content-start">
                  <div className={'category_title d-flex align-items-center'}>
                    <span>Bələdiyyələr</span>
                  </div>
                </div>
              </header>

              <section className="whitebox-body">
                <div className="row">
                  {this.props.isFetching ? <Loader /> : categories}
                </div>
              </section>

            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch, ownProps) => ({
  fetchMunicipalitiesCategories: () => dispatch(municipalitiesCategoryFetchData(ownProps))
})

const mapStateToProps = state => {
  return state.municipalitiesCategoriesReducer;
}

export default connect(mapStateToProps, mapDispatchToProps)(MunicipalitiesCategories);
