/**
 * @description check block
 * @param slug
 * @param blockName
 */
export const checkBlock = (slug, blockName) => {
  let menus = JSON.parse(localStorage.getItem('menus'));
  let menuArray = [];
  for (let [key, value] of Object.entries(menus)) {
    menuArray.push({key, value})
  }
  let menu = menuArray.filter(menu => {
    return menu.key === slug;
  });
  if (menu[0] && menu[0].value.blocks.length) {
    for (let i = 0; i < menu[0].value.blocks.length; i++) {
      if (menu[0].value.blocks[i] === blockName) {
        return true;
      }
    }
    return false;
  }
};

/**
 * @description check string value
 */
export const isBlank = (str) => {
  return (str.length === 0 || !str.trim());
};

/**
 * @description check string value
 */
export const isPage = (str) => {
  return (window.location.pathname.indexOf(str) >= 0);
};
