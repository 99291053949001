import {
  FCM_CLEAR_NOTIFY_FAILURE, FCM_CLEAR_NOTIFY_REQUEST, FCM_CLEAR_NOTIFY_SUCCESS,
  FCM_FETCH_BG_MESSAGE_FAILURE,
  FCM_FETCH_BG_MESSAGE_REQUEST,
  FCM_FETCH_BG_MESSAGE_SUCCESS,
  FCM_FETCH_TOKEN_FAILURE,
  FCM_FETCH_TOKEN_REQUEST,
  FCM_FETCH_TOKEN_SUCCESS,
  FCM_READ_NOTIFY_FAILURE,
  FCM_READ_NOTIFY_REQUEST,
  FCM_READ_NOTIFY_SUCCESS,
  FCM_TOKEN_REFRESH_FAILURE,
  FCM_TOKEN_REFRESH_REQUEST,
  FCM_TOKEN_REFRESH_SUCCESS
} from '../types/actionTypes';

/**
 * @description initial state for objectReducer
 */
const initialState = {};

/**
 * @description update user firebase token
 * @param {Object} state = initialState
 * @param {string} type = action.type
 * @param {} payload
 * @returns return state
 */
export default function firebaseReducer(state = initialState, {type, payload}) {
  switch (type) {
  case FCM_TOKEN_REFRESH_REQUEST:
    return {
      ...state,
      isFetching: true,
      success: false,
      notifyData: payload
    };
  case FCM_TOKEN_REFRESH_SUCCESS:
    return {
      ...state,
      isFetching: false,
      success: true,
      notifyData: payload
    };
  case FCM_TOKEN_REFRESH_FAILURE:
    return {
      ...state,
      isFetching: false,
      success: false,
      notifyData: payload
    };
  case FCM_READ_NOTIFY_REQUEST:
    return {
      ...state,
      isFetching: true,
      success: false,
      notifyData: payload
    };
  case FCM_READ_NOTIFY_SUCCESS:
    return {
      ...state,
      isFetching: false,
      success: true,
      notifyData: payload
    };
  case FCM_READ_NOTIFY_FAILURE:
    return {
      ...state,
      isFetching: false,
      success: false,
      notifyData: payload
    };
  case FCM_CLEAR_NOTIFY_REQUEST:
    return {
      ...state,
      isFetching: true,
      success: false
    };
  case FCM_CLEAR_NOTIFY_SUCCESS:
    return {
      ...state,
      isFetching: false,
      success: true
    };
  case FCM_CLEAR_NOTIFY_FAILURE:
    return {
      ...state,
      isFetching: false,
      success: false
    };
  case FCM_FETCH_TOKEN_REQUEST:
    return {
      ...state,
      isFetching: true,
      success: false,
      token: payload
    };
  case FCM_FETCH_TOKEN_SUCCESS:
    return {
      ...state,
      isFetching: false,
      success: true,
      token: payload
    };
  case FCM_FETCH_TOKEN_FAILURE:
    return {
      ...state,
      isFetching: false,
      success: false,
      token: payload
    };
  case FCM_FETCH_BG_MESSAGE_REQUEST:
    return {
      ...state,
      isFetching: true,
      bgMessage: payload
    };
  case FCM_FETCH_BG_MESSAGE_SUCCESS:
    return {
      ...state,
      isFetching: false,
      bgMessage: payload
    };
  case FCM_FETCH_BG_MESSAGE_FAILURE:
    return {
      ...state,
      isFetching: false,
      bgMessage: payload
    };
  default:
    return state
  }
}
