import axios from 'axios';
import {
  ADD_SUCCESS,
  ADD_REQUEST,
  ADD_FAILURE,
  FETCH_REQUEST,
  FETCH_SUCCESS,
  FETCH_FAILURE,
  FETCH_CATEGORY_REQUEST,
  FETCH_CATEGORY_SUCCESS,
  FETCH_CATEGORY_FAILURE,
  FETCH_ZONE_REQUEST,
  FETCH_ZONE_SUCCESS,
  FETCH_ZONE_FAILURE,
  FETCH_OBJECT_DETAIL_REQUEST,
  FETCH_OBJECT_DETAIL_SUCCESS,
  FETCH_OBJECT_DETAIL_FAILURE,
  EDIT_OBJECT_REQUEST,
  EDIT_OBJECT_SUCCESS,
  EDIT_OBJECT_FAILURE,
  FETCH_TYPE_REQUEST,
  FETCH_TYPE_SUCCESS,
  FETCH_TYPE_FAILURE,
  FETCH_ALL_OBJECT_REQUEST,
  FETCH_ALL_OBJECT_SUCCESS,
  FETCH_ALL_OBJECT_FAILURE,
  FETCH_ACTIVITY_CATEGORY_REQUEST,
  FETCH_ACTIVITY_CATEGORY_SUCCESS,
  FETCH_ACTIVITY_CATEGORY_FAILURE,
  SEARCH_OBJECT_REQUEST,
  SEARCH_OBJECT_SUCCESS,
  SEARCH_OBJECT_FAILURE,
  FETCH_OBJECT_BY_ZONE_REQUEST,
  FETCH_OBJECT_BY_ZONE_SUCCESS,
  FETCH_OBJECT_BY_ZONE_FAILURE,
  FETCH_KARABAKH_CATEGORY_REQUEST,
  FETCH_KARABAKH_CATEGORY_SUCCESS,
  FETCH_KARABAKH_CATEGORY_FAILURE
} from '../types/actionTypes';
import {
  OBJECT_API,
  OBJECT_CATEGORY_API,
  OBJECT_API_BY_ID,
  OBJECT_TYPE_API_BY_ID,
  ACTIVITY_CATEGORY_API,
  OBJECT_ZONE_API, KARABAKH_CATEGORY_API
} from '../services/api.service';
import moment from 'moment';

/**
 * @description redux action for object add
 * @param objectData
 * @param  {Object} ownProps
 */
export const objectPostData = (objectData, ownProps) => {
  return async dispatch => {
    dispatch({type: ADD_REQUEST});
    try {
      const {data} = await axios.post(OBJECT_API, {
        name: objectData.name,
        ownership:                  objectData.ownership,
        operation_status:           objectData.operation_status,
        block_count:                objectData.block_count,
        block_status:               objectData.block_status,
        floors_count:               objectData.floors_count,
        apartments_count:           objectData.apartments_count,
        registered_peoples_count:   objectData.registered_peoples_count,
        area_size:                  objectData.area_size,
        current_situation:          objectData.current_situation,
        activities:                 objectData.activities,
        education_status:           objectData.education_status,
        address:                    objectData.address,
        lat:                        objectData.lat,
        lng:                        objectData.lng,
        construction_date:          moment(objectData.construction_date).format('YYYY-MM-DD'),
        history:                    objectData.history,
        note:                       objectData.note,
        images:                     objectData.images,
        object_category_id:         Number(objectData.object_category_id),
        object_type_id:             Number(objectData.object_type_id),
        object_zone_id:             Number(objectData.object_zone_id)
      });
      dispatch({type: ADD_SUCCESS, payload: data});
    } catch (error) {
      dispatch({type: ADD_FAILURE, payload: error.response.data});
    }
  }
}

/**
 * @description fetch all objects by category
 * @param params
 * @param ownProps
 */
export const objectFetchData = (params, ownProps) => {
  return async dispatch => {
    dispatch({type: FETCH_REQUEST});
    try {
      const {data} = await axios.get(OBJECT_API, {params: params});
      dispatch({type: FETCH_SUCCESS, payload: data.data.objects, meta: data.meta});
    } catch (error) {
      dispatch({type: FETCH_FAILURE, payload: error.response.data});
    }
  }
}

/**
 * @description fetch all objects by zone
 * @param  {Number} paramId
 * @param ownProps
 */
export const objectFetchDataByZone = (paramId, ownProps) => {
  return async dispatch => {
    dispatch({type: FETCH_OBJECT_BY_ZONE_REQUEST});
    try {
      const {data} = await axios.get(OBJECT_API, {
        params: {
          zone_id: paramId
        }
      });
      dispatch({type: FETCH_OBJECT_BY_ZONE_SUCCESS, payload: data.data.objects});
    } catch (error) {
      dispatch({type: FETCH_OBJECT_BY_ZONE_FAILURE, payload: error.response.data});
    }
  }
}

/**
 * @description fetch all objects by activity
 * @param  {Number} paramId
 * @param ownProps
 */
export const objectFetchUsefulData = (paramId, ownProps) => {
  return async dispatch => {
    dispatch({type: FETCH_REQUEST});
    try {
      const {data} = await axios.get(OBJECT_API, {
        params: {
          activity_cat_id: paramId
        }
      });
      dispatch({type: FETCH_SUCCESS, payload: data.data.objects});
    } catch (error) {
      dispatch({type: FETCH_FAILURE, payload: error.response.data});
    }
  }
}

/**
 * @description fetch all objects
 */
export const fetchAllObjectsData = (ownProps) => {
  return async dispatch => {
    dispatch({type: FETCH_ALL_OBJECT_REQUEST});
    try {
      const {data} = await axios.get(OBJECT_API);
      dispatch({type: FETCH_ALL_OBJECT_SUCCESS, payload: data.data.objects});
    } catch (error) {
      dispatch({type: FETCH_ALL_OBJECT_FAILURE, payload: error.response.data});
    }
  }
}

/**
 * @description fetch all objects categories
 * @param  {Object} ownProps
 */
export const objectCategoryFetchData = (ownProps) => {
  return async dispatch => {
    dispatch({type: FETCH_CATEGORY_REQUEST});
    try {
      const {data} = await axios.get(OBJECT_CATEGORY_API);
      dispatch({type: FETCH_CATEGORY_SUCCESS, payload: data.data.objectCategories});
      const objectCategories = [];
      for (let i = 0; i < data.data.objectCategories.length; i++) {
        objectCategories.push(data.data.objectCategories[i]);
      }
      localStorage.setItem('object_categories', JSON.stringify(objectCategories));
    } catch (error) {
      dispatch({type: FETCH_CATEGORY_FAILURE, payload: error.response.data});
    }
  }
}

/**
 * @description fetch all objects by category
 * @param  {Number} paramId
 * @param ownProps
 */
export const objectFetchDetail = (paramId, ownProps) => {
  return async dispatch => {
    dispatch({type: FETCH_OBJECT_DETAIL_REQUEST});
    try {
      const {data} = await axios.get(OBJECT_API_BY_ID(paramId), {
      });
      dispatch({type: FETCH_OBJECT_DETAIL_SUCCESS, payload: data.data.object});
    } catch (error) {
      dispatch({type: FETCH_OBJECT_DETAIL_FAILURE, payload: error.response.data});
    }
  }
}

/**
 * @description redux action for edit objects
 * @param objectData
 * @param  {Object} ownProps
 */
export const objectEditData = (objectData, ownProps) => {
  return async dispatch => {
    dispatch({type: EDIT_OBJECT_REQUEST});
    try {
      const {data} = await axios.put(OBJECT_API_BY_ID(objectData.id), {
        name:                       objectData.name,
        ownership:                  objectData.ownership,
        operation_status:           objectData.operation_status,
        block_count:                objectData.block_count,
        block_status:               objectData.block_status,
        floors_count:               objectData.floors_count,
        apartments_count:           objectData.apartments_count,
        registered_peoples_count:   objectData.registered_peoples_count,
        area_size:                  objectData.area_size,
        current_situation:          objectData.current_situation,
        activities:                 objectData.activities,
        education_status:           objectData.education_status,
        address:                    objectData.address,
        lat:                        objectData.lat,
        lng:                        objectData.lng,
        construction_date:          moment(objectData.construction_date).format('YYYY-MM-DD'),
        history:                    objectData.history,
        note:                       objectData.note,
        images:                     objectData.images,
        object_category_id:         Number(objectData.object_category_id),
        object_type_id:             Number(objectData.object_type_id),
        object_zone_id:             Number(objectData.object_zone_id)
      });
      dispatch({type: EDIT_OBJECT_SUCCESS, payload: data});
    } catch (error) {
      dispatch({type: EDIT_OBJECT_FAILURE, payload: error.response.data});
    }
  }
}

/**
 * @description redux action for fetch object types
 * @param selectedCategoryID
 * @param  {Object} ownProps
 */
export const objectTypesFetchData = (selectedCategoryID, ownProps) => {
  return async dispatch => {
    dispatch({type: FETCH_TYPE_REQUEST});
    try {
      const {data} = await axios.get(OBJECT_TYPE_API_BY_ID(selectedCategoryID));
      dispatch({type: FETCH_TYPE_SUCCESS, payload: data.data.objectTypes});
    } catch (error) {
      dispatch({type: FETCH_TYPE_FAILURE, payload: error});
    }
  }
}

/**
 * @description redux action for fetch object activity categories
 * @param  {Object} ownProps
 */
export const objectActivitiesFetchData = (ownProps) => {
  return async dispatch => {
    dispatch({type: FETCH_ACTIVITY_CATEGORY_REQUEST});
    try {
      const {data} = await axios.get(ACTIVITY_CATEGORY_API);
      dispatch({type: FETCH_ACTIVITY_CATEGORY_SUCCESS, payload: data.data.activityCategories});
    } catch (error) {
      dispatch({type: FETCH_ACTIVITY_CATEGORY_FAILURE, payload: error});
    }
  }
}

/**
 * @description redux action for fetch object zones
 * @param  {Object} ownProps
 */
export const objectZonesFetchData = (ownProps) => {
  return async dispatch => {
    dispatch({type: FETCH_ZONE_REQUEST});
    try {
      const {data} = await axios.get(OBJECT_ZONE_API);
      dispatch({type: FETCH_ZONE_SUCCESS, payload: data.data.objectZones});
    } catch (error) {
      dispatch({type: FETCH_ZONE_FAILURE, payload: error});
    }
  }
}

/**
 * @description fetch all objects by parameters (search)
 * @param  {Object} objectParams
 * @param ownProps
 */
export const searchObjects = (objectParams, ownProps) => {
  return async dispatch => {
    dispatch({type: SEARCH_OBJECT_REQUEST});
    try {
      const {data} = await axios.get(OBJECT_API, {
        params: {
          keyword: objectParams.keyword || null,
          category_id: Number(objectParams.category_id) || null,
          zone_id: Number(objectParams.zone_id) || null
        }
      });
      dispatch({type: SEARCH_OBJECT_SUCCESS, payload: data.data.objects});
    } catch (error) {
      dispatch({type: SEARCH_OBJECT_FAILURE, payload: error.response.data});
    }
  }
}

/**
 * @description fetch all Karabakh categories
 * @param  {Object} ownProps
 */
export const karabakhCategoryFetchData = (ownProps) => {
  return async dispatch => {
    dispatch({type: FETCH_KARABAKH_CATEGORY_REQUEST});
    try {
      const {data} = await axios.get(KARABAKH_CATEGORY_API);
      dispatch({type: FETCH_KARABAKH_CATEGORY_SUCCESS, payload: data.data.categories});
      const categories = [];
      for (let i = 0; i < data.data.categories.length; i++) {
        categories.push(data.data.categories[i]);
      }
      localStorage.setItem('Karabakh_categories', JSON.stringify(categories));
    } catch (error) {
      dispatch({type: FETCH_KARABAKH_CATEGORY_FAILURE, payload: error.response.data});
    }
  }
}
