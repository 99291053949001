import React, {Component} from 'react';
import {isAuthenticated, isUnAuthPublicEnabled} from '../../../redux/services/auth.service';
import {checkBlock} from '../../../shared/checkBlocks';
import {connect} from 'react-redux';
import {aboutFetchData} from '../../../redux/actions/AboutActions';
import {ModalContext} from '../../../context/context';
import {Redirect} from 'react-router-dom';

/**
 * @description About component
 */
class About extends Component {
  componentDidMount() {
    this.props.fetchAbout();
  }
  render() {
    if (!isUnAuthPublicEnabled()) {
      return <Redirect to='/login' />
    }
    return (
      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-12">
            <div className="whitebox">
              <header className="whitebox-header bordered">
                <div className="row">
                  <div className="col-md-6">
                    <div className="whitebox-title">
                      <h4>Rayon haqqında</h4>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="float-right">
                      {isAuthenticated() && checkBlock('about', 'about-edit') ?
                        <ModalContext.Consumer>
                          {(context) => (
                            <button 
                               className="btn btn-default d-flex align-items-center"
                               onClick= {() => { context.showEditAboutModal(true, this.props.aboutDetails) }}
                            >
                              <i className="icon icon-edit mr-2"/>
                              Redaktə et
                            </button>
                          )}
                        </ModalContext.Consumer>
                        : null}
                    </div>
                  </div>
                </div>
              </header>
              {
                this.props.aboutDetails ?
                  <section className="whitebox-body">
                    <div className="row">
                      <div className="col-lg-12">
                        <article className="clr_blue">
                          <p>{this.props.aboutDetails.description || null}</p>
                        </article>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-12">
                        <article>
                          <ul>
                            {
                              this.props.aboutDetails.data.map((data, index) => (
                                <li key={index}>{data}</li>
                              ))
                            }
                          </ul>
                        </article>
                      </div>
                    </div>
                  </section>
                  : null
              }
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch, ownProps) => ({
  fetchAbout: () => dispatch(aboutFetchData(ownProps))
});

const mapStateToProps = state => {
  return state.aboutReducer;
};

export default connect(mapStateToProps, mapDispatchToProps)(About);
