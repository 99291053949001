import React, {Component} from 'react';
import {withFormik} from 'formik';
import {connect} from 'react-redux';
import CloseModal from '../../../shared/CloseModal';
import ModalSuccess from '../../../shared/ModalSuccess';
import {removeSuccessMessage} from '../../../../redux/actions/messageAction';
import {ModalContext} from '../../../../context/context';
import {aboutSchema} from '../../../../shared/validation';
import ButtonLoader from '../../../loader/ButtonLoader';
import CKEditor from 'ckeditor4-react';
import {victoryKarabakhUpdateData} from '../../../../redux/actions/karabakhAction';
import InputError from '../../errors/InputError';

/**
 * @description Component Class for EditKarabakhVictory
 */
class EditKarabakhVictory extends Component {
  static contextType = ModalContext;

  onEditorChange = (evt) => {
    this.props.setFieldValue('description', evt.editor.getData());
  }

  /**
   * @description all errors for one input
   * @param name
   * @returns {*}
   */
  handleInputError = (name) => {
    let heroErrors = this.props.victory && this.props.victory.error ? this.props.victory.data : [];

    let {touched, errors} = this.props;

    if (touched[name] && errors[name]) {
      heroErrors[name] = [errors[name]];
    }

    return <InputError errors={heroErrors} name={name}/>
  }

  render() {
    const {values, handleSubmit, handleChange, touched, errors} = this.props;

    return (
      <div className="modal-custom">
        <div className="container-fluid">
          <div className="row">
            <div className="col-xl-8 offset-xl-2">
              <div className="modal-custom-inner">
                <div className="modal-custom-header d-flex align-items-center justify-content-between">
                  <h4>
                    Zəfər günü
                  </h4>
                  <CloseModal tooltip={true} modal="showEditKarabakhVictoryModal" />
                </div>
                {this.props.victory ?
                  <div className="add-form about-form">
                    <div className="col-md-12">
                      <div className="form-group form-group-custom">
                        <label htmlFor="description">Zəfər günü</label>
                        <CKEditor
                          name="description"
                          data={values.description}
                          onChange={this.onEditorChange}
                        />
                        {this.handleInputError('description')}
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="form-group">
                        <label htmlFor="name">Youtube linki</label>
                        <input
                          type="text"
                          name="youtube_link"
                          className={'form-control form-control-custom ' + ((touched.youtube_link && errors.youtube_link) ? 'has-error' : null)}
                          placeholder="Youtube linki qeyd edin"
                          value={values.youtube_link}
                          onChange={handleChange}
                        />
                        {this.handleInputError('youtube_link')}
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="row">
                        <div className="col-md-4 offset-md-4">
                          <div className="form-group form-group-custom">
                            <button
                              type="button"
                              onClick={() => handleSubmit(values)}
                              className="btn btn-theme w-100"
                              disabled={this.props.isFetching ? true : null}
                            >
                              {this.props.isFetching ? <ButtonLoader /> : <span>Yadda saxla</span>}
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  : null}
                {this.props.success ? <ModalSuccess text="Zəfər günü Haqqında məlumat uğurla dəyişdirildi" /> : null }
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapPropsToValues = (props) => {
  if (props.victory) {
    const {victory} = props;

    return {
      'id': victory.id,
      'description': victory.description,
      'youtube_link': victory.youtube_link
    }
  }
  return {
    id: '',
    description: ''
  }
};

const EditKarabakhVictoryForm = withFormik({
  enableReinitialize: false,
  mapPropsToValues,
  validationSchema: aboutSchema,
  validateOnBlur: true,
  validateOnChange: true,
  handleSubmit: (values, {props, resetForm, setSubmitting}) => {
    props.editVictoryKarabakh(values);
    setTimeout(() => {
      props.removeSuccess();
    }, 4000);
  }
})(EditKarabakhVictory);

const mapDispatchToProps = (dispatch, ownProps) => ({
  editVictoryKarabakh: aboutData => dispatch(victoryKarabakhUpdateData(aboutData, ownProps)),
  removeSuccess: () => dispatch(removeSuccessMessage())
});

const mapStateToProps = state => {
  return state.karabakhReducer
};

export default connect(mapStateToProps, mapDispatchToProps)(EditKarabakhVictoryForm);

