import React, {Component} from 'react';
import {withFormik} from 'formik';
import AboutForm from '../AboutForm';
import {connect} from 'react-redux';
import CloseModal from '../../../shared/CloseModal';
import ModalSuccess from '../../../shared/ModalSuccess';
import {removeSuccessMessage} from '../../../../redux/actions/messageAction';
import {ModalContext} from '../../../../context/context';
import {aboutEditData} from '../../../../redux/actions/AboutActions';
import {aboutSchema} from '../../../../shared/validation';

/**
 * @description Component Class for EditAbout new object
 */
class EditAboutForm extends Component {
  static contextType = ModalContext;

  componentDidMount() {
    const {aboutDetails} = this.props;
    this.props.setValues({
      'id': aboutDetails.id,
      'description': aboutDetails.description,
      'data': aboutDetails.data
    });
  }

  /**
   * @description get data from about form
   */
  setData = (data) => {
    this.props.setFieldValue('data', data);
  };
  render() {
    return (
      <div className="modal-custom">
        <div className="container-fluid">
          <div className="row">
            <div className="col-xl-8 offset-xl-2">
              <div className="modal-custom-inner">
                <div className="modal-custom-header d-flex align-items-center justify-content-between">
                  <h4>
                    Rayon Haqqında
                  </h4>
                  <CloseModal tooltip={true} modal="showEditAboutModal" />
                </div>
                {this.props.aboutDetails ?
                  <AboutForm
                    edit={true}
                    formProps={this.props}
                    submitText={'Rayon Haqqında dəyiş'}
                    setParentData={this.setData}
                  />
                  : null}
                {this.props.success ? <ModalSuccess text="Rayon Haqqında məlumat uğurla dəyişdirildi" /> : null }
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapPropsToValues = (props) => {
  return {
    id: '',
    description: '',
    data: ['']
  }
};

const EditAbout = withFormik({
  enableReinitialize: false,
  mapPropsToValues,
  validationSchema: aboutSchema,
  validateOnBlur: true,
  validateOnChange: true,
  handleSubmit: (values, {props, resetForm, setSubmitting}) => {
    props.editAbout(values);
    setTimeout(() => {
      props.removeSuccess();
    }, 4000);
  }
})(EditAboutForm);

const mapDispatchToProps = (dispatch, ownProps) => ({
  editAbout: aboutData => dispatch(aboutEditData(aboutData, ownProps)),
  removeSuccess: () => dispatch(removeSuccessMessage())
});

const mapStateToProps = state => {
  return state.aboutReducer
};

export default connect(mapStateToProps, mapDispatchToProps)(EditAbout);

