import React from 'react';
import loader from '../../assets/img/loader.gif';

/**
 * @description Component Class for modal success
 */
const Loader = () =>

  <div className="col-md-12 text-center">
    <img className="loader" src={loader} alt={loader} />
  </div>

export default Loader;
