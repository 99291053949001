import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Redirect} from 'react-router-dom';
import {isAuthenticated} from '../../../../redux/services/auth.service';
import {ModalContext} from '../../../../context/context';
import {representativeFetchDetailData} from '../../../../redux/actions/representativeAction';
import Loader from '../../../shared/Loader';
import OwlCarousel from 'react-owl-carousel';

/**
 * @description AppealDetail component
 */
class NotesDetail extends Component {
  state = {
    itemCount: 3
  }
  static contextType = ModalContext;
  componentDidMount() {
    const {id} = this.props.match.params
    this.props.notesFetchDetail(id);
  }

  render() {
    if (!isAuthenticated()) {
      return <Redirect to='/login' />
    }
    const images = this.props.notesDetail && this.props.notesDetail.images ? this.props.notesDetail.images.map((img, i) => {
      return (
        <div className="object-images m-4" key={i}>
          <a target="_blank" rel="noopener noreferrer" href={`${process.env.REACT_APP_SERVER}/storage/` + img.path}>
            <img className="img-fluid" src={`${process.env.REACT_APP_SERVER}/storage/` + img.path} alt={img.path} />
          </a>
        </div>
      )
    }) : null
    return (
      <div className="container-fluid">
        <div className="row">

          <div className="col-lg-12">
            { this.props.notesDetail ?
              <div className="whitebox">
                <header
                  className="whitebox-header bordered d-md-flex align-items-center justify-content-between">
                  <div className="whitebox-title">
                    <h4>
                      {this.props.notesDetail.title}
                    </h4>
                    <ul className="breadcrumps">
                      <li className="breadcrumps-item">Nümayəndələrin qeydləri</li>
                      <li className="breadcrumps-item">{this.props.notesDetail.title}</li>
                    </ul>
                  </div>
                </header>

                <section className="whitebox-body">
                  <div className="row">
                    <div className="col-md-10">
                      <article>
                        <p>
                          {this.props.notesDetail.comment}
                        </p>
                        <h5 className="mt-4">Nümayəndə: {this.props.notesDetail.user.name}</h5>
                        <p className="mt-4">Ünvam: {this.props.notesDetail.address}</p>
                      </article>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-12">
                      <OwlCarousel
                        items={this.state.itemCount}
                        autoplay
                        dots={false}
                      >
                        {images}
                      </OwlCarousel>
                    </div>
                  </div>
                </section>
              </div>
              : <Loader />}
          </div>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch, ownProps) => ({
  notesFetchDetail: (paramId) => dispatch(representativeFetchDetailData(paramId, ownProps))
})

const mapStateToProps = state => {
  return state.representativeReducer
}

export default connect(mapStateToProps, mapDispatchToProps)(NotesDetail);
