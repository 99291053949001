import React, {Component} from 'react';
import {Map, GoogleApiWrapper, Polygon, InfoWindow, Marker} from 'google-maps-react';
import {MapOptions, RegionBoundaries} from '../../../../MapEnv';
import {objectFetchDataByZone} from '../../../../redux/actions/objectAction';
import {connect} from 'react-redux';

/**
 * @description Class for MainMap Component
 */
class MainMap extends Component {
  state = {
    color: '#44566c',
    fillColor: '#00FF7F',
    showingInfoWindow: false,
    activeMarker: {},
    selectedPlace: {},
    marker: {
      title: '',
      name: '',
      position: {lat: '', lng: ''}
    },
    map: null
  }

  /**
   * @description Fit clicked polygon and render markers
   * @param t
   * @param map
   * @param coord
   */
  onClickPolygon = (t, map, coord) => {
    const {google} = this.props
    const {paths} = t
    let bounds = new google.maps.LatLngBounds();

    for (let i = 0; i < paths.length; i++) {
      bounds.extend(new google.maps.LatLng(paths[i].lat, paths[i].lng));
    }

    this.state.map.fitBounds(bounds);

    this.setState({
      color: '#fff'
    });
  }

  /**
   * @description Set active when clicked marker
   * @param props
   * @param marker
   * @param e
   */
  onMarkerClick = (props, marker, e) => {
    return (
      this.setState({
        selectedPlace: props,
        activeMarker: marker,
        showingInfoWindow: true
      })
    )
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const {defaultMap} = this.props
    if (this.state.map && prevProps.defaultMap !== defaultMap) {
      this.state.map.setZoom(defaultMap.zoom)
      this.props.fetchObject(0);
    }
  }

  static getDerivedStateFromProps = (nextProps) => {
    if (nextProps.objectsByZone) {
      let markers = [];
      for (let i = 0; i < nextProps.objectsByZone.length; i++) {
        let obj = {};
        obj['title'] = nextProps.objectsByZone[i].category.name;
        obj['lat'] = nextProps.objectsByZone[i].building.lat;
        obj['lng'] = nextProps.objectsByZone[i].building.lng;
        obj['name'] = nextProps.objectsByZone[i].building.name;
        obj['address'] = nextProps.objectsByZone[i].building.address;
        obj['images'] = nextProps.objectsByZone[i].images;
        obj['owner'] = nextProps.objectsByZone[i].building.ownership;
        markers.push(obj)
      }
      return {markers};
    }

    return null;
  }
  /**
   * @description Set map element to state after nap initialising
   * @param props
   * @param map
   */
  setMap = (props, map) => {
    this.setState({map})
  }

  render() {
    const {mapStyles, mapCenter, defaultZoom} = MapOptions();
    const {badamdarCoords, bibiheybetCoords, sied1Coords, sied2Coords} = RegionBoundaries();
    const markers = this.state.markers ? this.state.markers.map((marker, index) => {
      return (
        <Marker
          key={index}
          title={marker.title}
          name={marker.name}
          address={marker.address}
          image={marker.images[0] ? marker.images[0].path : ''}
          owner={marker.owner}
          lng={marker.lng}
          lat={marker.lat}
          position={marker}
          onClick={this.onMarkerClick}
        />
      )
    }) : null
    return (
      <Map
        google={this.props.google}
        style={mapStyles}
        initialCenter={mapCenter}
        center={this.props.defaultMap.center}
        zoom={defaultZoom}
        onReady={this.setMap}
      >
        {markers}
        <InfoWindow marker={this.state.activeMarker} visible={this.state.showingInfoWindow}>
          <div className="map-object-info">
            <p>{this.state.selectedPlace.name}</p>
            <p>{this.state.selectedPlace.title}</p>
            <p>{this.state.selectedPlace.address}</p>

            <div className="row">
              <div className="col-md-6">
                <p>{this.state.selectedPlace.lng}</p>
              </div>
              <div className="col-md-6">
                <p>{this.state.selectedPlace.lat}</p>
              </div>
            </div>
          </div>
        </InfoWindow>
        <Polygon
          paths={badamdarCoords}
          strokeColor="#00FF7F"
          strokeOpacity = {0.5}
          strokeWeight={2}
          fillColor={this.state.fillColor}
          fillOpacity={0.5}
          zone={3}
          onClick={this.onClickPolygon}
        />

        <Polygon
          paths={bibiheybetCoords}
          strokeColor="#FEFE09"
          strokeOpacity = {0.5}
          strokeWeight={2}
          fillColor="#FEFE09"
          fillOpacity={0.5}
          zone={4}
          onClick={this.onClickPolygon}
        />

        <Polygon
          paths={sied1Coords}
          strokeColor="#FFB3FE"
          strokeOpacity = {0.5}
          strokeWeight={2}
          fillColor="#FFB3FE"
          fillOpacity={0.5}
          zone={1}
          onClick={this.onClickPolygon}
        />

        <Polygon
          paths={sied2Coords}
          strokeColor="#60ADFB"
          strokeOpacity = {0.5}
          strokeWeight={2}
          fillColor="#60ADFB"
          fillOpacity={0.5}
          zone={2}
          onClick={this.onClickPolygon}
        />
      </Map>
    );
  }
}

const mapDispatchToProps = (dispatch, ownProps) => ({
  fetchObject: (paramId) => dispatch(objectFetchDataByZone(paramId, ownProps))
})

const mapStateToProps = state => {
  return state.objectReducer;
}
export default connect(mapStateToProps, mapDispatchToProps)(GoogleApiWrapper({
  apiKey: process.env.REACT_APP_MAP_KEY,
  language: process.env.REACT_APP_MAP_LANG
})(MainMap));
