import React, {Component} from 'react';
import {connect} from 'react-redux';
import ButtonLoader from '../../loader/ButtonLoader';
import {legislationFetchCategories} from '../../../redux/actions/legislationActions';
import CKEditor from 'ckeditor4-react';

/**
 * @description LawForm component
 */
class LawForm extends Component {
  componentDidMount() {
    this.props.fetchLegislationCategories();
  }

  onEditorChange = (evt) => {
    this.props.formProps.setFieldValue('description', evt.editor.getData());
  }
  render() {
    const {values, handleBlur, handleChange, handleSubmit, touched, errors} = this.props.formProps;
    const categories = this.props.categories ? this.props.categories.map(category => {
      if (category.children.length) {
        return (
          <optgroup label={category.name} key={category.id}>
            {
              category.children.map((children) => {
                return (
                  <option key={children.id} value={children.id}>{children.name}</option>
                )
              })
            }
          </optgroup>
        )
      }
      return (
        <option key={category.id} value={category.id}>{category.name}</option>
      )
    }) : null;
    return (
      <section className="whitebox-body">
        <div className="add-form">
          <div className="row">

            <div className="col-lg-10">
              <div className="form-group form-group-custom">
                <label htmlFor="category_id">Kateqoriya</label>
                <select
                  name="category_id"
                  className='form-control form-control-custom selectbox'
                  value={values.category_id}
                  onChange={(e) => { e.persist(); handleChange(e); }}
                  onBlur={handleBlur}

                >
                  <option disabled value="">Kateqoriya seçin.</option>
                  {categories}
                </select>

                <p className="error">
                  {(this.props.formProps.law && this.props.formProps.law.error && this.props.formProps.law.data.category_id)
                    ? this.props.formProps.law.data.category_id.map(error => <span key={error}>{error}</span>)
                    : null}

                  {(touched.category_id && errors.category_id)
                    ? <span>{errors.category_id}</span>
                    : null}
                </p>
              </div>

              <div className="form-group form-group-custom">
                <label htmlFor="title">Başlıq</label>
                <input
                  type="text"
                  name="title"
                  className='form-control form-control-custom'
                  placeholder="başlıq"
                  min="2"
                  value={values.title}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                <p className="error">
                  {(this.props.formProps.law && this.props.formProps.law.error && this.props.formProps.law.data.title)
                    ? this.props.formProps.law.data.title.map(error => <span key={error}>{error}</span>)
                    : null}

                  {(touched.title && errors.title)
                    ? <span>{errors.title}</span>
                    : null}
                </p>
              </div>

              <div className="form-group form-group-custom">
                <label htmlFor="description">Ətraflı</label>
                <CKEditor
                  name="description"
                  data={values.description}
                  onChange={this.onEditorChange}
                  // onBlur={handleBlur}
                />
                <p className="error">
                  {(this.props.formProps.law && this.props.formProps.law.error && this.props.formProps.law.data.description)
                    ? this.props.formProps.law.data.description.map(error => <span key={error}>{error}</span>)
                    : null}

                  {(touched.description && errors.description)
                    ? <span>{errors.description}</span>
                    : null}
                </p>
              </div>

              <div className="row">
                <div className="form-group form-group-custom col-xl-6">
                  <label htmlFor="number">Qanun nömrəsi</label>
                  <input
                    type="text"
                    name="number"
                    className={'form-control form-control-custom ' + ((touched.number && errors.number) ? 'has-error' : null)}
                    placeholder="qanun nömrəsi"
                    maxLength="255"
                    value={values.number}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  <p className="error">
                    {(this.props.formProps.law && this.props.formProps.law.error && this.props.formProps.law.data.number)
                      ? this.props.formProps.law.data.number.map(error => <span key={error}>{error}</span>)
                      : null}

                    {(touched.number && errors.number)
                      ? <span>{errors.number}</span>
                      : null}
                  </p>
                </div>

                <div className="form-group form-group-custom col-xl-6">
                  <div className="col-xl-12">
                    <label htmlFor="adopted_at">Tarix</label>
                  </div>
                  <input
                    type="text"
                    className="form-control form-control-custom"
                    value={values.adopted_at}
                    name="adopted_at"
                    placeholder="Yaranma tarixi"
                    maxLength="255"
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />

                  <p className="error">
                    {(this.props.formProps.law && this.props.formProps.law.error && this.props.formProps.law.data.adopted_at)
                      ? this.props.formProps.law.data.adopted_at.map(error => <span key={error}>{error}</span>)
                      : null}

                    {(touched.adopted_at && errors.adopted_at)
                      ? <span>{errors.adopted_at}</span>
                      : null}
                  </p>
                </div>
              </div>

            </div>

            <div className="col-12">
              <div className="row">
                <div className="col-lg-6 col-xl-4">
                  <div className="form-group form-group-custom">
                    <button
                      type="button"
                      onClick={() => handleSubmit(values)}
                      className="btn btn-theme w-100"
                      disabled={this.props.isFetching ? true : null}
                    >
                      {this.props.isFetching ? <ButtonLoader /> : <span>Yadda saxla</span>}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  fetchLegislationCategories: () => dispatch(legislationFetchCategories())
});

const mapStateToProps = state => {
  return state.legislationReducer;
};

export default connect(mapStateToProps, mapDispatchToProps)(LawForm);

