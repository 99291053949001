import axios from 'axios';

/**
 * @description redux action for delete
 * @param id
 * @param types
 * @param api
 */
export const deleteAction = (id, types, api) => {
  return async dispatch => {
    dispatch({type: types.request});
    try {
      await axios.delete(api, {
        params: {
          ids: id
        }
      });
      dispatch({type: types.success});
    } catch (error) {
      dispatch({type: types.failure});
    }
  }
}
