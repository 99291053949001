import React, {Component} from 'react';
import {connect} from 'react-redux';
import InputMask from 'react-input-mask';
import ButtonLoader from '../../../loader/ButtonLoader';
import {vacancyCategoryFetchData, cityFetchData, experienceFetchData, educationFetchData} from '../../../../redux/actions/socialAction';
import {isAuthenticated} from '../../../../redux/services/auth.service';
import {Redirect} from 'react-router-dom';

/**
 * @description AddVacancy component
 */
class VacancyForm extends Component {
  state = {
    ages: [
      {
        value: 18
      },
      {
        value: 19
      },
      {
        value: 20
      },
      {
        value: 21
      },
      {
        value: 22
      },
      {
        value: 23
      },
      {
        value: 24
      },
      {
        value: 25
      },
      {
        value: 26
      },
      {
        value: 27
      },
      {
        value: 28
      },
      {
        value: 29
      },
      {
        value: 30
      },
      {
        value: 31
      },
      {
        value: 32
      },
      {
        value: 33
      },
      {
        value: 34
      },
      {
        value: 35
      },
      {
        value: 36
      },
      {
        value: 37
      },
      {
        value: 38
      },
      {
        value: 39
      },
      {
        value: 40
      },
      {
        value: 41
      },
      {
        value: 42
      },
      {
        value: 43
      },
      {
        value: 44
      },
      {
        value: 45
      },
      {
        value: 46
      },
      {
        value: 47
      },
      {
        value: 48
      },
      {
        value: 49
      },
      {
        value: 50
      },
      {
        value: 51
      },
      {
        value: 52
      },
      {
        value: 53
      },
      {
        value: 54
      },
      {
        value: 55
      },
      {
        value: 56
      },
      {
        value: 57
      },
      {
        value: 58
      },
      {
        value: 59
      },
      {
        value: 60
      },
      {
        value: 61
      },
      {
        value: 62
      },
      {
        value: 63
      }
    ]
  }
  componentDidMount() {
    this.props.fetchCategories();
    this.props.fetchCities();
    this.props.fetchExperience();
    this.props.fetchEducation();
  }
  render() {
    if (!isAuthenticated()) {
      return <Redirect to='/login' />
    }
    const {values, handleBlur, handleChange, handleSubmit, touched, errors} = this.props.formProps;
    const categories = this.props.categories ? this.props.categories.map(category => {
      return (
        <option key={category.id} value={category.id}>{category.name}</option>
      )
    }) : null

    const cities = this.props.cities ? this.props.cities.map(city => {
      return (
        <option key={city.id} value={city.id}>{city.name}</option>
      )
    }) : null

    const education = this.props.education ? this.props.education.map(edu => {
      return (
        <option key={edu.id} value={edu.id}>{edu.name}</option>
      )
    }) : null

    const experience = this.props.experiences ? this.props.experiences.map(e => {
      return (
        <option key={e.id} value={e.id}>{e.range}</option>
      )
    }) : null

    const ages = this.state.ages.map(age => {
      return (
        <option key={age.value} value={age.value}>{age.value}</option>
      )
    })
    return (
      <div className="add-form">
        <div className="row">

          <div className="col-lg-6 col-xl-4">
            <div className="form-group form-group-custom">
              <label htmlFor="vacancy_category_id">Kateqoriya</label>
              <select
                name="vacancy_category_id"
                className='form-control form-control-custom selectbox'
                value={values.vacancy_category_id}
                onChange={(e) => { e.persist(); handleChange(e); }}
                onBlur={handleBlur}

              >
                <option disabled value="">Kateqoriya seçin.</option>
                {categories}
              </select>

              <p className="error">
                {(this.props.formProps.vacancies && this.props.formProps.vacancies.error && this.props.formProps.vacancies.data.vacancy_categories_id)
                  ? this.props.formProps.vacancies.data.vacancy_categories_id.map(error => <span key={error}>{error}</span>)
                  : null}

                {(touched.vacancy_categories_id && errors.vacancy_categories_id)
                  ? <span>{errors.vacancy_categories_id}</span>
                  : null}
              </p>
            </div>

            <div className="form-group form-group-custom">
              <label htmlFor="profession">Vəzifə</label>
              <input
                type="text"
                name="profession"
                className='form-control form-control-custom'
                placeholder="Vəzifə"
                value={values.profession}
                onChange={handleChange}
                onBlur={handleBlur}
              />
              <p className="error">
                {(this.props.formProps.vacancies && this.props.formProps.vacancies.error && this.props.formProps.vacancies.data.profession)
                  ? this.props.formProps.vacancies.data.profession.map(error => <span key={error}>{error}</span>)
                  : null}

                {(touched.profession && errors.profession)
                  ? <span>{errors.profession}</span>
                  : null}
              </p>
            </div>

            <div className="form-group form-group-custom">
              <label htmlFor="city_id">Şəhər</label>
              <select
                name="city_id"
                className='form-control form-control-custom selectbox'
                value={values.city_id}
                onChange={(e) => { e.persist(); handleChange(e); }}
                onBlur={handleBlur}

              >
                <option disabled value="">Şəhər seçin.</option>
                {cities}
              </select>

              <p className="error">
                {(this.props.formProps.vacancies && this.props.formProps.vacancies.error && this.props.formProps.vacancies.data.city_id)
                  ? this.props.formProps.vacancies.data.city_id.map(error => <span key={error}>{error}</span>)
                  : null}

                {(touched.city_id && errors.city_id)
                  ? <span>{errors.city_id}</span>
                  : null}
              </p>
            </div>

            <div className="form-group form-group-custom">
              <div className="col-xs-12">
                <label htmlFor="salary">Maaş</label>
              </div>
              <div className="col-xs-12">
                <div className="row">
                  <div className="form-group form-group-custom col-xl-6">
                    <input
                      type="number"
                      name="min_salary"
                      className='form-control form-control-custom'
                      placeholder="Min"
                      value={values.min_salary}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    <p className="error">
                      {(this.props.formProps.vacancies && this.props.formProps.vacancies.error && this.props.formProps.vacancies.data.min_salary)
                        ? this.props.formProps.vacancies.data.min_salary.map(error => <span key={error}>{error}</span>)
                        : null}

                      {(touched.min_salary && errors.min_salary)
                        ? <span>{errors.min_salary}</span>
                        : null}
                    </p>
                  </div>

                  <div className="form-group form-group-custom col-xl-6">
                    <input
                      type="number"
                      name="max_salary"
                      className='form-control form-control-custom'
                      placeholder="Max"
                      value={values.max_salary}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    <p className="error">
                      {(this.props.formProps.vacancies && this.props.formProps.vacancies.error && this.props.formProps.vacancies.data.max_salary)
                        ? this.props.formProps.vacancies.data.max_salary.map(error => <span key={error}>{error}</span>)
                        : null}

                      {(touched.max_salary && errors.max_salary)
                        ? <span>{errors.max_salary}</span>
                        : null}
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-xl-12">
                <label htmlFor="age">Yaş</label>
              </div>
              <div className="form-group form-group-custom col-xl-6">
                <select
                  name="min_age"
                  className='form-control form-control-custom selectbox'
                  value={values.min_age}
                  onChange={(e) => { e.persist(); handleChange(e); }}
                  onBlur={handleBlur}

                >
                  <option disabled value=''>min</option>
                  {ages}
                </select>

                <p className="error">
                  {(this.props.formProps.vacancies && this.props.formProps.vacancies.error && this.props.formProps.vacancies.data.min_age)
                    ? this.props.formProps.vacancies.data.min_age.map(error => <span key={error}>{error}</span>)
                    : null}

                  {(touched.min_age && errors.min_age)
                    ? <span>{errors.min_age}</span>
                    : null}
                </p>
              </div>

              <div className="form-group form-group-custom col-xl-6">
                <select
                  name="max_age"
                  className='form-control form-control-custom selectbox'
                  value={values.max_age}
                  onChange={(e) => { e.persist(); handleChange(e); }}
                  onBlur={handleBlur}

                >
                  <option disabled value=''>max</option>
                  {ages}
                </select>
                <p className="error">
                  {(this.props.formProps.vacancies && this.props.formProps.vacancies.error && this.props.formProps.vacancies.data.max_age)
                    ? this.props.formProps.vacancies.data.max_age.map(error => <span key={error}>{error}</span>)
                    : null}

                  {(touched.max_age && errors.max_age)
                    ? <span>{errors.max_age}</span>
                    : null}
                </p>
              </div>
            </div>

            <div className="row">
              <div className="form-group form-group-custom col-xl-6">
                <label htmlFor="education">Təhsil</label>
                <select
                  name="education_id"
                  className='form-control form-control-custom selectbox'
                  value={values.education_id}
                  onChange={(e) => { e.persist(); handleChange(e); }}
                  onBlur={handleBlur}

                >
                  <option disabled value="">Təhsil seçin.</option>
                  {education}
                </select>
                <p className="error">
                  {(this.props.formProps.vacancies && this.props.formProps.vacancies.error && this.props.formProps.vacancies.data.education_id)
                    ? this.props.formProps.vacancies.data.education_id.map(error => <span key={error}>{error}</span>)
                    : null}

                  {(touched.education_id && errors.education_id)
                    ? <span>{errors.education_id}</span>
                    : null}
                </p>
              </div>

              <div className="form-group form-group-custom col-xl-6">
                <label htmlFor="experience">İş təcrübəsi</label>
                <select
                  name="experience_id"
                  className='form-control form-control-custom selectbox'
                  value={values.experience_id}
                  onChange={(e) => { e.persist(); handleChange(e); }}
                  onBlur={handleBlur}

                >
                  <option disabled value="">İş təcrübəsi</option>
                  {experience}
                </select>
                <p className="error">
                  {(this.props.formProps.vacancies && this.props.formProps.vacancies.error && this.props.formProps.vacancies.data.experience_id)
                    ? this.props.formProps.vacancies.data.experience_id.map(error => <span key={error}>{error}</span>)
                    : null}

                  {(touched.experience_id && errors.experience_id)
                    ? <span>{errors.experience_id}</span>
                    : null}
                </p>
              </div>
            </div>

            <div className="form-group form-group-custom">
              <label htmlFor="related_person">Əlaqədar şəxs</label>
              <input
                type="text"
                name="related_person"
                className='form-control form-control-custom'
                value={values.related_person}
                onChange={handleChange}
                onBlur={handleBlur}
              />
              <p className="error">
                {(this.props.formProps.vacancies && this.props.formProps.vacancies.error && this.props.formProps.vacancies.data.related_person)
                  ? this.props.formProps.vacancies.data.related_person.map(error => <span key={error}>{error}</span>)
                  : null}

                {(touched.related_person && errors.related_person)
                  ? <span>{errors.related_person}</span>
                  : null}
              </p>
            </div>

          </div>

          <div className="col-lg-6 col-xl-4">

            <div className="form-group form-group-custom">
              <label htmlFor="phone">Əlaqə nömrəsi</label>
              <InputMask
                mask="9999999999"
                className='form-control form-control-custom'
                maskChar={null}
                name="contact_number"
                value={values.contact_number}
                onChange={handleChange}
                onBlur={handleBlur}
                beforeMaskedValueChange={this.beforeMaskedValueChange}
                placeholder="xxxxxxxxxx"
              />
              <p className="error">
                {(this.props.vacancies && this.props.vacancies.error && this.props.vacancies.data.contact_number)
                  ? this.props.vacancies.data.contact_number.map(error => <span key={error}>{error}</span>)
                  : null}

                {(touched.contact_number && errors.contact_number)
                  ? <span>{errors.contact_number}</span>
                  : null}
              </p>
            </div>

            <div className="form-group form-group-custom">
              <label htmlFor="email">E-mail</label>
              <input
                type="email"
                name="email"
                className='form-control form-control-custom'
                placeholder="musakazımov@email.com"
                value={values.email}
                onChange={handleChange}
                onBlur={handleBlur}
              />
            </div>

            <div className="form-group form-group-custom">
              <label htmlFor="company_name">Müəssisənin adı</label>
              <input
                type="text"
                name="company_name"
                className='form-control form-control-custom'
                placeholder="Kənd Təsərrüfatı Nazirliyi"
                value={values.company_name}
                onChange={handleChange}
                onBlur={handleBlur}
              />
              <p className="error">
                {(this.props.vacancies && this.props.vacancies.error && this.props.vacancies.data.company_name)
                  ? this.props.vacancies.data.company_name.map(error => <span key={error}>{error}</span>)
                  : null}

                {(touched.company_name && errors.company_name)
                  ? <span>{errors.company_name}</span>
                  : null}
              </p>
            </div>

            <div className="form-group form-group-custom">
              <label htmlFor="requirements">Namizədə tələblər</label>
              <textarea
                name="candidate_requirements"
                className='form-control form-control-custom'
                value={values.candidate_requirements}
                onChange={handleChange}
                onBlur={handleBlur}
              />
              <p className="error">
                {(this.props.vacancies && this.props.vacancies.error && this.props.vacancies.data.candidate_requirements)
                  ? this.props.vacancies.data.candidate_requirements.map(error => <span key={error}>{error}</span>)
                  : null}

                {(touched.candidate_requirements && errors.candidate_requirements)
                  ? <span>{errors.candidate_requirements}</span>
                  : null}
              </p>
            </div>

            <div className="form-group form-group-custom">
              <label htmlFor="info">İş barədə məlumat</label>
              <textarea
                name="more_info"
                className='form-control form-control-custom'
                value={values.more_info}
                onChange={handleChange}
                onBlur={handleBlur}
              />
            </div>

          </div>

          <div className="col-12">
            <div className="row">
              <div className="col-lg-6 col-xl-4">
                <div className="form-group form-group-custom">
                  <button
                    type="button"
                    onClick={() => handleSubmit(values)}
                    className="btn btn-theme w-100"
                    disabled={this.props.isFetching ? true : null}
                  >
                    {this.props.isFetching ? <ButtonLoader /> : <span>{this.props.submitText}</span>}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  fetchCategories: () => dispatch(vacancyCategoryFetchData()),
  fetchCities: () => dispatch(cityFetchData()),
  fetchExperience: () => dispatch(experienceFetchData()),
  fetchEducation: () => dispatch(educationFetchData())
})

const mapStateToProps = state => {
  return state.socialReducer;
}

export default connect(mapStateToProps, mapDispatchToProps)(VacancyForm);

