import axios from 'axios';
import {isAuthenticated, getToken, logout} from '../redux/services/auth.service';

/**
 * @return interceptor
 */
const axiosInterceptor = () => {
  // const TIMEOUT = 1 * 60 * 1000;
  // axios.defaults.timeout = TIMEOUT;
  axios.defaults.baseURL = process.REACT_APP_BASE_URL
  // declaring a request interceptor
  return (
    axios.interceptors.response.use((response) => {
      return response
    }, error => {
      if (error.response.status === 401) {
        logout();
        window.location.href = '/login';
      }

      return Promise.reject(error);
    }),

    axios.interceptors.request.use(config => {
      // perform a task before the request is sent
      if (isAuthenticated) {
        config.headers.Authorization = `Bearer ${getToken()}`;
      }

      return config;
    }, error => {
      // handle the error
      return Promise.reject(error);
    })
  )
}

export default axiosInterceptor;
