import React, {Component} from 'react';
import {connect} from 'react-redux';
import {withFormik} from 'formik';
import {vacancyEditData, vacancyFetchDetail} from '../../../../../redux/actions/socialAction';
import {removeSuccessMessage} from '../../../../../redux/actions/messageAction';
import ModalSuccess from '../../../../shared/ModalSuccess';
import {vacancySchema} from '../../../../../shared/validation';
import {isAuthenticated} from '../../../../../redux/services/auth.service';
import {Redirect} from 'react-router-dom';
import VacancyForm from '../VacancyForm';
import {vacancyFormsMapProps} from '../../../../../shared/formMapProps';

/**
 * @description AddVacancy component
 */
class EditVacancy extends Component {
  componentDidMount() {
    const {id} = this.props.match.params
    this.props.fetchVacancy(id);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.vacancy && nextProps.vacancy !== this.props.vacancy) {
      const vacancy = nextProps.vacancy;
      this.props.setValues({
        'id': vacancy.id,
        'vacancy_category_id': vacancy.vacancy_category_id,
        'profession': vacancy.profession,
        'city_id': vacancy.city_id,
        'min_salary': vacancy.min_salary,
        'max_salary': vacancy.max_salary,
        'min_age': vacancy.min_age,
        'max_age': vacancy.max_age,
        'education_id': vacancy.education_id,
        'experience_id': vacancy.experience_id,
        'related_person': vacancy.related_person,
        'contact_number': vacancy.contact_number,
        'email': vacancy.email,
        'company_name': vacancy.company_name,
        'candidate_requirements': vacancy.candidate_requirements,
        'more_info': vacancy.more_info
      });
    }
  }

  render() {
    if (!isAuthenticated()) {
      return <Redirect to='/login' />
    }

    return (
      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-12">
            <div className="whitebox">
              <header className="whitebox-header bordered">

                <div className="whitebox-title ">
                  <h4>
                    Vakansiyanı redaktə et
                  </h4>
                  <ul className="breadcrumps">
                    <li className="breadcrumps-item">Sosial bölmə</li>
                    <li className="breadcrumps-item">Vakansiyalar</li>
                    <li className="breadcrumps-item">Redaktə</li>
                  </ul>
                </div>

              </header>

              <section className="whitebox-body">
                <VacancyForm formProps = {this.props} submitText={'Redaktə et'} />
              </section>
              {this.props.success ? <ModalSuccess text="Vakansiya uğurla redaktə edildi" /> : null }
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapPropsToValues = (props) => vacancyFormsMapProps(props)

const EditVacancyForm = withFormik({
  enableReinitialize: false,
  mapPropsToValues,
  validationSchema: vacancySchema,
  validateOnBlur: true,
  validateOnChange: true,
  handleSubmit: (values, {props, resetForm, setSubmitting}) => {
    props.editVacancy(values);

    setTimeout(() => {
      props.removeSuccess();
    }, 4000);
  }
})(EditVacancy);

const mapDispatchToProps = (dispatch) => ({
  editVacancy: (vacancyData) => dispatch(vacancyEditData(vacancyData)),
  fetchVacancy: (id) => dispatch(vacancyFetchDetail(id)),
  removeSuccess: () => dispatch(removeSuccessMessage())
})

const mapStateToProps = state => {
  return state.socialReducer;
}

export default connect(mapStateToProps, mapDispatchToProps)(EditVacancyForm);

