import React, {Component} from 'react';
import exclamation from '../../assets/img/exclamation.png'

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = {error: null, errorInfo: null};
  }

  componentDidCatch(error, errorInfo) {
    this.setState({
      error: error,
      errorInfo: errorInfo
    });
  }

  render() {
    if (this.state.errorInfo) {
      return (
        <div className="modal-custom">
          <div className="container-fluid">
            <div className="row">
              <div className="col-xl-4 offset-xl-4">
                <div className="modal-custom-inner text-center">

                  <div className="mb-5 mt-4">
                    <img height="250px" src={exclamation} alt="error"/>
                  </div>

                  <h4 className="modal-info-text">
                   Xəta baş verdi, biraz sonra yenidən cəhd edin!
                  </h4>

                  <p>
                    <a href="/dashboard" className="btn btn-theme mt-4" style={{height: 'auto'}}>Əsas səhifə</a>
                  </p>

                </div>
              </div>
            </div>
          </div>
        </div>
      );
    }
    // Render children if there's no error
    return this.props.children;
  }
}

export default ErrorBoundary;
