import {
  ADD_APPEAL_REQUEST,
  ADD_APPEAL_SUCCESS,
  ADD_APPEAL_FAILURE,
  FETCH_APPEAL_REQUEST,
  FETCH_APPEAL_SUCCESS,
  FETCH_APPEAL_FAILURE,
  REMOVE_SUCCESS_MESSAGE,
  EDIT_APPEAL_REQUEST,
  EDIT_APPEAL_SUCCESS,
  EDIT_APPEAL_FAILURE,
  FETCH_APPEAL_DETAIL_REQUEST,
  FETCH_APPEAL_DETAIL_SUCCESS,
  FETCH_APPEAL_DETAIL_FAILURE,
  APPEAL_FORWARD_REQUEST,
  APPEAL_FORWARD_SUCCESS,
  APPEAL_FORWARD_FAILURE,
  APPEAL_ACCEPT_REQUEST,
  APPEAL_ACCEPT_SUCCESS,
  APPEAL_ACCEPT_FAILURE,
  APPEAL_DONE_REQUEST,
  APPEAL_DONE_SUCCESS,
  APPEAL_DONE_FAILURE,
  FETCH_APPEAL_CATEGORY_REQUEST,
  FETCH_APPEAL_CATEGORY_SUCCESS,
  FETCH_APPEAL_CATEGORY_FAILURE,
  DELETE_APPEAL_REQUEST,
  DELETE_APPEAL_SUCCESS,
  DELETE_APPEAL_FAILURE
} from '../types/actionTypes';

/**
 * @description initial state for appealReducer
 */
const initialState = {};

/**
 * @description updates state depending on action data
 * @param {Object} state = initialState
 * @param {string} type = action.type
 * @param {} payload
 * @param {Array} appeals
 * @returns return state
 */
export default function appealReducer(state = initialState, {type, payload, meta}) {
  switch (type) {
  case ADD_APPEAL_REQUEST:
    return {
      ...state,
      appeal:payload,
      success: false,
      isFetching: true
    };
  case ADD_APPEAL_SUCCESS:
    return {
      ...state,
      appeal:payload,
      success: true,
      isFetching: false
    };
  case ADD_APPEAL_FAILURE:
    return {
      ...state,
      appeal:payload,
      success: false,
      isFetching: false
    };

  case DELETE_APPEAL_REQUEST:
    return {
      ...state,
      success: false,
      isFetching: true
    };
  case DELETE_APPEAL_SUCCESS:
    return {
      ...state,
      success: true,
      isFetching: false
    };
  case DELETE_APPEAL_FAILURE:
    return {
      ...state,
      success: false,
      isFetching: false
    };

  case EDIT_APPEAL_REQUEST:
    return {
      ...state,
      success: false,
      isFetching: true
    };
  case EDIT_APPEAL_SUCCESS:
    return {
      ...state,
      success: true,
      isFetching: false
    };
  case EDIT_APPEAL_FAILURE:
    return {
      ...state,
      success: false,
      isFetching: false
    };

  case FETCH_APPEAL_REQUEST:
    return {
      ...state,
      isFetching: true,
      appeals: payload
    };
  case FETCH_APPEAL_SUCCESS:
    return {
      ...state,
      isFetching: false,
      appeals: payload,
      meta: meta
    };
  case FETCH_APPEAL_FAILURE:
    return {
      ...state,
      isFetching: false,
      appeals: payload
    };
  case FETCH_APPEAL_DETAIL_REQUEST:
    return {
      ...state,
      isFetching: true,
      appealDetails: payload
    };
  case FETCH_APPEAL_DETAIL_SUCCESS:
    return {
      ...state,
      isFetching: false,
      appealDetails: payload
    };
  case FETCH_APPEAL_DETAIL_FAILURE:
    return {
      ...state,
      isFetching: false,
      appealDetails: payload
    };

  case APPEAL_FORWARD_REQUEST:
    return {
      ...state,
      isFetching: true,
      forwardStatus: false
    };
  case APPEAL_FORWARD_SUCCESS:
    return {
      ...state,
      isFetching: false,
      forwardStatus: true
    };
  case APPEAL_FORWARD_FAILURE:
    return {
      ...state,
      isFetching: false,
      forwardStatus: payload
    };

  case APPEAL_ACCEPT_REQUEST:
    return {
      ...state,
      isFetching: true,
      appealAcceptStatus: false
    };
  case APPEAL_ACCEPT_SUCCESS:
    return {
      ...state,
      isFetching: false,
      appealAcceptStatus: true
    };
  case APPEAL_ACCEPT_FAILURE:
    return {
      ...state,
      isFetching: false,
      appealAcceptStatus: false
    };

  case APPEAL_DONE_REQUEST:
    return {
      ...state,
      isFetching: true,
      success: false,
      appealDoneStatus: false,
      appealDoneData: payload
    };
  case APPEAL_DONE_SUCCESS:
    return {
      ...state,
      isFetching: false,
      appealDoneStatus: true,
      success: true,
      appealDoneData: payload
    };
  case APPEAL_DONE_FAILURE:
    return {
      ...state,
      isFetching: false,
      success: false,
      appealDoneStatus: false,
      appealDoneData: payload
    };

  case FETCH_APPEAL_CATEGORY_REQUEST:
    return {
      ...state,
      isFetching: true,
      appealCategories: payload
    };
  case FETCH_APPEAL_CATEGORY_SUCCESS:
    return {
      ...state,
      isFetching: false,
      appealCategories: payload
    };
  case FETCH_APPEAL_CATEGORY_FAILURE:
    return {
      ...state,
      isFetching: false,
      appealCategories: payload
    };

  case REMOVE_SUCCESS_MESSAGE:
    return {
      ...state,
      success:false,
      appealDoneStatus: false
    };
  default:
    return state
  }
}
