import waypointIcon from './assets/img/bus-waypoint.svg';
import originIcon from './assets/img/bus-origin-destination.svg';

/**
 * @description React Google Map Environment settings
 */
export const MapSettings = () => {
  return ({key: process.env.REACT_APP_MAP_KEY, language: process.env.REACT_APP_MAP_LANG, region: process.env.REACT_APP_MAP_REGION})
}

/**
 * @description React Google Map Environment options
 */
export const MapOptions = () => {
  return {
    mapStyles: {
      width: '100%',
      height: '100%'
    },
    mapCenter: {
      lat: 40.33435618965296,
      lng: 49.81755039319033
    },
    mapCenterDetail: {
      lat: 40.3390071,
      lng: 49.8276351
    },
    center: {
      lat: 40.3334754,
      lng: 49.8248459
    },
    defaultZoom: Number(process.env.REACT_APP_MAP_ZOOM)
  }
}

/**
 * @description make marker data for using transport routes
 * @param data
 * @returns {{visibility: boolean, name: string, icon: null, index: null, label: string, position: {}, type: string, title: string}}
 */
export const makeMarker = (data) => {
  const {name, type, index, label, title, icon, visibility, position, lat, lng} = data || {};
  let latLng = {}
  let defaultIcon = null;

  if (type) {
    defaultIcon = type === 'waypoint' ? waypointIcon : originIcon;
  }
  if (!position && lat && lng) {
    latLng = {lat: lat, lng: lng}
  }

  return {
    name: name || '',
    type: type || 'waypoint',
    index: index || index === 0 ? index : null,
    label: label || '',
    title: title || '',
    icon: icon || defaultIcon,
    visibility: visibility || false,
    position: position || latLng
  };
}

/**
 * @description gets All boundaries of projects region
 * @return {Object} all lat long
 */
export const RegionBoundaries = () => {
  return {
    badamdarCoords: [
      {
        lng: 49.811125490744203,
        lat: 40.353487187362902
      }, {
        lng: 49.810986828235762,
        lat: 40.354284123135919
      }, {
        lng: 49.810584749850371,
        lat: 40.355751267886561
      }, {
        lng: 49.809871147343628,
        lat: 40.355137953617493
      }, {
        lng: 49.808643984839442,
        lat: 40.35486884183171
      }, {
        lng: 49.806748495387481,
        lat: 40.35452114157512
      }, {
        lng: 49.803099511042362,
        lat: 40.354027608151327
      }, {
        lng: 49.799697844499029,
        lat: 40.353653815658369
      }, {
        lng: 49.798552826486507,
        lat: 40.354127144513527
      }, {
        lng: 49.797503092935841,
        lat: 40.354874755645191
      }, {
        lng: 49.798263931069719,
        lat: 40.351452296639721
      }, {
        lng: 49.79807253410074,
        lat: 40.348694566402031
      }, {
        lng: 49.796758834118613,
        lat: 40.34356462499035
      }, {
        lng: 49.795668004784467,
        lat: 40.340031325358687
      }, {
        lng: 49.793701355710311,
        lat: 40.335471881573362
      }, {
        lng: 49.792854527630702,
        lat: 40.334333277540892
      }, {
        lng: 49.791631332161423,
        lat: 40.331883920723442
      }, {
        lng: 49.79098077562108,
        lat: 40.329709428077997
      }, {
        lng: 49.79051020181538,
        lat: 40.326035480166503
      }, {
        lng: 49.791467757247588,
        lat: 40.32177267092915
      }, {
        lng: 49.79425947662611,
        lat: 40.317863206005669
      }, {
        lng: 49.795858963861427,
        lat: 40.313778471210931
      }, {
        lng: 49.797276241804163,
        lat: 40.313699019755752
      }, {
        lng: 49.80062545030664,
        lat: 40.314050172383347
      }, {
        lng: 49.800696102949203,
        lat: 40.3144636852019
      }, {
        lng: 49.800707002751992,
        lat: 40.314573598276553
      }, {
        lng: 49.80078125450342,
        lat: 40.314562686379929
      }, {
        lng: 49.800834022914323,
        lat: 40.314577149698522
      }, {
        lng: 49.80449405922473,
        lat: 40.314367578761718
      }, {
        lng: 49.806419922428617,
        lat: 40.315275732306283
      }, {
        lng: 49.809597887971123,
        lat: 40.315004278526672
      }, {
        lng: 49.81244795584341,
        lat: 40.314660663629638
      }, {
        lng: 49.813265020740573,
        lat: 40.314262234957553
      }, {
        lng: 49.817190148064952,
        lat: 40.315168131378179
      }, {
        lng: 49.818786400653913,
        lat: 40.317587640301333
      }, {
        lng: 49.817757791449552,
        lat: 40.318587723915762
      }, {
        lng: 49.816984879891002,
        lat: 40.318810016255242
      }, {
        lng: 49.816705150795713,
        lat: 40.319108338149739
      }, {
        lng: 49.816190793292101,
        lat: 40.323604340484628
      }, {
        lng: 49.814171137077572,
        lat: 40.327043061066732
      }, {
        lng: 49.813956142115401,
        lat: 40.330602388872698
      }, {
        lng: 49.813116906945659,
        lat: 40.331212773399763
      }, {
        lng: 49.814886313748197,
        lat: 40.333827536922342
      }, {
        lng: 49.814839654513079,
        lat: 40.334606229274449
      }, {
        lng: 49.816046033746389,
        lat: 40.335933189795881
      }, {
        lng: 49.815838437506613,
        lat: 40.337637450375823
      }, {
        lng: 49.81416496671968,
        lat: 40.341748374476531
      }, {
        lng: 49.814039449397093,
        lat: 40.344281041957167
      }, {
        lng: 49.81474231182905,
        lat: 40.346381589725553
      }, {
        lng: 49.815764560323622,
        lat: 40.347898314391571
      }, {
        lng: 49.817664905719369,
        lat: 40.349132691221442
      }, {
        lng: 49.819500033263907,
        lat: 40.349720415508202
      }, {
        lng: 49.821526652295802,
        lat: 40.34963402762375
      }, {
        lng: 49.822808666534868,
        lat: 40.349959982345567
      }, {
        lng: 49.822714416195439,
        lat: 40.351640748090901
      }, {
        lng: 49.822480997831804,
        lat: 40.354088065863181
      }, {
        lng: 49.821896800999468,
        lat: 40.354720862907918
      }, {
        lng: 49.821709236291248,
        lat: 40.35521215301862
      }, {
        lng: 49.821685776767957,
        lat: 40.355871146533758
      }, {
        lng: 49.819673287381441,
        lat: 40.355390026819173
      }, {
        lng: 49.818396572874889,
        lat: 40.3549089172637
      }, {
        lng: 49.817457538603868,
        lat: 40.356023574633852
      }, {
        lng: 49.812130902431861,
        lat: 40.353468326336852
      }, {
        lng: 49.811125490744203,
        lat: 40.353487187362902
      }
    ],
    bibiheybetCoords: [
      {
        lng: 49.765480307060983,
        lat: 40.304420765323812
      }, {
        lng: 49.767216023107117,
        lat: 40.301368856354692
      }, {
        lng: 49.766644249617173,
        lat: 40.300624492600761
      }, {
        lng: 49.767828995357043,
        lat: 40.298158441651204
      }, {
        lng: 49.771572011410903,
        lat: 40.298767063317413
      }, {
        lng: 49.771822934383103,
        lat: 40.298577962650633
      }, {
        lng: 49.772056708300823,
        lat: 40.298522697726192
      }, {
        lng: 49.772195756616632,
        lat: 40.29887797008179
      }, {
        lng: 49.774523452518409,
        lat: 40.300205091097737
      }, {
        lng: 49.776996197025632,
        lat: 40.301375719274724
      }, {
        lng: 49.777291400641829,
        lat: 40.301224949153607
      }, {
        lng: 49.777599813877487,
        lat: 40.301102287051997
      }, {
        lng: 49.77789689575301,
        lat: 40.301112269844921
      }, {
        lng: 49.77828375741511,
        lat: 40.301208638593799
      }, {
        lng: 49.778631448064992,
        lat: 40.301358366396343
      }, {
        lng: 49.778960065667142,
        lat: 40.301531040216062
      }, {
        lng: 49.779321315544777,
        lat: 40.301562435306238
      }, {
        lng: 49.779748411688189,
        lat: 40.301539668580183
      }, {
        lng: 49.779956306401317,
        lat: 40.301561079013503
      }, {
        lng: 49.779974368462547,
        lat: 40.301411417017007
      }, {
        lng: 49.779268585530168,
        lat: 40.301302719605118
      }, {
        lng: 49.779194503326117,
        lat: 40.301392562012893
      }, {
        lng: 49.779012824437523,
        lat: 40.301367980234652
      }, {
        lng: 49.778857784801993,
        lat: 40.301278192235422
      }, {
        lng: 49.778852862089238,
        lat: 40.301154018896689
      }, {
        lng: 49.779003319489959,
        lat: 40.301052988300647
      }, {
        lng: 49.779160130368339,
        lat: 40.301083146524967
      }, {
        lng: 49.779254557509361,
        lat: 40.301198662902443
      }, {
        lng: 49.780055272475877,
        lat: 40.301364611032412
      }, {
        lng: 49.780070875708901,
        lat: 40.301505225739582
      }, {
        lng: 49.780303021971243,
        lat: 40.301620163739443
      }, {
        lng: 49.780241833140792,
        lat: 40.301736978176407
      }, {
        lng: 49.780377165169178,
        lat: 40.302048032201121
      }, {
        lng: 49.781943285232508,
        lat: 40.302553323033457
      }, {
        lng: 49.784930003073939,
        lat: 40.303327350793957
      }, {
        lng: 49.785464926061181,
        lat: 40.303402213071173
      }, {
        lng: 49.785886415475566,
        lat: 40.303381791517722
      }, {
        lng: 49.786092988141519,
        lat: 40.303351396966747
      }, {
        lng: 49.786386675665788,
        lat: 40.303343364859202
      }, {
        lng: 49.78660495878767,
        lat: 40.303337372129491
      }, {
        lng: 49.788129345007022,
        lat: 40.303757623571883
      }, {
        lng: 49.78951529284452,
        lat: 40.303951962500349
      }, {
        lng: 49.790191808469302,
        lat: 40.304031763120769
      }, {
        lng: 49.790549602387408,
        lat: 40.304069870600003
      }, {
        lng: 49.792057350615892,
        lat: 40.304309964829208
      }, {
        lng: 49.792535791347667,
        lat: 40.304368208372921
      }, {
        lng: 49.794129116305761,
        lat: 40.304579395095303
      }, {
        lng: 49.794626582244923,
        lat: 40.304627838120552
      }, {
        lng: 49.794645945470187,
        lat: 40.304571672426491
      }, {
        lng: 49.795061089732272,
        lat: 40.304587095652209
      }, {
        lng: 49.79507336654617,
        lat: 40.30454135969503
      }, {
        lng: 49.795190488393558,
        lat: 40.304564473369773
      }, {
        lng: 49.795177960654428,
        lat: 40.304621662189959
      }, {
        lng: 49.795825234217702,
        lat: 40.304642242820378
      }, {
        lng: 49.795870689834288,
        lat: 40.304276306377112
      }, {
        lng: 49.795808885948041,
        lat: 40.30427353355671
      }, {
        lng: 49.795824026596307,
        lat: 40.304216694534283
      }, {
        lng: 49.796079585397422,
        lat: 40.304232826842266
      }, {
        lng: 49.796072607391501,
        lat: 40.304284416814333
      }, {
        lng: 49.796126059927843,
        lat: 40.304330967440137
      }, {
        lng: 49.796123561759693,
        lat: 40.304393093381989
      }, {
        lng: 49.796099976836672,
        lat: 40.304414775665101
      }, {
        lng: 49.796096213569527,
        lat: 40.304665312390611
      }, {
        lng: 49.797021010603927,
        lat: 40.30473589444248
      }, {
        lng: 49.798521547505317,
        lat: 40.304883707508957
      }, {
        lng: 49.79968584509276,
        lat: 40.304912276654314
      }, {
        lng: 49.801462695595433,
        lat: 40.304970128957002
      }, {
        lng: 49.801803022297413,
        lat: 40.304914467059973
      }, {
        lng: 49.802987528959569,
        lat: 40.304905058851652
      }, {
        lng: 49.804385253491247,
        lat: 40.304792413382117
      }, {
        lng: 49.804627811882362,
        lat: 40.304716912765137
      }, {
        lng: 49.805051865659088,
        lat: 40.304630970191432
      }, {
        lng: 49.805380613244942,
        lat: 40.304592711926048
      }, {
        lng: 49.805561417907569,
        lat: 40.304572779400637
      }, {
        lng: 49.805706044841138,
        lat: 40.304470054121047
      }, {
        lng: 49.805698440336727,
        lat: 40.304432852383798
      }, {
        lng: 49.80582198167663,
        lat: 40.304414316067707
      }, {
        lng: 49.80584475885837,
        lat: 40.304442641612582
      }, {
        lng: 49.805972603367557,
        lat: 40.304493306024092
      }, {
        lng: 49.806101266035377,
        lat: 40.304455585370746
      }, {
        lng: 49.806349207448889,
        lat: 40.30418885489604
      }, {
        lng: 49.806573628505276,
        lat: 40.30412880971852
      }, {
        lng: 49.80648229645756,
        lat: 40.303488582614712
      }, {
        lng: 49.80636839294494,
        lat: 40.30307437478745
      }, {
        lng: 49.806338381129308,
        lat: 40.302862412152678
      }, {
        lng: 49.806447832261782,
        lat: 40.302812057311407
      }, {
        lng: 49.806539682229833,
        lat: 40.302895471119463
      }, {
        lng: 49.80674232831619,
        lat: 40.304007845889743
      }, {
        lng: 49.80700920310052,
        lat: 40.304114608134817
      }, {
        lng: 49.807209834444599,
        lat: 40.304090230037012
      }, {
        lng: 49.807859345338827,
        lat: 40.304066047369879
      }, {
        lng: 49.808700697837303,
        lat: 40.304007874005841
      }, {
        lng: 49.8088545178556,
        lat: 40.30394245675047
      }, {
        lng: 49.809165476672497,
        lat: 40.303933396991667
      }, {
        lng: 49.80948635842816,
        lat: 40.304022417298377
      }, {
        lng: 49.809847736581361,
        lat: 40.304002496471142
      }, {
        lng: 49.810031317177192,
        lat: 40.303576986059952
      }, {
        lng: 49.8101477930147,
        lat: 40.303357304490227
      }, {
        lng: 49.810244678589299,
        lat: 40.303141418591359
      }, {
        lng: 49.810301388074571,
        lat: 40.303013163516212
      }, {
        lng: 49.810382412347977,
        lat: 40.302996745958112
      }, {
        lng: 49.810431686141051,
        lat: 40.303054534490158
      }, {
        lng: 49.810491389939237,
        lat: 40.303105964265797
      }, {
        lng: 49.81064792019459,
        lat: 40.30310730979393
      }, {
        lng: 49.810912370948706,
        lat: 40.303093721459142
      }, {
        lng: 49.811174552858127,
        lat: 40.303050522071374
      }, {
        lng: 49.81135224131252,
        lat: 40.302987152859203
      }, {
        lng: 49.811535137943743,
        lat: 40.302767404817963
      }, {
        lng: 49.811684440861569,
        lat: 40.302368990598502
      }, {
        lng: 49.811878166823213,
        lat: 40.301915605367739
      }, {
        lng: 49.812035987678847,
        lat: 40.301613665317923
      }, {
        lng: 49.812196209569379,
        lat: 40.301252484949657
      }, {
        lng: 49.81229731271246,
        lat: 40.301068200057493
      }, {
        lng: 49.812396855213763,
        lat: 40.300985223266153
      }, {
        lng: 49.812392878193492,
        lat: 40.30109604399253
      }, {
        lng: 49.81235421310312,
        lat: 40.301323082441229
      }, {
        lng: 49.812280108673832,
        lat: 40.301505857166418
      }, {
        lng: 49.812320559349693,
        lat: 40.301620888781478
      }, {
        lng: 49.812435264965899,
        lat: 40.301663807535341
      }, {
        lng: 49.812606742379209,
        lat: 40.301654879196249
      }, {
        lng: 49.812718520306809,
        lat: 40.30153885437894
      }, {
        lng: 49.812829477665183,
        lat: 40.3014152980167
      }, {
        lng: 49.813063485722687,
        lat: 40.301320128764772
      }, {
        lng: 49.813329834457441,
        lat: 40.301226187945787
      }, {
        lng: 49.813551402200282,
        lat: 40.301163206548061
      }, {
        lng: 49.813722996833782,
        lat: 40.301136007650513
      }, {
        lng: 49.813834991676678,
        lat: 40.301149343664257
      }, {
        lng: 49.813897082065289,
        lat: 40.301168172084331
      }, {
        lng: 49.814033143683822,
        lat: 40.301087786962967
      }, {
        lng: 49.814136926101042,
        lat: 40.301065339213558
      }, {
        lng: 49.814259584289999,
        lat: 40.301069329499519
      }, {
        lng: 49.81432420447495,
        lat: 40.301111011308933
      }, {
        lng: 49.814444484039079,
        lat: 40.301031637815548
      }, {
        lng: 49.81453404447322,
        lat: 40.301058581771358
      }, {
        lng: 49.814649088520802,
        lat: 40.301117643727387
      }, {
        lng: 49.814656502828562,
        lat: 40.301127899976507
      }, {
        lng: 49.81462311921657,
        lat: 40.301261949512323
      }, {
        lng: 49.81458328079291,
        lat: 40.30135795566013
      }, {
        lng: 49.814624208280151,
        lat: 40.301451518364537
      }, {
        lng: 49.8146276832951,
        lat: 40.30153417571244
      }, {
        lng: 49.814601331644198,
        lat: 40.301631814719549
      }, {
        lng: 49.814652698078028,
        lat: 40.301743585482797
      }, {
        lng: 49.814851301896859,
        lat: 40.301895859579119
      }, {
        lng: 49.815229495951833,
        lat: 40.302073132086747
      }, {
        lng: 49.815888524676517,
        lat: 40.302364547125329
      }, {
        lng: 49.816165240263111,
        lat: 40.302469675216983
      }, {
        lng: 49.8165741755437,
        lat: 40.302588760653833
      }, {
        lng: 49.816875546582907,
        lat: 40.302653721511028
      }, {
        lng: 49.816998014072503,
        lat: 40.302604947201772
      }, {
        lng: 49.817062286232158,
        lat: 40.302543853693422
      }, {
        lng: 49.817094840414683,
        lat: 40.302473897440898
      }, {
        lng: 49.817145272298092,
        lat: 40.302480604639811
      }, {
        lng: 49.817140446378708,
        lat: 40.302558585855436
      }, {
        lng: 49.817122395537332,
        lat: 40.302662895375462
      }, {
        lng: 49.817223105410747,
        lat: 40.302714738801541
      }, {
        lng: 49.817429502739529,
        lat: 40.302781385255678
      }, {
        lng: 49.819378818847589,
        lat: 40.303289496217289
      }, {
        lng: 49.819528745184073,
        lat: 40.303292030560257
      }, {
        lng: 49.819681658779622,
        lat: 40.303335443895897
      }, {
        lng: 49.819797914562542,
        lat: 40.303332072453102
      }, {
        lng: 49.819867843904632,
        lat: 40.303265360075862
      }, {
        lng: 49.81996499242878,
        lat: 40.303241942677431
      }, {
        lng: 49.820118681992177,
        lat: 40.303269233433987
      }, {
        lng: 49.820250490659816,
        lat: 40.303248953639901
      }, {
        lng: 49.82036711199283,
        lat: 40.303135321627472
      }, {
        lng: 49.820438408621293,
        lat: 40.303075355970677
      }, {
        lng: 49.820554870239818,
        lat: 40.302999840637924
      }, {
        lng: 49.820650296075733,
        lat: 40.302976993932312
      }, {
        lng: 49.82073944835571,
        lat: 40.303015347409982
      }, {
        lng: 49.82075873295998,
        lat: 40.303099591005981
      }, {
        lng: 49.820828965507779,
        lat: 40.303158011168932
      }, {
        lng: 49.821253813605779,
        lat: 40.303260509130361
      }, {
        lng: 49.821815916440578,
        lat: 40.303344725991138
      }, {
        lng: 49.82215761775992,
        lat: 40.303379304896652
      }, {
        lng: 49.822416757821607,
        lat: 40.303396809181251
      }, {
        lng: 49.82266525143757,
        lat: 40.303378245743723
      }, {
        lng: 49.822823519396053,
        lat: 40.303344492754952
      }, {
        lng: 49.822873243996632,
        lat: 40.303240163731829
      }, {
        lng: 49.822896973638102,
        lat: 40.303131082878288
      }, {
        lng: 49.822970125537381,
        lat: 40.303061434943238
      }, {
        lng: 49.823062898100211,
        lat: 40.302985996314732
      }, {
        lng: 49.823146156868852,
        lat: 40.302994274557172
      }, {
        lng: 49.824200064347018,
        lat: 40.303242899503203
      }, {
        lng: 49.824261133019753,
        lat: 40.30320864903593
      }, {
        lng: 49.824420129102037,
        lat: 40.302936462585087
      }, {
        lng: 49.824531427977611,
        lat: 40.302966555090897
      }, {
        lng: 49.82438093679523,
        lat: 40.303294153591033
      }, {
        lng: 49.824432328309577,
        lat: 40.303350384274573
      }, {
        lng: 49.825102031600522,
        lat: 40.303487823912441
      }, {
        lng: 49.825470879934578,
        lat: 40.303544474759669
      }, {
        lng: 49.825849905777893,
        lat: 40.3035666519987
      }, {
        lng: 49.826336411509949,
        lat: 40.303517693864862
      }, {
        lng: 49.82731814381949,
        lat: 40.303377094813847
      }, {
        lng: 49.828811292810883,
        lat: 40.303094068250907
      }, {
        lng: 49.830212318272032,
        lat: 40.302807282178293
      }, {
        lng: 49.830409562571987,
        lat: 40.302790481956052
      }, {
        lng: 49.830658718356389,
        lat: 40.302726522510618
      }, {
        lng: 49.830884361636713,
        lat: 40.302693369973888
      }, {
        lng: 49.83102453099275,
        lat: 40.302711262862069
      }, {
        lng: 49.831080604443358,
        lat: 40.302801054175781
      }, {
        lng: 49.831078337820543,
        lat: 40.302954836920058
      }, {
        lng: 49.831041875274252,
        lat: 40.303156827899919
      }, {
        lng: 49.831089755198811,
        lat: 40.303361860219432
      }, {
        lng: 49.831128658747168,
        lat: 40.303568835726473
      }, {
        lng: 49.831133117482267,
        lat: 40.303722152749337
      }, {
        lng: 49.831311031021862,
        lat: 40.303939124432063
      }, {
        lng: 49.83166725081734,
        lat: 40.304177153124449
      }, {
        lng: 49.831804648711042,
        lat: 40.304252260514929
      }, {
        lng: 49.831938341082029,
        lat: 40.304443670573583
      }, {
        lng: 49.831896467100208,
        lat: 40.30451895255225
      }, {
        lng: 49.8321402033895,
        lat: 40.304779932461003
      }, {
        lng: 49.833288595215947,
        lat: 40.306046770696597
      }, {
        lng: 49.833777372504912,
        lat: 40.30655792683585
      }, {
        lng: 49.834057228225383,
        lat: 40.306851047677632
      }, {
        lng: 49.834431786704357,
        lat: 40.307261150111373
      }, {
        lng: 49.834317374691722,
        lat: 40.307331831357523
      }, {
        lng: 49.833049457975292,
        lat: 40.305946254743503
      }, {
        lng: 49.832267474480297,
        lat: 40.305145049372769
      }, {
        lng: 49.8318019423983,
        lat: 40.304720055888971
      }, {
        lng: 49.831092006279128,
        lat: 40.304580569900139
      }, {
        lng: 49.830819247281383,
        lat: 40.304430392928559
      }, {
        lng: 49.830189343469613,
        lat: 40.304438789601313
      }, {
        lng: 49.829971605007209,
        lat: 40.304525186013642
      }, {
        lng: 49.829762440666237,
        lat: 40.304479556855817
      }, {
        lng: 49.829602452714227,
        lat: 40.304434431871023
      }, {
        lng: 49.829500257344293,
        lat: 40.304488209457283
      }, {
        lng: 49.829407208848281,
        lat: 40.304572605916043
      }, {
        lng: 49.829390271442733,
        lat: 40.304586275176533
      }, {
        lng: 49.829387631881062,
        lat: 40.304589346230017
      }, {
        lng: 49.829291315492192,
        lat: 40.304670884824688
      }, {
        lng: 49.829290622158858,
        lat: 40.304726782038571
      }, {
        lng: 49.829421304642047,
        lat: 40.304783533086891
      }, {
        lng: 49.829445076799267,
        lat: 40.304836877170487
      }, {
        lng: 49.829384410654768,
        lat: 40.3048973748691
      }, {
        lng: 49.829203324730372,
        lat: 40.304948201339592
      }, {
        lng: 49.829196605965748,
        lat: 40.30494980112362
      }, {
        lng: 49.829059381372218,
        lat: 40.304960066469462
      }, {
        lng: 49.828945840479193,
        lat: 40.304935644113193
      }, {
        lng: 49.828702299470883,
        lat: 40.305046214553272
      }, {
        lng: 49.828685623242272,
        lat: 40.305052670332771
      }, {
        lng: 49.82845053053569,
        lat: 40.305117219017554
      }, {
        lng: 49.828446473932523,
        lat: 40.305115474419281
      }, {
        lng: 49.828330725884243,
        lat: 40.305143001711592
      }, {
        lng: 49.828164586266027,
        lat: 40.305100544340043
      }, {
        lng: 49.82802259634812,
        lat: 40.305185062172853
      }, {
        lng: 49.82793743174657,
        lat: 40.305307325066401
      }, {
        lng: 49.828934098196228,
        lat: 40.306222460508721
      }, {
        lng: 49.829914962402157,
        lat: 40.305586840321673
      }, {
        lng: 49.8301692597826,
        lat: 40.305778501022537
      }, {
        lng: 49.83131450080014,
        lat: 40.306605157773383
      }, {
        lng: 49.831268783554272,
        lat: 40.306659548633633
      }, {
        lng: 49.830128449184137,
        lat: 40.305852814436463
      }, {
        lng: 49.829117001069712,
        lat: 40.306483838964972
      }, {
        lng: 49.827852978263152,
        lat: 40.305341311624879
      }, {
        lng: 49.827281978752133,
        lat: 40.305622237946338
      }, {
        lng: 49.827283914157292,
        lat: 40.305708417162293
      }, {
        lng: 49.827225249764872,
        lat: 40.305749999524032
      }, {
        lng: 49.826973186488161,
        lat: 40.30575436528818
      }, {
        lng: 49.826761117083642,
        lat: 40.305867048281279
      }, {
        lng: 49.826656043839257,
        lat: 40.305949163421509
      }, {
        lng: 49.826461653850892,
        lat: 40.306049668355442
      }, {
        lng: 49.826347295769921,
        lat: 40.306153369736997
      }, {
        lng: 49.826217154333079,
        lat: 40.306242018805243
      }, {
        lng: 49.826069008503943,
        lat: 40.306285256419862
      }, {
        lng: 49.826063545966768,
        lat: 40.30628675205115
      }, {
        lng: 49.82589993239354,
        lat: 40.306363077871183
      }, {
        lng: 49.825890603709887,
        lat: 40.306371355882987
      }, {
        lng: 49.825711819043313,
        lat: 40.306437413513549
      }, {
        lng: 49.825545736750307,
        lat: 40.306524821202892
      }, {
        lng: 49.825418418176362,
        lat: 40.306602086834118
      }, {
        lng: 49.825220558973101,
        lat: 40.306679793586667
      }, {
        lng: 49.825062630324858,
        lat: 40.306800008959527
      }, {
        lng: 49.825011346791278,
        lat: 40.306907085555238
      }, {
        lng: 49.825075277708521,
        lat: 40.307054433657541
      }, {
        lng: 49.825118160003029,
        lat: 40.307146124522092
      }, {
        lng: 49.825128700026632,
        lat: 40.307260078314329
      }, {
        lng: 49.825810413229043,
        lat: 40.307236488923813
      }, {
        lng: 49.82581568408186,
        lat: 40.307294584244723
      }, {
        lng: 49.825134157111407,
        lat: 40.307331141454533
      }, {
        lng: 49.825109730527821,
        lat: 40.308178467230448
      }, {
        lng: 49.82687723678238,
        lat: 40.308233464358352
      }, {
        lng: 49.826885211528896,
        lat: 40.308297302175923
      }, {
        lng: 49.824824521155463,
        lat: 40.308251301819809
      }, {
        lng: 49.824839108909828,
        lat: 40.307804805452747
      }, {
        lng: 49.824391742592312,
        lat: 40.307762345957492
      }, {
        lng: 49.824368368683643,
        lat: 40.308001555555279
      }, {
        lng: 49.824264864180194,
        lat: 40.30800416538613
      }, {
        lng: 49.824268832976067,
        lat: 40.30777098763604
      }, {
        lng: 49.824056215958549,
        lat: 40.307760474837337
      }, {
        lng: 49.82388422666223,
        lat: 40.307646957130963
      }, {
        lng: 49.823613903329942,
        lat: 40.307747773056668
      }, {
        lng: 49.823536060595671,
        lat: 40.307924225447287
      }, {
        lng: 49.824028456888023,
        lat: 40.308515544493133
      }, {
        lng: 49.823998378046319,
        lat: 40.308619402707507
      }, {
        lng: 49.822998285832313,
        lat: 40.30906211826602
      }, {
        lng: 49.8224907629154,
        lat: 40.311772440091879
      }, {
        lng: 49.822088892822478,
        lat: 40.313826647991426
      }, {
        lng: 49.822999096234341,
        lat: 40.31430813418195
      }, {
        lng: 49.823446158120788,
        lat: 40.314318966872861
      }, {
        lng: 49.823708535835557,
        lat: 40.314441710467442
      }, {
        lng: 49.82445956367507,
        lat: 40.313614911220903
      }, {
        lng: 49.824276352780387,
        lat: 40.313487069525721
      }, {
        lng: 49.824375577816703,
        lat: 40.313377135317559
      }, {
        lng: 49.824559509090392,
        lat: 40.313478583017542
      }, {
        lng: 49.825390430243367,
        lat: 40.312554613675992
      }, {
        lng: 49.825303534042497,
        lat: 40.31249499225725
      }, {
        lng: 49.825315816458243,
        lat: 40.312457266622062
      }, {
        lng: 49.824705222690383,
        lat: 40.312145255637482
      }, {
        lng: 49.824798913907038,
        lat: 40.312046893819037
      }, {
        lng: 49.82552472160738,
        lat: 40.312416473923612
      }, {
        lng: 49.82590226052411,
        lat: 40.311984737230333
      }, {
        lng: 49.825851611526453,
        lat: 40.311950651480963
      }, {
        lng: 49.825831770308547,
        lat: 40.311893825604272
      }, {
        lng: 49.8252127895977,
        lat: 40.311584288291847
      }, {
        lng: 49.825270670502363,
        lat: 40.311468071956057
      }, {
        lng: 49.825986028155661,
        lat: 40.311811958371379
      }, {
        lng: 49.826328581959899,
        lat: 40.311657304933981
      }, {
        lng: 49.826987221803677,
        lat: 40.311323975103342
      }, {
        lng: 49.826209550503627,
        lat: 40.310655355670349
      }, {
        lng: 49.826296061475738,
        lat: 40.310590652837448
      }, {
        lng: 49.827062072471151,
        lat: 40.311262565258872
      }, {
        lng: 49.827897448759138,
        lat: 40.310890137994093
      }, {
        lng: 49.827984364980452,
        lat: 40.310974372270032
      }, {
        lng: 49.828509999133558,
        lat: 40.31070782625428
      }, {
        lng: 49.828184071892053,
        lat: 40.310052280374933
      }, {
        lng: 49.828335996720163,
        lat: 40.309993285866412
      }, {
        lng: 49.828652294653367,
        lat: 40.310452488814818
      }, {
        lng: 49.829514528936862,
        lat: 40.309825020943101
      }, {
        lng: 49.830404046927853,
        lat: 40.309227533993472
      }, {
        lng: 49.831858236882297,
        lat: 40.310071044787371
      }, {
        lng: 49.832185788762473,
        lat: 40.31002291043589
      }, {
        lng: 49.832330291191496,
        lat: 40.310015064767697
      }, {
        lng: 49.832499511340288,
        lat: 40.310149007944233
      }, {
        lng: 49.832603210042883,
        lat: 40.31007118616612
      }, {
        lng: 49.832438885026512,
        lat: 40.309933893665693
      }, {
        lng: 49.832590770706183,
        lat: 40.309748343842237
      }, {
        lng: 49.832510865652559,
        lat: 40.309595568964163
      }, {
        lng: 49.832716628682718,
        lat: 40.309654934554104
      }, {
        lng: 49.833109003182237,
        lat: 40.309787592522078
      }, {
        lng: 49.83329464403036,
        lat: 40.309877391435663
      }, {
        lng: 49.833421155825341,
        lat: 40.309790815516969
      }, {
        lng: 49.833437414117192,
        lat: 40.309730696173808
      }, {
        lng: 49.835337781883922,
        lat: 40.309372842811662
      }, {
        lng: 49.835412154956941,
        lat: 40.309283454864961
      }, {
        lng: 49.835489720409328,
        lat: 40.309284043730941
      }, {
        lng: 49.835546159903643,
        lat: 40.309367591742102
      }, {
        lng: 49.835631738865182,
        lat: 40.30958838360263
      }, {
        lng: 49.835739228101851,
        lat: 40.309736812299207
      }, {
        lng: 49.835853042403798,
        lat: 40.309747443776757
      }, {
        lng: 49.836790257435943,
        lat: 40.312481353004173
      }, {
        lng: 49.837826093465189,
        lat: 40.312493780637062
      }, {
        lng: 49.837837925264452,
        lat: 40.312992043833553
      }, {
        lng: 49.836733574655248,
        lat: 40.313055501610222
      }, {
        lng: 49.837790930409881,
        lat: 40.316309396893161
      }, {
        lng: 49.838526615103582,
        lat: 40.318666692189581
      }, {
        lng: 49.839332463769722,
        lat: 40.321033407900622
      }, {
        lng: 49.840194797536903,
        lat: 40.32370869907146
      }, {
        lng: 49.835299134969503,
        lat: 40.324884189359672
      }, {
        lng: 49.830015836206137,
        lat: 40.325727405004393
      }, {
        lng: 49.828784780654189,
        lat: 40.326019608568707
      }, {
        lng: 49.82914153873552,
        lat: 40.327570593393503
      }, {
        lng: 49.826221954558122,
        lat: 40.328454333010562
      }, {
        lng: 49.824846338437439,
        lat: 40.326650649470238
      }, {
        lng: 49.82387898609619,
        lat: 40.325372665979238
      }, {
        lng: 49.822778277159443,
        lat: 40.323545310831591
      }, {
        lng: 49.822028440933487,
        lat: 40.322156309499192
      }, {
        lng: 49.821306161128618,
        lat: 40.320821611421273
      }, {
        lng: 49.820916763513473,
        lat: 40.317557413610253
      }, {
        lng: 49.819750123745493,
        lat: 40.317565012307192
      }, {
        lng: 49.818774155044082,
        lat: 40.317590783805329
      }, {
        lng: 49.818154934935613,
        lat: 40.316640552225849
      }, {
        lng: 49.817971509574853,
        lat: 40.316368298483638
      }, {
        lng: 49.817560105011573,
        lat: 40.31572690918734
      }, {
        lng: 49.817192186820101,
        lat: 40.315169055220423
      }, {
        lng: 49.816810791504032,
        lat: 40.315103023450838
      }, {
        lng: 49.814879139769488,
        lat: 40.314625528607941
      }, {
        lng: 49.813261210848232,
        lat: 40.314252195256429
      }, {
        lng: 49.812420704392373,
        lat: 40.314666337856373
      }, {
        lng: 49.809739701736213,
        lat: 40.314972440424313
      }, {
        lng: 49.809145513682502,
        lat: 40.315036963030607
      }, {
        lng: 49.806418826563757,
        lat: 40.315271778705913
      }, {
        lng: 49.804509071863841,
        lat: 40.314379101897408
      }, {
        lng: 49.800845669550156,
        lat: 40.31458183143554
      }, {
        lng: 49.800775722920612,
        lat: 40.314553511481563
      }, {
        lng: 49.800702941728439,
        lat: 40.314574029455287
      }, {
        lng: 49.800630292761511,
        lat: 40.314050316712553
      }, {
        lng: 49.797288167441479,
        lat: 40.313698237700159
      }, {
        lng: 49.79585921086781,
        lat: 40.313788423840272
      }, {
        lng: 49.794264837320704,
        lat: 40.317857736284218
      }, {
        lng: 49.792832536439469,
        lat: 40.319876729818887
      }, {
        lng: 49.791471696623439,
        lat: 40.321757055972022
      }, {
        lng: 49.791056819412837,
        lat: 40.323628379484887
      }, {
        lng: 49.790508080770842,
        lat: 40.326024219525593
      }, {
        lng: 49.785952431390989,
        lat: 40.321274326612212
      }, {
        lng: 49.782259294391089,
        lat: 40.316801989156922
      }, {
        lng: 49.778374117249747,
        lat: 40.31417107798363
      }, {
        lng: 49.774758277935582,
        lat: 40.312144944587743
      }, {
        lng: 49.772200488326952,
        lat: 40.310378806966199
      }, {
        lng: 49.769216476719002,
        lat: 40.308095534115402
      }, {
        lng: 49.767156633247588,
        lat: 40.306060602119032
      }, {
        lng: 49.765480307060983,
        lat: 40.304420765323812
      }
    ],
    sied1Coords: [
      {
        lng: 49.843767617416901,
        lat: 40.373872632311183
      }, {
        lng: 49.84315316500404,
        lat: 40.373699681175253
      }, {
        lng: 49.842613427409432,
        lat: 40.373576862368999
      }, {
        lng: 49.840368063222407,
        lat: 40.3729374547612
      }, {
        lng: 49.835397203008512,
        lat: 40.371636397420168
      }, {
        lng: 49.833857134263482,
        lat: 40.37128693428599
      }, {
        lng: 49.831077551910468,
        lat: 40.370571663805187
      }, {
        lng: 49.828610469760747,
        lat: 40.369561706182999
      }, {
        lng: 49.826278203335029,
        lat: 40.368695468322549
      }, {
        lng: 49.824865589167693,
        lat: 40.368740041228321
      }, {
        lng: 49.825270768451432,
        lat: 40.366557665080748
      }, {
        lng: 49.825774085450362,
        lat: 40.365258834325793
      }, {
        lng: 49.825302105933332,
        lat: 40.364807743682213
      }, {
        lng: 49.824713964992249,
        lat: 40.363812865804427
      }, {
        lng: 49.824429931869183,
        lat: 40.363572757880583
      }, {
        lng: 49.824061256204757,
        lat: 40.363577533499551
      }, {
        lng: 49.823483949895319,
        lat: 40.363501602636852
      }, {
        lng: 49.823084036191609,
        lat: 40.363463542313198
      }, {
        lng: 49.821594807724424,
        lat: 40.362119404139669
      }, {
        lng: 49.819925310202592,
        lat: 40.363245122878553
      }, {
        lng: 49.816808522625877,
        lat: 40.36086363254843
      }, {
        lng: 49.815364898815773,
        lat: 40.362161112819159
      }, {
        lng: 49.814011612097588,
        lat: 40.358795270927097
      }, {
        lng: 49.811157492763513,
        lat: 40.357197803547407
      }, {
        lng: 49.810743455215849,
        lat: 40.356373317593118
      }, {
        lng: 49.810604600551159,
        lat: 40.355738525124181
      }, {
        lng: 49.811074912240571,
        lat: 40.35412127884922
      }, {
        lng: 49.811130785143519,
        lat: 40.35347356429245
      }, {
        lng: 49.812064008585061,
        lat: 40.353474009605968
      }, {
        lng: 49.814263060609179,
        lat: 40.354514652995832
      }, {
        lng: 49.817471376788177,
        lat: 40.356039126921033
      }, {
        lng: 49.818391819798507,
        lat: 40.354909745742383
      }, {
        lng: 49.819714577743483,
        lat: 40.355453335969358
      }, {
        lng: 49.821760749223927,
        lat: 40.355891307981103
      }, {
        lng: 49.82235565851871,
        lat: 40.356404031086903
      }, {
        lng: 49.822521562765047,
        lat: 40.356138489228947
      }, {
        lng: 49.823022715309577,
        lat: 40.355649315508607
      }, {
        lng: 49.823199172913107,
        lat: 40.355814529862982
      }, {
        lng: 49.823522376105153,
        lat: 40.35571700190129
      }, {
        lng: 49.824196765066887,
        lat: 40.355778215423499
      }, {
        lng: 49.824319565576253,
        lat: 40.355889675869612
      }, {
        lng: 49.825389251971828,
        lat: 40.355404894273519
      }, {
        lng: 49.827507516148273,
        lat: 40.354538131168027
      }, {
        lng: 49.828582251841823,
        lat: 40.354434394738369
      }, {
        lng: 49.82980684899114,
        lat: 40.354306013309568
      }, {
        lng: 49.82982694780619,
        lat: 40.354157276353213
      }, {
        lng: 49.832023896412593,
        lat: 40.354017837272529
      }, {
        lng: 49.833805837167958,
        lat: 40.354507841864269
      }, {
        lng: 49.836085528238463,
        lat: 40.354574575377733
      }, {
        lng: 49.837749537564413,
        lat: 40.354374575701797
      }, {
        lng: 49.837869520942057,
        lat: 40.354921899257477
      }, {
        lng: 49.836002951458987,
        lat: 40.355236932334122
      }, {
        lng: 49.836535461983559,
        lat: 40.357410079383541
      }, {
        lng: 49.837903681995691,
        lat: 40.357287711278083
      }, {
        lng: 49.83791500302884,
        lat: 40.357193803624178
      }, {
        lng: 49.838389819022623,
        lat: 40.357165513104313
      }, {
        lng: 49.83863396036017,
        lat: 40.358844433192523
      }, {
        lng: 49.836964349704481,
        lat: 40.359230341566239
      }, {
        lng: 49.837374880529573,
        lat: 40.362215190899533
      }, {
        lng: 49.839738902865861,
        lat: 40.361997029468249
      }, {
        lng: 49.839779862032778,
        lat: 40.362002440092112
      }, {
        lng: 49.840127347582282,
        lat: 40.361843105619151
      }, {
        lng: 49.840142164427093,
        lat: 40.361468424911273
      }, {
        lng: 49.840198588928558,
        lat: 40.361429819410908
      }, {
        lng: 49.840781492740362,
        lat: 40.361712194518773
      }, {
        lng: 49.84065033705982,
        lat: 40.361774857142713
      }, {
        lng: 49.840607979778177,
        lat: 40.362329869390472
      }, {
        lng: 49.84042351061381,
        lat: 40.362464975288539
      }, {
        lng: 49.8400270253481,
        lat: 40.362155150119563
      }, {
        lng: 49.837417038302533,
        lat: 40.362401004457311
      }, {
        lng: 49.837770244514033,
        lat: 40.363184756247961
      }, {
        lng: 49.838071069729978,
        lat: 40.36352593583409
      }, {
        lng: 49.839862129514188,
        lat: 40.365313161552798
      }, {
        lng: 49.841912766691728,
        lat: 40.36682260564411
      }, {
        lng: 49.843826925598869,
        lat: 40.367817391735699
      }, {
        lng: 49.844570662099258,
        lat: 40.366869078583001
      }, {
        lng: 49.844742674400322,
        lat: 40.366894195976279
      }, {
        lng: 49.84405308182788,
        lat: 40.367861920635029
      }, {
        lng: 49.84888558848057,
        lat: 40.369399902057822
      }, {
        lng: 49.849163460467551,
        lat: 40.369257786454547
      }, {
        lng: 49.849511360588608,
        lat: 40.369342204258409
      }, {
        lng: 49.851371160628943,
        lat: 40.366159695371557
      }, {
        lng: 49.851681860021891,
        lat: 40.364940540321363
      }, {
        lng: 49.852287251751846,
        lat: 40.364503907495077
      }, {
        lng: 49.853084589995817,
        lat: 40.36527604323787
      }, {
        lng: 49.851620517660137,
        lat: 40.366146450455332
      }, {
        lng: 49.849729401041998,
        lat: 40.36943675308985
      }, {
        lng: 49.852630517361057,
        lat: 40.370406626963771
      }, {
        lng: 49.852895404758307,
        lat: 40.370630959743337
      }, {
        lng: 49.853448723277197,
        lat: 40.370761346871468
      }, {
        lng: 49.854369131437799,
        lat: 40.370736808150923
      }, {
        lng: 49.854968367197003,
        lat: 40.371079952540661
      }, {
        lng: 49.855132530982956,
        lat: 40.37144891046254
      }, {
        lng: 49.855547271180768,
        lat: 40.371621821267333
      }, {
        lng: 49.856484136162159,
        lat: 40.371567911149363
      }, {
        lng: 49.856662108460178,
        lat: 40.37092493456656
      }, {
        lng: 49.856791676213007,
        lat: 40.370930594483042
      }, {
        lng: 49.856528274256043,
        lat: 40.372637766654989
      }, {
        lng: 49.857625861158432,
        lat: 40.372990956649822
      }, {
        lng: 49.85570826888911,
        lat: 40.37751722253573
      }, {
        lng: 49.843767617416901,
        lat: 40.373872632311183
      }
    ],

    sied2Coords: [
      {
        lng: 49.821705451659341,
        lat: 40.355235846768252
      }, {
        lng: 49.821903761824629,
        lat: 40.354737426494182
      }, {
        lng: 49.82247773275607,
        lat: 40.354098479570837
      }, {
        lng: 49.822682157452043,
        lat: 40.351891067654194
      }, {
        lng: 49.822760991868478,
        lat: 40.350776995038892
      }, {
        lng: 49.82279806447356,
        lat: 40.349955942455743
      }, {
        lng: 49.821519206056969,
        lat: 40.349631621245187
      }, {
        lng: 49.819490515190232,
        lat: 40.349713653115657
      }, {
        lng: 49.817666079095289,
        lat: 40.349130403945857
      }, {
        lng: 49.815762764730749,
        lat: 40.347904126736303
      }, {
        lng: 49.814885329175162,
        lat: 40.346592876578903
      }, {
        lng: 49.81474254807749,
        lat: 40.346381005023218
      }, {
        lng: 49.814466141114451,
        lat: 40.345568096922349
      }, {
        lng: 49.81412593334101,
        lat: 40.344544189834551
      }, {
        lng: 49.814042986514892,
        lat: 40.344286467710617
      }, {
        lng: 49.8141699748737,
        lat: 40.341749323277178
      }, {
        lng: 49.81437877927349,
        lat: 40.34123443397295
      }, {
        lng: 49.81506509364025,
        lat: 40.339544574337829
      }, {
        lng: 49.815840501824091,
        lat: 40.337640222226902
      }, {
        lng: 49.816046516729088,
        lat: 40.335929671948037
      }, {
        lng: 49.815733383151453,
        lat: 40.335593728515157
      }, {
        lng: 49.814849111852631,
        lat: 40.334605429979028
      }, {
        lng: 49.814887665415966,
        lat: 40.333829230081683
      }, {
        lng: 49.813285606317869,
        lat: 40.331455078306931
      }, {
        lng: 49.813129542203519,
        lat: 40.331214874100773
      }, {
        lng: 49.813956902395979,
        lat: 40.330604073189257
      }, {
        lng: 49.81400353707248,
        lat: 40.329450515023609
      }, {
        lng: 49.814091284271022,
        lat: 40.328238325860681
      }, {
        lng: 49.814180808670038,
        lat: 40.327015983597803
      }, {
        lng: 49.81619142418338,
        lat: 40.323611141175199
      }, {
        lng: 49.816338854444318,
        lat: 40.322218992951143
      }, {
        lng: 49.81645688772381,
        lat: 40.321306252987434
      }, {
        lng: 49.816710406168887,
        lat: 40.319117660944649
      }, {
        lng: 49.816998886445496,
        lat: 40.318824925005927
      }, {
        lng: 49.817793265528557,
        lat: 40.318586827736013
      }, {
        lng: 49.818776704913553,
        lat: 40.31758157545908
      }, {
        lng: 49.82091608721737,
        lat: 40.317544062969247
      }, {
        lng: 49.8213086514086,
        lat: 40.320831107709523
      }, {
        lng: 49.822759276523627,
        lat: 40.323542856882327
      }, {
        lng: 49.823835224154919,
        lat: 40.325309323468097
      }, {
        lng: 49.826223046269199,
        lat: 40.328472144962532
      }, {
        lng: 49.829142508772762,
        lat: 40.327577902302252
      }, {
        lng: 49.82877703356349,
        lat: 40.326009982614103
      }, {
        lng: 49.829990027445362,
        lat: 40.325717120359748
      }, {
        lng: 49.83527007209095,
        lat: 40.324890004423906
      }, {
        lng: 49.840204964277653,
        lat: 40.323700941212913
      }, {
        lng: 49.842734480243131,
        lat: 40.33107448121541
      }, {
        lng: 49.843498670852341,
        lat: 40.33093710538612
      }, {
        lng: 49.843494785010847,
        lat: 40.330980573383222
      }, {
        lng: 49.845411790725592,
        lat: 40.330564917341718
      }, {
        lng: 49.845438174879639,
        lat: 40.330662317798719
      }, {
        lng: 49.843549170807663,
        lat: 40.331163673718322
      }, {
        lng: 49.842823194060237,
        lat: 40.331314697714852
      }, {
        lng: 49.843987404272262,
        lat: 40.334729862269903
      }, {
        lng: 49.84409951561635,
        lat: 40.334769438418512
      }, {
        lng: 49.844187308885978,
        lat: 40.334844330116319
      }, {
        lng: 49.84478358998512,
        lat: 40.336515039181407
      }, {
        lng: 49.845074344724033,
        lat: 40.336645692203433
      }, {
        lng: 49.845045699129663,
        lat: 40.336844100077244
      }, {
        lng: 49.844904755642922,
        lat: 40.337007275960168
      }, {
        lng: 49.844683277242908,
        lat: 40.336983203194627
      }, {
        lng: 49.844479239371857,
        lat: 40.33685718495915
      }, {
        lng: 49.843213787945501,
        lat: 40.337081785882653
      }, {
        lng: 49.841515192255457,
        lat: 40.337375842542187
      }, {
        lng: 49.839913276733853,
        lat: 40.337676257188832
      }, {
        lng: 49.837523365372732,
        lat: 40.338068720448483
      }, {
        lng: 49.837697553469418,
        lat: 40.338847342754249
      }, {
        lng: 49.839483348106853,
        lat: 40.338916971420574
      }, {
        lng: 49.840908100554557,
        lat: 40.338417511393139
      }, {
        lng: 49.841635102316907,
        lat: 40.338494146039068
      }, {
        lng: 49.842516548168128,
        lat: 40.339163280807547
      }, {
        lng: 49.842892457110359,
        lat: 40.340098218646339
      }, {
        lng: 49.842870149662431,
        lat: 40.340662540049422
      }, {
        lng: 49.844739460928373,
        lat: 40.341707307340471
      }, {
        lng: 49.844631950983981,
        lat: 40.341863779230309
      }, {
        lng: 49.845049565363041,
        lat: 40.342060816149242
      }, {
        lng: 49.845023411523108,
        lat: 40.342299163392013
      }, {
        lng: 49.845535623585327,
        lat: 40.342566378644271
      }, {
        lng: 49.845759697995838,
        lat: 40.342783779834242
      }, {
        lng: 49.846648535133831,
        lat: 40.343018069259948
      }, {
        lng: 49.847021331655498,
        lat: 40.34219391319025
      }, {
        lng: 49.846270380854179,
        lat: 40.34196373560512
      }, {
        lng: 49.846364141385131,
        lat: 40.341884018281377
      }, {
        lng: 49.846519611320353,
        lat: 40.341871144683068
      }, {
        lng: 49.847964810979711,
        lat: 40.3423499790565
      }, {
        lng: 49.848205390137032,
        lat: 40.342192328505668
      }, {
        lng: 49.848309893084341,
        lat: 40.341963540976977
      }, {
        lng: 49.847820273603162,
        lat: 40.341838866767013
      }, {
        lng: 49.847939351632547,
        lat: 40.341693304282373
      }, {
        lng: 49.84854782216965,
        lat: 40.341868079141292
      }, {
        lng: 49.848329863193626,
        lat: 40.342466701415347
      }, {
        lng: 49.849139552972652,
        lat: 40.342580725631286
      }, {
        lng: 49.854958002580751,
        lat: 40.343781903390507
      }, {
        lng: 49.86007115907519,
        lat: 40.344769602722657
      }, {
        lng: 49.860388382916319,
        lat: 40.344747674498223
      }, {
        lng: 49.860695361255317,
        lat: 40.344828139680033
      }, {
        lng: 49.860940607635342,
        lat: 40.345027951491907
      }, {
        lng: 49.861136033143097,
        lat: 40.345360342415752
      }, {
        lng: 49.861263120042167,
        lat: 40.345708770504388
      }, {
        lng: 49.861172254173653,
        lat: 40.345941817331763
      }, {
        lng: 49.860962972357463,
        lat: 40.34599501493917
      }, {
        lng: 49.857456426722159,
        lat: 40.345873471394953
      }, {
        lng: 49.857344787026683,
        lat: 40.346730438275713
      }, {
        lng: 49.857181987222617,
        lat: 40.3467334468845
      }, {
        lng: 49.857286858103542,
        lat: 40.345867299098003
      }, {
        lng: 49.856849794335602,
        lat: 40.345601363613937
      }, {
        lng: 49.855994790541317,
        lat: 40.345301807097101
      }, {
        lng: 49.855400849575823,
        lat: 40.345181170415572
      }, {
        lng: 49.854550976290298,
        lat: 40.345058625791587
      }, {
        lng: 49.85357847058291,
        lat: 40.34500717045136
      }, {
        lng: 49.852779462713649,
        lat: 40.345085357910669
      }, {
        lng: 49.852083828125103,
        lat: 40.345243051790952
      }, {
        lng: 49.851015349931373,
        lat: 40.345395444882982
      }, {
        lng: 49.849911302953409,
        lat: 40.345454315790789
      }, {
        lng: 49.847758392837633,
        lat: 40.345587246677603
      }, {
        lng: 49.84768629838748,
        lat: 40.345630944000767
      }, {
        lng: 49.847242519836492,
        lat: 40.346344726346508
      }, {
        lng: 49.847269916331662,
        lat: 40.346396634232043
      }, {
        lng: 49.848692804624527,
        lat: 40.347006671730547
      }, {
        lng: 49.848617973210509,
        lat: 40.347127185225943
      }, {
        lng: 49.847151862914522,
        lat: 40.346514739621483
      }, {
        lng: 49.84709326454842,
        lat: 40.346542735155872
      }, {
        lng: 49.846611664004683,
        lat: 40.347351506430392
      }, {
        lng: 49.846620486226328,
        lat: 40.3475702872339
      }, {
        lng: 49.846547226212593,
        lat: 40.347775507673951
      }, {
        lng: 49.846342034474027,
        lat: 40.347896001981212
      }, {
        lng: 49.846088185392581,
        lat: 40.347980811778463
      }, {
        lng: 49.845857097234102,
        lat: 40.347962640895922
      }, {
        lng: 49.845272564137289,
        lat: 40.348876270795131
      }, {
        lng: 49.845106732710903,
        lat: 40.349203187905779
      }, {
        lng: 49.84510610002485,
        lat: 40.350514275635241
      }, {
        lng: 49.845275945036477,
        lat: 40.350651736997079
      }, {
        lng: 49.847425793548517,
        lat: 40.351622684390613
      }, {
        lng: 49.84757529552018,
        lat: 40.351559077740191
      }, {
        lng: 49.847625442123928,
        lat: 40.351386415681347
      }, {
        lng: 49.847833178600013,
        lat: 40.351160314446503
      }, {
        lng: 49.848120514945371,
        lat: 40.351060853696438
      }, {
        lng: 49.848182454130871,
        lat: 40.350969668441287
      }, {
        lng: 49.848713394550977,
        lat: 40.351268536152581
      }, {
        lng: 49.848855330751867,
        lat: 40.35145666615918
      }, {
        lng: 49.84882742331579,
        lat: 40.351814979120832
      }, {
        lng: 49.848508620090307,
        lat: 40.35212217227528
      }, {
        lng: 49.847841169524671,
        lat: 40.352173907076917
      }, {
        lng: 49.844942840069827,
        lat: 40.350814340492107
      }, {
        lng: 49.844748923335217,
        lat: 40.350566176098859
      }, {
        lng: 49.844677812938478,
        lat: 40.349937807757037
      }, {
        lng: 49.844702662572757,
        lat: 40.349239946975977
      }, {
        lng: 49.844494638234927,
        lat: 40.349072274257587
      }, {
        lng: 49.843087071771059,
        lat: 40.348811591924012
      }, {
        lng: 49.842067220085767,
        lat: 40.348830526034718
      }, {
        lng: 49.84203350895374,
        lat: 40.348658585111593
      }, {
        lng: 49.84293960916176,
        lat: 40.348612515945533
      }, {
        lng: 49.843099724213637,
        lat: 40.348495753880009
      }, {
        lng: 49.843013571069342,
        lat: 40.347134555837052
      }, {
        lng: 49.840673241338628,
        lat: 40.347223223700432
      }, {
        lng: 49.840037433545817,
        lat: 40.347625448202912
      }, {
        lng: 49.840628476788062,
        lat: 40.348379473988388
      }, {
        lng: 49.840498319537289,
        lat: 40.3484558912224
      }, {
        lng: 49.839889965344248,
        lat: 40.347715050496973
      }, {
        lng: 49.839338016772523,
        lat: 40.348101529085319
      }, {
        lng: 49.838923762569742,
        lat: 40.348453444386642
      }, {
        lng: 49.83922417414032,
        lat: 40.348733938078688
      }, {
        lng: 49.839172229180143,
        lat: 40.348782516510703
      }, {
        lng: 49.838828428985153,
        lat: 40.34852608801647
      }, {
        lng: 49.83863196116554,
        lat: 40.348717786670257
      }, {
        lng: 49.838966366672061,
        lat: 40.348973850728129
      }, {
        lng: 49.83891289866412,
        lat: 40.349035835227227
      }, {
        lng: 49.838565122628253,
        lat: 40.348790999827692
      }, {
        lng: 49.838146815162688,
        lat: 40.349239703208823
      }, {
        lng: 49.837757819700713,
        lat: 40.349788688698332
      }, {
        lng: 49.837287337341927,
        lat: 40.350677410054871
      }, {
        lng: 49.837140111383512,
        lat: 40.351238681867287
      }, {
        lng: 49.837031780182642,
        lat: 40.351790216798747
      }, {
        lng: 49.837009398953612,
        lat: 40.352229157991992
      }, {
        lng: 49.837246744907041,
        lat: 40.353463265956492
      }, {
        lng: 49.837321602847076,
        lat: 40.353738827082687
      }, {
        lng: 49.837246521471087,
        lat: 40.353808489621628
      }, {
        lng: 49.835995600942169,
        lat: 40.354111607705569
      }, {
        lng: 49.835926260007383,
        lat: 40.35419793082859
      }, {
        lng: 49.836101865847233,
        lat: 40.354588043035207
      }, {
        lng: 49.834108360721167,
        lat: 40.354513000105193
      }, {
        lng: 49.833762679312329,
        lat: 40.354522864659842
      }, {
        lng: 49.832631879611718,
        lat: 40.354200610279989
      }, {
        lng: 49.832074158094123,
        lat: 40.354045924501463
      }, {
        lng: 49.829835899062488,
        lat: 40.354172437327449
      }, {
        lng: 49.829810680529128,
        lat: 40.354307760006229
      }, {
        lng: 49.827504635554931,
        lat: 40.35455263579626
      }, {
        lng: 49.82508419139198,
        lat: 40.355545497415513
      }, {
        lng: 49.824321491104477,
        lat: 40.355881874164773
      }, {
        lng: 49.824192064124013,
        lat: 40.355776513802041
      }, {
        lng: 49.823506117431883,
        lat: 40.355717668133522
      }, {
        lng: 49.82319664219203,
        lat: 40.355813611083313
      }, {
        lng: 49.823016848503997,
        lat: 40.355651428238929
      }, {
        lng: 49.822539356833254,
        lat: 40.356135308543529
      }, {
        lng: 49.822358629379188,
        lat: 40.356396904752764
      }, {
        lng: 49.821762603581682,
        lat: 40.355888758623827
      }, {
        lng: 49.821688468132614,
        lat: 40.355866740951207
      }, {
        lng: 49.821705451659341,
        lat: 40.355235846768252
      }
    ]
  }
}
