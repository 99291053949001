import React from 'react';
import {GoogleApiWrapper, Map} from 'google-maps-react';
import {ModalContext} from '../../../../context/context';
import CloseModal from '../../../shared/CloseModal';
import ModalSuccess from '../../../shared/ModalSuccess';
import Direction from '../../map/Direction';
import {MapOptions} from '../../../../MapEnv';
import GoogleMapComponent from '../../map/GoogleMapComponent';

class TransportDetail extends GoogleMapComponent {
  static contextType = ModalContext;

  state = {
    stations: []
  }

  componentDidMount() {
    const {details} = this.context.state;
    let position = {
      origin: '',
      destination: '',
      waypoints: []
    };

    details.transport.stations.map(station => {
      if (station.type === 'waypoint') {
        position['waypoints'].push({
          location: {lat: Number(station.lat), lng: Number(station.lng)},
          title: station.name
        })

        return position
      }
      position[station.type] = `${station.lat}, ${station.lng}`
      position[station.type + '_title'] = station.name

      return position
    });

    this.setState({
      stations: position
    });
  }

  render() {
    const {mapStyles, mapCenterDetail} = MapOptions();
    const {details} = this.context.state;

    return (
      <div className="modal-custom">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-10 offset-lg-1">
              <div className="modal-custom-inner">
                <div className="modal-custom-header d-flex align-items-center justify-content-between">
                  <h4>
                    Marşrut xətti
                  </h4>
                  <CloseModal tooltip={true} modal='showTransportDetailModal'/>
                </div>
                <div className="row">
                  <div className="col-md-4 scrolled">
                    <div className="jumbotron jumbotron-fluid">
                      <div className="container text-center">
                        <h3 className="display-4 mb-3">{details.name}</h3>
                        <p className="lead">Qiymət: {details.transport.cost} AZN</p>
                        <p className="lead mb-3">Vaxt: {details.transport.period}</p>
                        <p className="lead">{details.description}</p>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-8">
                    <div className="objects-wrapper">
                      <Map
                        google={this.props.google}
                        zoom={Number(process.env.REACT_APP_MAP_ZOOM)}
                        style={mapStyles}
                        initialCenter={mapCenterDetail}
                        onClick={this.setActiveMarker}
                      >
                        <Direction data={this.state.stations} {...this.props} />
                      </Map>
                    </div>
                  </div>
                </div>
                {this.props.success ? <ModalSuccess text="Marşrut xətti uğurla dəyişdirildi" /> : null }
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default GoogleApiWrapper({
  apiKey: process.env.REACT_APP_MAP_KEY
})(TransportDetail);
