
import React, {Component} from 'react';
import {connect} from 'react-redux';
import {withFormik} from 'formik';
import {resumeUpdateData, resumeFetchDetail} from '../../../../../redux/actions/socialAction';
import {removeSuccessMessage} from '../../../../../redux/actions/messageAction';
import {isAuthenticated} from '../../../../../redux/services/auth.service';
import {Redirect} from 'react-router-dom';
import {resumeSchema} from '../../../../../shared/validation';
import ModalSuccess from '../../../../shared/ModalSuccess';
import ResumeForm from '../ResumeForm';

/**
 * @description Apply component
 */
class EditResume extends Component {
  state = {
    files: [],
    salaries: [
      {
        value: 100,
        label: 100
      },
      {
        value: 200,
        label: 200
      },
      {
        value: 300,
        label: 300
      },
      {
        value: 400,
        label: 400
      },
      {
        value: 500,
        label: 500
      },
      {
        value: 600,
        label: 600
      },
      {
        value: 700,
        label: 700
      },
      {
        value: 800,
        label: 800
      },
      {
        value: 900,
        label: 900
      },
      {
        value: 1000,
        label: 1000
      },
      {
        value: 1100,
        label: 1100
      },
      {
        value: 1200,
        label: 1200
      },
      {
        value: 1300,
        label: 1300
      },
      {
        value: 1400,
        label: 1400
      },
      {
        value: 1500,
        label: 1500
      },
      {
        value: 1600,
        label: 1600
      },
      {
        value: 1700,
        label: 1700
      },
      {
        value: 1800,
        label: 1800
      },
      {
        value: 1900,
        label: 1900
      },
      {
        value: 2000,
        label: 2000
      },
      {
        value: 2100,
        label: 2100
      },
      {
        value: 2200,
        label: 2200
      },
      {
        value: 2300,
        label: 2300
      },
      {
        value: 2400,
        label: 2400
      },
      {
        value: 2500,
        label: 2500
      },
      {
        value: 2600,
        label: 2600
      },
      {
        value: 2700,
        label: 2700
      },
      {
        value: 2800,
        label: 2800
      },
      {
        value: 2900,
        label: 2900
      },
      {
        value: 3000,
        label: 3000
      },
      {
        value: 3100,
        label: 3100
      },
      {
        value: 3200,
        label: 3200
      },
      {
        value: 3300,
        label: 3300
      },
      {
        value: 3400,
        label: 3400
      },
      {
        value: 3500,
        label: 3500
      },
      {
        value: 3600,
        label: 3600
      },
      {
        value: 3700,
        label: 3700
      },
      {
        value: 3800,
        label: 3800
      },
      {
        value: 3900,
        label: 3900
      },
      {
        value: 4000,
        label: 4000
      },
      {
        value: 5000,
        label: 5000
      },
      {
        value: 6000,
        label: 6000
      },
      {
        value: 7000,
        label: 7000
      },
      {
        value: 8000,
        label: 8000
      },
      {
        value: 9000,
        label: 9000
      },
      {
        value: 10000,
        label: 10000
      }
    ],
    ages: [
      {
        value: 18
      },
      {
        value: 19
      },
      {
        value: 20
      },
      {
        value: 21
      },
      {
        value: 22
      },
      {
        value: 23
      },
      {
        value: 24
      },
      {
        value: 25
      },
      {
        value: 26
      },
      {
        value: 27
      },
      {
        value: 28
      },
      {
        value: 29
      },
      {
        value: 30
      },
      {
        value: 31
      },
      {
        value: 32
      },
      {
        value: 33
      },
      {
        value: 34
      },
      {
        value: 35
      },
      {
        value: 36
      },
      {
        value: 37
      },
      {
        value: 38
      },
      {
        value: 39
      },
      {
        value: 40
      },
      {
        value: 41
      },
      {
        value: 42
      },
      {
        value: 43
      },
      {
        value: 44
      },
      {
        value: 45
      },
      {
        value: 46
      },
      {
        value: 47
      },
      {
        value: 48
      },
      {
        value: 49
      },
      {
        value: 50
      },
      {
        value: 51
      },
      {
        value: 52
      },
      {
        value: 53
      },
      {
        value: 54
      },
      {
        value: 55
      },
      {
        value: 56
      },
      {
        value: 57
      },
      {
        value: 58
      },
      {
        value: 59
      },
      {
        value: 60
      },
      {
        value: 61
      },
      {
        value: 62
      },
      {
        value: 63
      }
    ]
  }

  componentDidMount() {
    const {id} = this.props.match.params
    this.props.fetchResume(id);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.social && nextProps.social.resume && nextProps.social.resume !== this.props.social.resume) {
      const resume = nextProps.social.resume;
      this.props.setValues({
        'id': resume.id,
        'vacancy_category_id': resume.vacancy_category_id,
        'profession': resume.profession,
        'fullname': resume.fullname,
        'city_id': resume.city_id,
        'min_salary': resume.min_salary,
        'max_salary': resume.max_salary,
        'age': resume.age,
        'education_id': resume.education_id,
        'experience_id': resume.experience_id,
        'gender': resume.gender_type === 'Kişi' ? 0 : 1,
        'contact_number': resume.contact_number,
        'email': resume.email,
        'ability': resume.ability,
        'image': resume.image,
        'more_info': resume.more_info
      });
    }
  }

  render() {
    if (!isAuthenticated()) {
      return <Redirect to='/login' />
    }

    return (
      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-12">
            <div className="whitebox">
              <header className="whitebox-header bordered">

                <div className="whitebox-title ">
                  <h4>
                  İş üçün müraciət
                  </h4>
                  <ul className="breadcrumps">
                    <li className="breadcrumps-item">Sosial bölmə</li>
                    <li className="breadcrumps-item">Vakansiyalar</li>
                    <li className="breadcrumps-item">İş üçün müraciət</li>
                  </ul>
                </div>

              </header>

              <section className="whitebox-body">
                <ResumeForm formProps = {this.props} submitText={'Müraciəti redaktə et'} />
              </section>

              {this.props.social.success ? <ModalSuccess text="Müraciət redaktə edildi" /> : null }

            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapPropsToValues = (props) => {
  return {
    vacancy_category_id: '',
    profession: '',
    fullname: '',
    city_id: '',
    min_salary: '',
    max_salary: '',
    age: '',
    education_id: '',
    experience_id: '',
    gender: '',
    contact_number: '',
    email: '',
    ability: '',
    more_info: '',
    image: []
  }
}

const EditResumeForm = withFormik({
  enableReinitialize: false,
  mapPropsToValues,
  validationSchema: resumeSchema,
  validateOnBlur: true,
  validateOnChange: true,
  handleSubmit: (values, {props, resetForm, setSubmitting}) => {
    props.updateResume(values);
    setTimeout(() => {
      props.removeSuccess();
    }, 4000);
  }
})(EditResume);

const mapDispatchToProps = (dispatch) => ({
  updateResume: (resumeData) => dispatch(resumeUpdateData(resumeData)),
  removeSuccess: () => dispatch(removeSuccessMessage()),
  fetchResume: (id) => dispatch(resumeFetchDetail(id))
})

const mapStateToProps = state => {
  return {
    files: state.fileReducer,
    social: state.socialReducer
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(EditResumeForm);
