import React from 'react';
import {ModalContext} from '../../../../context/context';
import {makeMarker, MapOptions} from '../../../../MapEnv';
import {GoogleApiWrapper, Map, Marker} from 'google-maps-react';
import InfoWindowTransport from './InfoWindowTransport';
import waypointIcon from '../../../../assets/img/bus-waypoint.svg'
import originIcon from '../../../../assets/img/bus-origin-destination.svg'
import MapForm from './MapForm';
import GoogleMapComponent from '../../map/GoogleMapComponent';

class TransportMap extends GoogleMapComponent {
  static contextType = ModalContext;

  state = {
    markers: {
      data: [],
      activeMarker: {}
    },
    inputFields: {},
    stationTypes: [
      {name: 'Dayanacaq', value: 'waypoint'},
      {name: 'Başlanğıc', value: 'origin'},
      {name: 'Son', value: 'destination'}
    ]
  }

  componentDidMount() {
    const {stations} = this.props;

    if (stations) {
      stations.forEach(station => {
        station['position'] = {lat: station.lat, lng: station.lng};
        this.pushMarker(this.makeMarker(station));
      })
    }
  }

  /**
   * @description make an empty marker
   * @param data
   * @returns {{visibility: boolean, name: string, icon: *, index: null, label: string, position: {}, type: string, title: string}}
   */
  makeMarker = (data) => {
    return makeMarker(data);
  }

  /**
   * @description getting clicked location for make new marker
   * @param e
   * @param map
   * @param cords
   */
  setActiveMarker = (e, map, cords) => {
    const {latLng} = cords;
    let markers = this.state.markers;
    markers.activeMarker = this.makeMarker({
      position: {lat: latLng.lat(), lng: latLng.lng()},
      visibility: true
    });

    this.setState({markers: markers})
  }

  /**
   * @description select clicked marker
   * @param index
   * @param cords
   */
  selectMarker = (index, cords) => {
    let markers = this.state.markers;
    markers.activeMarker = this.makeMarker({
      ...markers.data[index],
      index: index,
      visibility: true
    });

    if (cords) {
      const {latLng} = cords;
      const position = {lat: latLng.lat(), lng: latLng.lng()};
      markers.activeMarker.position = position;
      markers.data[index].position = position;
      markers.activeMarker.visibility = false;
    }

    this.setState({markers: markers});
  }

  /**
   * @description remove marker from state by index
   * @param index
   */
  removeMarker = (index) => {
    let markers = this.state.markers;
    markers.data.splice(index, 1);
    markers.activeMarker.visibility = false;
    this.setState({markers: markers});
  }

  /**
   * @description add new marker to state
   * @param data
   */
  pushMarker = (data) => {
    let markers = this.state.markers;
    data.icon = data.type === 'waypoint' ? waypointIcon : originIcon;

    if (data.index || data.index === 0) {
      markers.data[data.index] = data;
    } else {
      markers.data.push(data);
    }

    markers.activeMarker = this.makeMarker();
    this.setState({markers: markers});

    this.props.addStations(this.state.markers.data);
  }

  render() {
    const {stationTypes} = this.state;
    const {mapStyles, mapCenterDetail} = MapOptions();
    const {activeMarker} = this.state.markers;
    const markers = this.state.markers.data.map((marker, index) => (
      <Marker
        key={index}
        title={marker.title}
        label={marker.label}
        name={marker.name}
        type={marker.type}
        icon={{url: marker.icon, scaledSize: {width: 50, height: 50}}}
        position={marker.position}
        draggable={true}
        onDragend={(e, map, cords) => this.selectMarker(index, cords)}
        onClick={() => { this.selectMarker(index) }}
      />
    ));

    return (
      <div id="map-transport">
        <Map
          google={this.props.google}
          zoom={Number(process.env.REACT_APP_MAP_ZOOM)}
          style={mapStyles}
          initialCenter={mapCenterDetail}
          onClick={this.setActiveMarker}
        >
          {markers}
          <InfoWindowTransport
            visible={activeMarker.visibility}
            position={activeMarker.position}
          >
            <MapForm
              activeMarker={this.state.markers.activeMarker}
              stationTypes={stationTypes}
              pushMarker={this.pushMarker}
              removeMarker={this.removeMarker}
            />
          </InfoWindowTransport>
        </Map>
      </div>
    );
  }
}

export default GoogleApiWrapper({
  apiKey: process.env.REACT_APP_MAP_KEY
})(TransportMap);
