import {
  FETCH_TRANSPORT_REQUEST,
  FETCH_TRANSPORT_SUCCESS,
  FETCH_TRANSPORT_FAILURE,
  ADD_TRANSPORT_REQUEST,
  ADD_TRANSPORT_SUCCESS,
  ADD_TRANSPORT_FAILURE,
  EDIT_TRANSPORT_REQUEST,
  EDIT_TRANSPORT_SUCCESS,
  EDIT_TRANSPORT_FAILURE,
  DELETE_TRANSPORT_REQUEST,
  DELETE_TRANSPORT_SUCCESS,
  DELETE_TRANSPORT_FAILURE,
  REMOVE_SUCCESS_MESSAGE,
  FETCH_ACTIVITY_CATEGORY_REQUEST,
  FETCH_ACTIVITY_CATEGORY_FAILURE,
  FETCH_ACTIVITY_CATEGORY_SUCCESS
} from '../types/actionTypes';

/**
 * @description initial state for transportReducer
 */
const initialState = {};

/**
 * @description updates state depending on action data
 * @param state
 * @param type
 * @param payload
 * @returns {{}|{success: boolean, isFetching: boolean, useful: *}}
 */
export default function transportReducer(state = initialState, {type, payload}) {
  switch (type) {
  case FETCH_TRANSPORT_REQUEST:
    return {
      ...state,
      isFetching: true,
      useful: payload
    };
  case FETCH_TRANSPORT_SUCCESS:
    return {
      ...state,
      isFetching: false,
      useful: payload
    };
  case FETCH_TRANSPORT_FAILURE:
    return {
      ...state,
      isFetching: false,
      useful: payload
    };
  case FETCH_ACTIVITY_CATEGORY_REQUEST:
    return {
      ...state,
      isFetching: true,
      activityCategories: payload
    };
  case FETCH_ACTIVITY_CATEGORY_SUCCESS:
    return {
      ...state,
      isFetching: false,
      activityCategories: payload
    };
  case FETCH_ACTIVITY_CATEGORY_FAILURE:
    return {
      ...state,
      isFetching: false,
      activityCategories: payload
    };
  case ADD_TRANSPORT_REQUEST:
    return {
      ...state,
      success: false,
      isFetching: true,
      errorData: false
    };
  case ADD_TRANSPORT_SUCCESS:
    return {
      ...state,
      success: true,
      isFetching: false,
      errorData: false
    };
  case ADD_TRANSPORT_FAILURE:
    return {
      ...state,
      success: false,
      isFetching: false,
      errorData: payload
    };
  case EDIT_TRANSPORT_REQUEST:
    return {
      ...state,
      success: false,
      isFetching: true,
      errorData: false
    };
  case EDIT_TRANSPORT_SUCCESS:
    return {
      ...state,
      success: true,
      isFetching: false,
      errorData: false
    };
  case EDIT_TRANSPORT_FAILURE:
    return {
      ...state,
      success: false,
      isFetching: false,
      errorData: payload
    };
  case DELETE_TRANSPORT_REQUEST:
    return {
      ...state,
      success: false,
      isFetching: true
    };
  case DELETE_TRANSPORT_SUCCESS:
    return {
      ...state,
      success: true,
      isFetching: false
    };
  case DELETE_TRANSPORT_FAILURE:
    return {
      ...state,
      success: false,
      isFetching: false
    };
  case REMOVE_SUCCESS_MESSAGE:
    return {
      ...state,
      success:false
    };
  default:
    return state
  }
}
