import {
  FETCH_BANNER_HEADER_REQUEST,
  FETCH_BANNER_HEADER_SUCCESS,
  FETCH_BANNER_HEADER_FAILURE,
  FETCH_BANNER_RIGHT_REQUEST,
  FETCH_BANNER_RIGHT_SUCCESS,
  FETCH_BANNER_RIGHT_FAILURE,
  FETCH_BANNER_WEBSITES_REQUEST,
  FETCH_BANNER_WEBSITES_SUCCESS,
  FETCH_BANNER_WEBSITES_FAILURE
} from '../types/actionTypes';

/**
 * @description initial state for banner reducer
 */
const initialState = {};

/**
 * @description updates state depending on action data
 * @param {Object} state = initialState
 * @param {string} type = action.type
 * @param {} payload
 * @returns return state
 */
export default function bannerReducer(state = initialState, {type, payload}) {
  switch (type) {
  case FETCH_BANNER_HEADER_REQUEST:
    return {
      ...state,
      isFetching: true,
      bannersHeader: payload
    };
  case FETCH_BANNER_HEADER_SUCCESS:
    return {
      ...state,
      isFetching: false,
      bannersHeader: payload
    };
  case FETCH_BANNER_HEADER_FAILURE:
    return {
      ...state,
      isFetching: false,
      bannersHeader: payload
    };

  case FETCH_BANNER_RIGHT_REQUEST:
    return {
      ...state,
      isFetching: true,
      bannersRight: payload
    };
  case FETCH_BANNER_RIGHT_SUCCESS:
    return {
      ...state,
      isFetching: false,
      bannersRight: payload
    };
  case FETCH_BANNER_RIGHT_FAILURE:
    return {
      ...state,
      isFetching: false,
      bannersRight: payload
    };

  case FETCH_BANNER_WEBSITES_REQUEST:
    return {
      ...state,
      isFetching: true,
      bannersWebsites: payload
    };
  case FETCH_BANNER_WEBSITES_SUCCESS:
    return {
      ...state,
      isFetching: false,
      bannersWebsites: payload
    };
  case FETCH_BANNER_WEBSITES_FAILURE:
    return {
      ...state,
      isFetching: false,
      bannersWebsites: payload
    };
  default:
    return state
  }
}
