import axios from 'axios';
import {messaging} from '../../firebase/firebase';

import {
  FCM_CLEAR_NOTIFY_FAILURE,
  FCM_CLEAR_NOTIFY_REQUEST,
  FCM_CLEAR_NOTIFY_SUCCESS,
  FCM_FETCH_BG_MESSAGE_FAILURE,
  FCM_FETCH_BG_MESSAGE_REQUEST,
  FCM_FETCH_BG_MESSAGE_SUCCESS,
  FCM_FETCH_TOKEN_FAILURE,
  FCM_FETCH_TOKEN_REQUEST,
  FCM_FETCH_TOKEN_SUCCESS,
  FCM_READ_NOTIFY_FAILURE,
  FCM_READ_NOTIFY_REQUEST,
  FCM_READ_NOTIFY_SUCCESS,
  FCM_TOKEN_REFRESH_FAILURE,
  FCM_TOKEN_REFRESH_REQUEST,
  FCM_TOKEN_REFRESH_SUCCESS
} from '../types/actionTypes';

import {
  FCM_CLEAR_NOTIFICATIONS,
  FCM_READ_NOTIFICATION_API,
  FCM_REFRESH_TOKEN_API
} from '../services/api.service';

/**
 * @description Update user token
 * @param token
 * @param ownProps
 * @returns {function(...[*]=)}
 */
export const handleTokenRefresh = (token, ownProps) => {
  return async dispatch => {
    dispatch({type: FCM_TOKEN_REFRESH_REQUEST});
    try {
      const {data} = await axios.post(FCM_REFRESH_TOKEN_API, {
        token: token
      });
      dispatch({type: FCM_TOKEN_REFRESH_SUCCESS, payload: data.data});
    } catch (error) {
      dispatch({type: FCM_TOKEN_REFRESH_FAILURE, payload: error.response.data});
    }
  }
};

/**
 * @description Send notification id to backend for updating status
 * @param id
 * @param url
 * @param ownProps
 * @returns {function(...[*]=)}
 */
export const handleReadNotification = (id, url, ownProps) => {
  return async dispatch => {
    dispatch({type: FCM_READ_NOTIFY_REQUEST});
    try {
      const {data} = await axios.get(FCM_READ_NOTIFICATION_API(id));
      dispatch({type: FCM_READ_NOTIFY_SUCCESS, payload: data});

      if (url) {
        document.location.href = url;
      }
    } catch (error) {
      dispatch({type: FCM_READ_NOTIFY_FAILURE, payload: error.response.data});
    }
  }
};

/**
 * @description Clear user all notifications.
 * @param ownProps
 * @returns {function(...[*]=)}
 */
export const handleClearNotifications = (ownProps) => {
  return async dispatch => {
    dispatch({type: FCM_CLEAR_NOTIFY_REQUEST});
    try {
      const {data} = await axios.post(FCM_CLEAR_NOTIFICATIONS);
      dispatch({type: FCM_CLEAR_NOTIFY_SUCCESS, payload: data});
    } catch (error) {
      dispatch({type: FCM_CLEAR_NOTIFY_FAILURE, payload: error.response.data});
    }
  }
};

/**
 * @description Get user token from Firebase
 * @param ownProps
 * @returns {function(...[*]=)}
 */
export const getToken = (ownProps) => {
  return async dispatch => {
    dispatch({type: FCM_FETCH_TOKEN_REQUEST});
    try {
      let token = 'unsupported'
      if (!messaging.unsupported) {
        token = await messaging.getToken();
      }
      dispatch({type: FCM_FETCH_TOKEN_SUCCESS, payload: token});
    } catch (error) {
      // dispatch({type: FCM_FETCH_TOKEN_FAILURE, payload: error.response.data});
      console.log(error);
    }
  }
};

/**
 * @description Handling background messages
 * @param ownProps
 * @returns {function(...[*]=)}
 */
export const backgroundMessage = (ownProps) => {
  return async dispatch => {
    dispatch({type: FCM_FETCH_BG_MESSAGE_REQUEST});
    try {
      if (!messaging.unsupported) {
        messaging.onMessage((payload) => {
          dispatch({type: FCM_FETCH_BG_MESSAGE_SUCCESS, payload: payload})
        })
      }
    } catch (error) {
      dispatch({type: FCM_FETCH_BG_MESSAGE_FAILURE, payload: error.response.data});
    }
  }
};
