import React, {Component} from 'react';
import {NavLink} from 'react-router-dom';
import {connect} from 'react-redux';
import {searchObjects} from '../../../../redux/actions/objectAction';
import moment from 'moment';
import Loader from '../../../shared/Loader';
import {SearchContext} from '../../../../context/context'
import {isAuthenticated} from '../../../../redux/services/auth.service';
import {checkBlock} from '../../../../shared/checkBlocks';
import OpenModal from '../../../shared/OpenModal';

/**
 * @description AllObjects component
 */
class AllObjects extends Component {
static contextType = SearchContext;

componentDidMount() {
  this.props.searchObject(this.context.state.showSearchResult);
}

render() {
  const objects = this.props.objects ? this.props.objects.map(object => {
    return (
      <tr key={object.id} id={`object${object.id}`}>
        <td className="d-flex align-items-center">
          <span className="company-logo mr-2"/>
          {object.building.name}
        </td>
        <td>{object.building.address}</td>
        <td>{object.building.current_situation}</td>
        <td>{moment(object.building.construction_date).format('YYYY-MM-DD')}</td>
        <td>
          <div className="d-flex align-center justify-content-end">
            <NavLink
              to={'/objects/' + object.id}
              exact
              className="btn btn-rounded"
            >
              <i className="icon icon-detail" />
            </NavLink>
          </div>
        </td>
      </tr>
    )
  }) : null

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-lg-12">
          <div className="whitebox">
            <header className="whitebox-header bordered d-md-flex align-items-center justify-content-between">
              <div className="whitebox-title">
                <h4>
                  Axtarış Nəticələri
                </h4>
              </div>

              {isAuthenticated() && checkBlock('objects', 'object-add') ?
                <OpenModal modal="showObjectModal" text="Obyekt yarat" />
                : null}
            </header>

            <section className="whitebox-body">
              <div className="table-wrapper">
                {this.props.objects ?
                  <table className="table table-custom">
                    <thead>
                      <tr>
                        <th>Məkan adı</th>
                        <th>Ünvan</th>
                        <th>Cari Vəziyyət</th>
                        <th>Tarix</th>
                        <th/>
                      </tr>
                    </thead>

                    <tbody>
                      {objects}
                    </tbody>
                  </table> : <Loader />}
              </div>
            </section>

          </div>
        </div>
      </div>
    </div>
  );
}
}

const mapDispatchToProps = (dispatch, ownProps) => ({
  searchObject: (objectParams) => dispatch(searchObjects(objectParams, ownProps))
})

const mapStateToProps = state => {
  return state.objectReducer;
}

export default connect(mapStateToProps, mapDispatchToProps)(AllObjects);
