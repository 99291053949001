import React, {Component} from 'react';
import {connect} from 'react-redux';
import {vacancyFetchData} from '../../../../redux/actions/socialAction';
import {NavLink} from 'react-router-dom';
import Loader from '../../../shared/Loader';
import {isAuthenticated} from '../../../../redux/services/auth.service';
import {checkBlock} from '../../../../shared/checkBlocks';
import {deleteAction} from '../../../../redux/actions/deleteAction';
import {DELETE_VACANCY_REQUEST, DELETE_VACANCY_SUCCESS, DELETE_VACANCY_FAILURE} from '../../../../redux/types/actionTypes';
import {VACANCY_API} from '../../../../redux/services/api.service';

/**
 * @description Component Class for vacancies
 */
class Vacancy extends Component {
  componentDidMount() {
    this.props.fetchVacancy()
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.categoryId !== this.props.categoryId) {
      this.props.fetchVacancy(nextProps.categoryId)
    }
  }

  removeObject = (ids) => {
    const idsArray = [];
    const types =
    {
      request: DELETE_VACANCY_REQUEST,
      success: DELETE_VACANCY_SUCCESS,
      failure: DELETE_VACANCY_FAILURE
    };
    const api = VACANCY_API;
    idsArray.push(ids);
    this.props.deleteObject(idsArray, types, api);

    document.getElementById(`vacancy${ids}`).remove();
  }

  render() {
    const vacancies = this.props.vacancies ? this.props.vacancies.map(vacancy => {
      return (
        <tr key={vacancy.id} id={`vacancy${vacancy.id}`}>
          <td className="d-flex align-items-center">
            <span className="company-logo mr-2"/>
            {vacancy.company_name}
          </td>
          <td>{vacancy.contact_number} <br/> {vacancy.email}</td>
          <td>{vacancy.profession}</td>
          <td>
            <div className="d-flex align-items-center">
              <NavLink
                to={'/vacancy/' + vacancy.id}
                className="btn btn-rounded"
                activeClassName="active"
              >
                <i className="icon icon-detail" />
              </NavLink>
              {isAuthenticated() && checkBlock('social', 'vacancy-edit') ?
                <NavLink
                  to={'/vacancy/edit/' + vacancy.id}
                  className="btn btn-rounded"
                  activeClassName="active"
                >
                  <i className="icon icon-edit-n" />
                </NavLink>
                : null }
              {isAuthenticated() && checkBlock('social', 'vacancy-delete') ?
                <button
                  type="button"
                  className="btn btn-rounded"
                  onClick={() => this.removeObject(vacancy.id)}
                >
                  <i className="icon icon-delete-2 mr-1"/>
                </button>
                : null }
            </div>
          </td>
        </tr>
      )
    }) : null

    return  (
      <div className="table-wrapper">
        {this.props.vacancies ?
          <table className="table table-custom">
            <thead>
              <tr>
                <th>Müəssisənin adı</th>
                <th>Əlaqə</th>
                <th>Vəzifə</th>
                <th>Ətraflı</th>
              </tr>
            </thead>

            <tbody>
              {vacancies}
            </tbody>
          </table> : <Loader/>}
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch, ownProps) => ({
  fetchVacancy: (paramId = null) => dispatch(vacancyFetchData(paramId, ownProps)),
  deleteObject: (ids, types, api) => dispatch(deleteAction(ids, types, api))
})

const mapStateToProps = state => {
  return state.socialReducer;
}

export default connect(mapStateToProps, mapDispatchToProps)(Vacancy);
