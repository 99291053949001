import React, {Component} from 'react';
import {connect} from 'react-redux';
import {withFormik} from 'formik';
import ModalSuccess from '../../../shared/ModalSuccess';
import {removeSuccessMessage} from '../../../../redux/actions/messageAction';
import {eventSchema} from '../../../../shared/validation';
import EventForm from '../EventForm';
import {eventFetchDetail, eventEditData} from '../../../../redux/actions/eventAction';

/**
 * @description AddLaw component
 */
class EditEvent extends Component {
  componentDidMount() {
    const {id} = this.props.match.params
    this.props.fetchEvent(id)
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.event && nextProps.event !== this.props.event) {
      this.props.setValues({
        'id': nextProps.event.id,
        'title': nextProps.event.title,
        'place': nextProps.event.place,
        'note': nextProps.event.note,
        'start': new Date(nextProps.event.start),
        'end': new Date(nextProps.event.end)
      });
    }
  }
  render() {
    return (
      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-12">
            <div className="whitebox"  id="legislation">
              <header className="whitebox-header bordered">
                <div className="whitebox-title ">
                  <h4>
                    Tədbiri Redaktə et
                  </h4>
                  <ul className="breadcrumps">
                    <li className="breadcrumps-item">Tədbirlər</li>
                    <li className="breadcrumps-item">{this.props.event ? this.props.event.title : null}</li>
                  </ul>
                </div>

              </header>
              <EventForm formProps={this.props}/>
              {this.props.success ? <ModalSuccess text="Tədbir redaktə edildi" /> : null }
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapPropsToValues = (props) => {
  return {
    title: '',
    place: '',
    note: '',
    start: '',
    end: ''
  }
}

const EditEventForm = withFormik({
  enableReinitialize: false,
  mapPropsToValues,
  validationSchema: eventSchema,
  validateOnBlur: true,
  validateOnChange: true,
  handleSubmit: (values, {props, resetForm, setSubmitting}) => {
    props.editEvent(values);

    setTimeout(() => {
      props.removeSuccess();
    }, 4000);
  }
})(EditEvent);

const mapDispatchToProps = (dispatch) => ({
  fetchEvent: (id) => dispatch(eventFetchDetail(id)),
  editEvent: (eventData) => dispatch(eventEditData(eventData)),
  removeSuccess: () => dispatch(removeSuccessMessage())
});

const mapStateToProps = state => {
  return state.eventReducer;
};

export default connect(mapStateToProps, mapDispatchToProps)(EditEventForm);

