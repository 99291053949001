import React, {Component} from 'react';
import {isAuthenticated, isUnAuthPublicEnabled} from '../../../../redux/services/auth.service';
import {checkBlock} from '../../../../shared/checkBlocks';
import {connect} from 'react-redux';
import {ModalContext} from '../../../../context/context';
import {Redirect} from 'react-router-dom';
import {victoryKarabakhFetchData} from '../../../../redux/actions/karabakhAction';

/**
 * @description VictoryKarabakh component
 */
class VictoryKarabakh extends Component {
  componentDidMount() {
    this.props.fetchKarabakhVictory();
  }
  render() {
    if (!isUnAuthPublicEnabled()) {
      return <Redirect to='/login' />
    }
    const {victory} = this.props;

    return (
      <div className="container-fluid" id="karabakh">
        <div className="row">
          <div className="col-lg-12">
            <div className="whitebox">
              <header className="whitebox-header bordered d-md-flex align-items-center justify-content-between">
                <div className="whitebox-title">
                  <h4>Zəfər günü</h4>
                  <ul className="breadcrumps mb-3 mb-md-0">
                    <li className="breadcrumps-item">
                      <a href="/karabakh">
                        Qarabağ Azərbaycandır
                      </a>
                    </li>
                    <li className="breadcrumps-item">Zəfər günü</li>
                  </ul>
                </div>
                {isAuthenticated() && checkBlock('karabakh', 'karabakh-edit') ?
                  <ModalContext.Consumer>
                    {(context) => (
                      <button
                        className="btn btn-default d-flex align-items-center"
                        onClick= {() => { context.showEditKarabakhVictoryModal(true, this.props.about) }}
                      >
                        <i className="icon icon-edit mr-2"/>
                        Redaktə et
                      </button>
                    )}
                  </ModalContext.Consumer>
                  : null}
              </header>
              <section className="whitebox-body victory">
                {
                  victory ?
                    <>
                      <div dangerouslySetInnerHTML={{__html: victory.description ? victory.description : null}}/>
                      {
                        victory.youtube_id ?
                          <div className="youtube">
                            <iframe
                              width="100%"
                              height="600"
                              src={`https://www.youtube.com/embed/${victory.youtube_id}`}
                              frameBorder="0"
                              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                              allowFullScreen
                              title="Embedded youtube"
                            />
                          </div> : null
                      }
                    </> : null
                }
              </section>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch, ownProps) => ({
  fetchKarabakhVictory: () => dispatch(victoryKarabakhFetchData(ownProps))
});

const mapStateToProps = state => {
  return state.karabakhReducer;
};

export default connect(mapStateToProps, mapDispatchToProps)(VictoryKarabakh);
