import {REMOVE_SUCCESS_MESSAGE} from '../types/actionTypes';

/**
 * @description remove success message
 */
export const removeSuccessMessage = () => {
  return async dispatch => {
    dispatch({type: REMOVE_SUCCESS_MESSAGE});
  }
}
