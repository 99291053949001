import React, {Component} from 'react';
import {connect} from 'react-redux';
import {NavLink} from 'react-router-dom';
import {visitFetchData} from '../../../../redux/actions/karabakhAction';
import {isAuthenticated} from '../../../../redux/services/auth.service';
import {checkBlock} from '../../../../shared/checkBlocks';
import {
  KARABAKH_VISIT_DELETE_FAILURE,
  KARABAKH_VISIT_DELETE_REQUEST,
  KARABAKH_VISIT_DELETE_SUCCESS
} from '../../../../redux/types/actionTypes';
import {KARABAKH_VISIT_API} from '../../../../redux/services/api.service';
import {deleteAction} from '../../../../redux/actions/deleteAction';

/**
 * @description Page1 component
 */
class Visit extends Component {
  state = {};

  componentDidMount() {
    this.props.fetchVisits();
  }

  removeVisit = (ids) => {
    if (window.confirm('Are you sure?')) {
      const idsArray = [];
      idsArray.push(ids);
      const types =
        {
          request: KARABAKH_VISIT_DELETE_REQUEST,
          success: KARABAKH_VISIT_DELETE_SUCCESS,
          failure: KARABAKH_VISIT_DELETE_FAILURE
        };
      this.props.delete(idsArray, types, KARABAKH_VISIT_API);

      document.getElementById(`visit_${ids}`).remove();
    }
  }

  render() {
    const visits =  this.props.visits ? this.props.visits.map((visit) => {
      return (
        <div className="list_link" key={visit.id} id={`visit_${visit.id}`}>
          <a className="row d-flex">
            <div className="img p-2 col-md-3 col-12">
              <img src={`${process.env.REACT_APP_SERVER}/storage/` + (visit.image ? visit.image.path : '')} />
            </div>
            <div className="content col-md-9 col-12 align-self-center">
              <NavLink
                to={{pathname: `/karabakh/visit/${visit.id}`, aboutProps:{title:`${visit.name}`}}}
                exact
              >
                <h3 className="content_title">{visit.name}</h3>
                <p className="content_body">
                  {visit.short_description}
                </p>
              </NavLink>
              {isAuthenticated() && checkBlock('karabakh', 'karabakh-delete') ?
                <button
                  className="btn btn-default float-right"
                  onClick={() => this.removeVisit(visit.id)}
                >
                  <span className="d-lg-block">
                    <i className="icon icon-delete mr-2"/>
                  </span>
                </button> : null }
              {isAuthenticated() && checkBlock('karabakh', 'karabakh-edit') ?
                <NavLink
                  to={`/karabakh/visit/edit/${visit.id}`}
                  exact
                  className="btn btn-default float-right"
                >
                  <span className="d-lg-block">
                    <i className="icon icon-edit mr-2"/>
                  </span>
                </NavLink> : null }
            </div>
          </a>
        </div>
      );
    }) : null

    return (
      <div className="container-fluid" id="karabakh">
        <div className="row">
          <div className="col-lg-12">
            <div className="whitebox">
              <header className="whitebox-header bordered d-md-flex align-items-center justify-content-between">
                <div className="whitebox-title">
                  <h4>Azərbaycan Respublikası Prezidentinin Qarabağa səfərləri</h4>
                  <ul className="breadcrumps mb-3 mb-md-0">
                    <li className="breadcrumps-item">
                      <a href="/karabakh">
                        Qarabağ Azərbaycandır
                      </a>
                    </li>
                    <li className="breadcrumps-item">Azərbaycan Respublikası Prezidentinin Qarabağa səfərləri</li>
                  </ul>
                </div>
                {isAuthenticated() && checkBlock('karabakh', 'karabakh-add') ?
                  <NavLink
                    to={'/karabakh/visit/add/new'}
                    exact
                    className="btn btn-theme d-flex align-items-center"
                  >
                    <span className="d-none d-lg-block">Yeni səfər əlavə edin</span>
                    <i className="icon icon-plus" />
                  </NavLink> : null }
              </header>
              <section className="whitebox-body">
                {visits}
              </section>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch, ownProps) => ({
  fetchVisits: (params) => dispatch(visitFetchData(params, ownProps)),
  delete: (ids, types, api) => dispatch(deleteAction(ids, types, api))
})

const mapStateToProps = state => {
  return state.karabakhReducer;
}

export default connect(mapStateToProps, mapDispatchToProps)(Visit);
