import React from 'react';
import CloseModal from '../../../shared/CloseModal';
import AddLocationMap from '../map/AddLocationMap';
import {ModalContext} from '../../../../context/context';

/**
 * @description DetailMap component
 */
const DetailMap = (props) =>
  <div className="modal-custom">
    <div className="container-fluid">
      <div className="row">
        <div className="col-lg-8 offset-lg-2">
          <div className="modal-custom-inner">
            <div className="modal-custom-header d-flex align-items-center justify-content-end">
              <CloseModal modal='showDetailObjectMap'/>
            </div>
            <div className="row">
              <div className="col-md-12">
                <div className="objects-wrapper">
                  <ModalContext.Consumer>
                    {(context) => (
                      <AddLocationMap coords={context.state.coords} />
                    )}
                  </ModalContext.Consumer>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

export default DetailMap;
