import React, {Component} from 'react';
import {withFormik} from 'formik';
import {connect} from 'react-redux';
import CloseModal from '../../../shared/CloseModal';
import ModalSuccess from '../../../shared/ModalSuccess';
import {removeSuccessMessage} from '../../../../redux/actions/messageAction';
import {ModalContext} from '../../../../context/context';
import {aboutKarabakhSchema} from '../../../../shared/validation';
import ButtonLoader from '../../../loader/ButtonLoader';
import CKEditor from 'ckeditor4-react';
import {aboutKarabakhUpdateData} from '../../../../redux/actions/karabakhAction';
import InputError from '../../errors/InputError';

/**
 * @description Component Class for EditKarabakhAbout
 */
class EditKarabakhAbout extends Component {
  static contextType = ModalContext;

  onEditorChange = (evt) => {
    this.props.setFieldValue('description', evt.editor.getData());
  }

  /**
   * @description all errors for one input
   * @param name
   * @returns {*}
   */
  handleInputError = (name) => {
    let aboutErrors = this.props.about && this.props.about.error ? this.props.about.data : [];

    let {touched, errors} = this.props;

    if (touched[name] && errors[name]) {
      aboutErrors[name] = [errors[name]];
    }

    return <InputError errors={aboutErrors} name={name}/>
  }

  render() {
    const {values, handleSubmit} = this.props;

    return (
      <div className="modal-custom">
        <div className="container-fluid">
          <div className="row">
            <div className="col-xl-8 offset-xl-2">
              <div className="modal-custom-inner">
                <div className="modal-custom-header d-flex align-items-center justify-content-between">
                  <h4>
                    Qarabağ Haqqında
                  </h4>
                  <CloseModal tooltip={true} modal="showEditKarabakhAboutModal" />
                </div>
                {this.props.about ?
                  <div className="add-form about-form">
                    <div className="col-md-12">
                      <div className="form-group form-group-custom">
                        <label htmlFor="description">Qarabağ haqqında</label>
                        <CKEditor
                          name="description"
                          data={values.description}
                          onChange={this.onEditorChange}
                        />
                        {this.handleInputError('description')}
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="row">
                        <div className="col-md-4 offset-md-4">
                          <div className="form-group form-group-custom">
                            <button
                              type="button"
                              onClick={() => handleSubmit(values)}
                              className="btn btn-theme w-100"
                              disabled={this.props.isFetching ? true : null}
                            >
                              {this.props.isFetching ? <ButtonLoader /> : <span>Yadda saxla</span>}
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  : null}
                {this.props.success ? <ModalSuccess text="Qarabağ Haqqında məlumat uğurla dəyişdirildi" /> : null }
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapPropsToValues = (props) => {
  if (props.about) {
    const {about} = props;
    return {
      'id': about.id,
      'description': about.description
    }
  }
  return {
    id: '',
    description: ''
  }
};

const EditKarabakhAboutForm = withFormik({
  enableReinitialize: true,
  mapPropsToValues,
  validationSchema: aboutKarabakhSchema,
  validateOnBlur: true,
  validateOnChange: true,
  handleSubmit: (values, {props, resetForm, setSubmitting}) => {
    props.editAbout(values);
    setTimeout(() => {
      props.removeSuccess();
    }, 4000);
  }
})(EditKarabakhAbout);

const mapDispatchToProps = (dispatch, ownProps) => ({
  editAbout: aboutData => dispatch(aboutKarabakhUpdateData(aboutData, ownProps)),
  removeSuccess: () => dispatch(removeSuccessMessage())
});

const mapStateToProps = state => {
  return state.karabakhReducer
};

export default connect(mapStateToProps, mapDispatchToProps)(EditKarabakhAboutForm);

