import React, {Component} from 'react';
import {acceptTask, doneTask, taskFetchDetail} from '../../../../redux/actions/taskAction';
import {connect} from 'react-redux';
import {checkBlock} from '../../../../shared/checkBlocks';
import accept from '../../../../assets/img/accept.svg';
import assign from '../../../../assets/img/assign.svg';
import ForwardTask from '../../forward/ForwardTask';
import TaskComment from '../comment/TaskComment';
import SuccessMessage from '../../../shared/SuccessMessage';
import {removeSuccessMessage} from '../../../../redux/actions/messageAction';
import Loader from '../../../shared/Loader';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';

/**
 * @description Component Class for AssignmentDetail detail
 */
class AssignmentDetail extends Component {
  state = {
    showDropdownMenu: false,
    task_id: null,
    showAcceptBtn: true,
    showDoneBtn: false,
    showForwardBtn: false,
    itemCount: 3
  }

  /**
   * @description component on init
   * @description adds event listener to dom
   * @memberOf AddObject
   */
  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside);
    const {id} = this.props.match.params;
    this.props.fetchTaskDetail(id);
    this.setState({task_id: id});
  }

  closeModal = () => {
    this.props.removeSuccess();
  }

  showDropdownMenu = () => {
    if (this.state.showDropdownMenu) {
      this.setState({showDropdownMenu: false});
      return;
    }
    this.setState({showDropdownMenu: true});
  }

  /**
  * Set the wrapper ref
  */
  setWrapperRef = (node) => {
    this.wrapperRef = node;
  }

  handleClickOutside = (event) => {
    if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
      this.setState({showDropdownMenu: false});
    }
  }

  /**
   * @description accept task
   */
  acceptTask = () => {
    this.props.acceptTask(this.props.task.id);
    this.setState({showAcceptBtn: false, showDoneBtn: true})
  }

  /**
   * @description done task
   */
  doneTask = () => {
    this.props.doneTask(this.props.task.id)
  }
  render() {
    const images = this.props.task && this.props.task.images ? this.props.task.images.map((img, i) => {
      return (
        <div className="object-images m-4" key={i}>
          <a target="_blank" rel="noopener noreferrer" href={`${process.env.REACT_APP_SERVER}/storage/` + img.path}>
            <img src={`${process.env.REACT_APP_SERVER}/storage/` + img.path} alt={img.path} />
          </a>
        </div>
      )
    }) : null
    return (
      <div className="container-fluid">
        <div className="row">

          <div className="col-lg-12">
            {this.props.task ?
              <div className="whitebox">
                <header className="whitebox-header bordered d-flex align-items-center justify-content-between">
                  <div className="whitebox-title">
                    <div className="task-ownership">
                      <div className="task-ownership-pos">
                        <h3>{this.props.task.author.name}</h3>
                      </div>
                    </div>
                  </div>
                  {/* accept task */}
                  {this.props.task && checkBlock('assignments', 'task-accept') && (this.props.task.status.step === 1 && this.state.showAcceptBtn) ?
                    <button
                      className="btn btn-theme-default"
                      onClick={this.acceptTask}
                    >
                      <span>Qəbul et</span>
                      <img src={accept} alt={accept} />
                    </button> : null }
                  {/* done task  */}
                  {this.props.task && checkBlock('assignments', 'task-done') && (this.props.task.status.step === 2 || (this.state.showDoneBtn)) ?
                    <div className="d-lg-flex align-items-center justify-content-between">
                      <div>
                        <button
                          className="btn btn-theme-default"
                          onClick={this.doneTask}
                        >
                          <span>Həll et</span>
                          <img src={accept} alt={accept} />
                        </button>
                      </div>

                      {/* forward task */}
                      {checkBlock('assignments', 'task-forward') ?
                        <div className="dropdown-wrapper ml-lg-2">
                          <button
                            className="btn btn-theme-default"
                            onClick={this.showDropdownMenu}
                          >
                            <span>Tapşırığı yönləndir</span>
                            <img src={assign} alt={assign} />
                          </button>

                          <div className={`dropdown dropdown-for-component ${this.state.showDropdownMenu ? 'show' : 'hidden'}`} ref={this.setWrapperRef}>
                            <ForwardTask taskProps={this.props} />
                          </div>
                        </div> : null }
                    </div> : null }

                  {/* forward task */}
                </header>
                {this.props.taskDoneData && this.props.taskDoneData.error ? <p className='error px-3'>{this.props.taskDoneData.error}</p> : null}
                <section className="whitebox-body py-5">
                  <div className="task-box d-flex align-items-center">
                    <div className="task-content">
                      <p>
                        {this.props.task.description}
                      </p>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-12">
                      <OwlCarousel
                        items={this.state.itemCount}
                        autoplay
                        dots={false}
                      >
                        {images}
                      </OwlCarousel>
                    </div>
                  </div>
                </section>

                <section className="whitebox-footer">
                  <div className="container-fluid">
                    <div className="row">
                      <div className="col-12">
                        <TaskComment
                          paramId={this.state.task_id}
                          comments={this.props.task.comments}
                        />
                      </div>
                    </div>
                  </div>
                </section>
                {this.props.taskDoneStatus ?
                  <SuccessMessage
                    text='Uğurlu'
                    closeModal={this.closeModal}
                  /> : null }
              </div>
              : <Loader />}
          </div>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch, ownProps) => ({
  fetchTaskDetail: (paramId) => dispatch(taskFetchDetail(paramId)),
  acceptTask: (paramId) => dispatch(acceptTask(paramId, ownProps)),
  doneTask: (paramId) => dispatch(doneTask(paramId, ownProps)),
  removeSuccess: () => dispatch(removeSuccessMessage())
});

const mapStateToProps = state => {
  return state.taskReducer;
};

export default connect(mapStateToProps, mapDispatchToProps)(AssignmentDetail);
