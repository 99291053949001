import axios from 'axios';
import {
  FETCH_EVENTS_REQUEST,
  FETCH_EVENTS_SUCCESS,
  FETCH_EVENTS_FAILURE,
  ADD_EVENT_REQUEST,
  ADD_EVENT_SUCCESS,
  ADD_EVENT_FAILURE,
  EDIT_EVENTS_REQUEST,
  EDIT_EVENTS_SUCCESS,
  EDIT_EVENTS_FAILURE,
  FETCH_EVENT_DETAIL_REQUEST,
  FETCH_EVENT_DETAIL_SUCCESS,
  FETCH_EVENT_DETAIL_FAILURE
} from '../types/actionTypes';
import {
  EVENT_API,
  EVENT_API_BY_ID
} from '../services/api.service';
import moment from 'moment';

/**
 * @description fetch all events
 */
export const fetchEventsData = (ownProps) => {
  return async dispatch => {
    dispatch({type: FETCH_EVENTS_REQUEST});
    try {
      const {data} = await axios.get(EVENT_API);
      dispatch({type: FETCH_EVENTS_SUCCESS, payload: data.data.events});
    } catch (error) {
      dispatch({type: FETCH_EVENTS_FAILURE, payload: error});
    }
  }
};

/**
 * @description add events
 */
export const addEventData = (eventData, ownProps) => {
  return async dispatch => {
    dispatch({type: ADD_EVENT_REQUEST});
    try {
      const {data} = await axios.post(EVENT_API, {
        title: eventData.title,
        place: eventData.place,
        note: eventData.note,
        start: moment(eventData.start).format('YYYY-MM-DD HH:mm:ss'),
        end: moment(eventData.end).format('YYYY-MM-DD HH:mm:ss')
      });
      dispatch({type: ADD_EVENT_SUCCESS, payload: data.data.events});
    } catch (error) {
      dispatch({type: ADD_EVENT_FAILURE, payload: error.response.data});
    }
  }
}

/**
 * @description redux action for event fetch
 * @param  {Object} user
 * @param  {Object} ownProps
 * @param  {string} EVENT_API
 */
export const eventFetchDetail = (id, ownProps) => {
  return async dispatch => {
    dispatch({type: FETCH_EVENT_DETAIL_REQUEST});
    try {
      const {data} = await axios.get(EVENT_API_BY_ID(id));
      dispatch({type: FETCH_EVENT_DETAIL_SUCCESS, payload: data.data.event});
    } catch (error) {
      dispatch({type: FETCH_EVENT_DETAIL_FAILURE, payload: error.response.data.data});
    }
  }
}

/**
 * @description update event
 */
export const eventEditData = (eventData, ownProps) => {
  return async dispatch => {
    dispatch({type: EDIT_EVENTS_REQUEST});
    try {
      const {data} = await axios.put(EVENT_API_BY_ID(eventData.id), {
        title: eventData.title,
        place: eventData.place,
        note: eventData.note,
        start: moment(eventData.start).format('YYYY-MM-DD HH:mm:ss'),
        end: moment(eventData.end).format('YYYY-MM-DD HH:mm:ss')
      });
      dispatch({type: EDIT_EVENTS_SUCCESS, payload: data.data.events});
    } catch (error) {
      dispatch({type: EDIT_EVENTS_FAILURE, payload: error.response.data});
    }
  }
}
