import React from 'react';
import {NavLink} from 'react-router-dom';
import Loader from '../../shared/Loader';

/**
 * @description Useful component
 */
const Useful = (props) =>  {
  const transportDefinitionType = 'transport';
  const items = props.items ?
    props.items.map((item) => {
      return (
        <div className="col-sm-6 col-lg-4 col-xl-3" key={item.id}>
          <NavLink
            to={item.definition && item.definition === transportDefinitionType ?
              `/useful/${item.definition}/${item.id}` :
              `/useful/${item.id}`}
            exact
            className="objects-link"
          >
            <img src={`${process.env.REACT_APP_SERVER}/storage/` + item.image} alt={item.image} />
            <p className="count">{item.object_activity_statistics_count}</p>
            <span className="mt-1">{item.name}</span>
          </NavLink>
        </div>
      )
    }) : <Loader />
  return (
    <div className="row">
      {items}
    </div>
  );
}

export default Useful;
