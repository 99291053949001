import React, {Component} from 'react';
import {checkBlock} from '../../../shared/checkBlocks';
import {NavLink, Redirect} from 'react-router-dom';
import {connect} from 'react-redux';
import {ModalContext} from '../../../context/context';
import {appealFetchData} from '../../../redux/actions/appealAction';
import {isAuthenticated} from '../../../redux/services/auth.service';
import Loader from '../../shared/Loader';
import {deleteAction} from '../../../redux/actions/deleteAction';
import {DELETE_APPEAL_REQUEST, DELETE_APPEAL_SUCCESS, DELETE_APPEAL_FAILURE} from '../../../redux/types/actionTypes';
import {APPEAL_API} from '../../../redux/services/api.service';
import Pagination from '../pagination/Pagination';

/**
 * @description Appeal component
 */
class Appeal extends Component {
  state = {
    params: {
      page: 1,
      pageCount: 1,
      perPage: 10,
      statusId: 0,
      categoryId: 0
    }
  };

  static contextType = ModalContext;
  componentDidMount() {
    this.fetchData();
  }

  removeObject = (ids) => {
    const idsArray = [];
    const types =
    {
      request: DELETE_APPEAL_REQUEST,
      success: DELETE_APPEAL_SUCCESS,
      failure: DELETE_APPEAL_FAILURE
    };
    const api = APPEAL_API;
    idsArray.push(ids);
    this.props.deleteObject(idsArray, types, api);

    document.getElementById(`appeal${ids}`).remove();
  }

  callBack = (page) => {
    this.handlePaginationState({page: page})

    this.fetchData()
  }

  handlePaginationState = (params) => {
    let state = this.state;

    Object.keys(params).forEach((key, value) => {
      state['params'][key] = params[key]
    })

    this.setState(state);
  }

  fetchData = () => {
    let params = {
      page: this.state.params.page,
      per_page: this.state.params.perPage
    };

    this.props.fetchAppeals(params).then(() => {
      if (this.props.meta) {
        let meta = this.props.meta;
        this.handlePaginationState({
          perPage: parseInt(meta.per_page),
          page: meta.current_page,
          pageCount: meta.last_page
        });
      }
    });
  }

  render() {
    if (!isAuthenticated()) {
      return <Redirect to='/login' />
    }
    const appeals = this.props.appeals ? this.props.appeals
      .map(appeal => {
        return (
          <tr key={appeal.id} id={`appeal${appeal.id}`}>
            <td>{appeal.author.name}</td>
            <td>{appeal.title.length < 50 ? appeal.title : appeal.title.substring(0, 50) + '...' }</td>
            <td>{appeal.category.name}</td>
            {
              appeal.status && appeal.status.step ?
                <td>
                  <label className={'label-status label-' + appeal.status.step + ''}>
                    {appeal.status.name}
                  </label>
                </td> :
                null
            }
            <td>
              <div className="d-flex align-items-center">

                <NavLink
                  to={'/appeal/detail/' + appeal.id}
                  exact
                  className="btn btn-rounded"
                  activeClassName="active"
                >
                  <i className="icon icon-detail" />
                </NavLink>

                {isAuthenticated() && checkBlock('appeal', 'appeal-delete') ?
                  <button
                    type="button"
                    className="btn btn-rounded"
                    onClick={() => this.removeObject(appeal.id)}
                  >
                    <i className="icon icon-delete-2 mr-1"/>
                  </button>
                  : null }

              </div>
            </td>
          </tr>
        )
      }) : null
    return (
      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-12">
            <div className="whitebox">
              <header className="whitebox-header bordered d-flex align-items-center justify-content-between">
                <div className="whitebox-title ">
                  <h4>Vətəndaş müraciəti</h4>
                </div>
                {checkBlock('appeal', 'appeal-add') ?
                  <NavLink
                    to={'/appeal/add'}
                    exact
                    className="btn btn-theme d-flex align-items-center"
                    activeClassName="active"
                  >
                    <span className="d-none d-lg-block">Müraciət əlavə edin</span>
                    <i className="icon icon-plus" />
                  </NavLink> : null}
              </header>

              <section className="whitebox-body">
                <div className="table-wrapper mt-0">
                  {this.props.appeals ?
                    <table className="table table-custom">
                      <thead>
                        <tr>
                          <th>Vətəndaşın adı</th>
                          <th>Müraciətin başlığı</th>
                          <th>Kateqoriya</th>
                          <th className="text-center">Status</th>
                          <th>Əməliyyat</th>
                        </tr>
                      </thead>

                      <tbody>
                        {appeals}
                      </tbody>
                    </table> : <Loader />}
                  <Pagination
                    page={this.state.params.page}
                    perPage={this.state.params.perPage}
                    pageCount={this.state.params.pageCount}
                    callBack={this.callBack}
                  />
                </div>
              </section>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch, ownProps) => ({
  fetchAppeals: (params) => dispatch(appealFetchData(params, ownProps)),
  deleteObject: (ids, types, api) => dispatch(deleteAction(ids, types, api))
})

const mapStateToProps = state => {
  return state.appealReducer;
}

export default connect(mapStateToProps, mapDispatchToProps)(Appeal);
