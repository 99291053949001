import React, {Component} from 'react';
import {connect} from 'react-redux';
import company from '../../../../assets/img/company.svg';
import clock from '../../../../assets/img/clock.svg';
import db from '../../../../assets/img/db.svg';
import marker from '../../../../assets/img/marker_gray.svg';
import envelope from '../../../../assets/img/envelope.svg';
import {vacancyFetchDetail} from '../../../../redux/actions/socialAction';
import Loader from '../../../shared/Loader';
import moment from 'moment';

/**
 * @description VacancyInner component
 */
class VacancyInner extends Component {
  componentDidMount() {
    const {id} = this.props.match.params
    this.props.fetchVacancy(id);
  }

  displaySalary = (minSalary, maxSalary) => {
    if (minSalary === maxSalary) {
      return minSalary
    }

    return `${minSalary} - ${maxSalary}`
  }

  render() {
    return (
      <div className="container-fluid">
        <div className="row">

          <div className="col-lg-12">
            {this.props.vacancy ?
              <div className="whitebox">
                <header className="whitebox-header bordered d-flex align-items-center justify-content-between">
                  <div className="whitebox-title">
                    <h4>
                      {this.props.vacancy.profession}
                    </h4>
                    <ul className="breadcrumps">
                      <li className="breadcrumps-item">Sosial bölmə</li>
                      <li className="breadcrumps-item">Vakansiyalar</li>
                      <li className="breadcrumps-item">{this.props.vacancy.profession}</li>
                    </ul>
                    <ul className="detail-info mt-5">
                      <li>
                        <label className="label-status label-0">
                          {this.displaySalary(this.props.vacancy.min_salary, this.props.vacancy.max_salary)} AZN
                        </label>
                      </li>
                      <li>
                        <img src={company} alt={company} />
                        {this.props.vacancy.company_name}
                      </li>
                      <li>
                        <img src={clock} alt={clock} />
                        {moment(this.props.vacancy.created_at).format('DD-MM-YYYY')}
                      </li>
                      <li>
                        <img src={db} alt={db} />
                        {this.props.vacancy.category.name}
                      </li>
                      <li>
                        <img src={marker} alt={marker} />
                        {this.props.vacancy.city.name}
                      </li>
                      <li>
                        <img src={envelope} alt={envelope} />
                        {this.props.vacancy.email}
                      </li>
                    </ul>
                  </div>
                </header>

                <section className="whitebox-body">
                  <div className="row">
                    <div className="col-md-12">
                      <article className="clr_blue">
                        <h4>
                          İş haqqında məlumat
                        </h4>
                        <p>
                          {this.props.vacancy.candidate_requirements}
                        </p>
                        <p>
                          {this.props.vacancy.more_info}
                        </p>
                      </article>
                    </div>
                  </div>
                </section>

                <section className="whitebox-footer">
                  <div className="container-fluid">
                    <div className="row">
                      <div className="col-md-6">
                        <article>
                          <p><b>Yaş:</b> {this.props.vacancy.min_age}-{this.props.vacancy.max_age}</p>
                          <p><b>Təhsil:</b> {this.props.vacancy.education.name}</p>
                          <p><b>İş təcrübəsi:</b> {this.props.vacancy.experience.range}</p>
                        </article>
                      </div>

                      <div className="col-md-6">
                        <article>
                          {/* <p><b>İş saatları:</b> 09:00 - 18:00</p> */}
                          <p><b>Əlaqədar şəxs:</b> {this.props.vacancy.related_person}</p>
                          <p><b>Telefon:</b> {this.props.vacancy.contact_number}</p>
                        </article>
                      </div>
                    </div>
                  </div>
                </section>

              </div> : <Loader />}
          </div>

        </div>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  fetchVacancy: (id) => dispatch(vacancyFetchDetail(id))
})

const mapStateToProps = state => {
  return state.socialReducer;
}

export default connect(mapStateToProps, mapDispatchToProps)(VacancyInner);
