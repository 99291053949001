import React, {Component} from 'react';
import {connect} from 'react-redux';
import {withFormik} from 'formik';
import {vacancyPostData} from '../../../../redux/actions/socialAction';
import {removeSuccessMessage} from '../../../../redux/actions/messageAction';
import ModalSuccess from '../../../shared/ModalSuccess';
import {vacancySchema} from '../../../../shared/validation';
import {isAuthenticated} from '../../../../redux/services/auth.service';
import {Redirect} from 'react-router-dom';
import VacancyForm from './VacancyForm';
import {vacancyFormsMapProps} from '../../../../shared/formMapProps';

/**
 * @description AddVacancy component
 */
class AddVacancy extends Component {
  render() {
    if (!isAuthenticated()) {
      return <Redirect to='/login' />
    }
    return (
      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-12">
            <div className="whitebox">
              <header className="whitebox-header bordered">

                <div className="whitebox-title ">
                  <h4>
                    Vakansiya yarat
                  </h4>
                  <ul className="breadcrumps">
                    <li className="breadcrumps-item">Sosial bölmə</li>
                    <li className="breadcrumps-item">Vakansiyalar</li>
                    <li className="breadcrumps-item">Vakansiya yarat</li>
                  </ul>
                </div>

              </header>

              <section className="whitebox-body">
                <VacancyForm formProps = {this.props} submitText={'Vakansiya yarat'} />
              </section>
              {this.props.success ? <ModalSuccess text="Yeni Vakansiya uğurla yaradıldı" /> : null }
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapPropsToValues = (props) => vacancyFormsMapProps(props)

const AddVacancyForm = withFormik({
  enableReinitialize: false,
  mapPropsToValues,
  validationSchema: vacancySchema,
  validateOnBlur: true,
  validateOnChange: true,
  handleSubmit: (values, {props, resetForm, setSubmitting}) => {
    props.addVacancy(values);

    setTimeout(() => {
      props.removeSuccess();
    }, 4000);
  }
})(AddVacancy);

const mapDispatchToProps = (dispatch) => ({
  addVacancy: (vacancyData) => dispatch(vacancyPostData(vacancyData)),
  removeSuccess: () => dispatch(removeSuccessMessage())
})

const mapStateToProps = state => {
  return state.socialReducer;
}

export default connect(mapStateToProps, mapDispatchToProps)(AddVacancyForm);

