import {
  ADD_REQUEST,
  ADD_SUCCESS,
  ADD_FAILURE,
  FETCH_REQUEST,
  FETCH_SUCCESS,
  FETCH_FAILURE,
  FETCH_CATEGORY_REQUEST,
  FETCH_CATEGORY_SUCCESS,
  FETCH_CATEGORY_FAILURE,
  FETCH_ZONE_REQUEST,
  FETCH_ZONE_SUCCESS,
  FETCH_ZONE_FAILURE,
  FETCH_OBJECT_DETAIL_REQUEST,
  FETCH_OBJECT_DETAIL_SUCCESS,
  FETCH_OBJECT_DETAIL_FAILURE,
  EDIT_OBJECT_REQUEST,
  EDIT_OBJECT_SUCCESS,
  EDIT_OBJECT_FAILURE,
  FETCH_TYPE_REQUEST,
  FETCH_TYPE_SUCCESS,
  FETCH_TYPE_FAILURE,
  FETCH_ALL_OBJECT_REQUEST,
  FETCH_ALL_OBJECT_SUCCESS,
  FETCH_ALL_OBJECT_FAILURE,
  REMOVE_SUCCESS_MESSAGE,
  FETCH_ACTIVITY_CATEGORY_REQUEST,
  FETCH_ACTIVITY_CATEGORY_SUCCESS,
  FETCH_ACTIVITY_CATEGORY_FAILURE,
  DELETE_OBJECT_REQUEST,
  DELETE_OBJECT_SUCCESS,
  DELETE_OBJECT_FAILURE,
  SEARCH_OBJECT_REQUEST,
  SEARCH_OBJECT_SUCCESS,
  SEARCH_OBJECT_FAILURE,
  FETCH_OBJECT_BY_ZONE_REQUEST,
  FETCH_OBJECT_BY_ZONE_SUCCESS,
  FETCH_OBJECT_BY_ZONE_FAILURE
} from '../types/actionTypes';

/**
 * @description initial state for objectReducer
 */
const initialState = {};

/**
 * @description updates state depending on action data
 * @param {Object} state = initialState
 * @param {string} type = action.type
 * @param {} payload
 * @param {Array} objects
 * @returns return state
 */
export default function objectReducer(state = initialState, {type, payload, meta}) {
  switch (type) {
  case ADD_REQUEST:
    return {
      ...state,
      success: false,
      isFetching: true,
      errors: false
    };
  case ADD_SUCCESS:
    return {
      ...state,
      success: true,
      isFetching: false,
      errors: false
    };
  case ADD_FAILURE:
    return {
      ...state,
      success: false,
      isFetching: false,
      errors: payload
    };

  case DELETE_OBJECT_REQUEST:
    return {
      ...state,
      success: false,
      isFetching: true
    };
  case DELETE_OBJECT_SUCCESS:
    return {
      ...state,
      success: true,
      isFetching: false
    };
  case DELETE_OBJECT_FAILURE:
    return {
      ...state,
      success: false,
      isFetching: false,
      errors: payload
    };

  case EDIT_OBJECT_REQUEST:
    return {
      ...state,
      success: false,
      isFetching: true,
      errors: false
    };
  case EDIT_OBJECT_SUCCESS:
    return {
      ...state,
      success: true,
      isFetching: false,
      errors: false
    };
  case EDIT_OBJECT_FAILURE:
    return {
      ...state,
      success: false,
      isFetching: false,
      errors: payload
    };

  case FETCH_OBJECT_BY_ZONE_REQUEST:
    return {
      ...state,
      isFetching: true,
      objectsByZone: payload
    };
  case FETCH_OBJECT_BY_ZONE_SUCCESS:
    return {
      ...state,
      isFetching: false,
      objectsByZone: payload
    };
  case FETCH_OBJECT_BY_ZONE_FAILURE:
    return {
      ...state,
      isFetching: false,
      objectsByZone: payload
    };

  case FETCH_REQUEST:
    return {
      ...state,
      isFetching: true,
      objects: payload
    };
  case FETCH_SUCCESS:
    return {
      ...state,
      isFetching: false,
      objects: payload,
      meta: meta
    };
  case FETCH_FAILURE:
    return {
      ...state,
      isFetching: false,
      objects: payload
    };

  case FETCH_ALL_OBJECT_REQUEST:
    return {
      ...state,
      isFetching: true,
      objects: payload
    };
  case FETCH_ALL_OBJECT_SUCCESS:
    return {
      ...state,
      isFetching: false,
      objects: payload
    };
  case FETCH_ALL_OBJECT_FAILURE:
    return {
      ...state,
      isFetching: false,
      objects: payload
    };

  case FETCH_CATEGORY_REQUEST:
    return {
      ...state,
      isFetching: true,
      objectCategories: payload
    };
  case FETCH_CATEGORY_SUCCESS:
    return {
      ...state,
      isFetching: false,
      objectCategories: payload
    };
  case FETCH_CATEGORY_FAILURE:
    return {
      ...state,
      isFetching: false,
      objectCategories: payload
    };

  case FETCH_ZONE_REQUEST:
    return {
      ...state,
      isFetching: true,
      objectZones: payload
    };
  case FETCH_ZONE_SUCCESS:
    return {
      ...state,
      isFetching: false,
      objectZones: payload
    };
  case FETCH_ZONE_FAILURE:
    return {
      ...state,
      isFetching: false,
      objectZones: payload
    };

  case FETCH_ACTIVITY_CATEGORY_REQUEST:
    return {
      ...state,
      isFetching: true,
      activityCategory: payload
    };
  case FETCH_ACTIVITY_CATEGORY_SUCCESS:
    return {
      ...state,
      isFetching: false,
      activityCategory: payload
    };
  case FETCH_ACTIVITY_CATEGORY_FAILURE:
    return {
      ...state,
      isFetching: false,
      activityCategory: payload
    };

  case FETCH_TYPE_REQUEST:
    return {
      ...state,
      objectTypes: payload
    };
  case FETCH_TYPE_SUCCESS:
    return {
      ...state,
      objectTypes: payload
    };
  case FETCH_TYPE_FAILURE:
    return {
      ...state,
      objectTypes: payload
    };

  case FETCH_OBJECT_DETAIL_REQUEST:
    return {
      ...state,
      isFetching: true,
      objectDetails: payload
    };
  case FETCH_OBJECT_DETAIL_SUCCESS:
    return {
      ...state,
      isFetching: false,
      objectDetails: payload
    };
  case FETCH_OBJECT_DETAIL_FAILURE:
    return {
      ...state,
      isFetching: false,
      objectDetails: payload
    };
  case REMOVE_SUCCESS_MESSAGE:
    return {
      ...state,
      success: false
    };

  case SEARCH_OBJECT_REQUEST:
    return {
      ...state,
      isFetching: true,
      objects: payload
    };
  case SEARCH_OBJECT_SUCCESS:
    return {
      ...state,
      isFetching: false,
      objects: payload
    };
  case SEARCH_OBJECT_FAILURE:
    return {
      ...state,
      isFetching: false,
      objects: payload
    };

  default:
    return state
  }
}
