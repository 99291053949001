import React, {Component} from 'react';
import {NavLink, withRouter} from 'react-router-dom';
import {compose} from 'redux';
import {connect} from 'react-redux';
import logo from '../../../../assets/img/logo.svg';
import logoSmall from '../../../../assets/img/gerb.svg';
import logoutIcon from '../../../../assets/img/logout.svg';
import loginIcon from '../../../../assets/img/login.svg';
import logoutPattern from '../../../../assets/img/logout-pattern.svg';
import collapsed from '../../../../assets/img/collapsed.svg';
import {userLogoutFetch} from '../../../../redux/actions/userAction';
import {SidebarContext} from '../../../../context/context';
import sidebarIsCollapsed from './sidebar-condition';
import {menusFetchData} from '../../../../redux/actions/menuAction';
import {isAuthenticated} from '../../../../redux/services/auth.service';
import {isPage} from '../../../../shared/checkBlocks';

/**
 * Class for Sidebar Component
 */
class Sidebar extends Component {
  static contextType = SidebarContext;

  state = {
    menus: []
  }

  closeSidebar = () => {
    if (window.innerWidth < 991) {
      this.context.controlSidebar(true);
      localStorage.setItem('sidebar', 'collapsed')
    }
  }
  /**
   * @description handling logout of user
   * @memberOf Sidebar
   */
  handleLogout = (e) => {
    e.preventDefault();
    this.props.userLogout();
  }

  controlSidebar = () => {
    if (this.context.collapsed || sidebarIsCollapsed())  {
      this.context.controlSidebar(false);
      localStorage.removeItem('sidebar')
    } else {
      this.context.controlSidebar(true);
      localStorage.setItem('sidebar', 'collapsed')
    }
  }

  componentDidMount(prevProps) {
    this.props.fetchMenus();

    isPage('appeal');
  }

  /**
   * @description link to appeal page
   * @memberOf Sidebar
   */
  toAppeal = () => {
    if (isAuthenticated()) {
      this.props.history.push('/appeal');
      return;
    }
    this.props.history.push('/pre/login');
  }
  render() {
    const menus =
    this.props.menus.menus ?
      this.props.menus.menus.map((menu, index) => {
        if (menu.key === 'appeal') {
          return (
            <li key={index}>
              <button
                className={`btn w-100 text-left nav-link ${isPage('appeal') ? 'active' : null}`}
                onClick={this.toAppeal}
              >
                <img src={`${process.env.REACT_APP_SERVER}/storage/` + menu.value.icon} alt={menu.value.icon} />
                <span>{menu.value.title}</span>
              </button>
            </li>
          )
        }
        return (
          <li key={index}>
            <NavLink
              to={`/${menu.key}`}
              className="nav-link"
              activeClassName="active">
              <img src={`${process.env.REACT_APP_SERVER}/storage/` + menu.value.icon} alt={menu.value.icon} />
              <span>{menu.value.title}</span>
            </NavLink>
          </li>
        )
      }) : null
    return (
      <aside id="sidebar" className={(this.context.collapsed || sidebarIsCollapsed()) ? 'collapsed' : null}>

        <div className="aside-header">
          {(this.context.collapsed || sidebarIsCollapsed()) ? <img src={logoSmall} alt={logoSmall}/> : <img src={logo} alt={logo}/>}
        </div>

        <nav onClick={this.closeSidebar} className="sidebar-list d-flex flex-column justify-content-start align-items-start">
          <ul>
            {menus}
          </ul>

          {isAuthenticated() ?
            <button
              href="#"
              className="btn btn-logout w-100 text-left"
              onClick={this.handleLogout}
            >
              <img src={logoutIcon} alt={logoutIcon} />
              <span>Çıxış</span>
            </button> :
            <NavLink
              to={'/login'}
              className="btn btn-logout w-100 text-left"
              activeClassName="active">
              <img src={loginIcon} alt={loginIcon} />
              <span>Giriş</span>
            </NavLink>}
        </nav>

        <button
          className="control-sidebar"
          onClick= {this.controlSidebar}
        >
          {this.context.collapsed || sidebarIsCollapsed() ? <img src={collapsed} alt={collapsed} /> : <img src={logoutPattern} alt={logoutPattern} /> }
        </button>
      </aside>
    );
  }
}

const mapDispatchToProps = (dispatch, ownProps) => ({
  userLogout: () => dispatch(userLogoutFetch(ownProps)),
  fetchMenus: () => dispatch(menusFetchData())
})

const mapStateToProps = state => {
  return {
    menus: state.menuReducer
  }
}

export default compose(
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps)
)(Sidebar);
