import React, {Component} from 'react';
import telegram from '../../../assets/img/telegram.svg';
import {Formik} from 'formik';
import {getUserName} from '../../../redux/services/auth.service';
import {isBlank} from '../../../shared/checkBlocks';
// import file1 from '../../../../assets/img/file1.png';
// import file2 from '../../../../assets/img/file2.png';
import moment from 'moment';

/**
 * @description Component Class for CommentBox detail
 */
class CommentBox extends Component {
  state = {
    comments: []
  };
  render() {
    const comments = this.props.comments ?
      this.props.comments.map((comment, index) => {
        return (
          <div key={index} className="comments">
            <div className="comment-text">
              <h5>{comment.user.name}</h5>
              <p>
                {comment.text}
              </p>
              <time>{moment(comment.created_at).format('YYYY-MM-DD hh:mm')}</time>
            </div>
          </div>
        )
      }) : null;
    const currentComment = this.state.comments ?
      this.state.comments.map((comment, index) => {
        return (
          <div key={index} className="comments">
            <div className="comment-text">
              <h5>{getUserName()}</h5>
              <p>
                {comment.text}
              </p>
              <time>{moment(new Date()).format('YYYY-MM-DD hh:mm')}</time>
            </div>
          </div>
        )
      }) : null
    return (
      <div className="comment-box">
        {/* <div className="comments">
          <div className="uploaded-files">
            <img src={file1} alt={file1} />
            <img src={file2} alt={file2} />
          </div>
        </div> */}

        <div className="comments-text-wrapper">
          {comments}
          {currentComment}
        </div>

        <div className="comment-form-box">
          <Formik
            initialValues={{
              text: ''
            }}
            onSubmit={(commentContent, {resetForm}) => {
              if (!isBlank(commentContent.text)) {
                this.props.addComment(commentContent, this.props.paramId);
                const comments = [];
                comments.push({text: commentContent.text, user_id: this.props.userName});
                this.setState({comments: [...this.state.comments, ...comments]});
                resetForm();
                return;
              }
              return;
            }}
          >
            {props => (
              <form onSubmit={props.handleSubmit}>
                <input
                  type="text"
                  className="comment-input"
                  name="text"
                  placeholder="Rəy yaz..."
                  value={props.values.text}
                  onChange={props.handleChange}
                  onBlur={props.handleBlur}
                  onKeyDown={this.handleLoginKeyUp}
                />
                {/* <div className="upload-file">
                  <input type="file"/>
                </div> */}

                <button
                  className="btn send-comment"
                  type="submit"
                >
                  <img src={telegram} alt={telegram} />
                </button>
              </form>
            )}
          </Formik>
        </div>
      </div>
    );
  }
}

export default CommentBox
