import React from 'react';
import {Route, withRouter} from 'react-router-dom';

/**
 * @description HOC function for handle Private Routing
 * @param  {Component} component
 * @param  {} authenticated
 * @return {Route}
 */
const PrivateRoute = ({
  component: Component,
  ...rest
}) => {
  return (

  /**
   * @description Checking User Authenticated by using authenticated parameter
   * @param  {} props
   * @param  {Component} isAuthenticated?<Dashboard><Component{...props}/></Dashboard>:<Redirect to="/login"/>
   */
    <Route {...rest}  render = {
      (props) => (
        <Component { ...props }/>)
    } />

  )
}

/**
 * @param  {} PrivateRoute
 */
export default withRouter(PrivateRoute);
