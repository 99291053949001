import React, {Component} from 'react';
import {Route, Redirect, BrowserRouter as Router, Switch} from 'react-router-dom';
import SignUp from './auth/SignUp';
import Dashboard from './dashboard/Dashboard';
import LoginWrapper from './auth/LoginWrapper';
import {getToken} from '../redux/services/auth.service';
import ErrorBoundary from './shared/ErrorBoundary';

/**
 * Class for App component
 */
class App extends Component {
  render() {
    return (
      <Router>
        <div className="app">
          <ErrorBoundary>
            <details id="token">
              {getToken()}
            </details>
            <Switch>
              <Route exact path="/login" component={LoginWrapper} />
              <Route exact path="/register" component={SignUp} />
              <Redirect exact from="/" to="/dashboard" />
              <Route path="/" component={Dashboard} />
            </Switch>
          </ErrorBoundary>
        </div>
      </Router>
    );
  }
}

export default App;
