import axios from 'axios';
import {
  FETCH_LEGISLATION_REQUEST,
  FETCH_LEGISLATION_SUCCESS,
  FETCH_LEGISLATION_FAILURE,
  FETCH_LEGISLATION_CATEGORY_REQUEST,
  FETCH_LEGISLATION_CATEGORY_SUCCESS,
  FETCH_LEGISLATION_CATEGORY_FAILURE,
  FETCH_LEGISLATION_DETAIL_REQUEST,
  FETCH_LEGISLATION_DETAIL_SUCCESS,
  FETCH_LEGISLATION_DETAIL_FAILURE,
  ADD_LEGISLATION_REQUEST,
  ADD_LEGISLATION_SUCCESS,
  ADD_LEGISLATION_FAILURE,
  EDIT_LEGISLATION_REQUEST, EDIT_LEGISLATION_SUCCESS, EDIT_LEGISLATION_FAILURE
} from '../types/actionTypes';
import {
  LEGISLATION_API, LEGISLATION_API_DETAIL, LEGISLATION_API_CATEGORY, LEGISLATION_EDIT_API
} from '../services/api.service';

/**
 * @description fetch all legislation categories
 */
export const legislationFetchCategories = (ownProps) => {
  return async dispatch => {
    dispatch({type: FETCH_LEGISLATION_CATEGORY_REQUEST});
    try {
      const {data} = await axios.get(LEGISLATION_API_CATEGORY);
      dispatch({type: FETCH_LEGISLATION_CATEGORY_SUCCESS, payload: data.data.lawCategories});
      localStorage.setItem('law_categories', JSON.stringify(data.data.lawCategories));
    } catch (error) {
      dispatch({type: FETCH_LEGISLATION_CATEGORY_FAILURE, payload: error});
    }
  }
};

/**
 * @description add all normative's acts categories
 */
export const legislationPostData = (lawData, ownProps) => {
  return async dispatch => {
    dispatch({type: ADD_LEGISLATION_REQUEST});
    try {
      const {data} = await axios.post(LEGISLATION_API, {
        category_id: Number(lawData.category_id),
        title: lawData.title,
        description: lawData.description,
        number: lawData.number,
        adopted_at: lawData.adopted_at
      });
      dispatch({type: ADD_LEGISLATION_SUCCESS, payload: data.data.law});
    } catch (error) {
      dispatch({type: ADD_LEGISLATION_FAILURE, payload: error.response.data});
    }
  }
}

/**
 * @description update law
 */
export const legislationEditData = (lawData, ownProps) => {
  return async dispatch => {
    dispatch({type: EDIT_LEGISLATION_REQUEST});
    try {
      const {data} = await axios.put(LEGISLATION_EDIT_API(lawData.id), {
        category_id: Number(lawData.category_id),
        title: lawData.title,
        description: lawData.description,
        number: lawData.number,
        adopted_at: lawData.adopted_at
      });
      dispatch({type: EDIT_LEGISLATION_SUCCESS, payload: data.data.law});
    } catch (error) {
      dispatch({type: EDIT_LEGISLATION_FAILURE, payload: error.response.data});
    }
  }
}

/**
 * @description redux action for NORMATIVE's ACTS fetch
 * @param id
 * @param  {Object} ownProps
 */
export const legislationFetchList = (id, ownProps) => {
  return async dispatch => {
    dispatch({type: FETCH_LEGISLATION_REQUEST});
    try {
      const {data} = await axios.get(LEGISLATION_API, {
        params: {
          category_id: id
        }
      });
      dispatch({type: FETCH_LEGISLATION_SUCCESS, payload: data.data.laws});
    } catch (error) {
      dispatch({type: FETCH_LEGISLATION_FAILURE, payload: error.response.data.data});
    }
  }
}

/**
 * @description redux action for NORMATIVE's ACTS fetch
 * @param id
 * @param  {Object} ownProps
 */
export const legislationFetchDetailData = (id, ownProps) => {
  return async dispatch => {
    dispatch({type: FETCH_LEGISLATION_DETAIL_REQUEST});
    try {
      const {data} = await axios.get(LEGISLATION_API_DETAIL(id));
      dispatch({type: FETCH_LEGISLATION_DETAIL_SUCCESS, payload: data.data.law});
    } catch (error) {
      dispatch({type: FETCH_LEGISLATION_DETAIL_FAILURE, payload: error.response.data.data});
    }
  }
}
