import React, {Component} from 'react';
import {connect} from 'react-redux';
import {withFormik} from 'formik';
import ModalSuccess from '../../../shared/ModalSuccess';
import {removeSuccessMessage} from '../../../../redux/actions/messageAction';
import {eventSchema} from '../../../../shared/validation';
import EventForm from '../EventForm';
import {addEventData} from '../../../../redux/actions/eventAction';

/**
 * @description AddLaw component
 */
class AddEvent extends Component {
  render() {
    return (
      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-12">
            <div className="whitebox"  id="legislation">
              <header className="whitebox-header bordered">
                <div className="whitebox-title ">
                  <h4>
                    Tədbir yarat
                  </h4>
                  <ul className="breadcrumps">
                    <li className="breadcrumps-item">Tədbirlər</li>
                    <li className="breadcrumps-item">Tədbir yarat</li>
                  </ul>
                </div>

              </header>
              <EventForm formProps={this.props}/>
              {this.props.success ? <ModalSuccess text="Yeni Tədbir əlavə edildi" /> : null }
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapPropsToValues = (props) => {
  return {
    title: '',
    place: '',
    note: '',
    start: '',
    end: ''
  }
}

const AddEventForm = withFormik({
  enableReinitialize: false,
  mapPropsToValues,
  validationSchema: eventSchema,
  validateOnBlur: true,
  validateOnChange: true,
  handleSubmit: (values, {props, resetForm, setSubmitting}) => {
    props.addEvent(values);

    setTimeout(() => {
      props.removeSuccess();
    }, 4000);
  }
})(AddEvent);

const mapDispatchToProps = (dispatch) => ({
  addEvent: (eventData) => dispatch(addEventData(eventData)),
  removeSuccess: () => dispatch(removeSuccessMessage())
});

const mapStateToProps = state => {
  return state.eventReducer;
};

export default connect(mapStateToProps, mapDispatchToProps)(AddEventForm);

