import React, {Component} from 'react';
import {connect} from 'react-redux';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import {fetchBannersWebsites} from '../../../redux/actions/bannerAction';

/**
 * @description Component Class for modal success
 */
class WebsiteBanner extends Component {
    state = {
      responsive:{
        0:{
          items:1
        },
        600:{
          items:3
        },
        1000:{
          items:5
        }
      },
      autoPlay: false
    }
    componentDidMount() {
      this.props.fetchBanner();
    }

  /**
   * @description set auto play when page loaded from mobile devices
   * @param event
   */
  owlInitialized = (event) => {
    if (event.page.size === 1 && !this.state.autoPlay) {
      this.setState({autoPlay: true})
    }
  }
  render() {
    const banners = this.props.bannersWebsites ? this.props.bannersWebsites.map((banner, i) => {
      return (
        <div key={i} className="jumbotron jumbotron-fluid">
          <a href={banner.url} target="_blank" rel="noopener noreferrer">
            <p className="text-center text-dark">{banner.title}</p>
          </a>
        </div>
      )
    }) : null
    return (
      <div className="websites-banner">
        <div className="container-fluid">
          {
            this.props.bannersWebsites ?
              <OwlCarousel
                loop
                dots={false}
                autoplay={this.state.autoPlay}
                responsive={this.state.responsive}
                onInitialized={this.owlInitialized}
              >
                {banners}
              </OwlCarousel> : null
          }
        </div>
      </div>
    )
  }
}

const mapDispatchToProps = (dispatch, ownProps) => ({
  fetchBanner: () => dispatch(fetchBannersWebsites(ownProps))
})

const mapStateToProps = state => {
  return state.bannerReducer;
}

export default connect(mapStateToProps, mapDispatchToProps)(WebsiteBanner);
