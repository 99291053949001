import {
    FETCH_KARABAKH_VISIT_REQUEST,
    FETCH_KARABAKH_VISIT_SUCCESS,
    FETCH_KARABAKH_VISIT_FAILURE,
    FETCH_KARABAKH_VISIT_DETAIL_REQUEST,
    FETCH_KARABAKH_VISIT_DETAIL_SUCCESS,
    FETCH_KARABAKH_VISIT_DETAIL_FAILURE,
    KARABAKH_VISIT_ADD_REQUEST,
    KARABAKH_VISIT_ADD_SUCCESS,
    KARABAKH_VISIT_ADD_FAILURE,
    KARABAKH_VISIT_UPDATE_REQUEST,
    KARABAKH_VISIT_UPDATE_SUCCESS,
    KARABAKH_VISIT_UPDATE_FAILURE,
    FETCH_KARABAKH_ABOUT_DETAIL_REQUEST,
    FETCH_KARABAKH_ABOUT_DETAIL_SUCCESS,
    FETCH_KARABAKH_ABOUT_DETAIL_FAILURE,
    KARABAKH_HERO_UPDATE_FAILURE,
    KARABAKH_HERO_UPDATE_SUCCESS,
    KARABAKH_HERO_UPDATE_REQUEST,
    KARABAKH_HERO_ADD_FAILURE,
    KARABAKH_HERO_ADD_SUCCESS,
    KARABAKH_HERO_ADD_REQUEST,
    FETCH_KARABAKH_HERO_DETAIL_FAILURE,
    FETCH_KARABAKH_HERO_DETAIL_SUCCESS,
    FETCH_KARABAKH_HERO_DETAIL_REQUEST,
    FETCH_KARABAKH_HERO_FAILURE,
    FETCH_KARABAKH_HERO_SUCCESS,
    FETCH_KARABAKH_HERO_REQUEST,
    KARABAKH_ABOUT_UPDATE_REQUEST,
    KARABAKH_ABOUT_UPDATE_SUCCESS,
    KARABAKH_ABOUT_UPDATE_FAILURE,
    FETCH_KARABAKH_VICTORY_DETAIL_REQUEST,
    FETCH_KARABAKH_VICTORY_DETAIL_SUCCESS,
    FETCH_KARABAKH_VICTORY_DETAIL_FAILURE,
    KARABAKH_VICTORY_UPDATE_REQUEST,
    KARABAKH_VICTORY_UPDATE_SUCCESS,
    KARABAKH_VICTORY_UPDATE_FAILURE,
    REMOVE_SUCCESS_MESSAGE,
    KARABAKH_ORGANIZATION_UPDATE_FAILURE,
    KARABAKH_ORGANIZATION_UPDATE_SUCCESS,
    KARABAKH_ORGANIZATION_UPDATE_REQUEST,
    KARABAKH_ORGANIZATION_ADD_FAILURE,
    KARABAKH_ORGANIZATION_ADD_SUCCESS,
    KARABAKH_ORGANIZATION_ADD_REQUEST,
    FETCH_KARABAKH_ORGANIZATION_DETAIL_FAILURE,
    FETCH_KARABAKH_ORGANIZATION_DETAIL_SUCCESS,
    FETCH_KARABAKH_ORGANIZATION_DETAIL_REQUEST,
    FETCH_KARABAKH_ORGANIZATION_FAILURE, FETCH_KARABAKH_ORGANIZATION_SUCCESS, FETCH_KARABAKH_ORGANIZATION_REQUEST, KARABAKH_MUNICIPALITIES_LIST_REQUEST, KARABAKH_MUNICIPALITIES_LIST_SUCCESS, KARABAKH_MUNICIPALITIES_LIST_FAILURE, KARABAKH_MUNICIPALITIES_SHOW_REQUEST, KARABAKH_MUNICIPALITIES_SHOW_SUCCESS, KARABAKH_MUNICIPALITIES_SHOW_FAILURE, KARABAKH_MUNICIPALITIES_ADD_REQUEST, KARABAKH_MUNICIPALITIES_ADD_SUCCESS, KARABAKH_MUNICIPALITIES_ADD_FAILURE, KARABAKH_MUNICIPALITIES_UPDATE_REQUEST, KARABAKH_MUNICIPALITIES_UPDATE_FAILURE, KARABAKH_MUNICIPALITIES_UPDATE_SUCCESS, KARABAKH_MUNICIPALITIES_DELETE_REQUEST, KARABAKH_MUNICIPALITIES_DELETE_SUCCESS, KARABAKH_MUNICIPALITIES_DELETE_FAILURE
} from '../types/actionTypes';
import {MUNICIPALITIES_LIST_API} from "../services/api.service";

/**
 * @description initial state for karabakhReducer
 */
const initialState = {};

/**
 * @description updates state depending on action data
 * @param {Object} state = initialState
 * @param {string} type = action.type
 * @param {Array} objects
 * @returns return state
 */
export default function karabakhReducer(state = initialState, {type, payload, meta}) {
    switch (type) {
        case FETCH_KARABAKH_VISIT_REQUEST:
            return {
                ...state,
                isFetching: true,
                visits: payload
            };
        case FETCH_KARABAKH_VISIT_SUCCESS:
            return {
                ...state,
                isFetching: false,
                visits: payload
            };
        case FETCH_KARABAKH_VISIT_FAILURE:
            return {
                ...state,
                isFetching: false,
                visits: payload
            };
        case FETCH_KARABAKH_VISIT_DETAIL_REQUEST:
            return {
                ...state,
                isFetching: true,
                visit: payload
            };
        case FETCH_KARABAKH_VISIT_DETAIL_SUCCESS:
            return {
                ...state,
                isFetching: false,
                visit: payload
            };
        case FETCH_KARABAKH_VISIT_DETAIL_FAILURE:
            return {
                ...state,
                isFetching: false,
                visit: payload
            };
        case KARABAKH_VISIT_ADD_REQUEST:
            return {
                ...state,
                isFetching: true,
                success: false
            };
        case KARABAKH_VISIT_ADD_SUCCESS:
            return {
                ...state,
                isFetching: false,
                visit: payload,
                success: true
            };
        case KARABAKH_VISIT_ADD_FAILURE:
            return {
                ...state,
                isFetching: false,
                visit: payload,
                success: false
            };
        case KARABAKH_VISIT_UPDATE_REQUEST:
            return {
                ...state,
                isFetching: true,
                success: false
            };
        case KARABAKH_VISIT_UPDATE_SUCCESS:
            return {
                ...state,
                isFetching: false,
                visit: payload,
                success: true
            };
        case KARABAKH_VISIT_UPDATE_FAILURE:
            return {
                ...state,
                isFetching: false,
                visit: payload,
                success: false
            };
        case FETCH_KARABAKH_HERO_REQUEST:
            return {
                ...state,
                isFetching: true,
                heroes: payload
            };
        case FETCH_KARABAKH_HERO_SUCCESS:
            return {
                ...state,
                isFetching: false,
                heroes: payload
            };
        case FETCH_KARABAKH_HERO_FAILURE:
            return {
                ...state,
                isFetching: false,
                heroes: payload
            };
        case FETCH_KARABAKH_HERO_DETAIL_REQUEST:
            return {
                ...state,
                isFetching: true,
                hero: payload
            };
        case FETCH_KARABAKH_HERO_DETAIL_SUCCESS:
            return {
                ...state,
                isFetching: false,
                hero: payload
            };
        case FETCH_KARABAKH_HERO_DETAIL_FAILURE:
            return {
                ...state,
                isFetching: false,
                hero: payload
            };
        case KARABAKH_HERO_ADD_REQUEST:
            return {
                ...state,
                isFetching: true,
                success: false
            };
        case KARABAKH_HERO_ADD_SUCCESS:
            return {
                ...state,
                isFetching: false,
                hero: payload,
                success: true
            };
        case KARABAKH_HERO_ADD_FAILURE:
            return {
                ...state,
                isFetching: false,
                hero: payload,
                success: false
            };
        case KARABAKH_HERO_UPDATE_REQUEST:
            return {
                ...state,
                isFetching: true,
                success: false
            };
        case KARABAKH_HERO_UPDATE_SUCCESS:
            return {
                ...state,
                isFetching: false,
                hero: payload,
                success: true
            };
        case KARABAKH_HERO_UPDATE_FAILURE:
            return {
                ...state,
                isFetching: false,
                hero: payload,
                success: false
            };
        case FETCH_KARABAKH_ABOUT_DETAIL_REQUEST:
            return {
                ...state,
                isFetching: true,
                about: payload
            };
        case FETCH_KARABAKH_ABOUT_DETAIL_SUCCESS:
            return {
                ...state,
                isFetching: false,
                about: payload
            };
        case FETCH_KARABAKH_ABOUT_DETAIL_FAILURE:
            return {
                ...state,
                isFetching: false,
                about: payload
            };

        case KARABAKH_ABOUT_UPDATE_REQUEST:
            return {
                ...state,
                isFetching: true,
                success: false
            };
        case KARABAKH_ABOUT_UPDATE_SUCCESS:
            return {
                ...state,
                isFetching: false,
                about: payload,
                success: true
            };
        case KARABAKH_ABOUT_UPDATE_FAILURE:
            return {
                ...state,
                isFetching: false,
                about: payload,
                success: false
            };
        case FETCH_KARABAKH_VICTORY_DETAIL_REQUEST:
            return {
                ...state,
                isFetching: true,
                victory: payload
            };
        case FETCH_KARABAKH_VICTORY_DETAIL_SUCCESS:
            return {
                ...state,
                isFetching: false,
                victory: payload
            };
        case FETCH_KARABAKH_VICTORY_DETAIL_FAILURE:
            return {
                ...state,
                isFetching: false,
                victory: payload
            };

        case KARABAKH_VICTORY_UPDATE_REQUEST:
            return {
                ...state,
                isFetching: true,
                success: false
            };
        case KARABAKH_VICTORY_UPDATE_SUCCESS:
            return {
                ...state,
                isFetching: false,
                victory: payload,
                success: true
            };
        case KARABAKH_VICTORY_UPDATE_FAILURE:
            return {
                ...state,
                isFetching: false,
                victory: payload,
                success: false
            };
        case FETCH_KARABAKH_ORGANIZATION_REQUEST:
            return {
                ...state,
                isFetching: true,
                organizations: payload
            };
        case FETCH_KARABAKH_ORGANIZATION_SUCCESS:
            return {
                ...state,
                isFetching: false,
                organizations: payload
            };
        case FETCH_KARABAKH_ORGANIZATION_FAILURE:
            return {
                ...state,
                isFetching: false,
                organizations: payload
            };
        case FETCH_KARABAKH_ORGANIZATION_DETAIL_REQUEST:
            return {
                ...state,
                isFetching: true,
                organization: payload
            };
        case FETCH_KARABAKH_ORGANIZATION_DETAIL_SUCCESS:
            return {
                ...state,
                isFetching: false,
                organization: payload
            };
        case FETCH_KARABAKH_ORGANIZATION_DETAIL_FAILURE:
            return {
                ...state,
                isFetching: false,
                organization: payload
            };
        case KARABAKH_ORGANIZATION_ADD_REQUEST:
            return {
                ...state,
                isFetching: true,
                success: false
            };
        case KARABAKH_ORGANIZATION_ADD_SUCCESS:
            return {
                ...state,
                isFetching: false,
                organization: payload,
                success: true
            };
        case KARABAKH_ORGANIZATION_ADD_FAILURE:
            return {
                ...state,
                isFetching: false,
                organization: payload,
                success: false
            };
        case KARABAKH_ORGANIZATION_UPDATE_REQUEST:
            return {
                ...state,
                isFetching: true,
                success: false
            };
        case KARABAKH_ORGANIZATION_UPDATE_SUCCESS:
            return {
                ...state,
                isFetching: false,
                organization: payload,
                success: true
            };
        case KARABAKH_ORGANIZATION_UPDATE_FAILURE:
            return {
                ...state,
                isFetching: false,
                organization: payload,
                success: false
            };


        case KARABAKH_MUNICIPALITIES_LIST_REQUEST:
            return {
                ...state,
                isFetching: true,
                municipalityNews: payload
            };
        case KARABAKH_MUNICIPALITIES_LIST_SUCCESS:
            return {
                ...state,
                isFetching: false,
                municipalityNews: payload
            };
        case KARABAKH_MUNICIPALITIES_LIST_FAILURE:
            return {
                ...state,
                isFetching: false,
                municipalityNews: payload
            };
        case KARABAKH_MUNICIPALITIES_SHOW_REQUEST:
            return {
                ...state,
                isFetching: true,
                municipalityNewsDetail: payload
            };
        case KARABAKH_MUNICIPALITIES_SHOW_SUCCESS:
            return {
                ...state,
                isFetching: false,
                municipalityNewsDetail: payload
            };
        case KARABAKH_MUNICIPALITIES_SHOW_FAILURE:
            return {
                ...state,
                isFetching: false,
                municipalityNewsDetail: payload
            };
        case KARABAKH_MUNICIPALITIES_ADD_REQUEST:
            return {
                ...state,
                isFetching: true,
                success: false
            };
        case KARABAKH_MUNICIPALITIES_ADD_SUCCESS:
            return {
                ...state,
                isFetching: false,
                municipalityNewsDetail: payload,
                success: true
            };
        case KARABAKH_MUNICIPALITIES_ADD_FAILURE:
            return {
                ...state,
                isFetching: false,
                municipalityNewsDetail: payload,
                success: false
            };
        case KARABAKH_MUNICIPALITIES_UPDATE_REQUEST:
            return {
                ...state,
                isFetching: true,
                success: false
            };
        case KARABAKH_MUNICIPALITIES_UPDATE_SUCCESS:
            return {
                ...state,
                isFetching: false,
                municipalityNewsDetail: payload,
                success: true
            };
        case KARABAKH_MUNICIPALITIES_UPDATE_FAILURE:
            return {
                ...state,
                isFetching: false,
                municipalityNewsDetail: payload,
                success: false
            };
        case KARABAKH_MUNICIPALITIES_DELETE_REQUEST:
            return {
                ...state,
                isFetching: true,
                success: false
            };
        case KARABAKH_MUNICIPALITIES_DELETE_SUCCESS:
            return {
                ...state,
                isFetching: false,
                municipalityNewsDetail: payload,
                success: true
            };
        case KARABAKH_MUNICIPALITIES_DELETE_FAILURE:
            return {
                ...state,
                isFetching: false,
                municipalityNewsDetail: payload,
                success: false
            };
        case REMOVE_SUCCESS_MESSAGE:
            return {
                ...state,
                success: false
            };
        default:
            return state
    }
}
