import React from 'react';
import {NavLink} from 'react-router-dom';
import {ModalContext} from '../../context/context';

/**
 * @description Component Class for modal success
 */
const InfoModal = (props) =>

  <div className="modal-custom">
    <div className="container-fluid">
      <div className="row">
        <div className="col-xl-4 offset-xl-4">
          <div className="modal-custom-inner">

            <p className="modal-info-text">
              Giris etmek lazimdir
            </p>

            <div className="d-flex  justify-content-end">
              <NavLink
                to='/login'
                className="btn btn-theme d-flex align-items-center"
              >
                Giris
              </NavLink>
              <ModalContext.Consumer>
                {(context) => (
                  <button
                    className="btn btn-default"
                    onClick={() => context.showInfoModal(false)}
                  >
                    Legv et
                  </button>
                )}
              </ModalContext.Consumer>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

export default InfoModal;
