import React, {Component} from 'react';
import AuthHeader from './AuthHeader';
import {connect} from 'react-redux';
import {Formik} from 'formik';
import {registerSchema} from '../../shared/validation';
import ButtonLoader from '../loader/ButtonLoader';
import {userSignUpFetch} from '../../redux/actions/userAction';
import {isUnAuthPublicEnabled} from '../../redux/services/auth.service';
import {Redirect} from 'react-router-dom';
import {handleInputError} from '../../shared/helpers';
import ReCAPTCHA from "react-google-recaptcha";
/**
 * @description Component Class for SignUp
 */
class SignUp extends Component {
  state = {
    hasError: "",
    userData:{
      name: '',
      email: '',
      phone: '',
      password: '',
      password_confirmation: '',
      recaptcha:  ""
    }
  }

  /**
   * @description handles backend error on key event (sets null)
   * @memberOf SignUp
   */
  handleLoginKeyUp = () => {
    if (this.props.user && this.props.user.error) {
      this.props.user.error = null
    }
  }

  /**
   * @description for Formik usable form management for react
   * @returns Form
   * @memberOf Login
   */
  render() {
    if (!isUnAuthPublicEnabled()) {
      return <Redirect to='/login' />
    }
    return  (
      <section className="auth-wrapper">
        <AuthHeader/>
        <div className="col-lg-5 offset-lg-7 p-0">
          <div className="auth-box">
            <div className="auth-form-wrapper">
              <div className="d-flex align-items-center justify-content-between mb-5">
                <h5 className="auth-box-title">Qeydiyyat</h5>
              </div>
              <Formik
                initialValues={{
                  name: '',
                  email: '',
                  phone: '',
                  password: '',
                  password_confirmation: '',
                  recaptcha:  ""
                }}
                validationSchema={registerSchema}
                onSubmit={(userData) => {
                  const data = {...this.state.userData, name: userData.name, email: userData.email, phone: userData.phone, password:userData.password, password_confirmation: userData.password_confirmation };
                  this.setState(data);
                  this.props.userSignUp(data);
                }}
              >
                {props => (
                  <form onSubmit={props.handleSubmit}>
                    <div className="form-group form-group-custom">
                      <label htmlFor="username">Ad Soyad</label>
                      <input
                        type="text"
                        name="name"
                        value={props.values.name}
                        onChange={props.handleChange}
                        onBlur={props.handleBlur}
                        onKeyDown={this.handleLoginKeyUp}
                        className={'form-control form-control-custom' + ((props.touched.name && props.errors.name) || (this.props.user && this.props.user.error && this.props.user.data.name) ? ' has-error' : null)}
                        placeholder="Ad Soyad daxil edin"
                      />
                      <p className="error">
                        {(props.touched.name && props.errors.name)
                          ? <span>{props.errors.name}</span>
                          : null}

                        {(this.props.user && this.props.user.error && this.props.user.data.name)
                          ? this.props.user.data.name.map(error => <p className="error" key={error}>{error}</p>)
                          : null}
                      </p>

                    </div>
                    <div className="form-group form-group-custom">
                      <label htmlFor="username">Elektron poçt</label>
                      <input
                        type="email"
                        name="email"
                        value={props.values.email}
                        onChange={props.handleChange}
                        onBlur={props.handleBlur}
                        onKeyDown={this.handleLoginKeyUp}
                        className={'form-control form-control-custom' + ((props.touched.email && props.errors.email) || (this.props.user && this.props.user.error && this.props.user.data.email) ? ' has-error' : null)}
                        placeholder="Elektron poçt ünvanı daxil edin"
                      />
                      <p className="error">
                        {(props.touched.email && props.errors.email)
                          ? <span>{props.errors.email}</span>
                          : null}

                        {(this.props.user && this.props.user.error && this.props.user.data.email)
                          ? this.props.user.data.email.map(error => <p className="error" key={error}>{error}</p>)
                          : null}
                      </p>

                    </div>

                    <div className="form-group form-group-custom">
                      <label htmlFor="phone">Telefon</label>
                      <input
                        type="text"
                        name="phone"
                        value={props.values.phone}
                        onChange={props.handleChange}
                        onBlur={props.handleBlur}
                        onKeyDown={this.handleLoginKeyUp}
                        className={'form-control form-control-custom' + ((props.touched.phone && props.errors.phone) || (this.props.user && this.props.user.error && this.props.user.data.phone) ? ' has-error' : null)}
                        placeholder="Telefon nömrısini daxil edin"
                      />
                      {handleInputError('phone', this.props.user, props)}
                    </div>
                    <div className="form-group form-group-custom">
                      <label htmlFor="password">Şifrə</label>
                      <input
                        type="password"
                        name="password"
                        value={props.values.password}
                        onChange={props.handleChange}
                        onBlur={props.handleBlur}
                        onKeyDown={this.handleLoginKeyUp}
                        className={'form-control form-control-custom' + ((props.touched.password && props.errors.password) || (this.props.user && this.props.user.error && this.props.user.data.password) ? ' has-error' : null)}
                        placeholder="Şifrəni daxil edin"
                      />
                      <p className="error">
                        {(props.touched.password && props.errors.password)
                          ? <span>{props.errors.password}</span>
                          : null}

                        {(this.props.user && this.props.user.error && this.props.user.data.password)
                          ? this.props.user.data.password.map(error => <p className="error" key={error}>{error}</p>)
                          : null}
                      </p>
                    </div>
                    <div className="form-group form-group-custom">
                      <label htmlFor="password">Şifrənin təkrarı</label>
                      <input
                        type="password"
                        name="password_confirmation"
                        value={props.values.password_confirmation}
                        onChange={props.handleChange}
                        onBlur={props.handleBlur}
                        onKeyDown={this.handleLoginKeyUp}
                        className={'form-control form-control-custom' + ((props.touched.password_confirmation && props.errors.password_confirmation) || (this.props.user && this.props.user.error && this.props.user.data.password_confirmation) ? ' has-error' : null)}
                        placeholder="Şifrəni təkrar daxil edin"
                      />
                      <p className="error">
                        {(props.touched.password_confirmation && props.errors.password_confirmation)
                          ? <span>{props.errors.password_confirmation}</span>
                          : null}

                        {(this.props.user && this.props.user.error && this.props.user.data.password_confirmation)
                          ? this.props.user.data.password_confirmation.map(error => <p className="error" key={error}>{error}</p>)
                          : null}
                      </p>
                    </div>

                    <div className="form-group form-group-custom">
                    <ReCAPTCHA  onChange={event => {
                      this.setState({userData:{...this.state.userData, recaptcha:event}},function(){
                        console.log(this.state);
                      })
                    }} name="g-recaptcha" class="g-recaptcha"  sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}/>
                      <button
                        type="submit"
                        className="btn btn-theme w-100 mt-2 mt-md-5"
                        disabled={this.props.isFetching ? true : null}
                      >
                        {this.props.isFetching ? <ButtonLoader /> : <span>qeydiyyat</span>}
                      </button>
                    </div>

                  </form>
                )}
              </Formik>
            </div>
          </div>
        </div>
      </section>
    );
  }
}
const mapDispatchToProps = (dispatch, ownProps) => ({
  userSignUp: userData => dispatch(userSignUpFetch(userData, ownProps))
})

const mapStateToProps = state => {
  return state.userReducer;
}

export default connect(mapStateToProps, mapDispatchToProps)(SignUp);
