import React, {Component} from 'react';
import {withFormik} from 'formik';
import {connect} from 'react-redux';
import CloseModal from '../../../shared/CloseModal';
import {ModalContext} from '../../../../context/context';
import AppealForm from '../AppealForm';
import {appealFetchDetail} from '../../../../redux/actions/appealAction';

/**
 * @description Component Class for EditAppealForm new object
 */
class EditAppealForm extends Component {
  static contextType = ModalContext
  state = {
    appealDetails: [],
    selectedUsers: []
  }

  render() {
    return (
      <div className="modal-custom">
        <div className="container-fluid">
          <div className="row">
            <div className="col-xl-4 offset-xl-4">
              <div className="modal-custom-inner">
                <div
                  className="modal-custom-header d-flex align-items-center justify-content-between">
                  <h4>
                  Redaktə et
                  </h4>
                  <CloseModal modal="showAppealEditModal"/>
                </div>
                <AppealForm formProps={this.props}/>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapPropsToValues = (props) => {
  return {
    id: '',
    status: '',
    assignor: [],
    description: ''
  }
}
const EditAppeal = withFormik({
  enableReinitialize: false,
  mapPropsToValues,
  validateOnBlur: true,
  validateOnChange: true,
  handleSubmit: (values, {props, setSubmitting}) => {
    props.editObject(values);

    setTimeout(() => {
      props.removeSuccess();
    }, 4000);
  }
})(EditAppealForm);

const mapDispatchToProps = (dispatch, ownProps) => ({
  appealFetchDetail: (paramId) => dispatch(appealFetchDetail(paramId, ownProps))
})

const mapStateToProps = state => {
  return state.appealReducer;
}

export default connect(mapStateToProps, mapDispatchToProps)(EditAppeal);
