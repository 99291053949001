import React, {Component} from 'react';
import {connect} from 'react-redux';
import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
import interactionPlugin from '@fullcalendar/interaction'; // needed for dayClick
import '@fullcalendar/core/main.css';
import '@fullcalendar/daygrid/main.css';
import '../../../assets/styles/_calendar.scss'
import {isUnAuthPublicEnabled, isAuthenticated} from '../../../redux/services/auth.service';
import {Redirect, NavLink} from 'react-router-dom';
import {fetchEventsData} from '../../../redux/actions/eventAction';
import Loader from '../../shared/Loader';
import ReactTooltip from 'react-tooltip'
import {checkBlock} from '../../../shared/checkBlocks';
import {ModalContext} from '../../../context/context';

/**
 * @description EventCalendar component
 */
class EventCalendar extends Component {
  static contextType = ModalContext;
  state = {
    events: []
  }
  componentDidMount() {
    this.props.fetchEvents();
  }

  handleDateClick = (info) => { // bind with an arrow function
    this.context.showEventDetailModal(true, info);
    return;
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.events.removed) {
      this.props.fetchEvents();
    }
  }

  render() {
    if (!isUnAuthPublicEnabled()) {
      return <Redirect to='/login' />
    }

    return (
      <div className="container-fluid">
        <div className="row">

          <div className="col-lg-12">
            <div className="whitebox">
              <header className="whitebox-header bordered d-flex align-items-center justify-content-between">
                <div className="whitebox-title">
                  <h4>
                    Tədbirlər
                  </h4>
                </div>
                {isAuthenticated() && checkBlock('events', 'event-add') ?
                  <NavLink
                    to={'/events/new/add'}
                    exact
                    className="btn btn-theme d-flex align-items-center"
                    activeClassName="active"
                  >
                    <span className="d-none d-lg-block">Tədbir əlavə edin</span>
                    <i className="icon icon-plus" />
                  </NavLink> : null }
              </header>

              <section className="whitebox-body">

                {this.props.events.events ?
                  <FullCalendar
                    defaultView="dayGridMonth"
                    plugins={[ dayGridPlugin, interactionPlugin ]}
                    events={this.props.events}
                    eventClick={this.handleDateClick}
                  /> : <Loader />}
                <ReactTooltip/>
              </section>

            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  fetchEvents: () => dispatch(fetchEventsData())
});

const mapStateToProps = state => {
  return {
    events: state.eventReducer
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(EventCalendar);
