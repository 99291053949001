import axios from 'axios';
import {
  FETCH_VACANCY_REQUEST,
  FETCH_VACANCY_SUCCESS,
  FETCH_VACANCY_FAILURE,
  ADD_VACANCY_REQUEST,
  ADD_VACANCY_SUCCESS,
  ADD_VACANCY_FAILURE,
  FETCH_VACANCY_DETAIL_REQUEST,
  FETCH_VACANCY_DETAIL_SUCCESS,
  FETCH_VACANCY_DETAIL_FAILURE,
  FETCH_VACANCY_CATEGORY_REQUEST,
  FETCH_VACANCY_CATEGORY_SUCCESS,
  FETCH_VACANCY_CATEGORY_FAILURE,
  FETCH_CITY_REQUEST,
  FETCH_CITY_SUCCESS,
  FETCH_CITY_FAILURE,
  FETCH_EXPERIENCE_REQUEST,
  FETCH_EXPERIENCE_SUCCESS,
  FETCH_EXPERIENCE_FAILURE,
  FETCH_EDUCATION_REQUEST,
  FETCH_EDUCATION_SUCCESS,
  FETCH_EDUCATION_FAILURE,
  FETCH_RESUME_REQUEST,
  FETCH_RESUME_SUCCESS,
  FETCH_RESUME_FAILURE,
  ADD_RESUME_REQUEST,
  ADD_RESUME_SUCCESS,
  ADD_RESUME_FAILURE,
  FETCH_RESUME_DETAIL_REQUEST,
  FETCH_RESUME_DETAIL_SUCCESS,
  FETCH_RESUME_DETAIL_FAILURE,
  EDIT_VACANCY_REQUEST,
  EDIT_VACANCY_SUCCESS,
  EDIT_VACANCY_FAILURE
} from '../types/actionTypes';
import {
  VACANCY_API,
  VACANCY_API_DETAIL,
  VACANCY_CATEGORY_API,
  CITY_API,
  EXPERIENCE_API,
  EDUCATION_API,
  RESUME_API,
  RESUME_API_DETAIL,
  RESUME_API_BY_ID
} from '../services/api.service';

/**
 * @description fetch all vacancies
 */
export const vacancyFetchData = (paramId, ownProps) => {
  return async dispatch => {
    dispatch({type: FETCH_VACANCY_REQUEST});
    try {
      const {data} = await axios.get(VACANCY_API, {
        params: {
          category_id: Number(paramId) || null
        }
      });
      dispatch({type: FETCH_VACANCY_SUCCESS, payload: data.data.vacancies});
    } catch (error) {
      dispatch({type: FETCH_VACANCY_FAILURE, payload: error});
    }
  }
}

/**
 * @description fetch  vacancy detail
 * @param  {Number} paramId
 */
export const vacancyFetchDetail = (paramId, ownProps) => {
  return async dispatch => {
    dispatch({type: FETCH_VACANCY_DETAIL_REQUEST});
    try {
      const {data} = await axios.get(VACANCY_API_DETAIL(paramId));
      dispatch({type: FETCH_VACANCY_DETAIL_SUCCESS, payload: data.data.vacancy});
    } catch (error) {
      dispatch({type: FETCH_VACANCY_DETAIL_FAILURE, payload: error});
    }
  }
}

/**
 * @description fetch all cities
 */
export const cityFetchData = (ownProps) => {
  return async dispatch => {
    dispatch({type: FETCH_CITY_REQUEST});
    try {
      const {data} = await axios.get(CITY_API);
      dispatch({type: FETCH_CITY_SUCCESS, payload: data.data.cities});
    } catch (error) {
      dispatch({type: FETCH_CITY_FAILURE, payload: error});
    }
  }
}

/**
 * @description fetch all experience
 */
export const experienceFetchData = (ownProps) => {
  return async dispatch => {
    dispatch({type: FETCH_EXPERIENCE_REQUEST});
    try {
      const {data} = await axios.get(EXPERIENCE_API);
      dispatch({type: FETCH_EXPERIENCE_SUCCESS, payload: data.data.experiences});
    } catch (error) {
      dispatch({type: FETCH_EXPERIENCE_FAILURE, payload: error});
    }
  }
}

/**
 * @description fetch all education
 */
export const educationFetchData = (ownProps) => {
  return async dispatch => {
    dispatch({type: FETCH_EDUCATION_REQUEST});
    try {
      const {data} = await axios.get(EDUCATION_API);
      dispatch({type: FETCH_EDUCATION_SUCCESS, payload: data.data.educations});
    } catch (error) {
      dispatch({type: FETCH_EDUCATION_FAILURE, payload: error});
    }
  }
}

/**
 * @description fetch all representayives's notes
 */
export const vacancyCategoryFetchData = (ownProps) => {
  return async dispatch => {
    dispatch({type: FETCH_VACANCY_CATEGORY_REQUEST});
    try {
      const {data} = await axios.get(VACANCY_CATEGORY_API);
      dispatch({type: FETCH_VACANCY_CATEGORY_SUCCESS, payload: data.data.vacancyCategories});
    } catch (error) {
      dispatch({type: FETCH_VACANCY_CATEGORY_FAILURE, payload: error});
    }
  }
}

/**
 * @description post vacancy
 */
export const vacancyPostData = (vacancyData, ownProps) => {
  return async dispatch => {
    dispatch({type: ADD_VACANCY_REQUEST});
    try {
      const {data} = await axios.post(VACANCY_API, {
        vacancy_category_id:  Number(vacancyData.vacancy_category_id),
        profession:             vacancyData.profession,
        city_id:                Number(vacancyData.city_id),
        min_salary:             Number(vacancyData.min_salary),
        max_salary:             Number(vacancyData.max_salary),
        min_age:                Number(vacancyData.min_age),
        max_age:                Number(vacancyData.max_age),
        education_id:           Number(vacancyData.education_id),
        experience_id:          Number(vacancyData.experience_id),
        related_person:         vacancyData.related_person,
        contact_number:         vacancyData.contact_number,
        email:                  vacancyData.email,
        company_name:           vacancyData.company_name,
        candidate_requirements: vacancyData.candidate_requirements,
        more_info:              vacancyData.more_info
      });
      dispatch({type: ADD_VACANCY_SUCCESS, payload: data.data.vacancies});
    } catch (error) {
      dispatch({type: ADD_VACANCY_FAILURE, payload: error.response.data});
    }
  }
}

/**
 * @description post vacancy
 */
export const vacancyEditData = (vacancyData, ownProps) => {
  return async dispatch => {
    dispatch({type: EDIT_VACANCY_REQUEST});
    try {
      const {data} = await axios.put(VACANCY_API_DETAIL(vacancyData.id), {
        vacancy_category_id:  Number(vacancyData.vacancy_category_id),
        profession:             vacancyData.profession,
        city_id:                Number(vacancyData.city_id),
        min_salary:             Number(vacancyData.min_salary),
        max_salary:             Number(vacancyData.max_salary),
        min_age:                Number(vacancyData.min_age),
        max_age:                Number(vacancyData.max_age),
        education_id:           Number(vacancyData.education_id),
        experience_id:          Number(vacancyData.experience_id),
        related_person:         vacancyData.related_person,
        contact_number:         vacancyData.contact_number,
        email:                  vacancyData.email,
        company_name:           vacancyData.company_name,
        candidate_requirements: vacancyData.candidate_requirements,
        more_info:              vacancyData.more_info
      });
      dispatch({type: EDIT_VACANCY_SUCCESS, payload: data.data.vacancies});
    } catch (error) {
      dispatch({type: EDIT_VACANCY_FAILURE, payload: error});
    }
  }
}

/**
 * @description post resume
 */
export const resumePostData = (resumeData, ownProps) => {
  return async dispatch => {
    dispatch({type: ADD_RESUME_REQUEST});
    try {
      const {data} = await axios.post(RESUME_API, {
        vacancy_category_id:  Number(resumeData.vacancy_category_id),
        profession:             resumeData.profession,
        fullname:               resumeData.fullname,
        city_id:                Number(resumeData.city_id),
        min_salary:             Number(resumeData.min_salary),
        max_salary:             Number(resumeData.max_salary),
        age:                    Number(resumeData.age),
        education_id:           Number(resumeData.education_id),
        experience_id:          Number(resumeData.experience_id),
        gender:                 Number(resumeData.gender),
        contact_number:         resumeData.contact_number,
        email:                  resumeData.email,
        ability:                resumeData.ability,
        more_info:              resumeData.more_info,
        image:                  resumeData.image
      });
      dispatch({type: ADD_RESUME_SUCCESS, payload: data.data.resumes});
    } catch (error) {
      dispatch({type: ADD_RESUME_FAILURE, payload: error.response.data});
    }
  }
}

/**
 * @description post resume
 */
export const resumeUpdateData = (resumeData, ownProps) => {
  return async dispatch => {
    dispatch({type: ADD_RESUME_REQUEST});
    try {
      const {data} = await axios.put(RESUME_API_BY_ID(resumeData.id), {
        vacancy_category_id:  Number(resumeData.vacancy_category_id),
        profession:             resumeData.profession,
        fullname:               resumeData.fullname,
        city_id:                Number(resumeData.city_id),
        min_salary:             Number(resumeData.min_salary),
        max_salary:             Number(resumeData.max_salary),
        age:                    Number(resumeData.age),
        education_id:           Number(resumeData.education_id),
        experience_id:          Number(resumeData.experience_id),
        gender:                 Number(resumeData.gender),
        contact_number:         resumeData.contact_number,
        email:                  resumeData.email,
        ability:                resumeData.ability,
        more_info:              resumeData.more_info,
        image:                  resumeData.image
      });
      dispatch({type: ADD_RESUME_SUCCESS, payload: data.data.resumes});
    } catch (error) {
      dispatch({type: ADD_RESUME_FAILURE, payload: error.response.data});
    }
  }
}

/**
 * @description fetch all resumes
 */
export const resumeFetchData = (ownProps) => {
  return async dispatch => {
    dispatch({type: FETCH_RESUME_REQUEST});
    try {
      const {data} = await axios.get(RESUME_API);
      dispatch({type: FETCH_RESUME_SUCCESS, payload: data.data.jobSeekers});
    } catch (error) {
      dispatch({type: FETCH_RESUME_FAILURE, payload: error});
    }
  }
}

/**
 * @description fetch all resumes
 */
export const resumeFetchDetail = (id) => {
  return async dispatch => {
    dispatch({type: FETCH_RESUME_DETAIL_REQUEST});
    try {
      const {data} = await axios.get(RESUME_API_DETAIL(id));
      dispatch({type: FETCH_RESUME_DETAIL_SUCCESS, payload: data.data.jobSeeker});
    } catch (error) {
      dispatch({type: FETCH_RESUME_DETAIL_FAILURE, payload: error});
    }
  }
}
