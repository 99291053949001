import {
  LOGIN_REQUEST,
  LOGIN_SUCCESS,
  LOGIN_FAILURE,
  LOGOUT_FAILURE,
  LOGOUT_SUCCESS,
  LOGOUT_REQUEST,
  FETCH_USERS_REQUEST,
  FETCH_USERS_SUCCESS,
  FETCH_USERS_FAILURE,
  SIGN_UP_REQUEST,
  SIGN_UP_SUCCESS,
  SIGN_UP_FAILURE
} from '../types/actionTypes';

/**
 * @description initial state for loginReducer
 */
const initialState = {};

/**
 * @description updates state depending on action data
 * @param {Object} state = initialState
 * @param {string} type = action.type
 * @param {} payload
 * @param {boolean} authenticated
 * @param {false} authenticated
 * @returns return state
 */
export default function userReducer(state = initialState, {type, payload}) {
  switch (type) {
  case LOGIN_REQUEST:
    return {
      ...state,
      authenticated: false,
      isFetching: true,
      user: payload
    };
  case LOGIN_SUCCESS:
    return {
      ...state,
      authenticated: true,
      isFetching: false,
      user: payload
    };
  case LOGIN_FAILURE:
    return {
      ...state,
      authenticated: false,
      isFetching: false,
      user: payload
    };
  case SIGN_UP_REQUEST:
    return {
      ...state,
      success: false,
      isFetching: true,
      user: payload
    };
  case SIGN_UP_SUCCESS:
    return {
      ...state,
      success: true,
      isFetching: false,
      user: payload
    };
  case SIGN_UP_FAILURE:
    return {
      ...state,
      success: false,
      isFetching: false,
      user: payload
    };
  case LOGOUT_REQUEST:
    return {
      ...state,
      authenticated: true,
      isFetching: true,
      user: payload
    };
  case LOGOUT_SUCCESS:
    return {
      ...state,
      authenticated: false,
      isFetching: false,
      user: payload
    };
  case LOGOUT_FAILURE:
    return {
      ...state,
      authenticated: true,
      isFetching: true,
      user: payload
    };

  case FETCH_USERS_REQUEST:
    return {
      ...state,
      isFetching: true,
      users: payload
    };
  case FETCH_USERS_SUCCESS:
    return {
      ...state,
      isFetching: false,
      users: payload
    };
  case FETCH_USERS_FAILURE:
    return {
      ...state,
      isFetching: false,
      users: payload
    };
  default:
    return state
  }
}
