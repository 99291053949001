import React, {Component} from 'react';
import {NavLink} from 'react-router-dom';
import {connect} from 'react-redux';
import {objectFetchData} from '../../../../redux/actions/objectAction';
import OpenModal from '../../../shared/OpenModal';
import moment from 'moment';
import {isAuthenticated} from '../../../../redux/services/auth.service';
import {checkBlock} from '../../../../shared/checkBlocks';
import Loader from '../../../shared/Loader';
import {DELETE_OBJECT_FAILURE, DELETE_OBJECT_REQUEST, DELETE_OBJECT_SUCCESS} from '../../../../redux/types/actionTypes';
import {OBJECT_API} from '../../../../redux/services/api.service';
import {deleteAction} from '../../../../redux/actions/deleteAction';
import Pagination from '../../pagination/Pagination';

/**
 * @description AllObjects component
 */
class AllObjects extends Component {
  state = {
    categoryTitle: '',
    objects: [],
    params: {
      page: 1,
      pageCount: 1,
      perPage: 10,
      statusId: 0,
      categoryId: 0
    }
  };

  removeObject = (ids) => {
    const idsArray = [];
    const types =
    {
      request: DELETE_OBJECT_REQUEST,
      success: DELETE_OBJECT_SUCCESS,
      failure: DELETE_OBJECT_FAILURE
    };
    const api = OBJECT_API;
    idsArray.push(ids);
    this.props.deleteObject(idsArray, types, api);

    document.getElementById(`object${ids}`).remove();
  }

  componentDidMount() {
    const {id} = this.props.match.params
    this.handlePaginationState({categoryId: parseInt(id)});
    this.fetchData();

    let categories = JSON.parse(localStorage.getItem('object_categories'));
    categories = categories.filter((category) => category.id === Number(id));
    this.setState({categoryTitle: categories[0].name});
  }

  callBack = (page) => {
    this.handlePaginationState({page: page})

    this.fetchData()
  }

  handlePaginationState = (params) => {
    let state = this.state;

    Object.keys(params).forEach((key, value) => {
      state['params'][key] = params[key]
    })

    this.setState(state);
  }

  fetchData = () => {
    let params = {
      page: this.state.params.page,
      per_page: this.state.params.perPage
    };

    if (this.state.params.categoryId) {
      params['category_id'] = this.state.params.categoryId
    }

    this.props.fetchObject(params).then(() => {
      if (this.props.meta) {
        let meta = this.props.meta;
        this.handlePaginationState({
          perPage: parseInt(meta.per_page),
          page: meta.current_page,
          pageCount: meta.last_page
        });
      }
    });
  }

  render() {
    const objects = this.props.objects ? this.props.objects.map(object => {
      return (
        <tr key={object.id} id={`object${object.id}`}>
          <td className="d-flex align-items-center">
            <span className="company-logo mr-2"/>
            {object.building.name}
          </td>
          <td>{object.building.address}</td>
          <td>{object.building.current_situation}</td>
          <td>{moment(object.building.construction_date).format('YYYY-MM-DD')}</td>
          <td>
            <div className="d-flex align-center justify-content-end">
              <NavLink
                to={'/objects/' + object.id}
                exact
                className="btn btn-rounded"
              >
                <i className="icon icon-detail" />
              </NavLink>
              {isAuthenticated() && checkBlock('objects', 'object-delete') ?
                <button
                  type="button"
                  className="btn btn-rounded"
                  onClick={() => this.removeObject(object.id)}
                >
                  <i className="icon icon-delete-2 mr-1"/>
                </button>
                : null }
            </div>
          </td>
        </tr>
      )
    }) : null

    return (
      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-12">
            <div className="whitebox">
              <header className="whitebox-header bordered d-md-flex align-items-center justify-content-between">
                <div className="whitebox-title">
                  <h4>{this.state.categoryTitle}</h4>
                  <ul className="breadcrumps mb-3 mb-md-0">
                    <li className="breadcrumps-item">Obyektlər</li>
                    <li className="breadcrumps-item">{this.state.categoryTitle}</li>
                  </ul>
                </div>

                {isAuthenticated() && checkBlock('objects', 'object-add') ?
                  <OpenModal modal="showObjectModal" text="Obyekt yarat" />
                  : null}
              </header>

              <section className="whitebox-body">
                <div className="table-wrapper">
                  {this.props.objects ?
                    <table className="table table-custom">
                      <thead>
                        <tr>
                          <th>Məkan adı</th>
                          <th>Ünvan</th>
                          <th>Cari Vəziyyət</th>
                          <th>Tarix</th>
                          <th/>
                        </tr>
                      </thead>

                      <tbody>
                        {objects}
                      </tbody>
                    </table> : <Loader />}
                  <Pagination
                    page={this.state.params.page}
                    perPage={this.state.params.perPage}
                    pageCount={this.state.params.pageCount}
                    callBack={this.callBack}
                  />
                </div>
              </section>

            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch, ownProps) => ({
  fetchObject: (params) => dispatch(objectFetchData(params, ownProps)),
  deleteObject: (ids, types, api) => dispatch(deleteAction(ids, types, api))
})

const mapStateToProps = state => {
  return state.objectReducer;
}

export default connect(mapStateToProps, mapDispatchToProps)(AllObjects);
