import React, {Component, Fragment} from 'react';
import ButtonLoader from '../../loader/ButtonLoader';
import {
  objectCategoryFetchData,
  objectTypesFetchData,
  objectActivitiesFetchData,
  objectZonesFetchData
} from '../../../redux/actions/objectAction';
import {connect} from 'react-redux';
import {filePostFetch} from '../../../redux/actions/fileAction';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import InputError from '../errors/InputError';

/**
 * @description Object form
 */
class ObjectForm extends Component {
  state = {
    constructionDate: null,
    inputFields: [{category_id: 0, name: '', workers: '', description: ''}]
  }

  componentDidMount() {
    this.props.fetchObjectCategories();
    this.props.fetchActivityCategory();
    this.props.fetchZones();

    if (this.props.activities && this.props.activities.length) {
      let values = this.props.activities;
      this.setState({
        inputFields: values
      })
    }

    if (this.props.objectCategory) {
      this.props.fetchObjectTypes(this.props.objectCategory);
      this.props.formProps.setFieldValue('object_type_id', this.props.objectType);
    }
  }

  /**
   * @description gets Object Type by category id
   * @param {Object} e
   * @memberOf ObjectForm
   */
  onSelectCategory(e) {
    this.props.fetchObjectTypes(e.target.value)
  }

  /**
   * @description add date of object
   * @memberOf AssignmentForm
   * @param date
   */
  handleDateChange = date => {
    this.setState({
      constructionDate: date
    });
    this.props.formProps.setFieldValue('construction_date', date)
  };

  /**
   * @description file upload
   * @param {Object} e
   * @memberOf ObjectForm
   */
  setFile = (e) => {
    const fileData = new FormData();
    for (let i = 0; i < e.currentTarget.files.length; i++) {
      fileData.append('files[]', e.currentTarget.files[i])
    }
    this.setState({
      fileData
    })
    this.props.uploadFile(fileData)
  }

  /**
   * @description activities input handle changes
   * @memberOf ObjectForm
   */
  handleInputChange = (index, event) => {
    const values = [...this.state.inputFields];
    switch (event.target.name) {
    case 'category_id':
      values[index].category_id = event.target.value;
      break;
    case 'name':
      values[index].name = event.target.value;
      break;
    case 'workers':
      values[index].workers = event.target.value;
      break;
    default:
      values[index].description = event.target.value;
      break;
    }
    this.setState({
      inputFields: values
    });

    this.props.parentCallBack(values);
  };

  handleAddFields = () => {
    const values = [...this.state.inputFields];
    values.push({category_id: 0, name: '', workers: '', description: ''});
    this.setState({
      inputFields: values
    });
    this.props.parentCallBack(values);
  };

  handleRemoveFields = index => {
    const values = [...this.state.inputFields];
    values.splice(index, 1);
    this.setState({
      inputFields: values
    });

    this.props.parentCallBack(values);
  };

  /**
   * @description file delete
   * @memberOf ObjectForm
   * @param token
   */
  removeFiles = (token) => {
    const editedImages = this.props.formProps.values.images.filter((file) => file.token !== token);
    this.props.formProps.setFieldValue('images', editedImages);
  }

  /**
   * @description all errors for one input
   * @param name
   * @returns {*}
   */
  handleInputError = (name) => {
    let objectErrors = this.props.objects && this.props.objects.errors ? this.props.objects.errors.data : [];
    let {touched, errors} = this.props.formProps;

    if (touched[name] && errors[name]) {
      objectErrors[name] = [errors[name]];
    }

    return <InputError errors={objectErrors} name={name}/>
  }

  render() {
    const {values, handleSubmit, handleBlur, handleChange, touched, errors} = this.props.formProps;
    const fileNames = values.images ? values.images.map((file, index) => {
      return (
        <p className="file-item mr-3 mb-4" key={index}>
          <img src={`${process.env.REACT_APP_SERVER}/storage/` + file.path} alt={file.path}/>
          <button
            className="btn-remove"
            onClick={() => this.removeFiles(file.token)}
          >x
          </button>
        </p>
      )
    }) : ''
    const objectCategories = this.props.objects.objectCategories ? this.props.objects.objectCategories.map(category => {
      return (
        <option key={category.id} value={category.id}>{category.name}</option>
      )
    }) : null

    const activityCategory = this.props.objects.activityCategory ? this.props.objects.activityCategory.map(category => {
      return (
        <option key={category.id} value={category.id}>{category.name}</option>
      )
    }) : null

    const objectZones = this.props.objects.objectZones ? this.props.objects.objectZones.map(category => {
      return (
        <option key={category.id} value={category.id}>{category.name}</option>
      )
    }) : null

    const objectTypes = this.props.objects.objectTypes ? this.props.objects.objectTypes.map((type) => {
      return (
        <option key={type.id} value={type.id}>{type.name}</option>
      )
    }) : null

    return (
      <div className="add-form">
        <div className="form-group form-group-custom">
          <label htmlFor="name">Obyektin adı</label>
          <input
            type="text"
            name="name"
            className={'form-control form-control-custom ' + ((touched.name && errors.name) ? 'has-error' : null)}
            placeholder="Obyektin adını qeyd edin"
            value={values.name}
            onChange={handleChange}
            onBlur={handleBlur}
          />
          {this.handleInputError('name')}
        </div>

        <div className="form-group form-group-custom">
          <label htmlFor="ownership">Obyektin mülkiyyət növü</label>
          <input
            type="text"
            name="ownership"
            className={'form-control form-control-custom ' + ((touched.ownership && errors.ownership) ? 'has-error' : null)}
            placeholder="Obyektin mülkiyyət növü"
            value={values.ownership}
            onChange={handleChange}
            onBlur={handleBlur}
          />
          {this.handleInputError('ownership')}
        </div>

        <div className="form-group form-group-custom">
          <label htmlFor="operation_status">İstismar vəziyyəti</label>
          <input
            type="text"
            name="operation_status"
            className={'form-control form-control-custom ' + ((touched.operation_status && errors.operation_status) ? 'has-error' : null)}
            placeholder="İstismar vəziyyəti"
            value={values.operation_status}
            onChange={handleChange}
            onBlur={handleBlur}
          />
          {this.handleInputError('operation_status')}
        </div>

        <div className="form-group form-group-custom">
          <label htmlFor="block_count">Obyektdə olan blokların sayi</label>
          <input
            type="text"
            name="block_count"
            className={'form-control form-control-custom ' + ((touched.block_count && errors.block_count) ? 'has-error' : null)}
            placeholder="Obyektdə olan blokların sayi"
            value={values.block_count}
            onChange={handleChange}
            onBlur={handleBlur}
          />
          {this.handleInputError('block_count')}
        </div>

        <div className="form-group form-group-custom">
          <label htmlFor="block_status">Blokların vəziyyəti</label>
          <input
            type="text"
            name="block_status"
            className={'form-control form-control-custom ' + ((touched.block_status && errors.block_status) ? 'has-error' : null)}
            placeholder="Blokların vəziyyəti"
            value={values.block_status}
            onChange={handleChange}
            onBlur={handleBlur}
          />
          {this.handleInputError('block_status')}
        </div>

        <div className="form-group form-group-custom">
          <label htmlFor="floors_count">Obyektdəki mərtəbələrin sayı</label>
          <input
            type="text"
            name="floors_count"
            className={'form-control form-control-custom ' + ((touched.floors_count && errors.floors_count) ? 'has-error' : null)}
            placeholder="Obyektdəki mərtəbələrin sayı"
            value={values.floors_count}
            onChange={handleChange}
            onBlur={handleBlur}
          />
          {this.handleInputError('floors_count')}
        </div>

        <div className="form-group form-group-custom">
          <label htmlFor="apartments_count">Obyektdə olan mənzillərin sayı</label>
          <input
            type="text"
            name="apartments_count"
            className={'form-control form-control-custom ' + ((touched.apartments_count && errors.apartments_count) ? 'has-error' : null)}
            placeholder="Obyektdə olan mənzillərin sayı"
            value={values.apartments_count}
            onChange={handleChange}
            onBlur={handleBlur}
          />
          {this.handleInputError('apartments_count')}
        </div>

        <div className="form-group form-group-custom">
          <label htmlFor="registered_peoples_count">Qeydiyyatda olan ailə üzvlərinin sayı</label>
          <input
            type="text"
            name="registered_peoples_count"
            className={'form-control form-control-custom ' + ((touched.registered_peoples_count && errors.registered_peoples_count) ? 'has-error' : null)}
            placeholder="Qeydiyyatda olan ailə üzvlərinin sayı"
            value={values.registered_peoples_count}
            onChange={handleChange}
            onBlur={handleBlur}
          />
          {this.handleInputError('registered_peoples_count')}
        </div>

        <div className="form-group form-group-custom">
          <label htmlFor="area_size">Sahə ölçüsü</label>
          <input
            type="text"
            name="area_size"
            className={'form-control form-control-custom ' + ((touched.area_size && errors.area_size) ? 'has-error' : null)}
            placeholder="Sahə ölçüsü"
            value={values.area_size}
            onChange={handleChange}
            onBlur={handleBlur}
          />
          {this.handleInputError('area_size')}
        </div>

        <div className="form-group form-group-custom">
          <label htmlFor="current_situation">Mövcud vəziyyəti</label>
          <input
            type="text"
            name="current_situation"
            className={'form-control form-control-custom ' + ((touched.current_situation && errors.current_situation) ? 'has-error' : null)}
            placeholder="Mövcud vəziyyəti"
            value={values.current_situation}
            onChange={handleChange}
            onBlur={handleBlur}
          />
          {this.handleInputError('current_situation')}
        </div>

        <div className="form-group form-group-custom">
          <label htmlFor="activities">Fəaliyyətlər</label>

          {this.state.inputFields.map((inputField, index) => (
            <Fragment key={`${inputField}~${index}`}>
              <select
                name="category_id"
                className={'form-control form-control-custom selectbox mb-3'}
                value={inputField.category_id}
                onChange={event => this.handleInputChange(index, event)}
              >
                <option disabled value="0">Fəaliyyət seçin</option>
                {activityCategory}
              </select>
              {this.handleInputError(`activities.${index}.category_id`)}

              <input
                type="text"
                className="form-control form-control-custom mb-3"
                name="name"
                value={inputField.name}
                placeholder="Fəaliyyət adı"
                onChange={event => this.handleInputChange(index, event)}
              />
              {this.handleInputError(`activities.${index}.name`)}

              <input
                type="text"
                className="form-control form-control-custom mb-3"
                name="workers"
                value={inputField.workers}
                placeholder="İşçi sayı"
                onChange={event => this.handleInputChange(index, event)}
              />
              {this.handleInputError(`activities.${index}.workers`)}

              <input
                type="text"
                className="form-control form-control-custom"
                name="description"
                value={inputField.description}
                placeholder="Ətraflı"
                onChange={event => this.handleInputChange(index, event)}
              />
              {this.handleInputError(`activities.${index}.description`)}

              <div className="d-flex align-items-center justify-content-end mb-4">
                <button
                  className="btn btn-link"
                  type="button"
                  onClick={() => this.handleRemoveFields(index)}
                >
                  -
                </button>
                <button
                  className="btn btn-link"
                  type="button"
                  onClick={() => this.handleAddFields(index)}
                >
                  +
                </button>
              </div>
            </Fragment>
          ))}
        </div>

        <div className="form-group form-group-custom">
          <label htmlFor="education_status">Təhsil Statusu</label>
          <input
            type="text"
            name="education_status"
            className={'form-control form-control-custom ' + ((touched.education_status && errors.education_status) ? 'has-error' : null)}
            placeholder="Təhsil Statusu"
            value={values.education_status}
            onChange={handleChange}
            onBlur={handleBlur}
          />
          {this.handleInputError('education_status')}
        </div>

        <div className="form-group form-group-custom">
          <label htmlFor="address">Ünvan</label>
          <textarea
            name="address"
            className={'form-control form-control-custom ' + ((touched.address && errors.address) ? 'has-error' : null)}
            placeholder="Ünvanı daxil edin"
            value={values.address}
            onChange={handleChange}
            onBlur={handleBlur}
            maxLength="255"
          />
          {this.handleInputError('address')}
        </div>

        <div className="row">
          <div className="form-group form-group-custom col-md-6">
            <label htmlFor="lat">Lat.</label>
            <input
              type="text"
              name="lat"
              className={'form-control form-control-custom ' + ((touched.lat && errors.lat) ? 'has-error' : null)}
              placeholder="40.397849"
              value={values.lat}
              onChange={handleChange}
              onBlur={handleBlur}
            />
            {this.handleInputError('lat')}
          </div>

          <div className="form-group form-group-custom col-md-6">
            <label htmlFor="lng">Long.</label>
            <input
              type="text"
              name="lng"
              className={'form-control form-control-custom ' + ((touched.lng && errors.lng) ? 'has-error' : null)}
              placeholder="49.893261"
              value={values.lng}
              onChange={handleChange}
              onBlur={handleBlur}
            />
            {this.handleInputError('lng')}
          </div>
        </div>

        <div className="form-group form-group-custom">
          <label htmlFor="construction_date">Obyektin tikilmə ili.</label>
          <DatePicker
            name="construction_date"
            autoComplete="off"
            // dateFormat="YYYY-MM-DD"
            selected={values.construction_date}
            onChange={this.handleDateChange}
            showMonthDropdown
            className={'form-control form-control-custom ' + ((touched.construction_date && errors.construction_date) ? 'has-error' : null)}
            placeholderText="Obyektin tikilmə ili."
            onBlur={handleBlur}
          />
          {this.handleInputError('construction_date')}
        </div>

        <div className="form-group form-group-custom">
          <label htmlFor="note">Qeydlər.</label>
          <textarea
            name="note"
            className={'form-control form-control-custom '}
            placeholder="Qeydlər"
            value={values.note}
            onChange={handleChange}
            onBlur={handleBlur}
          />
        </div>

        <div className="form-group form-group-custom">
          <label htmlFor="history">Tarixçə.</label>
          <textarea
            name="history"
            className={'form-control form-control-custom '}
            placeholder="Tarixçə"
            value={values.history}
            onChange={handleChange}
            onBlur={handleBlur}
            maxLength="255"
          />
        </div>

        <div className="form-group form-group-custom">
          <label htmlFor="object_category_id">Obyektin kateqoriyası.</label>
          <select
            name="object_category_id"
            className={'form-control form-control-custom selectbox ' + ((touched.object_category_id && errors.object_category_id) ? 'has-error' : null)}
            value={values.object_category_id}
            onChange={(e) => {
              e.persist();
              handleChange(e);
              this.onSelectCategory(e)
            }}
            onBlur={handleBlur}

          >
            <option disabled value="">Kateqoriya seçin.</option>
            {objectCategories}
          </select>
          {this.handleInputError('object_category_id')}
        </div>

        <div className="form-group form-group-custom">
          <label htmlFor="object_type_id">Obyektin növü.</label>
          <select
            name="object_type_id"
            className={'form-control form-control-custom selectbox ' + ((touched.object_category_id && errors.object_category_id) ? 'has-error' : null)}
            value={values.object_type_id}
            onChange={handleChange}
            onBlur={handleBlur}

          >
            <option disabled value="">Obyektin növünü seçin</option>
            {objectTypes}
          </select>
          {this.handleInputError('object_type_id')}
        </div>

        <div className="form-group form-group-custom">
          <label htmlFor="object_zone_id">Ərazi.</label>
          <select
            name="object_zone_id"
            className={'form-control form-control-custom selectbox ' + ((touched.object_zone_id && errors.object_zone_id) ? 'has-error' : null)}
            value={values.object_zone_id}
            onChange={handleChange}
            onBlur={handleBlur}

          >
            <option disabled value="">Ərazi seçin.</option>
            {objectZones}
          </select>
          {this.handleInputError('object_zone_id')}
        </div>

        <div className="row">
          <div className="col-md-6 form-group form-group-custom">
            <div className="file-name d-flex justify-content-start flex-wrap">
              {fileNames}
            </div>
          </div>
          <div className="col-md-6 form-group form-group-custom over-hide">
            <div className="btn file-button">Şəkil yüklə</div>
            <input
              name="file"
              type="file"
              multiple
              placeholder="Şəkil yüklə"
              // value={values.images}
              onChange={(e) => {
                this.setFile(e)
              }}
              onBlur={handleBlur}
            />
          </div>
        </div>

        <div className="form-group form-group-custom">
          <button
            type="button"
            onClick={() => (handleSubmit(values))}
            className="btn btn-theme w-100"
            disabled={this.props.isFetching ? true : null}
          >
            {this.props.files.isFetching ? <ButtonLoader/> : <span>Yadda saxla</span>}
          </button>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch, ownProps) => ({
  fetchObjectCategories: () => dispatch(objectCategoryFetchData(ownProps)),
  fetchObjectTypes: selectedCategoryId => dispatch(objectTypesFetchData(selectedCategoryId, ownProps)),
  fetchActivityCategory: () => dispatch(objectActivitiesFetchData()),
  fetchZones: () => dispatch(objectZonesFetchData()),
  uploadFile: file => dispatch(filePostFetch(file, ownProps))
})

const mapStateToProps = state => {
  return {
    objects: state.objectReducer,
    files: state.fileReducer
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ObjectForm);
