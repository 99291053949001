import React, {Component} from 'react';
import {connect} from 'react-redux';
import {statisticsFetchDetailData} from '../../../redux/actions/statisticAction';
import Loader from '../../shared/Loader';

/**
 * @description StatisticInner component
 */
class StatisticInner extends Component {
  state = {
    statisticTitle: ''
  }

  componentDidMount() {
    const slug = window.location.pathname.split('/')[2];
    this.props.fetchStatisticDetail(slug);

    let statistics = JSON.parse(localStorage.getItem('statistics'));
    statistics = statistics.filter((statistic) => statistic.value.key === slug);
    this.setState({statisticTitle: statistics[0].value.title});
  }

  render() {
    const statistics = this.props.statisticDetails ? this.props.statisticDetails.map((statistic) => {
      return (
        <div className="col-sm-6 col-xl-3" key={statistic.id}>
          <div className="objects-link statistic-link"
          >
            {statistic.image ? <img src={`${process.env.REACT_APP_SERVER}/storage/` + statistic.image} alt={statistic.img} /> : null}
            <p className="count">{statistic.count}</p>
            <span>{statistic.name}</span>
          </div>
        </div>
      )
    }) : <Loader />

    return (
      <div className="container-fluid">
        <div className="row">

          <div className="col-lg-12">
            <div className="whitebox">
              <header className="whitebox-header bordered">
                <div className="whitebox-title">
                  <h4>
                    {this.state.statisticTitle}
                  </h4>
                  <ul className="breadcrumps">
                    <li className="breadcrumps-item">Statistika</li>
                    <li className="breadcrumps-item">{this.state.statisticTitle}</li>
                  </ul>
                </div>
              </header>

              <section className="whitebox-body">
                <div className="row">
                  {statistics}
                </div>
              </section>

            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch, ownProps) => ({
  fetchStatisticDetail: (slug) => dispatch(statisticsFetchDetailData(slug))
})

const mapStateToProps = state => {
  return state.statisticReducer;
}

export default connect(mapStateToProps, mapDispatchToProps)(StatisticInner);
