import React from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import App from './components/App';
import * as serviceWorker from './serviceWorker';
// redux
import {createStore, applyMiddleware} from 'redux';
import thunk from 'redux-thunk';
import {Provider} from 'react-redux';
import rootReducer from './redux/reducers/rootReducer';
import {composeWithDevTools} from 'redux-devtools-extension';
import axiosInterceptor from './interceptors/axios-interceptor';

/**
 * @param  {} rootReducer
 * @param  {} composeWithDevTools for using react developer tools extension
 */
const store = createStore(
  rootReducer,

  /**
   * @param  {} applyMiddleware
   */
  composeWithDevTools(applyMiddleware(thunk))
);

/**
 * @description running axios interceptor
 */
axiosInterceptor();

ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls. Learn
// more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
