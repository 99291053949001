/**
 * @description User Login action types
 */
export const LOGIN_REQUEST = 'USERS_LOGIN_REQUEST';
export const LOGIN_SUCCESS = 'USERS_LOGIN_SUCCESS';
export const LOGIN_FAILURE = 'USERS_LOGIN_FAILURE';

/**
 * @description User  Register action types
 */
export const SIGN_UP_REQUEST = 'USERS_SIGN_UP_REQUEST';
export const SIGN_UP_SUCCESS = 'USERS_SIGN_UP_SUCCESS';
export const SIGN_UP_FAILURE = 'USERS_SIGN_UP_FAILURE';

/**
 * @description User logout action types
 */
export const LOGOUT_REQUEST = 'USERS_LOGOUT_REQUEST';
export const LOGOUT_SUCCESS = 'USERS_LOGOUT_SUCCESS';
export const LOGOUT_FAILURE = 'USERS_LOGOUT_FAILURE';

/**
 * @description Task Add action types
 */
export const ADD_TASK_REQUEST = 'ADD_TASK_REQUEST';
export const ADD_TASK_SUCCESS = 'ADD_TASK_SUCCESS';
export const ADD_TASK_FAILURE = 'ADD_TASK_FAILURE';

/**
 * @description Task EDIT action types
 */
export const EDIT_TASK_REQUEST = 'EDIT_TASK_REQUEST';
export const EDIT_TASK_SUCCESS = 'EDIT_TASK_SUCCESS';
export const EDIT_TASK_FAILURE = 'EDIT_TASK_FAILURE';

/**
 * @description TASK fetch action types
 */
export const FETCH_TASK_REQUEST = 'FETCH_TASK_REQUEST';
export const FETCH_TASK_SUCCESS = 'FETCH_TASK_SUCCESS';
export const FETCH_TASK_FAILURE = 'FETCH_TASK_FAILURE';

/**
 * @description TASK fetch action details
 */
export const FETCH_TASK_DETAIL_REQUEST = 'FETCH_TASK_DETAIL_REQUEST';
export const FETCH_TASK_DETAIL_SUCCESS = 'FETCH_TASK_DETAIL_SUCCESS';
export const FETCH_TASK_DETAIL_FAILURE = 'FETCH_TASK_DETAIL_FAILURE';

/**
 * @description APPEAL FORWARD action types
 */
export const TASK_FORWARD_REQUEST = 'TASK_FORWARD_REQUEST';
export const TASK_FORWARD_SUCCESS = 'TASK_FORWARD_SUCCESS';
export const TASK_FORWARD_FAILURE = 'TASK_FORWARD_FAILURE';

/**
 * @description APPEAL ACCEPT action types
 */
export const TASK_ACCEPT_REQUEST = 'TASK_ACCEPT_REQUEST';
export const TASK_ACCEPT_SUCCESS = 'TASK_ACCEPT_SUCCESS';
export const TASK_ACCEPT_FAILURE = 'TASK_ACCEPT_FAILURE';

/**
 * @description APPEAL Done action types
 */
export const TASK_DONE_REQUEST = 'TASK_DONE_REQUEST';
export const TASK_DONE_SUCCESS = 'TASK_DONE_SUCCESS';
export const TASK_DONE_FAILURE = 'TASK_DONE_FAILURE';

/**
 * @description Get Users action types
 */
export const FETCH_USERS_REQUEST = 'FETCH_USERS_REQUEST';
export const FETCH_USERS_SUCCESS = 'FETCH_USERS_SUCCESS';
export const FETCH_USERS_FAILURE = 'FETCH_USERS_FAILURE';

/**
 * @description Object Add action types
 */
export const ADD_REQUEST = 'ADD_OBJECT_REQUEST';
export const ADD_SUCCESS = 'ADD_OBJECT_SUCCESS';
export const ADD_FAILURE = 'ADD_OBJECT_FAILURE';

/**
 * @description Object DELETE action types
 */
export const DELETE_OBJECT_REQUEST = 'DELETE_OBJECT_REQUEST';
export const DELETE_OBJECT_SUCCESS = 'DELETE_OBJECT_SUCCESS';
export const DELETE_OBJECT_FAILURE = 'DELETE_OBJECT_FAILURE';

/**
 * @description Object fetch  action types
 */
export const FETCH_REQUEST = 'FETCH_OBJECT_REQUEST';
export const FETCH_SUCCESS = 'FETCH_OBJECT_SUCCESS';
export const FETCH_FAILURE = 'FETCH_OBJECT_FAILURE';

/**
 * @description Object fetch  action types
 */
export const FETCH_OBJECT_BY_ZONE_REQUEST = 'FETCH_OBJECT_BY_ZONE_REQUEST';
export const FETCH_OBJECT_BY_ZONE_SUCCESS = 'FETCH_OBJECT_BY_ZONE_SUCCESS';
export const FETCH_OBJECT_BY_ZONE_FAILURE = 'FETCH_OBJECT_BY_ZONE_FAILURE';

/**
 * @description Object Edit action types
 */
export const EDIT_OBJECT_REQUEST = 'EDIT_OBJECT_REQUEST';
export const EDIT_OBJECT_SUCCESS = 'EDIT_OBJECT_SUCCESS';
export const EDIT_OBJECT_FAILURE = 'EDIT_OBJECT_FAILURE';

/**
 * @description Object Edit action types
 */
export const FETCH_ALL_OBJECT_REQUEST = 'FETCH_ALL_OBJECT_REQUEST';
export const FETCH_ALL_OBJECT_SUCCESS = 'FETCH_ALL_OBJECT_SUCCESS';
export const FETCH_ALL_OBJECT_FAILURE = 'FETCH_ALL_OBJECT_FAILURE';

/**
 * @description Object CATEGORY FETCH  action types
 */
export const FETCH_CATEGORY_REQUEST = 'FETCH_OBJECT_CATEGORY_REQUEST';
export const FETCH_CATEGORY_SUCCESS = 'FETCH_OBJECT_CATEGORY_SUCCESS';
export const FETCH_CATEGORY_FAILURE = 'FETCH_OBJECT_CATEGORY_FAILURE';

/**
 * @description Object Search
 */
export const SEARCH_OBJECT_REQUEST = 'SEARCH_OBJECT_REQUEST';
export const SEARCH_OBJECT_SUCCESS = 'SEARCH_OBJECT_SUCCESS';
export const SEARCH_OBJECT_FAILURE = 'SEARCH_OBJECT_FAILURE';

/**
 * @description Object ZONE FETCH  action types
 */
export const FETCH_ZONE_REQUEST = 'FETCH_OBJECT_ZONE_REQUEST';
export const FETCH_ZONE_SUCCESS = 'FETCH_OBJECT_ZONE_SUCCESS';
export const FETCH_ZONE_FAILURE = 'FETCH_OBJECT_ZONE_FAILURE';

/**
 * @description Object types FETCH  action types
 */
export const FETCH_TYPE_REQUEST = 'FETCH_OBJECT_TYPE_REQUEST';
export const FETCH_TYPE_SUCCESS = 'FETCH_OBJECT_TYPE_SUCCESS';
export const FETCH_TYPE_FAILURE = 'FETCH_OBJECT_TYPE_FAILURE';

/**
 * @description Object DETAIL action types
 */
export const FETCH_OBJECT_DETAIL_REQUEST = 'FETCH_OBJECT_DETAIL_REQUEST';
export const FETCH_OBJECT_DETAIL_SUCCESS = 'FETCH_OBJECT_DETAIL_SUCCESS';
export const FETCH_OBJECT_DETAIL_FAILURE = 'FETCH_OBJECT_DETAIL_FAILURE';

/**
 * @description Object activity category
 */
export const FETCH_ACTIVITY_CATEGORY_REQUEST = 'FETCH_ACTIVITY_CATEGORY_REQUEST';
export const FETCH_ACTIVITY_CATEGORY_SUCCESS = 'FETCH_ACTIVITY_CATEGORY_SUCCESS';
export const FETCH_ACTIVITY_CATEGORY_FAILURE = 'FETCH_ACTIVITY_CATEGORY_FAILURE';

/**
 * @description File Upload action types
 */
export const FILE_REQUEST = 'FILE_REQUEST';
export const FILE_SUCCESS = 'FILE_SUCCESS';
export const FILE_FAILURE = 'FILE_FAILURE';

/**
 * @description File Delete action types
 */
export const FILE_DELETE_REQUEST = 'FILE_DELETE_REQUEST';
export const FILE_DELETE_SUCCESS = 'FILE_DELETE_SUCCESS';
export const FILE_DELETE_FAILURE = 'FILE_DELETE_FAILURE';

export const REMOVE_SUCCESS_MESSAGE = 'REMOVE_SUCCESS_MESSAGE';

/**
 * @description APPEAL Add action types
 */
export const ADD_APPEAL_REQUEST = 'ADD_APPEAL_REQUEST';
export const ADD_APPEAL_SUCCESS = 'ADD_APPEAL_SUCCESS';
export const ADD_APPEAL_FAILURE = 'ADD_APPEAL_FAILURE';

/**
 * @description APPEAL delete action types
 */
export const DELETE_APPEAL_REQUEST = 'DELETE_APPEAL_REQUEST';
export const DELETE_APPEAL_SUCCESS = 'DELETE_APPEAL_SUCCESS';
export const DELETE_APPEAL_FAILURE = 'DELETE_APPEAL_FAILURE';

/**
 * @description APPEAL EDIT action types
 */
export const EDIT_APPEAL_REQUEST = 'EDIT_APPEAL_REQUEST';
export const EDIT_APPEAL_SUCCESS = 'EDIT_APPEAL_SUCCESS';
export const EDIT_APPEAL_FAILURE = 'EDIT_APPEAL_FAILURE';

/**
 * @description APPEAL fetch action types
 */
export const FETCH_APPEAL_REQUEST = 'FETCH_APPEAL_REQUEST';
export const FETCH_APPEAL_SUCCESS = 'FETCH_APPEAL_SUCCESS';
export const FETCH_APPEAL_FAILURE = 'FETCH_APPEAL_FAILURE';

/**
 * @description APPEAL fetch action types
 */
export const FETCH_APPEAL_CATEGORY_REQUEST = 'FETCH_APPEAL_CATEGORY_REQUEST';
export const FETCH_APPEAL_CATEGORY_SUCCESS = 'FETCH_APPEAL_CATEGORY_SUCCESS';
export const FETCH_APPEAL_CATEGORY_FAILURE = 'FETCH_APPEAL_CATEGORY_FAILURE';

/**
 * @description Appeal DETAIL action types
 */
export const FETCH_APPEAL_DETAIL_REQUEST = 'FETCH_APPEAL_DETAIL_REQUEST';
export const FETCH_APPEAL_DETAIL_SUCCESS = 'FETCH_APPEAL_DETAIL_SUCCESS';
export const FETCH_APPEAL_DETAIL_FAILURE = 'FETCH_APPEAL_DETAIL_FAILURE';

/**
 * @description TASK status action types
 */
export const FETCH_STATUS_REQUEST = 'FETCH_STATUS_REQUEST';
export const FETCH_STATUS_SUCCESS = 'FETCH_STATUS_SUCCESS';
export const FETCH_STATUS_FAILURE = 'FETCH_STATUS_FAILURE';

/**
 * @description REPRESENTATIVE fetch action types
 */
export const FETCH_REPRESENTATIVE_REQUEST = 'FETCH_REPRESENTATIVE_REQUEST';
export const FETCH_REPRESENTATIVE_SUCCESS = 'FETCH_REPRESENTATIVE_SUCCESS';
export const FETCH_REPRESENTATIVE_FAILURE = 'FETCH_REPRESENTATIVE_FAILURE';

/**
 * @description REPRESENTATIVE fetch action types
 */
export const ADD_REPRESENTATIVE_REQUEST = 'ADD_REPRESENTATIVE_REQUEST';
export const ADD_REPRESENTATIVE_SUCCESS = 'ADD_REPRESENTATIVE_SUCCESS';
export const ADD_REPRESENTATIVE_FAILURE = 'ADD_REPRESENTATIVE_FAILURE';

/**
 * @description REPRESENTATIVE delete action types
 */
export const DELETE_REPRESENTATIVE_REQUEST = 'DELETE_REPRESENTATIVE_REQUEST';
export const DELETE_REPRESENTATIVE_SUCCESS = 'DELETE_REPRESENTATIVE_SUCCESS';
export const DELETE_REPRESENTATIVE_FAILURE = 'DELETE_REPRESENTATIVE_FAILURE';

/**
 * @description REPRESENTATIVE DETAIL action types
 */
export const FETCH_REPRESENTATIVE_DETAIL_REQUEST = 'FETCH_REPRESENTATIVE_DETAIL_REQUEST';
export const FETCH_REPRESENTATIVE_DETAIL_SUCCESS = 'FETCH_REPRESENTATIVE_DETAIL_SUCCESS';
export const FETCH_REPRESENTATIVE_DETAIL_FAILURE = 'FETCH_REPRESENTATIVE_DETAIL_FAILURE';

/**
 * @description APPEAL FORWARD action types
 */
export const APPEAL_FORWARD_REQUEST = 'APPEAL_FORWARD_REQUEST';
export const APPEAL_FORWARD_SUCCESS = 'APPEAL_FORWARD_SUCCESS';
export const APPEAL_FORWARD_FAILURE = 'APPEAL_FORWARD_FAILURE';

/**
 * @description APPEAL ACCEPT action types
 */
export const APPEAL_ACCEPT_REQUEST = 'APPEAL_ACCEPT_REQUEST';
export const APPEAL_ACCEPT_SUCCESS = 'APPEAL_ACCEPT_SUCCESS';
export const APPEAL_ACCEPT_FAILURE = 'APPEAL_ACCEPT_FAILURE';

/**
 * @description APPEAL Done action types
 */
export const APPEAL_DONE_REQUEST = 'APPEAL_DONE_REQUEST';
export const APPEAL_DONE_SUCCESS = 'APPEAL_DONE_SUCCESS';
export const APPEAL_DONE_FAILURE = 'APPEAL_DONE_FAILURE';

/**
 * @description LEGISLATION add action types
 */
export const ADD_LEGISLATION_REQUEST = 'ADD_LEGISLATION_REQUEST';
export const ADD_LEGISLATION_SUCCESS = 'ADD_LEGISLATION_SUCCESS';
export const ADD_LEGISLATION_FAILURE = 'ADD_LEGISLATION_FAILURE';

/**
 * @description LEGISLATION delete action types
 */
export const DELETE_LEGISLATION_REQUEST = 'DELETE_LEGISLATION_REQUEST';
export const DELETE_LEGISLATION_SUCCESS = 'DELETE_LEGISLATION_SUCCESS';
export const DELETE_LEGISLATION_FAILURE = 'DELETE_LEGISLATION_FAILURE';

/**
 * @description LEGISLATION fetch action types
 */
export const FETCH_LEGISLATION_REQUEST = 'FETCH_LEGISLATION_REQUEST';
export const FETCH_LEGISLATION_SUCCESS = 'FETCH_LEGISLATION_SUCCESS';
export const FETCH_LEGISLATION_FAILURE = 'FETCH_LEGISLATION_FAILURE';

/**
 * @description LEGISLATION_CATEGORY fetch action types
 */
export const FETCH_LEGISLATION_CATEGORY_REQUEST = 'FETCH_LEGISLATION_CATEGORY_REQUEST';
export const FETCH_LEGISLATION_CATEGORY_SUCCESS = 'FETCH_LEGISLATION_CATEGORY_SUCCESS';
export const FETCH_LEGISLATION_CATEGORY_FAILURE = 'FETCH_LEGISLATION_CATEGORY_FAILURE';

/**
 * @description LEGISLATION DETAIL action types
 */
export const FETCH_LEGISLATION_DETAIL_REQUEST = 'FETCH_LEGISLATION_DETAIL_REQUEST';
export const FETCH_LEGISLATION_DETAIL_SUCCESS = 'FETCH_LEGISLATION_DETAIL_SUCCESS';
export const FETCH_LEGISLATION_DETAIL_FAILURE = 'FETCH_LEGISLATION_DETAIL_FAILURE';

/**
 * @description LEGISLATION edit action types
 */
export const EDIT_LEGISLATION_REQUEST = 'EDIT_LEGISLATION_REQUEST';
export const EDIT_LEGISLATION_SUCCESS = 'EDIT_LEGISLATION_SUCCESS';
export const EDIT_LEGISLATION_FAILURE = 'EDIT_LEGISLATION_FAILURE';

/**
 * @description menus fetch action types
 */
export const FETCH_MENUS_REQUEST = 'FETCH_MENUS_REQUEST';
export const FETCH_MENUS_SUCCESS = 'FETCH_MENUS_SUCCESS';
export const FETCH_MENUS_FAILURE = 'FETCH_MENUS_FAILURE';

/**
 * @description appeal comments add action types
 */
export const ADD_COMMENTS_REQUEST = 'ADD_COMMENTS_REQUEST';
export const ADD_COMMENTS_SUCCESS = 'ADD_COMMENTS_SUCCESS';
export const ADD_COMMENTS_FAILURE = 'ADD_COMMENTS_FAILURE';

/**
 * @description appeal comments fetch action types
 */
export const FETCH_COMMENTS_REQUEST = 'FETCH_COMMENTS_REQUEST';
export const FETCH_COMMENTS_SUCCESS = 'FETCH_COMMENTS_SUCCESS';
export const FETCH_COMMENTS_FAILURE = 'FETCH_COMMENTS_FAILURE';

/**
 * @description task comments fetch action types
 */
export const FETCH_TASK_COMMENTS_REQUEST = 'FETCH_TASK_COMMENTS_REQUEST';
export const FETCH_TASK_COMMENTS_SUCCESS = 'FETCH_TASK_COMMENTS_SUCCESS';
export const FETCH_TASK_COMMENTS_FAILURE = 'FETCH_TASK_COMMENTS_FAILURE';

/**
 * @description task comments add action types
 */
export const ADD_TASK_COMMENTS_REQUEST = 'ADD_TASK_COMMENTS_REQUEST';
export const ADD_TASK_COMMENTS_SUCCESS = 'ADD_TASK_COMMENTS_SUCCESS';
export const ADD_TASK_COMMENTS_FAILURE = 'ADD_TASK_COMMENTS_FAILURE';

/**
 * @description task delete
 */
export const DELETE_TASK_REQUEST = 'DELETE_TASK_REQUEST';
export const DELETE_TASK_SUCCESS = 'DELETE_TASK_SUCCESS';
export const DELETE_TASK_FAILURE = 'DELETE_TASK_FAILURE';

/**
 * @description Firebase token action types
 * @type {string}
 */
export const FCM_TOKEN_REFRESH_REQUEST = 'FCM_TOKEN_REFRESH_REQUEST';
export const FCM_TOKEN_REFRESH_SUCCESS = 'FCM_TOKEN_REFRESH_SUCCESS';
export const FCM_TOKEN_REFRESH_FAILURE = 'FCM_TOKEN_REFRESH_FAILURE';

export const FCM_FETCH_TOKEN_REQUEST = 'FCM_FETCH_TOKEN_REQUEST';
export const FCM_FETCH_TOKEN_SUCCESS = 'FCM_FETCH_TOKEN_SUCCESS';
export const FCM_FETCH_TOKEN_FAILURE = 'FCM_FETCH_TOKEN_FAILURE';

export const FCM_READ_NOTIFY_REQUEST = 'FCM_READ_NOTIFY_REQUEST';
export const FCM_READ_NOTIFY_SUCCESS = 'FCM_READ_NOTIFY_SUCCESS';
export const FCM_READ_NOTIFY_FAILURE = 'FCM_READ_NOTIFY_FAILURE';

export const FCM_CLEAR_NOTIFY_REQUEST = 'FCM_CLEAR_NOTIFY_REQUEST';
export const FCM_CLEAR_NOTIFY_SUCCESS = 'FCM_CLEAR_NOTIFY_SUCCESS';
export const FCM_CLEAR_NOTIFY_FAILURE = 'FCM_CLEAR_NOTIFY_FAILURE';

export const FCM_FETCH_BG_MESSAGE_REQUEST = 'FCM_FETCH_BG_MESSAGE_REQUEST';
export const FCM_FETCH_BG_MESSAGE_SUCCESS = 'FCM_FETCH_BG_MESSAGE_SUCCESS';
export const FCM_FETCH_BG_MESSAGE_FAILURE = 'FCM_FETCH_BG_MESSAGE_FAILURE';

/**
 * @description useful  action types
 */
export const FETCH_USEFUL_REQUEST = 'FETCH_USEFUL_REQUEST';
export const FETCH_USEFUL_SUCCESS = 'FETCH_USEFUL_SUCCESS';
export const FETCH_USEFUL_FAILURE = 'FETCH_USEFUL_FAILURE';

/**
 * @description VACANCY fetch action types
 */
export const FETCH_VACANCY_REQUEST = 'FETCH_VACANCY_REQUEST';
export const FETCH_VACANCY_SUCCESS = 'FETCH_VACANCY_SUCCESS';
export const FETCH_VACANCY_FAILURE = 'FETCH_VACANCY_FAILURE';

/**
 * @description VACANCY add action types
 */
export const ADD_VACANCY_REQUEST = 'ADD_VACANCY_REQUEST';
export const ADD_VACANCY_SUCCESS = 'ADD_VACANCY_SUCCESS';
export const ADD_VACANCY_FAILURE = 'ADD_VACANCY_FAILURE';

/**
 * @description VACANCY edit action types
 */
export const EDIT_VACANCY_REQUEST = 'EDIT_VACANCY_REQUEST';
export const EDIT_VACANCY_SUCCESS = 'EDIT_VACANCY_SUCCESS';
export const EDIT_VACANCY_FAILURE = 'EDIT_VACANCY_FAILURE';

/**
 * @description REPRESENTATIVE delete action types
 */
export const DELETE_VACANCY_REQUEST = 'DELETE_VACANCY_REQUEST';
export const DELETE_VACANCY_SUCCESS = 'DELETE_VACANCY_SUCCESS';
export const DELETE_VACANCY_FAILURE = 'DELETE_VACANCY_FAILURE';

/**
 * @description VACANCY detail fetch action types
 */
export const FETCH_VACANCY_DETAIL_REQUEST = 'FETCH_VACANCY_DETAIL_REQUEST';
export const FETCH_VACANCY_DETAIL_SUCCESS = 'FETCH_VACANCY_DETAIL_SUCCESS';
export const FETCH_VACANCY_DETAIL_FAILURE = 'FETCH_VACANCY_DETAIL_FAILURE';

/**
 * @description VACANCY categories fetch action types
 */
export const FETCH_VACANCY_CATEGORY_REQUEST = 'FETCH_VACANCY_CATEGORY_REQUEST';
export const FETCH_VACANCY_CATEGORY_SUCCESS = 'FETCH_VACANCY_CATEGORY_SUCCESS';
export const FETCH_VACANCY_CATEGORY_FAILURE = 'FETCH_VACANCY_CATEGORY_FAILURE';

/**
 * @description cities action types
 */
export const FETCH_CITY_REQUEST = 'FETCH_CITY_REQUEST';
export const FETCH_CITY_SUCCESS = 'FETCH_CITY_SUCCESS';
export const FETCH_CITY_FAILURE = 'FETCH_CITY_FAILURE';

/**
 * @description experinece action types
 */
export const FETCH_EXPERIENCE_REQUEST = 'FETCH_EXPERIENCE_REQUEST';
export const FETCH_EXPERIENCE_SUCCESS = 'FETCH_EXPERIENCE_SUCCESS';
export const FETCH_EXPERIENCE_FAILURE = 'FETCH_EXPERIENCE_FAILURE';

/**
 * @description education action types
 */
export const FETCH_EDUCATION_REQUEST = 'FETCH_EDUCATION_REQUEST';
export const FETCH_EDUCATION_SUCCESS = 'FETCH_EDUCATION_SUCCESS';
export const FETCH_EDUCATION_FAILURE = 'FETCH_EDUCATION_FAILURE';

/**
 * @description statistics action types
 */
export const FETCH_STATISTIC_REQUEST = 'FETCH_STATISTIC_REQUEST';
export const FETCH_STATISTIC_SUCCESS = 'FETCH_STATISTIC_SUCCESS';
export const FETCH_STATISTIC_FAILURE = 'FETCH_STATISTIC_FAILURE';

/**
 * @description statistics detail action types
 */
export const FETCH_STATISTIC_DETAIL_REQUEST = 'FETCH_STATISTIC_DETAIL_REQUEST';
export const FETCH_STATISTIC_DETAIL_SUCCESS = 'FETCH_STATISTIC_DETAIL_SUCCESS';
export const FETCH_STATISTIC_DETAIL_FAILURE = 'FETCH_STATISTIC_DETAIL_FAILURE';
/**
 * @description about fetch action types
 */

export const FETCH_ABOUT_REQUEST = 'FETCH_ABOUT_REQUEST';
export const FETCH_ABOUT_SUCCESS = 'FETCH_ABOUT_SUCCESS';
export const FETCH_ABOUT_FAILURE = 'FETCH_ABOUT_FAILURE';

/**
 * @description about edit action types
 */
export const EDIT_ABOUT_REQUEST = 'EDIT_ABOUT_REQUEST';
export const EDIT_ABOUT_SUCCESS = 'EDIT_ABOUT_SUCCESS';
export const EDIT_ABOUT_FAILURE = 'EDIT_ABOUT_FAILURE';

/**
 * @description RESUME fetch action types
 */
export const FETCH_RESUME_REQUEST = 'FETCH_RESUME_REQUEST';
export const FETCH_RESUME_SUCCESS = 'FETCH_RESUME_SUCCESS';
export const FETCH_RESUME_FAILURE = 'FETCH_RESUME_FAILURE';

/**
 * @description RESUME add action types
 */
export const ADD_RESUME_REQUEST = 'ADD_RESUME_REQUEST';
export const ADD_RESUME_SUCCESS = 'ADD_RESUME_SUCCESS';
export const ADD_RESUME_FAILURE = 'ADD_RESUME_FAILURE';

/**
 * @description RESUME delete action types
 */
export const DELETE_RESUME_REQUEST = 'DELETE_RESUME_REQUEST';
export const DELETE_RESUME_SUCCESS = 'DELETE_RESUME_SUCCESS';
export const DELETE_RESUME_FAILURE = 'DELETE_RESUME_FAILURE';

/**
 * @description RESUME detail fetch action types
 */
export const FETCH_RESUME_DETAIL_REQUEST = 'FETCH_RESUME_DETAIL_REQUEST';
export const FETCH_RESUME_DETAIL_SUCCESS = 'FETCH_RESUME_DETAIL_SUCCESS';
export const FETCH_RESUME_DETAIL_FAILURE = 'FETCH_RESUME_DETAIL_FAILURE';

/**
 * @description BANNER fetch action types
 */
export const FETCH_BANNER_HEADER_REQUEST = 'FETCH_BANNER_HEADER_REQUEST';
export const FETCH_BANNER_HEADER_SUCCESS = 'FETCH_BANNER_HEADER_SUCCESS';
export const FETCH_BANNER_HEADER_FAILURE = 'FETCH_BANNER_HEADER_FAILURE';

export const FETCH_BANNER_RIGHT_REQUEST = 'FETCH_BANNER_RIGHT_REQUEST';
export const FETCH_BANNER_RIGHT_SUCCESS = 'FETCH_BANNER_RIGHT_SUCCESS';
export const FETCH_BANNER_RIGHT_FAILURE = 'FETCH_BANNER_RIGHT_FAILURE';

export const FETCH_BANNER_WEBSITES_REQUEST = 'FETCH_BANNER_WEBSITES_REQUEST';
export const FETCH_BANNER_WEBSITES_SUCCESS = 'FETCH_BANNER_WEBSITES_SUCCESS';
export const FETCH_BANNER_WEBSITES_FAILURE = 'FETCH_BANNER_WEBSITES_FAILURE';

/**
 * @description fetch evenets action types
 */
export const FETCH_EVENTS_REQUEST = 'FETCH_EVENTS_REQUEST';
export const FETCH_EVENTS_SUCCESS = 'FETCH_EVENTS_SUCCESS';
export const FETCH_EVENTS_FAILURE = 'FETCH_EVENTS_FAILURE';

/**
 * @description delete events
 */
export const DELETE_EVENTS_REQUEST = 'DELETE_EVENTS_REQUEST';
export const DELETE_EVENTS_SUCCESS = 'DELETE_EVENTS_SUCCESS';
export const DELETE_EVENTS_FAILURE = 'DELETE_EVENTS_FAILURE';

/**
 * @description fetch event detail action types
 */
export const FETCH_EVENT_DETAIL_REQUEST = 'FETCH_EVENT_DETAIL_REQUEST';
export const FETCH_EVENT_DETAIL_SUCCESS = 'FETCH_EVENT_DETAIL_SUCCESS';
export const FETCH_EVENT_DETAIL_FAILURE = 'FETCH_EVENT_DETAIL_FAILURE';

/**
 * @description event Add action types
 */
export const ADD_EVENT_REQUEST = 'ADD_EVENT_REQUEST';
export const ADD_EVENT_SUCCESS = 'ADD_EVENT_SUCCESS';
export const ADD_EVENT_FAILURE = 'ADD_EVENT_FAILURE';

/**
 * @description edit events action types
 */
export const EDIT_EVENTS_REQUEST = 'EDIT_EVENTS_REQUEST';
export const EDIT_EVENTS_SUCCESS = 'EDIT_EVENTS_SUCCESS';
export const EDIT_EVENTS_FAILURE = 'EDIT_EVENTS_FAILURE';

/**
 * @description fetch transport action types
 */
export const FETCH_TRANSPORT_REQUEST = 'FETCH_TRANSPORT_REQUEST';
export const FETCH_TRANSPORT_SUCCESS = 'FETCH_TRANSPORT_SUCCESS';
export const FETCH_TRANSPORT_FAILURE = 'FETCH_TRANSPORT_FAILURE';

/**
 * @description transport add action types
 */
export const ADD_TRANSPORT_REQUEST = 'ADD_TRANSPORT_REQUEST';
export const ADD_TRANSPORT_SUCCESS = 'ADD_TRANSPORT_SUCCESS';
export const ADD_TRANSPORT_FAILURE = 'ADD_TRANSPORT_FAILURE';

/**
 * @description edit transport action types
 */
export const EDIT_TRANSPORT_REQUEST = 'EDIT_TRANSPORT_REQUEST';
export const EDIT_TRANSPORT_SUCCESS = 'EDIT_TRANSPORT_SUCCESS';
export const EDIT_TRANSPORT_FAILURE = 'EDIT_TRANSPORT_FAILURE';

/**
 * @description delete transport action types
 */
export const DELETE_TRANSPORT_REQUEST = 'DELETE_TRANSPORT_REQUEST';
export const DELETE_TRANSPORT_SUCCESS = 'DELETE_TRANSPORT_SUCCESS';
export const DELETE_TRANSPORT_FAILURE = 'DELETE_TRANSPORT_FAILURE';

/**
 * @description KARABAKH CATEGORY FETCH  action types
 */
export const FETCH_KARABAKH_CATEGORY_REQUEST = 'FETCH_KARABAKH_CATEGORY_REQUEST';
export const FETCH_KARABAKH_CATEGORY_SUCCESS = 'FETCH_KARABAKH_CATEGORY_SUCCESS';
export const FETCH_KARABAKH_CATEGORY_FAILURE = 'FETCH_KARABAKH_CATEGORY_FAILURE';
/**
 * @description MUNICIPALITIES CATEGORY FETCH  action types
 */
export const FETCH_MUNICIPALITIES_CATEGORY_REQUEST = 'FETCH_MUNICIPALITIES_CATEGORY_REQUEST';
export const FETCH_MUNICIPALITIES_CATEGORY_SUCCESS = 'FETCH_MUNICIPALITIES_CATEGORY_SUCCESS';
export const FETCH_MUNICIPALITIES_CATEGORY_FAILURE = 'FETCH_MUNICIPALITIES_CATEGORY_FAILURE';

/**
 * @description KARABAKH VISIT FETCH  action types
 */
export const FETCH_KARABAKH_VISIT_REQUEST = 'FETCH_KARABAKH_VISIT_REQUEST';
export const FETCH_KARABAKH_VISIT_SUCCESS = 'FETCH_KARABAKH_VISIT_SUCCESS';
export const FETCH_KARABAKH_VISIT_FAILURE = 'FETCH_KARABAKH_VISIT_FAILURE';

/**
 * @description KARABAKH VISIT DETAIL FETCH  action types
 */
export const FETCH_KARABAKH_VISIT_DETAIL_REQUEST = 'FETCH_KARABAKH_VISIT_DETAIL_REQUEST';
export const FETCH_KARABAKH_VISIT_DETAIL_SUCCESS = 'FETCH_KARABAKH_VISIT_DETAIL_SUCCESS';
export const FETCH_KARABAKH_VISIT_DETAIL_FAILURE = 'FETCH_KARABAKH_VISIT_DETAIL_FAILURE';

/**
 * @description KARABAKH VISIT ADD action types
 */
export const KARABAKH_VISIT_ADD_REQUEST = 'KARABAKH_VISIT_ADD_REQUEST';
export const KARABAKH_VISIT_ADD_SUCCESS = 'KARABAKH_VISIT_ADD_SUCCESS';
export const KARABAKH_VISIT_ADD_FAILURE = 'KARABAKH_VISIT_ADD_FAILURE';

/**
 * @description KARABAKH VISIT UPDATE action types
 */
export const KARABAKH_VISIT_UPDATE_REQUEST = 'KARABAKH_VISIT_UPDATE_REQUEST';
export const KARABAKH_VISIT_UPDATE_SUCCESS = 'KARABAKH_VISIT_UPDATE_SUCCESS';
export const KARABAKH_VISIT_UPDATE_FAILURE = 'KARABAKH_VISIT_UPDATE_FAILURE';

/**
 * @description KARABAKH VISIT DELETE action types
 */
export const KARABAKH_VISIT_DELETE_REQUEST = 'KARABAKH_VISIT_DELETE_REQUEST';
export const KARABAKH_VISIT_DELETE_SUCCESS = 'KARABAKH_VISIT_DELETE_SUCCESS';
export const KARABAKH_VISIT_DELETE_FAILURE = 'KARABAKH_VISIT_DELETE_FAILURE';

/**
 * @description KARABAKH VISIT HERO  action types
 */
export const FETCH_KARABAKH_HERO_REQUEST = 'FETCH_KARABAKH_HERO_REQUEST';
export const FETCH_KARABAKH_HERO_SUCCESS = 'FETCH_KARABAKH_HERO_SUCCESS';
export const FETCH_KARABAKH_HERO_FAILURE = 'FETCH_KARABAKH_HERO_FAILURE';

/**
 * @description KARABAKH HERO DETAIL FETCH  action types
 */
export const FETCH_KARABAKH_HERO_DETAIL_REQUEST = 'FETCH_KARABAKH_HERO_DETAIL_REQUEST';
export const FETCH_KARABAKH_HERO_DETAIL_SUCCESS = 'FETCH_KARABAKH_HERO_DETAIL_SUCCESS';
export const FETCH_KARABAKH_HERO_DETAIL_FAILURE = 'FETCH_KARABAKH_HERO_DETAIL_FAILURE';

/**
 * @description KARABAKH HERO ADD action types
 */
export const KARABAKH_HERO_ADD_REQUEST = 'KARABAKH_HERO_ADD_REQUEST';
export const KARABAKH_HERO_ADD_SUCCESS = 'KARABAKH_HERO_ADD_SUCCESS';
export const KARABAKH_HERO_ADD_FAILURE = 'KARABAKH_HERO_ADD_FAILURE';

/**
 * @description KARABAKH HERO UPDATE action types
 */
export const KARABAKH_HERO_UPDATE_REQUEST = 'KARABAKH_HERO_UPDATE_REQUEST';
export const KARABAKH_HERO_UPDATE_SUCCESS = 'KARABAKH_VISIT_UPDATE_SUCCESS';
export const KARABAKH_HERO_UPDATE_FAILURE = 'KARABAKH_VISIT_UPDATE_FAILURE';

/**
 * @description KARABAKH HERO DELETE action types
 */
export const KARABAKH_HERO_DELETE_REQUEST = 'KARABAKH_HERO_DELETE_REQUEST';
export const KARABAKH_HERO_DELETE_SUCCESS = 'KARABAKH_HERO_DELETE_SUCCESS';
export const KARABAKH_HERO_DELETE_FAILURE = 'KARABAKH_HERO_DELETE_FAILURE';

/**
 * @description KARABAKH VISIT ABOUT FETCH  action types
 */
export const FETCH_KARABAKH_ABOUT_DETAIL_REQUEST = 'FETCH_KARABAKH_ABOUT_DETAIL_REQUEST';
export const FETCH_KARABAKH_ABOUT_DETAIL_SUCCESS = 'FETCH_KARABAKH_ABOUT_DETAIL_SUCCESS';
export const FETCH_KARABAKH_ABOUT_DETAIL_FAILURE = 'FETCH_KARABAKH_ABOUT_DETAIL_FAILURE';

/**
 * @description KARABAKH VISIT ABOUT UPDATE  action types
 */
export const KARABAKH_ABOUT_UPDATE_REQUEST = 'KARABAKH_ABOUT_UPDATE_REQUEST';
export const KARABAKH_ABOUT_UPDATE_SUCCESS = 'KARABAKH_ABOUT_UPDATE_SUCCESS';
export const KARABAKH_ABOUT_UPDATE_FAILURE = 'KARABAKH_ABOUT_UPDATE_FAILURE';

/**
 * @description KARABAKH VISIT VICTORY FETCH  action types
 */
export const FETCH_KARABAKH_VICTORY_DETAIL_REQUEST = 'FETCH_KARABAKH_VICTORY_DETAIL_REQUEST';
export const FETCH_KARABAKH_VICTORY_DETAIL_SUCCESS = 'FETCH_KARABAKH_VICTORY_DETAIL_SUCCESS';
export const FETCH_KARABAKH_VICTORY_DETAIL_FAILURE = 'FETCH_KARABAKH_VICTORY_DETAIL_FAILURE';

/**
 * @description KARABAKH VISIT VICTORY UPDATE  action types
 */
export const KARABAKH_VICTORY_UPDATE_REQUEST = 'KARABAKH_VICTORY_UPDATE_REQUEST';
export const KARABAKH_VICTORY_UPDATE_SUCCESS = 'KARABAKH_VICTORY_UPDATE_SUCCESS';
export const KARABAKH_VICTORY_UPDATE_FAILURE = 'KARABAKH_VICTORY_UPDATE_FAILURE';

/**
 * @description KARABAKH ORGANIZATION FETCH  action types
 */
export const FETCH_KARABAKH_ORGANIZATION_REQUEST = 'FETCH_KARABAKH_ORGANIZATION_REQUEST';
export const FETCH_KARABAKH_ORGANIZATION_SUCCESS = 'FETCH_KARABAKH_ORGANIZATION_SUCCESS';
export const FETCH_KARABAKH_ORGANIZATION_FAILURE = 'FETCH_KARABAKH_ORGANIZATION_FAILURE';

/**
 * @description KARABAKH ORGANIZATION DETAIL FETCH  action types
 */
export const FETCH_KARABAKH_ORGANIZATION_DETAIL_REQUEST = 'FETCH_KARABAKH_ORGANIZATION_DETAIL_REQUEST';
export const FETCH_KARABAKH_ORGANIZATION_DETAIL_SUCCESS = 'FETCH_KARABAKH_ORGANIZATION_DETAIL_SUCCESS';
export const FETCH_KARABAKH_ORGANIZATION_DETAIL_FAILURE = 'FETCH_KARABAKH_ORGANIZATION_DETAIL_FAILURE';

/**
 * @description KARABAKH ORGANIZATION ADD action types
 */
export const KARABAKH_ORGANIZATION_ADD_REQUEST = 'KARABAKH_ORGANIZATION_ADD_REQUEST';
export const KARABAKH_ORGANIZATION_ADD_SUCCESS = 'KARABAKH_ORGANIZATION_ADD_SUCCESS';
export const KARABAKH_ORGANIZATION_ADD_FAILURE = 'KARABAKH_ORGANIZATION_ADD_FAILURE';


/**
 * @description KARABAKH MUNICIPALITIES ADD action types
 */
export const KARABAKH_MUNICIPALITIES_ADD_REQUEST = 'KARABAKH_MUNICIPALITIES_ADD_REQUEST';
export const KARABAKH_MUNICIPALITIES_ADD_SUCCESS = 'KARABAKH_MUNICIPALITIES_ADD_SUCCESS';
export const KARABAKH_MUNICIPALITIES_ADD_FAILURE = 'KARABAKH_MUNICIPALITIES_ADD_FAILURE';

/**
 * @description KARABAKH MUNICIPALITIES ADD action types
 */
export const KARABAKH_MUNICIPALITIES_UPDATE_REQUEST = 'KARABAKH_MUNICIPALITIES_UPDATE_REQUEST';
export const KARABAKH_MUNICIPALITIES_UPDATE_SUCCESS = 'KARABAKH_MUNICIPALITIES_UPDATE_SUCCESS';
export const KARABAKH_MUNICIPALITIES_UPDATE_FAILURE = 'KARABAKH_MUNICIPALITIES_UPDATE_FAILURE';

export const KARABAKH_MUNICIPALITIES_SHOW_REQUEST = 'KARABAKH_MUNICIPALITIES_SHOW_REQUEST';
export const KARABAKH_MUNICIPALITIES_SHOW_SUCCESS = 'KARABAKH_MUNICIPALITIES_SHOW_SUCCESS';
export const KARABAKH_MUNICIPALITIES_SHOW_FAILURE = 'KARABAKH_MUNICIPALITIES_SHOW_FAILURE';

export const KARABAKH_MUNICIPALITIES_LIST_REQUEST = 'KARABAKH_MUNICIPALITIES_LIST_REQUEST';
export const KARABAKH_MUNICIPALITIES_LIST_SUCCESS = 'KARABAKH_MUNICIPALITIES_LIST_SUCCESS';
export const KARABAKH_MUNICIPALITIES_LIST_FAILURE = 'KARABAKH_MUNICIPALITIES_LIST_FAILURE';
export const KARABAKH_MUNICIPALITIES_DELETE_REQUEST = 'KARABAKH_MUNICIPALITIES_DELETE_REQUEST';
export const KARABAKH_MUNICIPALITIES_DELETE_SUCCESS = 'KARABAKH_MUNICIPALITIES_DELETE_SUCCESS';
export const KARABAKH_MUNICIPALITIES_DELETE_FAILURE = 'KARABAKH_MUNICIPALITIES_DELETE_FAILURE';

/**
 * @description KARABAKH ORGANIZATION UPDATE action types
 */
export const KARABAKH_ORGANIZATION_UPDATE_REQUEST = 'KARABAKH_ORGANIZATION_UPDATE_REQUEST';
export const KARABAKH_ORGANIZATION_UPDATE_SUCCESS = 'KARABAKH_ORGANIZATION_UPDATE_SUCCESS';
export const KARABAKH_ORGANIZATION_UPDATE_FAILURE = 'KARABAKH_ORGANIZATION_UPDATE_FAILURE';

/**
 * @description KARABAKH ORGANIZATION DELETE action types
 */
export const KARABAKH_ORGANIZATION_DELETE_REQUEST = 'KARABAKH_ORGANIZATION_DELETE_REQUEST';
export const KARABAKH_ORGANIZATION_DELETE_SUCCESS = 'KARABAKH_ORGANIZATION_DELETE_SUCCESS';
export const KARABAKH_ORGANIZATION_DELETE_FAILURE = 'KARABAKH_ORGANIZATION_DELETE_FAILURE';

