import React, {Component} from 'react';
import {usersFetchData} from '../../../redux/actions/userAction';
import {connect} from 'react-redux';
import ButtonLoader from '../../loader/ButtonLoader';
import InputMask from 'react-input-mask';
import {appealFetchCategory} from '../../../redux/actions/appealAction';
import {filePostFetch} from '../../../redux/actions/fileAction';
import {handleInputError} from '../../../shared/helpers';

/**
 * @description Appeal form
 */
class AppealForm extends Component {
  state = {
    fileData: {}
  }
  componentDidMount() {
    this.props.getUsers();
    this.props.getCategories();
  }

  /**
   * @description file upload
   * @param {Object} e
   * @memberOf AppealForm
   */
  setFile = (e) =>  {
    const fileData  = new FormData();
    for (let i = 0; i < e.currentTarget.files.length; i++) {
      fileData.append('files[]', e.currentTarget.files[i])
    }
    this.setState({
      fileData
    })
    this.props.uploadFile(fileData)
  }

  /**
   * @description file delete
   * @param {Object} e
   * @memberOf Assignmentform
   */
  removeFiles = (token) => {
    const editedImages = this.props.formProps.values.images.filter((file) => file.token !== token);
    this.props.formProps.setFieldValue('images', editedImages);
  }
  render() {
    const {values, handleBlur, handleChange, handleSubmit, touched, errors} = this.props.formProps;
    const appealCategories = this.props.categories.appealCategories ? this.props.categories.appealCategories.map(category => {
      return (
        <option key={category.id} value={category.id}>{category.name}</option>
      )
    }) : null

    const fileNames = values.images ? values.images.map((file, index) => {
      return (
        <p className="file-item mr-3 mb-4" key={index}>
          <img src={`${process.env.REACT_APP_SERVER}/storage/` + file.path} alt={file.path}/>
          <button
            className="btn-remove"
            onClick={() => this.removeFiles(file.token)}
          >x</button>
        </p>
      )
    }) : ''
    return (
      <div className="add-form">
        <div className="row">
          <div className="form-group form-group-custom col-lg-6">
            <label htmlFor="title">Başlıq</label>
            <input
              type="text"
              name="title"
              value={values.title}
              onChange={handleChange}
              onBlur={handleBlur}
              onKeyDown={this.handleLoginKeyUp}
              className={'form-control form-control-custom ' + ((touched.title && errors.title) ? 'has-error' : null)}
              placeholder="Başlıq daxil edin"
            />
            {handleInputError('title', this.props.formProps.appeals.appeal, this.props.formProps)}
          </div>

          <div className="form-group form-group-custom col-lg-6">
            <label htmlFor="appeal_category_id">Kateqoriya</label>
            <select
              name="appeal_category_id"
              className={'form-control form-control-custom selectbox ' + ((touched.appeal_category_id && errors.appeal_category_id) ? 'has-error' : null)}
              value={values.appeal_category_id}
              onChange={handleChange}
              onBlur={handleBlur}
            >
              <option disabled value="">Kateqoriya seçin</option>
              {appealCategories}
            </select>
            {handleInputError('appeal_category_id', this.props.formProps.appeals.appeal, this.props.formProps)}
          </div>

          <div className="form-group form-group-custom col-lg-6">
            <label htmlFor="phone">Telefon</label>
            <InputMask
              mask="9999999999"
              className={'form-control form-control-custom ' + ((touched.phone && errors.phone) ? 'has-error' : null)}
              maskChar={null}
              name="phone"
              value={values.phone}
              onChange={handleChange}
              onBlur={handleBlur}
              beforeMaskedValueChange={this.beforeMaskedValueChange}
              placeholder="0501122111"
            />
            {handleInputError('phone', this.props.formProps.appeals.appeal, this.props.formProps)}
          </div>

          <div className="form-group form-group-custom col-lg-6">
            <label htmlFor="address">Ünvan</label>
            <input
              type="text"
              name="address"
              value={values.address}
              onChange={handleChange}
              onBlur={handleBlur}
              onKeyDown={this.handleLoginKeyUp}
              className={'form-control form-control-custom ' + ((touched.address && errors.address) ? 'has-error' : null)}
              placeholder="Ünvanı daxil edin"
            />
            {handleInputError('address', this.props.formProps.appeals.appeal, this.props.formProps)}
          </div>

          <div className="form-group form-group-custom col-lg-6">
            <label htmlFor="text">Text</label>
            <textarea
              className={'form-control form-control-custom ' + ((touched.text && errors.text) ? 'has-error' : null)}
              name="text"
              placeholder="Müraciətin mətni"
              value={values.text}
              onChange={handleChange}
              onBlur={handleBlur}
            />
            {handleInputError('text', this.props.formProps.appeals.appeal, this.props.formProps)}
          </div>
        </div>

        <div className="row">
          <div className="col-md-6 form-group form-group-custom">
            <div className="file-name d-flex justify-content-start flex-wrap">
              {fileNames}
            </div>
          </div>
          <div className="col-md-6 form-group form-group-custom over-hide">
            <div className="btn file-button">Şəkil yüklə</div>
            <input
              name="file"
              type="file"
              multiple
              placeholder="Şəkil yüklə"
              // value={values.images}
              onChange={(e) => { this.setFile(e) }}
              onBlur={handleBlur}
            />
          </div>
        </div>

        <div className="row">
          <div className="form-group form-group-custom col-lg-6">
            <button
              type="button"
              onClick={() => handleSubmit(values)}
              className="btn btn-theme w-100"
              disabled={this.props.isFetching ? true : null}
            >
              {this.props.files.isFetching ? <ButtonLoader /> : <span>Yadda saxla</span>}
            </button>
          </div>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch, ownProps) => ({
  getUsers: () => dispatch(usersFetchData()),
  getCategories: () => dispatch(appealFetchCategory()),
  uploadFile: file => dispatch(filePostFetch(file, ownProps))
})

const mapStateToProps = state => {
  return {
    users: state.userReducer,
    categories: state.appealReducer,
    files: state.fileReducer
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(AppealForm);
