import React, {Component} from 'react';
import {NavLink, Redirect} from 'react-router-dom';
import {connect} from 'react-redux';
import {statisticsFetchData} from '../../../redux/actions/statisticAction';
import Loader from '../../shared/Loader';
import {isUnAuthPublicEnabled} from '../../../redux/services/auth.service';
/**
 * @description Statistics component
 */
class Statistics extends Component {
  componentDidMount() {
    this.props.fetchStatistic()
  }

  render() {
    if (!isUnAuthPublicEnabled()) {
      return <Redirect to='/login' />
    }
    const statistics = this.props.statistics ?  this.props.statistics.map((statistic, i) => {
      if (statistic.value.details) {
        return (
          <div className="col-md-6 col-lg-4 col-xl-3" key={i}>
            <NavLink
              to={`/statistics/${statistic.value.key}`}
              exact
              className="objects-link"
            >
              <img src={`${process.env.REACT_APP_SERVER}/storage/` + statistic.value.image} alt={statistic.value.image} />
              <p className="count">{statistic.value.count}</p>
              <span className="mt-1">{statistic.value.title}</span>
            </NavLink>
          </div>
        )
      } else {
        return (
          <div className="col-md-6 col-lg-4 col-xl-3" key={i}>
            <div className="objects-link">
              <img src={`${process.env.REACT_APP_SERVER}/storage/` + statistic.value.image} alt={statistic.img} />
              <p className="count">{statistic.value.count}</p>
              <span className="mt-1">{statistic.value.title}</span>
            </div>
          </div>
        )
      }
    }) : <Loader />
    return (
      <div className="statistics">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12">
              <h4>Statistika</h4>
            </div>
          </div>
        </div>
        <div className="container-fluid">
          <div className="row">
            {statistics}
          </div>
        </div>

      </div>
    );
  }
}

const mapDispatchToProps = (dispatch, ownProps) => ({
  fetchStatistic: () => dispatch(statisticsFetchData(ownProps))
})

const mapStateToProps = state => {
  return state.statisticReducer;
}

export default connect(mapStateToProps, mapDispatchToProps)(Statistics);
