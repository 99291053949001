import React from 'react';
import Login from '../../auth/Login';
import {isUnAuthPublicEnabled} from '../../../redux/services/auth.service';
import {Redirect} from 'react-router-dom';

/**
 * @description Component Class for modal success
 */

const PreLogin = (props) => {
  if (!isUnAuthPublicEnabled()) {
    return <Redirect to='/login' />
  }

  return (
    <div className="container-fluid">
      <div className="row">

        <div className="col-lg-12">
          <div className="whitebox">

            <section className="whitebox-body">
              <article className="clr_blue">
                <h3>
                  Bu səhifəni görmək üçün giriş etmək lazımdır!
                </h3>
              </article>

              <div className="row">
                <div className="col-md-7">
                  <Login ownProps={props} />
                </div>
              </div>
            </section>

          </div>
        </div>
      </div>
    </div>
  )
}
export default PreLogin;
