import React, {Component} from 'react';
import {connect} from 'react-redux';
import {
  taskCommentPostData,
  taskCommentsFetchData
} from '../../../../redux/actions/commentsAction';
import CommentBox from '../../comment/CommentBox';

/**
 * @description Component Class for CommentBox detail for tasks
 */
class TaskComment extends Component {
  componentDidMount() {
    this.props.getTaskComments(this.props.paramId)
  }
  render() {
    return (
      <CommentBox
        comments={this.props.comments}
        addComment={this.props.addComment}
      />
    );
  }
}

const mapDispatchToProps = (dispatch, ownProps) => ({
  addComment: commentData => dispatch(taskCommentPostData(commentData, ownProps)),
  getTaskComments: id => dispatch(taskCommentsFetchData(id))
});

const mapStateToProps = state => {
  return state.taskCommentsReducer;
};

export default connect(mapStateToProps, mapDispatchToProps)(TaskComment);

