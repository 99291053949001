import React from 'react';
import {connect} from 'react-redux';
import InputError from '../../errors/InputError';
import {activityCategoryFetchData} from '../../../../redux/actions/transportAction';
import ButtonLoader from '../../../loader/ButtonLoader';

class TransportForm extends React.Component {
  state = {
    stations: []
  }
  componentDidMount() {
    this.props.fetchActivityCategory();
  }

  /**
   * @description all errors for one input
   * @param name
   * @returns {*}
   */
  handleInputError = (name) => {
    let errorData = this.props.errorData && this.props.errorData.data ? this.props.errorData.data : [];
    let {touched, errors} = this.props.formProps;

    if (touched[name] && errors[name]) {
      errorData[name] = [errors[name]];
    }

    return <InputError errors={errorData} name={name}/>
  }

  render() {
    const {values, handleSubmit, handleBlur, handleChange, touched, errors} = this.props.formProps;
    const activityCategory = this.props.activityCategories ? this.props.activityCategories.map(category => {
      return (
        <option key={category.id} value={category.id}>{category.name}</option>
      )
    }) : null
    return (
      <div className="add-form">
        <div className="form-group form-group-custom">
          <label htmlFor="category_id">Kateqoriya</label>
          <select
            name="category_id"
            className={'form-control form-control-custom selectbox mb-3'}
            defaultValue={values.category_id}
            onChange={handleChange}
            onBlur={handleBlur}
          >
            {activityCategory}
          </select>
          {this.handleInputError('category_id')}
        </div>
        <div className="form-group form-group-custom">
          <label htmlFor="name">Marşrut adı</label>
          <input
            type="text"
            name="name"
            className={'form-control form-control-custom ' + ((touched.name && errors.name) ? 'has-error' : null)}
            placeholder="Obyektin adını qeyd edin"
            value={values.name || ''}
            onChange={handleChange}
            onBlur={handleBlur}
          />
          {this.handleInputError('name')}
        </div>
        <div className="form-group form-group-custom">
          <label htmlFor="description">Ətraflı</label>
          <textarea
            name="description"
            className={'form-control form-control-custom ' + ((touched.description && errors.description) ? 'has-error' : null)}
            placeholder="Ətraflı"
            value={values.description || ''}
            onChange={handleChange}
            onBlur={handleBlur}
          />
          {this.handleInputError('description')}
        </div>
        <div className="form-group form-group-custom">
          <label htmlFor="transport[period]">Vaxt periodu</label>
          <input
            type="text"
            className="form-control form-control-custom"
            name="transport[period]"
            value={values.transport.period || ''}
            placeholder="Vaxt"
            onChange={handleChange}
            onBlur={handleBlur}
          />
          {this.handleInputError('transport.period')}
        </div>
        <div className="form-group form-group-custom">
          <label htmlFor="transport[cost]">Qiyməti</label>
          <input
            type="text"
            className="form-control form-control-custom"
            name="transport[cost]"
            value={values.transport.cost || ''}
            placeholder="Qiymət"
            onChange={handleChange}
            onBlur={handleBlur}
          />
          {this.handleInputError('transport.cost')}
        </div>
        <hr/>
        <div className="form-group form-group-custom">
          {this.handleInputError('stations')}
          {values.stations ? values.stations.map((field, index) => {
            this.handleInputError(`stations.${index}.name`)
            this.handleInputError(`stations.${index}.type`)
            this.handleInputError(`stations.${index}.lat`)
            this.handleInputError(`stations.${index}.lng`)
            return null
          }) : null}
        </div>
        <div className="form-group form-group-custom">
          <button
            type="button"
            onClick={() => (handleSubmit(values))}
            className="btn btn-theme w-100"
            disabled={this.props.isFetching ? true : null}
          >
            {this.props.isFetching ? <ButtonLoader /> : <span>Yadda saxla</span>}
          </button>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch, ownProps) => ({
  fetchActivityCategory: () => dispatch(activityCategoryFetchData({definition: 'transport'}))
})

const mapStateToProps = state => {
  return state.transportReducer
}

export default connect(mapStateToProps, mapDispatchToProps)(TransportForm);
