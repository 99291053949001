import React from 'react';
import {Map, GoogleApiWrapper, Marker} from 'google-maps-react';
import {MapOptions} from '../../../../MapEnv';
import {ModalContext} from '../../../../context/context';
import GoogleMapComponent from '../../map/GoogleMapComponent';

/**
 * @description Component Class for Map (using for adding objects by clicking to map)
 */
class AddLocationMap extends GoogleMapComponent {
  static contextType = ModalContext;

  state = {
    marker: {
      title: '',
      name: '',
      position: {lat: '', lng: ''}
    }
  }

  /**
   * @description gets clicked location from map and trigger onSelectCoords
   * @param t
   * @param map
   * @param {Object} coord clicked location
   * @memberOf AddLocationMap
   */
  getCurrentLocation = (t, map, coord) => {
    if (!this.context.state.detailObjectMap) {
      const {latLng} = coord;
      const lat = latLng.lat();
      const lng = latLng.lng();
      this.props.onSelectCoords(lat, lng);

      this.setState(previousState => {
        return {
          ...previousState.marker,
          marker: {
            title: '',
            name: '',
            position: {lat, lng}
          }
        }
      });
    }
  }

  render() {
    const {mapStyles, mapCenterDetail} = MapOptions();
    return (
      <Map
        google={this.props.google}
        zoom={Number(process.env.REACT_APP_MAP_ZOOM)}
        style={mapStyles}
        initialCenter={mapCenterDetail}
        onClick={this.getCurrentLocation}
      >

        {
          this.props.coords ?
            <Marker
              title={this.state.marker.title}
              name={this.state.marker.name}
              position={this.props.coords}
            /> : null
        }

        <Marker
          title={this.state.marker.title}
          name={this.state.marker.name}
          position={this.state.marker.position}
        />
      </Map>
    );
  }
}

export default GoogleApiWrapper({
  apiKey: process.env.REACT_APP_MAP_KEY
})(AddLocationMap);
