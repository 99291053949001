import React, {Component} from 'react';
import {connect} from 'react-redux';

/**
 * @description display all input errors from front-end and back-end validations
 */
class InputError extends Component {
  render() {
    const {errors, name} = this.props;
    return (errors && errors[name] && errors[name].length) ?
      <p className='error'>
        {
          errors[name].map(error =>
            <span key={error}>{error}</span>)
        }
      </p> : null
  }
}

export default connect(null, null)(InputError);
