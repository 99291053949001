import React, {Component} from 'react';
import {connect} from 'react-redux';
import shapeUser from '../../../../assets/img/shape_user.svg';
import shapeCombine from '../../../../assets/img/shape_combine.svg';
import shapeMarker from '../../../../assets/img/shape_marker.svg';
import shapePhone from '../../../../assets/img/shape_phone.svg';
import assign from '../../../../assets/img/assign.svg';
import accept from '../../../../assets/img/accept.svg';
import {appealFetchDetail, acceptAppeal, doneAppeal} from '../../../../redux/actions/appealAction';
import Forward from '../../forward/Forward';
import {checkBlock} from '../../../../shared/checkBlocks';
import AppealComment from '../comment/AppealComment';
import {Redirect} from 'react-router-dom';
import {isAuthenticated} from '../../../../redux/services/auth.service';
import {ModalContext} from '../../../../context/context';
import SuccessMessage from '../../../shared/SuccessMessage';
import {removeSuccessMessage} from '../../../../redux/actions/messageAction';
import Loader from '../../../shared/Loader';

/**
 * @description AppealDetail component
 */
class AppealDetail extends Component {
  static contextType = ModalContext;
  state = {
    showDropdownMenu: false,
    appeal_id: null,
    showAcceptBtn: true,
    showDoneBtn: false,
    showForwardBtn: false,
    successMessage: true
  }
  /**
   * @description component on init
   * @description adds event listener to dom
   * @memberOf AddObject
   */
  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside);
    const {id} = this.props.match.params
    this.props.appealFetchDetail(id);
    this.setState({appeal_id: id});
  }

  closeModal = () => {
    // setTimeout(() => {
    this.props.removeSuccess();
    // }, 5000);
  }

  showDropdownMenu = () => {
    if (this.state.showDropdownMenu) {
      this.setState({showDropdownMenu: false});
      return;
    }
    this.setState({showDropdownMenu: true});
  }

  /**
  * @description Set the wrapper ref
  */
 setWrapperRef = (node) => {
   this.wrapperRef = node;
 }

  handleClickOutside = (event) => {
    if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
      this.setState({showDropdownMenu: false});
    }
  }

  /**
  * @description accept appeal
  */
  acceptAppeal = () => {
    this.props.acceptAppeal(this.props.appeals.appealDetails.id);
    this.setState({showAcceptBtn: false, showDoneBtn: true})
  }

  /**
  * @description done appeal
  */
  doneAppeal = () => {
    this.props.doneAppeal(this.props.appeals.appealDetails.id);
  }

  render() {
    if (!isAuthenticated()) {
      return <Redirect to='/login' />
    }

    const images = this.props.appeals.appealDetails && this.props.appeals.appealDetails.images ? this.props.appeals.appealDetails.images.map((img, i) => {
      return (
        <div className="mb-4" key={i}>
          <img className="img-fluid" src={`${process.env.REACT_APP_SERVER}/storage/` + img.path} alt={img.path} />
        </div>
      )
    }) : null;
    return (
      <div className="container-fluid">
        <div className="row">

          <div className="col-lg-12">
            { this.props.appeals.appealDetails ?
              <div className="whitebox">
                <header
                  className="whitebox-header bordered d-md-flex align-items-center justify-content-between">
                  <div className="whitebox-title">
                    <h4>
                      {this.props.appeals.appealDetails.title}
                    </h4>
                    <ul className="breadcrumps">
                      <li className="breadcrumps-item">Vətəndaş müraciəti</li>
                      <li className="breadcrumps-item">{this.props.appeals.appealDetails.title}</li>
                    </ul>
                  </div>

                  {/* accept appeal */}
                  {this.props.appeals && checkBlock('appeal', 'appeal-accept') && (this.props.appeals.appealDetails.status.step === 1 && (this.state.showAcceptBtn)) ?
                    <button
                      className="btn btn-theme-default"
                      onClick={this.acceptAppeal}
                    >
                      <span>Qəbul et</span>
                      <img src={accept} alt={accept} />
                    </button> : null }

                  {/* done appeal  */}
                  {this.props.appeals && checkBlock('appeal', 'appeal-done') && (this.props.appeals.appealDetails.status.step === 2  || (this.state.showDoneBtn)) ?
                    <div className="d-lg-flex align-items-center justify-content-between">
                      <div>
                        <button
                          className="btn btn-theme-default"
                          onClick={this.doneAppeal}
                        >
                          <span>Həll et</span>
                          <img src={accept} alt={accept} />
                        </button>
                      </div>

                      {/* forward appeal */}
                      {checkBlock('appeal', 'appeal-forward') ?
                        <div className="dropdown-wrapper ml-lg-2">
                          <button
                            className="btn btn-theme-default"
                            onClick={this.showDropdownMenu}
                          >
                            <span>Müraciəti yönləndir</span>
                            <img src={assign} alt={assign} />
                          </button>

                          <div className={`dropdown dropdown-for-component ${this.state.showDropdownMenu ? 'show' : 'hidden'}`} ref={this.setWrapperRef}>
                            <Forward appealsProps={this.props.appeals} />
                          </div>
                        </div> : null }
                    </div> : null }

                  {/* forward appeal */}
                </header>
                {this.props.appeals.appealDoneData && this.props.appeals.appealDoneData.error ? <p className='error px-3'>{this.props.appeals.appealDoneData.error}</p> : null}

                <section className="whitebox-body">
                  <div className="row">
                    <div className="col-md-8">
                      <article>
                        <p>
                          {this.props.appeals.appealDetails.text}
                        </p>
                      </article>
                    </div>

                    <div className="col-md-4">
                      {images}
                    </div>

                    <div className="col-md-12">
                      <ul className="detail-info">
                        <li>
                          <img src={shapeUser} alt={shapeUser} />
                          Vətəndaş : {this.props.appeals.appealDetails.author.name}
                        </li>
                        <li>
                          <img src={shapeCombine} alt={shapeCombine} />
                          Kateqoriya : {this.props.appeals.appealDetails.category.name}
                        </li>
                        <li>
                          <img src={shapeMarker} alt={shapeMarker} />
                          Ünvan : {this.props.appeals.appealDetails.address}
                        </li>
                        <li>
                          <img src={shapePhone} alt={shapePhone} />
                          {this.props.appeals.appealDetails.phone}
                        </li>
                      </ul>
                    </div>
                  </div>
                </section>

                <section className="whitebox-footer">
                  <div className="container-fluid">

                    <div className="row">
                      <div className="col-12">
                        <AppealComment
                          paramId={this.state.appeal_id}
                          comments={this.props.appeals.appealDetails.comments}
                        />
                      </div>
                    </div>
                  </div>
                </section>
                { this.props.appeals.appealDoneStatus ?
                  <SuccessMessage
                    text='Uğurlu'
                    closeModal={this.closeModal}
                  /> : null }
              </div>
              : <Loader /> }
          </div>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch, ownProps) => ({
  appealFetchDetail: (paramId) => dispatch(appealFetchDetail(paramId, ownProps)),
  acceptAppeal: (paramId) => dispatch(acceptAppeal(paramId, ownProps)),
  doneAppeal: (paramId) => dispatch(doneAppeal(paramId, ownProps)),
  removeSuccess: () => dispatch(removeSuccessMessage())
})

const mapStateToProps = state => {
  return {
    appeals: state.appealReducer
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(AppealDetail);
