import React, {Component} from 'react';
import ReactPaginate from 'react-paginate';

/**
 * @description Pagination component
 */
class Pagination extends Component {
  handlePageClick = (data) => {
    this.props.callBack(data.selected + 1);
  }

  getPageRangeDisplay = () => {
    return (this.props.pageCount / this.props.perPage) || 0;
  }
  render() {
    return (
      <div>
        <nav aria-label="Page navigation">
          <ReactPaginate
            previousLabel={'Əvvəlki'}
            nextLabel={'Növbəti'}
            breakLabel={'...'}
            breakClassName={'break-me'}
            pageCount={this.props.pageCount}
            marginPagesDisplayed={2}
            pageRangeDisplayed={this.getPageRangeDisplay()}
            onPageChange={this.handlePageClick}
            containerClassName={'pagination'}
            pageClassName={'page-item'}
            subContainerClassName={'page-item'}
            previousClassName={'page-item'}
            nextClassName={'page-item'}
            previousLinkClassName={'page-link'}
            pageLinkClassName={'page-link'}
            nextLinkClassName={'page-link'}
            activeClassName={'active'}
          />
        </nav>
      </div>
    );
  }
}

export default Pagination;
