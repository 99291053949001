import React, {Component} from 'react';
import OpenModal from '../../shared/OpenModal';
import {connect} from 'react-redux';
import {NavLink, Redirect} from 'react-router-dom';
import {representativeNotesFetchData} from '../../../redux/actions/representativeAction';
import {isAuthenticated} from '../../../redux/services/auth.service';
import {checkBlock} from '../../../shared/checkBlocks';
import Loader from '../../shared/Loader';
import {DELETE_REPRESENTATIVE_REQUEST, DELETE_REPRESENTATIVE_SUCCESS, DELETE_REPRESENTATIVE_FAILURE} from '../../../redux/types/actionTypes';
import {deleteAction} from '../../../redux/actions/deleteAction';
import {REPRESENTATIVE_API} from '../../../redux/services/api.service';
import Pagination from '../pagination/Pagination';

/**
 * @description RepresentativeNotes component
 */
class RepresentativeNotes extends Component {
  state = {
    params: {
      page: 1,
      pageCount: 1,
      perPage: 10,
      statusId: 0,
      categoryId: 0
    }
  };

  componentDidMount() {
    this.fetchData();
  }

  removeObject = (ids) => {
    const idsArray = [];
    const types =
    {
      request: DELETE_REPRESENTATIVE_REQUEST,
      success: DELETE_REPRESENTATIVE_SUCCESS,
      failure: DELETE_REPRESENTATIVE_FAILURE
    };
    const api = REPRESENTATIVE_API;
    idsArray.push(ids);
    this.props.deleteObject(idsArray, types, api);

    document.getElementById(`note${ids}`).remove();
  }

  callBack = (page) => {
    this.handlePaginationState({page: page})

    this.fetchData()
  }

  handlePaginationState = (params) => {
    let state = this.state;

    Object.keys(params).forEach((key, value) => {
      state['params'][key] = params[key]
    })

    this.setState(state);
  }

  fetchData = () => {
    let params = {
      page: this.state.params.page,
      per_page: this.state.params.perPage
    };

    this.props.fetchRepresentatives(params).then(() => {
      if (this.props.meta) {
        let meta = this.props.meta;
        this.handlePaginationState({
          perPage: parseInt(meta.per_page),
          page: meta.current_page,
          pageCount: meta.last_page
        });
      }
    });
  }

  render() {
    if (!isAuthenticated()) {
      return <Redirect to='/login' />
    }

    const notes = this.props.notes ?
      this
        .props
        .notes
        .map((note) => {
          return (
            <tr key={note.id} id={`note${note.id}`}>
              <td>{note.user.name}</td>
              <td>{note.title}</td>
              <td>{note.address}</td>
              <td>
                <div className="d-flex align-items-center">
                  <NavLink
                    to={'notes/' + note.id}
                    className="btn btn-rounded"
                    activeClassName="active"
                  >
                    <i className="icon icon-detail" />
                  </NavLink>

                  {isAuthenticated() && checkBlock('notes', 'note-delete') ?
                    <button
                      type="button"
                      className="btn btn-rounded"
                      onClick={() => this.removeObject(note.id)}
                    >
                      <i className="icon icon-delete-2 mr-1"/>
                    </button>
                    : null }
                </div>
              </td>
            </tr>
          )
        }) : null
    return (
      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-12">
            <div className="whitebox">
              <header className="whitebox-header bordered">

                <div className="d-md-flex align-items-center justify-content-between">
                  <div className="whitebox-title mb-3 mb-0">
                    <h4>Nümayəndələrin qeydləri</h4>
                  </div>
                  <OpenModal modal="showNotesModal" text="Qeyd yarat" />
                </div>

              </header>

              <section className="whitebox-body">
                <div className="table-wrapper">
                  {this.props.notes ?
                    <table className="table table-custom">
                      <thead>
                        <tr>
                          <th>Nümayəndənin adı</th>
                          <th>Qeydin başlığı</th>
                          <th>Ünvan</th>
                          <th>Əməliyyat</th>
                        </tr>
                      </thead>

                      <tbody>
                        {notes}
                      </tbody>
                    </table> : <Loader />}
                  <Pagination
                    page={this.state.params.page}
                    perPage={this.state.params.perPage}
                    pageCount={this.state.params.pageCount}
                    callBack={this.callBack}
                  />
                </div>
              </section>

            </div>
          </div>
        </div>
      </div>
    );
  }
}
const mapDispatchToProps = (dispatch, ownProps) => ({
  fetchRepresentatives: (params) => dispatch(representativeNotesFetchData(params, ownProps)),
  deleteObject: (ids, types, api) => dispatch(deleteAction(ids, types, api))
})

const mapStateToProps = state => {
  return state.representativeReducer;
}

export default connect(mapStateToProps, mapDispatchToProps)(RepresentativeNotes);
