import React, {Component} from 'react';
import {connect} from 'react-redux';
import Loader from '../../shared/Loader';
import {ModalContext} from '../../../context/context';
import {legislationFetchList} from '../../../redux/actions/legislationActions';
import {checkBlock} from '../../../shared/checkBlocks';
import {NavLink} from 'react-router-dom';
import {isAuthenticated} from '../../../redux/services/auth.service';
import {DELETE_LEGISLATION_REQUEST, DELETE_LEGISLATION_SUCCESS, DELETE_LEGISLATION_FAILURE} from '../../../redux/types/actionTypes';
import {LEGISLATION_API} from '../../../redux/services/api.service';
import {deleteAction} from '../../../redux/actions/deleteAction';

/**
 * @description ActsInner component
 */
class LawList extends Component {
  static contextType = ModalContext;

  state = {
    categoryTitle: ''
  }

  removeLaw = (ids) => {
    const idsArray = [];
    idsArray.push(ids);
    const types =
    {
      request: DELETE_LEGISLATION_REQUEST,
      success: DELETE_LEGISLATION_SUCCESS,
      failure: DELETE_LEGISLATION_FAILURE
    };
    const api = LEGISLATION_API;
    this.props.deleteLaw(idsArray, types, api);

    document.getElementById(`law${ids}`).remove();
  }

  showDetail = (id) => {
    this.context.showLawDetailModal(true, id);
  }
  componentDidMount() {
    const {id} = this.props.match.params
    this.props.fetchLawList(id);
    let categories = JSON.parse(localStorage.getItem('law_categories'));
    let newCategoryArray = categories;

    for (let i = 0; i < categories.length; i++) {
      if (categories[i].children.length) {
        newCategoryArray = [...newCategoryArray, ...categories[i].children]
      }
    }

    newCategoryArray = newCategoryArray.filter((category) => {
      return category.id === Number(id)
    });

    this.setState({categoryTitle: newCategoryArray[0].name});
  }
  render() {
    const lawList = this.props.laws ? this.props.laws.map((law) =>
      <div key={law.id} id={`law${law.id}`} className="mb-4">
        <div className="col-md-12">
          <div className="row">
            <div className="col-md-3 left-column">
              <p className="category-title">{law.category.name}</p>
              {law.number ? <b className="number float-right">№ {law.number}</b> : null}
            </div>
            <div className="col-md-9">
              {isAuthenticated() && checkBlock('acts', 'legislation-delete') ?
                <button
                  className="btn btn-default float-right"
                  onClick={() => this.removeLaw(law.id)}
                >
                  <span className="d-lg-block">
                    <i className="icon icon-delete mr-2"/>
                  </span>
                </button> : null }
              {isAuthenticated() && checkBlock('acts', 'legislation-edit') ?
                <NavLink
                  to={`/acts/edit/${law.id}`}
                  exact
                  className="btn btn-default float-right"
                >
                  <span className="d-lg-block">
                    <i className="icon icon-edit mr-2"/>
                  </span>
                </NavLink> : null }
              <div className="cur-point" onClick={() => this.showDetail(law.id)}>
                <h4 className="law-title" dangerouslySetInnerHTML={{__html: law.title}} />
              </div>
            </div>
          </div>
        </div>
      </div>
    ) : <Loader />
    return (
      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-12">
            <div className="whitebox" id="legislation">
              <header className="whitebox-header bordered d-flex align-items-center justify-content-between">
                <div className="whitebox-title">
                  <h4>
                    {this.state.categoryTitle}
                  </h4>
                  <ul className="breadcrumps">
                    <li className="breadcrumps-item">Qanunvericilik</li>
                    <li className="breadcrumps-item">{this.state.categoryTitle}</li>
                  </ul>
                </div>
              </header>
              <section className="whitebox-body">
                <div className="row">
                  <div className="col-md-12">
                    <article className="clr_blue law">
                      {lawList}
                    </article>
                  </div>
                </div>
              </section>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch, ownProps) => ({
  fetchLawList: (id) => dispatch(legislationFetchList(id, ownProps)),
  deleteLaw: (ids, types, api) => dispatch(deleteAction(ids, types, api))
})

const mapStateToProps = state => {
  return state.legislationReducer;
}

export default connect(mapStateToProps, mapDispatchToProps)(LawList);
