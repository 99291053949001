import axios from 'axios';
import {
  ADD_COMMENTS_REQUEST,
  ADD_COMMENTS_SUCCESS,
  ADD_COMMENTS_FAILURE,
  FETCH_COMMENTS_REQUEST,
  FETCH_COMMENTS_SUCCESS,
  FETCH_COMMENTS_FAILURE,
  ADD_TASK_COMMENTS_REQUEST,
  ADD_TASK_COMMENTS_SUCCESS,
  ADD_TASK_COMMENTS_FAILURE,
  FETCH_TASK_COMMENTS_REQUEST,
  FETCH_TASK_COMMENTS_SUCCESS,
  FETCH_TASK_COMMENTS_FAILURE
} from '../types/actionTypes';
import {
  APPEAL_COMMENTS_API, TASK_COMMENTS_API
} from '../services/api.service';

/**
 * @description fetch all appeal comments
 * @param ownProps
 */
export const appealCommentsFetchData = (paramId, ownProps) => {
  return async dispatch => {
    dispatch({type: FETCH_COMMENTS_REQUEST});
    try {
      const {data} = await axios.get(APPEAL_COMMENTS_API, {
        params: {
          appeal_id: Number(paramId)
        }
      });
      dispatch({type: FETCH_COMMENTS_SUCCESS, payload: data.data.comments});
    } catch (error) {
      dispatch({type: FETCH_COMMENTS_FAILURE, payload: error});
    }
  }
}

/**
 * @description redux action for Comment Add
 * @param commentData
 * @param paramId
 * @param  {Object} ownProps
 */
export const appealCommentPostData = (commentData, paramId, ownProps) => {
  return async dispatch => {
    dispatch({type: ADD_COMMENTS_REQUEST});
    try {
      const {data} = await axios.post(APPEAL_COMMENTS_API, {
        text: commentData.text,
        appeal_id: Number(paramId.paramId)
      });
      dispatch({type: ADD_COMMENTS_SUCCESS, payload: data.data.comments});
    } catch (error) {
      dispatch({type: ADD_COMMENTS_FAILURE, payload: error.response.data.data});
    }
  }
}

/**
 * @description fetch all task comments
 * @param paramId
 * @param ownProps
 */
export const taskCommentsFetchData = (paramId, ownProps) => {
  return async dispatch => {
    dispatch({type: FETCH_TASK_COMMENTS_REQUEST});
    try {
      const {data} = await axios.get(TASK_COMMENTS_API, {
        params: {
          task_id: Number(paramId)
        }
      });
      dispatch({type: FETCH_TASK_COMMENTS_SUCCESS, payload: data.data.comments});
    } catch (error) {
      dispatch({type: FETCH_TASK_COMMENTS_FAILURE, payload: error});
    }
  }
}

/**
 * @description redux action for task Add
 * @param commentData
 * @param paramId
 */
export const taskCommentPostData = (commentData, paramId) => {
  return async dispatch => {
    dispatch({type: ADD_TASK_COMMENTS_REQUEST});
    try {
      const {data} = await axios.post(TASK_COMMENTS_API, {
        text: commentData.text,
        task_id: Number(paramId.paramId)
      });
      dispatch({type: ADD_TASK_COMMENTS_SUCCESS, payload: data.data.comments});
    } catch (error) {
      dispatch({type: ADD_TASK_COMMENTS_FAILURE, payload: error.response.data.data});
    }
  }
}

