import React, {Component} from 'react';
import {connect} from 'react-redux';
import {ModalContext} from '../../../../context/context';
import Loader from '../../../shared/Loader';
import CloseModal from '../../../shared/CloseModal';
import {legislationFetchDetailData} from '../../../../redux/actions/legislationActions';

/**
 * @description Component Class for Acts Detail
 */
class LawDetail extends Component {
static contextType = ModalContext;
componentDidMount() {
  this.props.fetchDetails(this.context.state.details);
}
render() {
  return (
    <div className="modal-custom">
      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-10 offset-lg-1">
            {this.props.lawDetails ?
              <div className="modal-custom-inner">
                <div className="modal-custom-header d-flex align-items-center justify-content-between">
                  <h4 className="law-title" dangerouslySetInnerHTML={{__html: this.props.lawDetails.title}} />
                  <CloseModal modal="showLawDetailModal" />
                </div>
                <div className="row">
                  <div className="col-md-12">
                    <div dangerouslySetInnerHTML={{__html: this.props.lawDetails.description}} />
                  </div>
                </div>

                <div className="row mt-4">
                  <div className="col-md-12">
                    {this.props.lawDetails.adopted_at}
                  </div>
                  {
                    this.props.lawDetails.number ?
                      <div className="col-md-12">
                            №: {this.props.lawDetails.number}
                      </div> :
                      null
                  }
                </div>
              </div> : <Loader />}
          </div>
        </div>
      </div>
    </div>
  );
}
}

const mapDispatchToProps = (dispatch, ownProps) => ({
  fetchDetails: id => dispatch(legislationFetchDetailData(id, ownProps))
});

const mapStateToProps = state => {
  return state.legislationReducer;
}

export default connect(mapStateToProps, mapDispatchToProps)(LawDetail);
