import {
  FETCH_VACANCY_REQUEST,
  FETCH_VACANCY_SUCCESS,
  FETCH_VACANCY_FAILURE,
  ADD_VACANCY_REQUEST,
  ADD_VACANCY_SUCCESS,
  ADD_VACANCY_FAILURE,
  FETCH_VACANCY_DETAIL_REQUEST,
  FETCH_VACANCY_DETAIL_SUCCESS,
  FETCH_VACANCY_DETAIL_FAILURE,
  FETCH_VACANCY_CATEGORY_REQUEST,
  FETCH_VACANCY_CATEGORY_SUCCESS,
  FETCH_VACANCY_CATEGORY_FAILURE,
  FETCH_CITY_REQUEST,
  FETCH_CITY_SUCCESS,
  FETCH_CITY_FAILURE,
  FETCH_EXPERIENCE_REQUEST,
  FETCH_EXPERIENCE_SUCCESS,
  FETCH_EXPERIENCE_FAILURE,
  FETCH_EDUCATION_REQUEST,
  FETCH_EDUCATION_SUCCESS,
  FETCH_EDUCATION_FAILURE,
  REMOVE_SUCCESS_MESSAGE,
  FETCH_RESUME_REQUEST,
  FETCH_RESUME_SUCCESS,
  FETCH_RESUME_FAILURE,
  ADD_RESUME_REQUEST,
  ADD_RESUME_SUCCESS,
  ADD_RESUME_FAILURE,
  DELETE_VACANCY_REQUEST,
  DELETE_VACANCY_SUCCESS,
  DELETE_VACANCY_FAILURE,
  FETCH_RESUME_DETAIL_REQUEST,
  FETCH_RESUME_DETAIL_SUCCESS,
  FETCH_RESUME_DETAIL_FAILURE,
  DELETE_RESUME_REQUEST,
  DELETE_RESUME_SUCCESS,
  DELETE_RESUME_FAILURE,
  EDIT_VACANCY_REQUEST,
  EDIT_VACANCY_SUCCESS,
  EDIT_VACANCY_FAILURE
} from '../types/actionTypes';

/**
 * @description initial state for social vacancy
 */
const initialState = {};

/**
 * @description updates state depending on action data
 * @param {Object} state = initialState
 * @param {string} type = action.type
 * @param {} payload
 * @param {Array} statuses
 * @returns return state
 */
export default function socialReducer(state = initialState, {type, payload}) {
  switch (type) {
  case ADD_VACANCY_REQUEST:
    return {
      ...state,
      isFetching: true,
      success: false,
      vacancies: payload
    };
  case ADD_VACANCY_SUCCESS:
    return {
      ...state,
      isFetching: false,
      success: true,
      vacancies: payload
    };
  case ADD_VACANCY_FAILURE:
    return {
      ...state,
      isFetching: false,
      success: false,
      vacancies: payload
    };

  case EDIT_VACANCY_REQUEST:
    return {
      ...state,
      isFetching: true,
      success: false,
      vacancies: payload
    };
  case EDIT_VACANCY_SUCCESS:
    return {
      ...state,
      isFetching: false,
      success: true,
      vacancies: payload
    };
  case EDIT_VACANCY_FAILURE:
    return {
      ...state,
      isFetching: false,
      success: false,
      vacancies: payload
    };

  case DELETE_VACANCY_REQUEST:
    return {
      ...state,
      success: false,
      isFetching: true
    };
  case DELETE_VACANCY_SUCCESS:
    return {
      ...state,
      success: true,
      isFetching: false
    };
  case DELETE_VACANCY_FAILURE:
    return {
      ...state,
      success: false,
      isFetching: false,
      errors: payload
    };

  case FETCH_VACANCY_REQUEST:
    return {
      ...state,
      isFetching: true,
      vacancies: payload
    };
  case FETCH_VACANCY_SUCCESS:
    return {
      ...state,
      isFetching: false,
      vacancies: payload
    };
  case FETCH_VACANCY_FAILURE:
    return {
      ...state,
      isFetching: false,
      vacancies: payload
    };

  case FETCH_VACANCY_DETAIL_REQUEST:
    return {
      ...state,
      isFetching: true,
      vacancy: payload
    };
  case FETCH_VACANCY_DETAIL_SUCCESS:
    return {
      ...state,
      isFetching: false,
      vacancy: payload
    };
  case FETCH_VACANCY_DETAIL_FAILURE:
    return {
      ...state,
      isFetching: false,
      vacancy: payload
    };

  case FETCH_VACANCY_CATEGORY_REQUEST:
    return {
      ...state,
      isFetching: true,
      categories: payload
    };
  case FETCH_VACANCY_CATEGORY_SUCCESS:
    return {
      ...state,
      isFetching: false,
      categories: payload
    };
  case FETCH_VACANCY_CATEGORY_FAILURE:
    return {
      ...state,
      isFetching: false,
      categories: payload
    };

  case FETCH_CITY_REQUEST:
    return {
      ...state,
      isFetching: true,
      cities: payload
    };
  case FETCH_CITY_SUCCESS:
    return {
      ...state,
      isFetching: false,
      cities: payload
    };
  case FETCH_CITY_FAILURE:
    return {
      ...state,
      isFetching: false,
      cities: payload
    }

  case FETCH_EXPERIENCE_REQUEST:
    return {
      ...state,
      isFetching: true,
      experiences: payload
    };
  case FETCH_EXPERIENCE_SUCCESS:
    return {
      ...state,
      isFetching: false,
      experiences: payload
    };
  case FETCH_EXPERIENCE_FAILURE:
    return {
      ...state,
      isFetching: false,
      experiences: payload
    };

  case FETCH_EDUCATION_REQUEST:
    return {
      ...state,
      isFetching: true,
      education: payload
    };
  case FETCH_EDUCATION_SUCCESS:
    return {
      ...state,
      isFetching: false,
      education: payload
    };
  case FETCH_EDUCATION_FAILURE:
    return {
      ...state,
      isFetching: false,
      education: payload
    };

  case ADD_RESUME_REQUEST:
    return {
      ...state,
      isFetching: true,
      success: false,
      resumes: payload
    };
  case ADD_RESUME_SUCCESS:
    return {
      ...state,
      isFetching: false,
      success: true,
      resumes: payload
    };
  case ADD_RESUME_FAILURE:
    return {
      ...state,
      isFetching: false,
      success: false,
      resumes: payload
    };

  case DELETE_RESUME_REQUEST:
    return {
      ...state,
      success: false,
      isFetching: true
    };
  case DELETE_RESUME_SUCCESS:
    return {
      ...state,
      success: true,
      isFetching: false
    };
  case DELETE_RESUME_FAILURE:
    return {
      ...state,
      success: false,
      isFetching: false,
      errors: payload
    };

  case FETCH_RESUME_REQUEST:
    return {
      ...state,
      isFetching: true,
      resumes: payload
    };
  case FETCH_RESUME_SUCCESS:
    return {
      ...state,
      isFetching: false,
      resumes: payload
    };
  case FETCH_RESUME_FAILURE:
    return {
      ...state,
      isFetching: false,
      resumes: payload
    };

  case FETCH_RESUME_DETAIL_REQUEST:
    return {
      ...state,
      isFetching: true,
      resume: payload
    };
  case FETCH_RESUME_DETAIL_SUCCESS:
    return {
      ...state,
      isFetching: false,
      resume: payload
    };
  case FETCH_RESUME_DETAIL_FAILURE:
    return {
      ...state,
      isFetching: false,
      resume: payload
    };

  case REMOVE_SUCCESS_MESSAGE:
    return {
      ...state,
      success:false
    };
  default:
    return state
  }
}
