import React, {Component} from 'react';
import {connect} from 'react-redux';
import {withFormik} from 'formik';
import ModalSuccess from '../../../shared/ModalSuccess';
import {removeSuccessMessage} from '../../../../redux/actions/messageAction';
import {lawsSchema} from '../../../../shared/validation';
import {
  legislationFetchCategories,
  legislationPostData
} from '../../../../redux/actions/legislationActions';
import LawForm from '../LawForm';

/**
 * @description AddLaw component
 */
class AddLaw extends Component {
  render() {
    return (
      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-12">
            <div className="whitebox"  id="legislation">
              <header className="whitebox-header bordered">
                <div className="whitebox-title ">
                  <h4>
                    Qanun yarat
                  </h4>
                  <ul className="breadcrumps">
                    <li className="breadcrumps-item">Qanunvericilik</li>
                    <li className="breadcrumps-item">Qanun yarat</li>
                  </ul>
                </div>

              </header>
              <LawForm formProps={this.props}/>
              {this.props.success ? <ModalSuccess text="Yeni Qanun əlavə edildi" /> : null }
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapPropsToValues = (props) => {
  return {
    category_id: '',
    title: '',
    description: '',
    number: '',
    adopted_at: ''
  }
}

const LawAddForm = withFormik({
  enableReinitialize: false,
  mapPropsToValues,
  validationSchema: lawsSchema,
  validateOnBlur: true,
  validateOnChange: true,
  handleSubmit: (values, {props, resetForm, setSubmitting}) => {
    props.addLaw(values);

    setTimeout(() => {
      props.removeSuccess();
    }, 4000);
  }
})(AddLaw);

const mapDispatchToProps = (dispatch) => ({
  fetchLegislationCategories: () => dispatch(legislationFetchCategories()),
  addLaw: (lawData) => dispatch(legislationPostData(lawData)),
  removeSuccess: () => dispatch(removeSuccessMessage())
});

const mapStateToProps = state => {
  return state.legislationReducer;
};

export default connect(mapStateToProps, mapDispatchToProps)(LawAddForm);

