import React, {Component} from 'react';
import {connect} from 'react-redux';
import {withFormik} from 'formik';
import ModalSuccess from '../../../shared/ModalSuccess';
import {removeSuccessMessage} from '../../../../redux/actions/messageAction';
import LawForm from '../LawForm';
import {
  legislationEditData,
  legislationFetchCategories,
  legislationFetchDetailData
} from '../../../../redux/actions/legislationActions';
import {lawsSchema} from '../../../../shared/validation';

/**
 * @description EditLaw component
 */
class EditLaw extends Component {
  componentDidMount() {
    const {id} = this.props.match.params;
    this.props.fetchLawDetail(id);

    if (this.props.lawDetails) {
      const law = this.props.lawDetails;
      this.props.setValues({
        'id': law.category_id,
        'category_id': law.category_id,
        'title': law.title,
        'description': law.description,
        'number': law.number,
        'adopted_at': law.adopted_at
      });
    }
  }
  render() {
    return (
      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-12">
            <div className="whitebox"  id="legislation">
              <header className="whitebox-header bordered">
                <div className="whitebox-title ">
                  <h4>
                    Qanun redaktə etmək
                  </h4>
                  <ul className="breadcrumps">
                    <li className="breadcrumps-item">Qanunvericilik</li>
                    <li className="breadcrumps-item">Redaktə etmək</li>
                  </ul>
                </div>

              </header>
              <LawForm formProps={this.props}/>
              {this.props.success ? <ModalSuccess text="Qanun readktə edildi" /> : null }
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapPropsToValues = (props) => {
  if (props.lawDetails) {
    return {
      id: props.lawDetails.id,
      category_id: props.lawDetails.category_id,
      title: props.lawDetails.title,
      description: props.lawDetails.description,
      number: props.lawDetails.number,
      adopted_at: props.lawDetails.adopted_at
    }
  }
  return {
    id: '',
    category_id: '',
    title: '',
    description: '',
    number: '',
    adopted_at: ''
  }
}

const LawEditForm = withFormik({
  enableReinitialize: true,
  mapPropsToValues,
  validationSchema: lawsSchema,
  validateOnBlur: true,
  validateOnChange: true,
  handleSubmit: (values, {props, resetForm, setSubmitting}) => {
    props.updateLaw(values);

    setTimeout(() => {
      props.removeSuccess();
    }, 4000);
  }
})(EditLaw);

const mapDispatchToProps = (dispatch) => ({
  fetchCategories: () => dispatch(legislationFetchCategories()),
  fetchLawDetail: (id) => dispatch(legislationFetchDetailData(id)),
  updateLaw: (lawData) => dispatch(legislationEditData(lawData)),
  removeSuccess: () => dispatch(removeSuccessMessage())
});

const mapStateToProps = state => {
  return state.legislationReducer;
}

export default connect(mapStateToProps, mapDispatchToProps)(LawEditForm);

