import axios from 'axios'
import {
  FETCH_TRANSPORT_REQUEST,
  FETCH_TRANSPORT_SUCCESS,
  FETCH_TRANSPORT_FAILURE,
  ADD_TRANSPORT_REQUEST,
  ADD_TRANSPORT_SUCCESS,
  ADD_TRANSPORT_FAILURE,
  EDIT_TRANSPORT_REQUEST,
  EDIT_TRANSPORT_SUCCESS,
  EDIT_TRANSPORT_FAILURE,
  FETCH_ACTIVITY_CATEGORY_REQUEST,
  FETCH_ACTIVITY_CATEGORY_SUCCESS,
  FETCH_ACTIVITY_CATEGORY_FAILURE
} from '../types/actionTypes'
import {
  ACTIVITY_CATEGORY_API,
  TRANSPORT_API,
  TRANSPORT_API_BY_ID
} from '../services/api.service'

/**
 * @description fetch all transport
 * @param ownProps
 * @returns {function(...[*]=)}
 */
export const transportFetchData = (ownProps) => {
  return async dispatch => {
    dispatch({type: FETCH_TRANSPORT_REQUEST})
    try {
      const {data} = await axios.get(TRANSPORT_API)
      dispatch({type: FETCH_TRANSPORT_SUCCESS, payload: data.data.useful})
    } catch (error) {
      dispatch({type: FETCH_TRANSPORT_FAILURE, payload: error})
    }
  }
}

/**
 * @description add new transport
 * @param transportData
 * @param ownProps
 * @returns {function(...[*]=)}
 */
export const transportPostData = (transportData, ownProps) => {
  return async dispatch => {
    dispatch({type: ADD_TRANSPORT_REQUEST})
    try {
      const {data} = await axios.post(TRANSPORT_API, transportDataRestoration(transportData))
      dispatch({type: ADD_TRANSPORT_SUCCESS, payload: data.data.useful})
    } catch (error) {
      dispatch({type: ADD_TRANSPORT_FAILURE, payload: error.response.data})
    }
  }
}

/**
 * @description update transport
 * @param transportData
 * @param ownProps
 * @returns {function(...[*]=)}
 */
export const transportEditData = (transportData, ownProps) => {
  return async dispatch => {
    dispatch({type: EDIT_TRANSPORT_REQUEST})
    try {
      const {data} = await axios.put(TRANSPORT_API_BY_ID(transportData.id), transportDataRestoration(transportData))
      dispatch({type: EDIT_TRANSPORT_SUCCESS, payload: data.data.useful})
    } catch (error) {
      dispatch({type: EDIT_TRANSPORT_FAILURE, payload: error.response.data})
    }
  }
}

/**
 * @description remove transports by id[]
 * @param transportIds
 * @param ownProps
 * @returns {function(...[*]=)}
 */
export const transportRemove = (transportIds, ownProps) => {
  return async dispatch => {
    dispatch({type: ADD_TRANSPORT_REQUEST})
    try {
      const {data} = await axios.delete(TRANSPORT_API, {data: transportIds})
      dispatch({type: ADD_TRANSPORT_SUCCESS, payload: data.data})
    } catch (error) {
      dispatch({type: ADD_TRANSPORT_FAILURE, payload: error})
    }
  }
}

/**
 * Get activity category for transport
 * @param activityCatParams
 * @param ownProps
 * @returns {function(...[*]=)}
 */
export const activityCategoryFetchData = (activityCatParams, ownProps) => {
  return async dispatch => {
    dispatch({type: FETCH_ACTIVITY_CATEGORY_REQUEST})
    try {
      const {data} = await axios.get(ACTIVITY_CATEGORY_API, {params: activityCatParams || {}})
      dispatch({type: FETCH_ACTIVITY_CATEGORY_SUCCESS, payload: data.data.activityCategories})
    } catch (error) {
      dispatch({type: FETCH_ACTIVITY_CATEGORY_FAILURE, payload: error})
    }
  }
}

/**
 * @description restore transport data
 * @param transportData
 */
const transportDataRestoration = (transportData) => {
  transportData.stations.forEach((station, index) => {
    station.lat = station.position.lat
    station.lng = station.position.lng
  })

  return transportData
}
