import React, { Component, useEffect, useState } from "react";
import { Link, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { Formik } from "formik";
import { loginSchema } from "../../shared/validation";
import ButtonLoader from "../loader/ButtonLoader";
import { userLoginFetch } from "../../redux/actions/userAction";
import { isAuthenticated } from "../../redux/services/auth.service";
import ReCAPTCHA from "react-google-recaptcha";



/**
 * @description Component Class for Login
 */
class Login extends Component {

  state = {
    hasError: "",
    userData: {
      username: "",
      password: "",
     recaptcha:  ""
    }
  };

  
  /**
   * @description handles backend error on key event (sets null)
   * @memberOf Login
   */
  handleLoginKeyUp = () => {
    if (this.props.user && this.props.user.error) {
      this.props.user.error = null;
    }
  };

  /**
   * @description for Formik usable form management for react
   * @returns Form
   * @memberOf Login
   */
  render() {
   
    if (isAuthenticated()) {
      return <Redirect to="/dashboard" />;
    }
    return (
      <Formik
        initialValues={this.state.userData}
        validationSchema={loginSchema}
        onSubmit={(userData) => {
          const data = {...this.state.userData, username:userData.username,password:userData.password };
          this.setState(data);
          this.props.userLogin(data);
          
        }}
      >
        {(props) => (
          
          <form   onSubmit={props.handleSubmit}>
              

            <div className="form-group form-group-custom">
              <label htmlFor="username">İstifadəçi adı</label>
              <input
                type="text"
                name="username"
                value={props.values.username}
                onChange={props.handleChange}
                onBlur={props.handleBlur}
                onKeyDown={this.handleLoginKeyUp}
                className={
                  "form-control form-control-custom"
                }
                placeholder="İstifadəçi adını daxil edin"
              />
            
            </div>
  
            <div className="form-group form-group-custom">
          
              <label htmlFor="password">Şifrə</label>
              <input
                type="password"
                name="password"
                value={props.values.password}
                onChange={props.handleChange}
                onBlur={props.handleBlur}
                onKeyDown={this.handleLoginKeyUp}
                className={
                  "form-control form-control-custom" 
                }
                placeholder="Şifrəni daxil edin"
              />
              {this.props.user &&
                this.props.user.error ? 
                  window.location.reload()
                 : null }
              <p className="error">
           
              {this.props.user &&
              this.props.user.error
                ? 
                <p className="error">
                Epoçt və ya şifrə yanlışdır!
             </p>  : null}
            </p>
              <ReCAPTCHA  onChange={event => {
                this.setState({userData:{...this.state.userData, recaptcha:event}},function(){
                 
                })
              }} name="g-recaptcha" className="g-recaptcha"  sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}/>
            </div>

            <div className="form-group form-group-custom">
              <button
                type="submit"
                className="btn btn-theme w-100 mt-2 mt-md-5"
                disabled={this.props.isFetching ? true : null}
                
              >
                {this.props.isFetching ? <ButtonLoader /> : <span>Giriş</span>}
              </button>
            </div>

            <Link to="/register" className="forget-password text-center">
              Qeydiyyat
            </Link>
          </form>
        )}
      </Formik>
    );
  }
}

const mapDispatchToProps = (dispatch, ownProps) => ({
  userLogin: (userData) => dispatch(userLoginFetch(userData, ownProps)),
});

const mapStateToProps = (state) => {
  return state.userReducer;
};

export default connect(mapStateToProps, mapDispatchToProps)(Login);
