import {
  FETCH_LEGISLATION_REQUEST,
  FETCH_LEGISLATION_SUCCESS,
  FETCH_LEGISLATION_FAILURE,
  FETCH_LEGISLATION_DETAIL_REQUEST,
  FETCH_LEGISLATION_DETAIL_SUCCESS,
  FETCH_LEGISLATION_DETAIL_FAILURE,
  FETCH_LEGISLATION_CATEGORY_REQUEST,
  FETCH_LEGISLATION_CATEGORY_SUCCESS,
  FETCH_LEGISLATION_CATEGORY_FAILURE,
  ADD_LEGISLATION_REQUEST,
  ADD_LEGISLATION_SUCCESS,
  ADD_LEGISLATION_FAILURE,
  DELETE_LEGISLATION_REQUEST,
  DELETE_LEGISLATION_SUCCESS,
  DELETE_LEGISLATION_FAILURE,
  REMOVE_SUCCESS_MESSAGE, EDIT_LEGISLATION_REQUEST, EDIT_LEGISLATION_SUCCESS, EDIT_LEGISLATION_FAILURE
} from '../types/actionTypes';

/**
 * @description initial state for normativeReducer
 */
const initialState = {};

/**
 * @description updates state depending on action data
 * @param {Object} state = initialState
 * @param {string} type = action.type
 * @param {} payload
 * @param {Array} normative laws
 * @returns return state
 */
export default function legislationReducer(state = initialState, {type, payload}) {
  switch (type) {
  case ADD_LEGISLATION_REQUEST:
    return {
      ...state,
      isFetching: true,
      success: false
    };
  case ADD_LEGISLATION_SUCCESS:
    return {
      ...state,
      isFetching: false,
      success: true
    };
  case ADD_LEGISLATION_FAILURE:
    return {
      ...state,
      isFetching: false,
      law: payload,
      success: false
    };
  case EDIT_LEGISLATION_REQUEST:
    return {
      ...state,
      isFetching: true,
      success: false
    };
  case EDIT_LEGISLATION_SUCCESS:
    return {
      ...state,
      isFetching: false,
      success: true
    };
  case EDIT_LEGISLATION_FAILURE:
    return {
      ...state,
      isFetching: false,
      law: payload,
      success: false
    };

  case DELETE_LEGISLATION_REQUEST:
    return {
      ...state,
      isFetching: true,
      success: false
    };
  case DELETE_LEGISLATION_SUCCESS:
    return {
      ...state,
      isFetching: false,
      success: true
    };
  case DELETE_LEGISLATION_FAILURE:
    return {
      ...state,
      isFetching: false,
      law: payload,
      success: false
    };

  case FETCH_LEGISLATION_REQUEST:
    return {
      ...state,
      isFetching: true,
      laws: payload
    };
  case FETCH_LEGISLATION_SUCCESS:
    return {
      ...state,
      isFetching: false,
      laws: payload
    };
  case FETCH_LEGISLATION_FAILURE:
    return {
      ...state,
      isFetching: false,
      laws: payload
    };
  case FETCH_LEGISLATION_DETAIL_REQUEST:
    return {
      ...state,
      isFetching: true,
      lawDetails: payload
    };
  case FETCH_LEGISLATION_DETAIL_SUCCESS:
    return {
      ...state,
      isFetching: false,
      lawDetails: payload
    };
  case FETCH_LEGISLATION_DETAIL_FAILURE:
    return {
      ...state,
      isFetching: false,
      lawDetails: payload
    };

  case FETCH_LEGISLATION_CATEGORY_REQUEST:
    return {
      ...state,
      isFetching: true,
      categories: payload
    };
  case FETCH_LEGISLATION_CATEGORY_SUCCESS:
    return {
      ...state,
      isFetching: false,
      categories: payload
    };
  case FETCH_LEGISLATION_CATEGORY_FAILURE:
    return {
      ...state,
      isFetching: false,
      categories: payload
    };

  case REMOVE_SUCCESS_MESSAGE:
    return {
      ...state,
      success:false
    };
  default:
    return state
  }
}
