/**
 * @description all endpoints (APIs)
 */

// authentication
export const LOGIN_API = `${process.env.REACT_APP_BASE_URL}/oauth/`;
export const SIGN_UP_API = `${process.env.REACT_APP_BASE_URL}/register/`;
export const LOGOUT_API = `${process.env.REACT_APP_BASE_URL}/logout`;
export const FETCH_USERS_API = `${process.env.REACT_APP_BASE_URL}/user`;

// objects
export const OBJECT_API = `${process.env.REACT_APP_BASE_URL}/object/`;
export const OBJECT_API_BY_ID = (id) => `${process.env.REACT_APP_BASE_URL}/object/${id}`;
export const OBJECT_CATEGORY_API = `${process.env.REACT_APP_BASE_URL}/object/category/`;
export const OBJECT_ZONE_API = `${process.env.REACT_APP_BASE_URL}/object/zone`;
export const OBJECT_TYPE_API_BY_ID = (id) => `${process.env.REACT_APP_BASE_URL}/object/type/${id}`;

// activity category
export const ACTIVITY_CATEGORY_API = `${process.env.REACT_APP_BASE_URL}/object/activity/category`;

// file
export const FILE_API = (type) => `${process.env.REACT_APP_BASE_URL}/upload/${type}`;
export const FILE_API_DELETE = (type) => `${process.env.REACT_APP_BASE_URL}/file/delete/${type}`;

// banner
export const BANNER_API = `${process.env.REACT_APP_BASE_URL}/banner`;

// tasks
export const TASK_API = `${process.env.REACT_APP_BASE_URL}/task/`;
export const TASK_API_BY_ID = (id) => `${process.env.REACT_APP_BASE_URL}/task/${id}`;

// appeal
export const APPEAL_API = `${process.env.REACT_APP_BASE_URL}/appeal/`;
export const APPEAL_API_BY_ID = (id) => `${process.env.REACT_APP_BASE_URL}/appeal/${id}`;
export const APPEAL_API_CATEGORY = `${process.env.REACT_APP_BASE_URL}/appeal/category/`;

// status
export const STATUS_API = `${process.env.REACT_APP_BASE_URL}/task/status/`;

// representative
export const REPRESENTATIVE_API = `${process.env.REACT_APP_BASE_URL}/representative/note`;
export const REPRESENTATIVE_API_BY_ID = (id) => `${process.env.REACT_APP_BASE_URL}/representative/note/${id}`;

// normative acts
export const NORMATIVE_API = `${process.env.REACT_APP_BASE_URL}/normative/act`;
export const NORMATIVE_API_BY_ID = (id) => `${process.env.REACT_APP_BASE_URL}/normative/act${id}`;

// forward
export const FORWARD_APPEAL_API = `${process.env.REACT_APP_BASE_URL}/appeal/forward`;
// accept appeal
export const ACCEPT_APPEAL_API = (id) => `${process.env.REACT_APP_BASE_URL}/appeal/accept/${id}`;


// done appeal;
export const DONE_APPEAL_API = (id) => `${process.env.REACT_APP_BASE_URL}/appeal/done/${id}`;

// menus
export const MENU_API = `${process.env.REACT_APP_BASE_URL}/menu`;

// comments
export const APPEAL_COMMENTS_API = `${process.env.REACT_APP_BASE_URL}/appeal/comment`;

// forward task
export const FORWARD_TASK_API = `${process.env.REACT_APP_BASE_URL}/task/forward`;

// accept task
export const ACCEPT_TASK_API = (id) => `${process.env.REACT_APP_BASE_URL}/task/accept/${id}`;
// done task
export const DONE_TASK_API = (id) => `${process.env.REACT_APP_BASE_URL}/task/done/${id}`;

// comments
export const TASK_COMMENTS_API = `${process.env.REACT_APP_BASE_URL}/task/comment`;

// Firebase refresh token
export const FCM_REFRESH_TOKEN_API = `${process.env.REACT_APP_BASE_URL}/user/fcm/token`;

// Read notification
export const FCM_READ_NOTIFICATION_API = (id) => `${process.env.REACT_APP_BASE_URL}/user/fcm/token/${id}`;

// Firebase clear notifications
export const FCM_CLEAR_NOTIFICATIONS = `${process.env.REACT_APP_BASE_URL}/user/notifications/clear`;

// useful
export const USEFUL_API = `${process.env.REACT_APP_BASE_URL}/statistics/useful`;

// transport
export const TRANSPORT_API = `${process.env.REACT_APP_BASE_URL}/useful?definition=transport`;
export const TRANSPORT_API_BY_ID = (id) => `${process.env.REACT_APP_BASE_URL}/useful/${id}`;

// vacancy
export const VACANCY_API = `${process.env.REACT_APP_BASE_URL}/vacancy`;

export const VACANCY_API_DETAIL = (id) => `${process.env.REACT_APP_BASE_URL}/vacancy/${id}`;

export const VACANCY_CATEGORY_API = `${process.env.REACT_APP_BASE_URL}/vacancy/categories`;

// resumes
export const RESUME_API = `${process.env.REACT_APP_BASE_URL}/job/searching`;

export const RESUME_API_BY_ID = (id) => `${process.env.REACT_APP_BASE_URL}/job/searching/${id}`;

export const RESUME_API_DETAIL = (id) => `${process.env.REACT_APP_BASE_URL}/job/searching/${id}`;

// get all cities
export const CITY_API = `${process.env.REACT_APP_BASE_URL}/city`;

// get all experiences
export const EXPERIENCE_API = `${process.env.REACT_APP_BASE_URL}/experience`;

// get all educations
export const EDUCATION_API = `${process.env.REACT_APP_BASE_URL}/education`;

// get all statistics
export const STATISTIC_API = `${process.env.REACT_APP_BASE_URL}/statistics`;

// statistic detail
export const STATISTIC_API_BY_ID = (slug) => `${process.env.REACT_APP_BASE_URL}/statistics/${slug}`;

// get all legislations
export const LEGISLATION_API = `${process.env.REACT_APP_BASE_URL}/legislation`;
export const LEGISLATION_EDIT_API = id => `${process.env.REACT_APP_BASE_URL}/legislation/${id}`;
export const LEGISLATION_API_CATEGORY = `${process.env.REACT_APP_BASE_URL}/legislation/categories`;
export const LEGISLATION_API_DETAIL = id => `${process.env.REACT_APP_BASE_URL}/legislation/${id}`;

// get about
export const ABOUT_API = `${process.env.REACT_APP_BASE_URL}/about/district`;

// update about
export const ABOUT_EDIT_API = (id) => `${process.env.REACT_APP_BASE_URL}/about/district/${id}`;

// events
export const EVENT_API = `${process.env.REACT_APP_BASE_URL}/event`;
export const EVENT_API_BY_ID = (id) => `${process.env.REACT_APP_BASE_URL}/event/${id}`;

// Karabakh
export const KARABAKH_CATEGORY_API = `${process.env.REACT_APP_BASE_URL}/karabakh/category/`;

export const KARABAKH_MUNICIPALITIES_API = `${process.env.REACT_APP_BASE_URL}/karabakh/municipalities/`;

export const KARABAKH_VISIT_API = `${process.env.REACT_APP_BASE_URL}/karabakh/visit/`;
export const KARABAKH_VISIT_API_BY_ID = (id) => `${process.env.REACT_APP_BASE_URL}/karabakh/visit/${id}`;

export const KARABAKH_HERO_API = `${process.env.REACT_APP_BASE_URL}/karabakh/hero/`;
export const KARABAKH_HERO_API_BY_ID = (id) => `${process.env.REACT_APP_BASE_URL}/karabakh/hero/${id}`;
export const KARABAKH_HERO_API_BY_TYPE = (type) => `${process.env.REACT_APP_BASE_URL}/karabakh/hero/type/${type}`;

export const KARABAKH_ABOUT_API = `${process.env.REACT_APP_BASE_URL}/karabakh/about/`;

export const KARABAKH_VICTORY_API = `${process.env.REACT_APP_BASE_URL}/karabakh/victory/`;

export const KARABAKH_ORGANIZATION_API = `${process.env.REACT_APP_BASE_URL}/karabakh/organization/`;
export const MUNICIPALITIES_LIST_API = `${process.env.REACT_APP_BASE_URL}/karabakh/municipalityNews/`;
export const MUNICIPALITIES_SHOW_API = `${process.env.REACT_APP_BASE_URL}/karabakh/municipalityNews/show/`;
export const MUNICIPALITIES_POST_API = `${process.env.REACT_APP_BASE_URL}/karabakh/municipalityNews/`;
export const MUNICIPALITIES_UPDATE_API = `${process.env.REACT_APP_BASE_URL}/karabakh/municipalityNews/`;
export const MUNICIPALITIES_DELETE_API = `${process.env.REACT_APP_BASE_URL}/karabakh/municipalityNews/delete/`;
export const KARABAKH_ORGANIZATION_API_BY_ID = (id) => `${process.env.REACT_APP_BASE_URL}/karabakh/organization/${id}`;

