import React, {Component} from 'react';
import {connect} from 'react-redux';
import ButtonLoader from '../../loader/ButtonLoader';
import {legislationFetchCategories} from '../../../redux/actions/legislationActions';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

/**
 * @description LawForm component
 */
class EventForm extends Component {
  state = {
    started_date: '',
    end_date: ''
  }
  componentDidMount() {
    this.props.fetchLegislationCategories();
  }

  handleStartDate = date => {
    this.setState({
      start_date: date
    });
    this.props.formProps.setFieldValue('start', date)
  };

  handleEndDate = date => {
    this.setState({
      end_date: date
    });
    this.props.formProps.setFieldValue('end', date)
  };

  render() {
    const {values, handleBlur, handleChange, handleSubmit, touched, errors} = this.props.formProps;
    return (
      <section className="whitebox-body">
        <div className="add-form">
          <div className="row">

            <div className="col-lg-10">
              <div className="form-group form-group-custom">
                <label htmlFor="title">Başlıq</label>
                <input
                  type="text"
                  name="title"
                  className='form-control form-control-custom'
                  placeholder="başlıq"
                  min="2"
                  maxLength="255"
                  value={values.title}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                <p className="error">
                  {(this.props.formProps.events && this.props.formProps.events.error && this.props.formProps.events.data.title)
                    ? this.props.formProps.events.data.title.map(error => <span key={error}>{error}</span>)
                    : null}

                  {(touched.title && errors.title)
                    ? <span>{errors.title}</span>
                    : null}
                </p>
              </div>

              <div className="form-group form-group-custom">
                <label htmlFor="place">Yer</label>
                <input
                  type="text"
                  name="place"
                  className='form-control form-control-custom'
                  placeholder="yer"
                  min="2"
                  value={values.place}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                <p className="error">
                  {(this.props.formProps.events && this.props.formProps.events.error && this.props.formProps.events.data.place)
                    ? this.props.formProps.events.data.place.map(error => <span key={error}>{error}</span>)
                    : null}

                  {(touched.place && errors.place)
                    ? <span>{errors.place}</span>
                    : null}
                </p>
              </div>

              <div className="form-group form-group-custom">
                <label htmlFor="note">Qeyd</label>
                <textarea
                  name="note"
                  className={'form-control form-control-custom '}
                  placeholder="Qeyd"
                  value={values.note}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                <p className="error">
                  {(this.props.formProps.events && this.props.formProps.events.error && this.props.formProps.events.data.note)
                    ? this.props.formProps.events.data.note.map(error => <span key={error}>{error}</span>)
                    : null}

                  {(touched.note && errors.note)
                    ? <span>{errors.note}</span>
                    : null}
                </p>
              </div>

              <div className="row">

                <div className="form-group col-md-6">
                  <DatePicker
                    name="start"
                    autoComplete="off"
                    selected={values.start}
                    onChange={(date) => this.handleStartDate(date)}
                    showMonthDropdown
                    showTimeSelect
                    timeFormat="HH:mm"
                    timeIntervals={15}
                    timeCaption="time"
                    dateFormat="MMMM d, yyyy h:mm aa"
                    className={'form-control form-control-custom'}
                    placeholderText="Tədbirin başlanma tarixi"
                    onBlur={handleBlur}
                  />
                  <p className="error">
                    {(this.props.formProps.events && this.props.formProps.events.error && this.props.formProps.events.data.start)
                      ? this.props.formProps.events.data.start.map(error => <span key={error}>{error}</span>)
                      : null}

                    {(touched.start && errors.start)
                      ? <span>{errors.start}</span>
                      : null}
                  </p>
                </div>

                <div className="form-group col-md-6">
                  <DatePicker
                    name="end"
                    autoComplete="off"
                    selected={values.end}
                    onChange={(date) => this.handleEndDate(date)}
                    showMonthDropdown
                    showTimeSelect
                    timeFormat="HH:mm"
                    timeIntervals={15}
                    timeCaption="time"
                    dateFormat="MMMM d, yyyy h:mm aa"
                    className={'form-control form-control-custom'}
                    placeholderText="Tədbirin bitmə tarixi"
                    onBlur={handleBlur}
                  />
                  <p className="error">
                    {(this.props.formProps.events && this.props.formProps.events.error && this.props.formProps.events.data.end)
                      ? this.props.formProps.events.data.end.map(error => <span key={error}>{error}</span>)
                      : null}

                    {(touched.end && errors.end)
                      ? <span>{errors.end}</span>
                      : null}
                  </p>
                </div>

              </div>
            </div>

            <div className="col-12">
              <div className="row">
                <div className="col-lg-6 col-xl-4">
                  <div className="form-group form-group-custom">
                    <button
                      type="button"
                      onClick={() => handleSubmit(values)}
                      className="btn btn-theme w-100"
                      disabled={this.props.isFetching ? true : null}
                    >
                      {this.props.isFetching ? <ButtonLoader /> : <span>Yadda saxla</span>}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  fetchLegislationCategories: () => dispatch(legislationFetchCategories())
});

const mapStateToProps = state => {
  return state.legislationReducer;
};

export default connect(mapStateToProps, mapDispatchToProps)(EventForm);

